import * as React from 'react';
import { type FunctionComponent, useEffect, useState } from 'react';

import { formatDuration, testAttribute } from '../../../util/Util';
import { Button } from '../../../lib/Button';
import { downloadExecutedActions } from '../../../services/ApiService';
import { ExecutionFilter } from '../../../interfaces/Interfaces';
import { Spinner } from '@blueprintjs/core';
import { PageableDatasource } from '../../../lib/Table';
import { alertToast } from '../../Toast/AlertToast';
import i18n from '../../../i18n/i18n';
import { IconDownload } from '../../../icons/svg/IconDownload';

const BulkDownloadButton: FunctionComponent<{
  filter: ExecutionFilter;
  executionDatasource?: PageableDatasource<any>;
}> = ({ filter, executionDatasource }) => {
  const [loading, setLoading] = useState(false);
  const [timeEstimate, setTimeEstimate] = useState<string | null>('');
  const [abortController, setAbortController] = useState<AbortController | undefined>();

  useEffect(
    () => () => {
      // Stop download if the component is unmounted
      abortController?.abort();
    },
    [abortController],
  );

  function concatUint8Arrays(chunks: any) {
    let totalLength = chunks.reduce((sum: any, value: any) => sum + value.length, 0);
    let result = new Uint8Array(totalLength);

    let length = 0;
    for (let array of chunks) {
      result.set(array, length);
      length += array.length;
    }

    return result;
  }

  function hasExecutionDateFilter() {
    return filter.executedBefore !== undefined || filter.executedAfter !== undefined
  }

  const download = () => {
    setLoading(true);
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    downloadExecutedActions(filter, newAbortController)
      .then(async response => {
        if (!response.body) return;
        const reader = response.body.getReader();
        let receivedLength = 0;
        let chunks = [];
        let totalRows = 0;
        const startTime = Date.now();

        // Download the actual csv here
        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            break;
          }

          chunks.push(value);
          receivedLength += value.length;
          const rows = new TextDecoder('utf-8').decode(value).split('\n').length;
          totalRows += rows;

          // Calculate remaining time estimate
          const elapsedTime = (Date.now() - startTime) / 1000; // Time in seconds
          const averageTimePerRow = elapsedTime / totalRows;
          const estimatedTotalTime = averageTimePerRow * (executionDatasource?.totalItemCount ?? 1);
          const estimatedRemainingTime = estimatedTotalTime - elapsedTime;
          if (estimatedRemainingTime > 10) {
            setTimeEstimate(formatDuration(estimatedRemainingTime));
          } else {
            setTimeEstimate(null);
          }
        }

        let csvContent = new TextDecoder('utf-8').decode(concatUint8Arrays(chunks));

        // Create a blob from the CSV content
        let blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a link and trigger a download
        let downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'downloaded_file.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        alertToast(err);
      });
  };

  if (loading)
    return (
      <Button type={'tertiary'} testId={testAttribute('dnlw', 'action-page.downloading')} icon={<Spinner size={10} />}>
        {i18n.t('action-page.downloading')} {timeEstimate && <>({timeEstimate})</>}
      </Button>
    );

  return (
    <Button
      disabled={!hasExecutionDateFilter()}
      onClick={download}
      type={'tertiary'}
      icon={<IconDownload />}
      testId={testAttribute('dnlw', 'action-page.downloading')}
      tooltip={hasExecutionDateFilter() ? i18n.t('action-page.download') : i18n.t('action-page.download-disabled-explanation')}
    />
  );
};

export { BulkDownloadButton };
