import { type FunctionComponent, useMemo, useState } from 'react';

import { Card } from '@blueprintjs/core';
import { SearchTemplate } from '@blueprintjs/icons';

import ArchivedRuleSetDialog from 'components/Dialog/ArchivedRuleSetDialog/ArchivedRuleSetDialog';
import i18n from 'i18n/i18n';
import { type ArchivedRuleSet } from 'interfaces/Interfaces';
import { Button } from 'lib/Button';
import { type ColumnDefinition, ColumnModifier, SingleFetchDatasource } from 'lib/Table';
import FilteredTable from 'lib/Table/FilteredTable';
import { TextFilterInput } from 'lib/Table/TableFilter/Filters/TextFilterInput';
import TableRow from 'lib/Table/TableRow';
import { type Filter, FilterType } from 'lib/Table/types/FilterModel';
import { getArchivedRuleSets } from 'services/ApiService';
import { testAttribute } from 'util/Util';

import './RulesetArchive.style.sass';

const RuleSetArchivePage: FunctionComponent = () => {
  const [displayedRuleSet, setDisplayedRuleSet] = useState<ArchivedRuleSet | undefined>();

  function openArchivedRuleSetDialog(item: ArchivedRuleSet) {
    setDisplayedRuleSet(item);
  }

  const cols: Array<ColumnDefinition<ArchivedRuleSet>> = useMemo(
    () => [
      {
        accessor: item => item.archived,
        renderer: value => typeof value === 'string' && new Date(value).toLocaleDateString(i18n.language),
        headerName: i18n.t('archive.table.archive'),
        sortable: true,
      },
      {
        accessor: item => item.archivedBy,
        headerName: i18n.t('archive.table.archived_by'),
        sortable: true,
      },
      {
        accessor: item => item.name,
        headerName: i18n.t('archive.table.name'),
        modifiers: [ColumnModifier.FILL],
        sortable: true,
      },
      {
        accessor: item => item.id,
        renderer: (id, item) => (
          <Button
            onClick={() => openArchivedRuleSetDialog(item)}
            testId={testAttribute('rsad', 'search-template', item.name)}
            type="tertiary"
            icon={<SearchTemplate />}
          />
        ),
        headerName: '',
        modifiers: [ColumnModifier.FIT_CONTENT],
      },
    ],
    [],
  );

  const filter: Filter[] = [
    {
      key: 'name',
      name: i18n.t('archive.table.name'),
      render: props => <TextFilterInput {...props} />,
      type: FilterType.DYNAMIC,
    },
    {
      key: 'archivedBy',
      name: i18n.t('archive.table.archived_by'),
      render: props => <TextFilterInput {...props} />,
      type: FilterType.DYNAMIC,
    },
  ];

  return (
    <div className="archive-wrapper">
      <ArchivedRuleSetDialog
        onClose={() => setDisplayedRuleSet(undefined)}
        open={!!displayedRuleSet}
        ruleset={displayedRuleSet}
      />
      <div className="title">{i18n.t('title_archive')}</div>
      <Card className="ruleset-archive card">
        <FilteredTable
          sortable
          i18n={{
            addFilter: i18n.t('archive.filter.add'),
            entries: i18n.t('archive.filter.entries'),
            entry: i18n.t('archive.filter.entry'),
            resetFilters: i18n.t('archive.filter.reset'),
            search: i18n.t('archive.filter.search'),
            searchResult: i18n.t('archive.filter.search-result'),
          }}
          columns={cols}
          dataSource={new SingleFetchDatasource(getArchivedRuleSets)}
          filter={filter}
          noData={<div>{i18n.t('archive.table.no-data')}</div>}
          noVisibleData={<div>{i18n.t('archive.table.no-visible-data')}</div>}
          rowRenderer={(item, columns, idx) => (
            <TableRow
              key={idx}
              columns={columns}
              rowItem={item}
              data-test-id={testAttribute('rsai', 'table.row', item.name)}
            />
          )}
        />
      </Card>
    </div>
  );
};

export default RuleSetArchivePage;
