/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CaseConditionDto } from './CaseConditionDto';
import {
    CaseConditionDtoFromJSON,
    CaseConditionDtoFromJSONTyped,
    CaseConditionDtoToJSON,
} from './CaseConditionDto';
import type { CustomerCaseConditionDto } from './CustomerCaseConditionDto';
import {
    CustomerCaseConditionDtoFromJSON,
    CustomerCaseConditionDtoFromJSONTyped,
    CustomerCaseConditionDtoToJSON,
} from './CustomerCaseConditionDto';

/**
 * 
 * @export
 * @interface RuleSetAssignmentCriteriaDto
 */
export interface RuleSetAssignmentCriteriaDto {
    /**
     * 
     * @type {Array<CaseConditionDto>}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    caseConditions: Array<CaseConditionDto>;
    /**
     * 
     * @type {Array<CustomerCaseConditionDto>}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    customerCaseConditions: Array<CustomerCaseConditionDto>;
    /**
     * 
     * @type {Array<object>}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    actions?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    ruleSetId?: string;
    /**
     * 
     * @type {Date}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    lastEditor?: string;
}

/**
 * Check if a given object implements the RuleSetAssignmentCriteriaDto interface.
 */
export function instanceOfRuleSetAssignmentCriteriaDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "caseConditions" in value;
    isInstance = isInstance && "customerCaseConditions" in value;

    return isInstance;
}

export function RuleSetAssignmentCriteriaDtoFromJSON(json: any): RuleSetAssignmentCriteriaDto {
    return RuleSetAssignmentCriteriaDtoFromJSONTyped(json, false);
}

export function RuleSetAssignmentCriteriaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetAssignmentCriteriaDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caseConditions': ((json['caseConditions'] as Array<any>).map(CaseConditionDtoFromJSON)),
        'customerCaseConditions': ((json['customerCaseConditions'] as Array<any>).map(CustomerCaseConditionDtoFromJSON)),
        'actions': !exists(json, 'actions') ? undefined : json['actions'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ruleSetId': !exists(json, 'ruleSetId') ? undefined : json['ruleSetId'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'lastEditor': !exists(json, 'lastEditor') ? undefined : json['lastEditor'],
    };
}

export function RuleSetAssignmentCriteriaDtoToJSON(value?: RuleSetAssignmentCriteriaDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caseConditions': ((value.caseConditions as Array<any>).map(CaseConditionDtoToJSON)),
        'customerCaseConditions': ((value.customerCaseConditions as Array<any>).map(CustomerCaseConditionDtoToJSON)),
        'actions': value.actions,
        'id': value.id,
        'ruleSetId': value.ruleSetId,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'lastEditor': value.lastEditor,
    };
}

