import React, { type FunctionComponent } from 'react';

import { Icon } from '@blueprintjs/core';
import { DoubleCaretVertical, SortAsc, SortDesc } from '@blueprintjs/icons';

import { type ColumnDefinition } from './types/ColumnDefinition';
import { SortDirection } from './types/SortState';

interface TableHeaderCellProps<T> {
  column: ColumnDefinition<T>;
  direction?: SortDirection;
  sortBy?: (col: ColumnDefinition<T>) => void;
}

export const TableHeaderCell: FunctionComponent<TableHeaderCellProps<any>> = ({
  column,
  direction = SortDirection.NONE,
  sortBy,
}) => {
  function getIcon() {
    if (direction === SortDirection.NONE) return <DoubleCaretVertical />;
    if (direction === SortDirection.ASC) return <SortAsc />;
    if (direction === SortDirection.DESC) return <SortDesc />;
  }

  return (
    <th className="lib-table__table-cell lib-table__table-cell--header">
      {column.sortable && <Icon onClick={() => sortBy?.(column)} className="filter-icon" icon={getIcon()} />}
      {column.headerName}
    </th>
  );
};

export default TableHeaderCell;
