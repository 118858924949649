export const IconMigrate = () => (
  <span className="pc-icon">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.646446 4.1464C0.451184 4.34167 0.451185 4.65825 0.646447 4.85351C0.841709 5.04877 1.15829 5.04877 1.35355 4.85351L4 2.20706L4 13.5C4 13.7761 4.22386 14 4.5 14C4.77614 14 5 13.7761 5 13.5L5 2.20706L7.64645 4.85351C7.84171 5.04877 8.15829 5.04877 8.35355 4.85351C8.54882 4.65825 8.54882 4.34167 8.35355 4.1464L4.5 0.292847L0.646446 4.1464Z"
        fill="#030307"
      />
      <path
        d="M15.3536 11.1464C15.5488 11.3417 15.5488 11.6582 15.3536 11.8535L11.5 15.7071L7.64645 11.8535C7.45118 11.6582 7.45118 11.3417 7.64645 11.1464C7.84171 10.9511 8.15829 10.9511 8.35355 11.1464L11 13.7929L11 2.49996C11 2.22381 11.2239 1.99996 11.5 1.99996C11.7761 1.99996 12 2.22382 12 2.49996L12 13.7929L14.6464 11.1464C14.8417 10.9511 15.1583 10.9511 15.3536 11.1464Z"
        fill="#030307"
      />
    </svg>
  </span>
);
