import { RuleSetForReact, RuleTemplate } from '../../../../../interfaces/Interfaces';
import { ValidationResult } from '../../../../DunningSelectionPage/DunningSelection/input/Validators';

export function validateRuleNameIsSet(
  ruleEdit: RuleTemplate,
  ruleSet: RuleSetForReact,
): ValidationResult | null {
  if (!ruleEdit?.name || ruleEdit.name.length === 0) {
    return { error: true, message: 'dialog.name-mandatory' };
  }
  return null;
}
