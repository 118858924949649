/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeDunningLevelRequestDto
 */
export interface ChangeDunningLevelRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeDunningLevelRequestDto
     */
    levelName: string;
    /**
     * 
     * @type {Date}
     * @memberof ChangeDunningLevelRequestDto
     */
    skipExecutionUntil: Date;
}

/**
 * Check if a given object implements the ChangeDunningLevelRequestDto interface.
 */
export function instanceOfChangeDunningLevelRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "levelName" in value;
    isInstance = isInstance && "skipExecutionUntil" in value;

    return isInstance;
}

export function ChangeDunningLevelRequestDtoFromJSON(json: any): ChangeDunningLevelRequestDto {
    return ChangeDunningLevelRequestDtoFromJSONTyped(json, false);
}

export function ChangeDunningLevelRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeDunningLevelRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'levelName': json['levelName'],
        'skipExecutionUntil': (new Date(json['skipExecutionUntil'])),
    };
}

export function ChangeDunningLevelRequestDtoToJSON(value?: ChangeDunningLevelRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'levelName': value.levelName,
        'skipExecutionUntil': (value.skipExecutionUntil.toISOString().substring(0,10)),
    };
}

