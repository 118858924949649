import { RuleDefinitionValue } from '../../RuleDefinition/RuleCondition/RuleInputs/RuleDefinitionBaseProps';
import {
  validateHasEmojiPresentation,
  validateHasForbiddenPunctuation,
  validateIsEmptyString,
} from '../../../../../util/Util';

export const isSanitizedProvider = (message: string) => (value: RuleDefinitionValue) => {
  const unwrapped = value.toValue();
  if (
    validateHasEmojiPresentation(unwrapped) ||
    validateHasForbiddenPunctuation(unwrapped) ||
    validateIsEmptyString(unwrapped)
  ) {
    return {
      error: true,
      message: message,
    };
  }
  return null;
};
