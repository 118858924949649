import React, { type FunctionComponent } from 'react';

import { Checkbox } from '../../../../../../lib/Checkbox';
import { RuleDefinitionBaseProps, RuleDefinitionBooleanValue, useValidation } from './RuleDefinitionBaseProps';

interface RuleDefinitionIntegerProps extends RuleDefinitionBaseProps {}

const RuleDefinitionBooleanInput: FunctionComponent<RuleDefinitionIntegerProps> = ({
  value,
  onChange,
  label,
  isEditable,
  validators,
}) => {
  const [validatedOnChange, error] = useValidation(onChange, value, validators);

  if (!value || !(value instanceof RuleDefinitionBooleanValue)) return <></>;

  return (
    <>
      <span className="condition-text">{label}</span>
      <Checkbox
        disabled={!isEditable}
        isChecked={value.toValue()}
        onClick={() => validatedOnChange(new RuleDefinitionBooleanValue(!value.toValue()))}
      />
    </>
  );
};

export default RuleDefinitionBooleanInput;
