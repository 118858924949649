/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BasicRuleSetDto } from './BasicRuleSetDto';
import {
    BasicRuleSetDtoFromJSON,
    BasicRuleSetDtoFromJSONTyped,
    BasicRuleSetDtoToJSON,
} from './BasicRuleSetDto';

/**
 * 
 * @export
 * @interface RuleSetListDto
 */
export interface RuleSetListDto {
    /**
     * 
     * @type {Array<BasicRuleSetDto>}
     * @memberof RuleSetListDto
     */
    ruleSets: Array<BasicRuleSetDto>;
}

/**
 * Check if a given object implements the RuleSetListDto interface.
 */
export function instanceOfRuleSetListDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ruleSets" in value;

    return isInstance;
}

export function RuleSetListDtoFromJSON(json: any): RuleSetListDto {
    return RuleSetListDtoFromJSONTyped(json, false);
}

export function RuleSetListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ruleSets': ((json['ruleSets'] as Array<any>).map(BasicRuleSetDtoFromJSON)),
    };
}

export function RuleSetListDtoToJSON(value?: RuleSetListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ruleSets': ((value.ruleSets as Array<any>).map(BasicRuleSetDtoToJSON)),
    };
}

