import { type ReactNode } from 'react';

export enum ColumnModifier {
  FIT_CONTENT,
  FILL,
  SMALL,
  CENTERED,
}

export interface ColumnDefinition<T, G = number | string | boolean> {
  accessor: (rowItem: T) => G;
  renderer?: (value: G, item: T) => ReactNode;
  headerName: string;
  sortable?: boolean;
  modifiers?: ColumnModifier[];
}
