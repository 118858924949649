/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    detail?: string;
    /**
     * 
     * @type {number}
     * @memberof ErrorResponse
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    traceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    errorType?: ErrorResponseErrorTypeEnum;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof ErrorResponse
     */
    additionalInformation?: Array<{ [key: string]: string; }>;
}


/**
 * @export
 */
export const ErrorResponseErrorTypeEnum = {
    ClientError: 'CLIENT_ERROR',
    ServerError: 'SERVER_ERROR'
} as const;
export type ErrorResponseErrorTypeEnum = typeof ErrorResponseErrorTypeEnum[keyof typeof ErrorResponseErrorTypeEnum];


/**
 * Check if a given object implements the ErrorResponse interface.
 */
export function instanceOfErrorResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ErrorResponseFromJSON(json: any): ErrorResponse {
    return ErrorResponseFromJSONTyped(json, false);
}

export function ErrorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'detail': !exists(json, 'detail') ? undefined : json['detail'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'traceId': !exists(json, 'traceId') ? undefined : json['traceId'],
        'errorType': !exists(json, 'errorType') ? undefined : json['errorType'],
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
    };
}

export function ErrorResponseToJSON(value?: ErrorResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'detail': value.detail,
        'status': value.status,
        'title': value.title,
        'traceId': value.traceId,
        'errorType': value.errorType,
        'additionalInformation': value.additionalInformation,
    };
}

