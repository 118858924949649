import { type FunctionComponent } from 'react';

import { Button as BpButton, MenuItem } from '@blueprintjs/core';
import { CaretDown } from '@blueprintjs/icons';
import { type ItemRenderer, Select } from '@blueprintjs/select';

import i18n from 'i18n/i18n';
import { Checkbox } from 'lib/Checkbox';

interface Selectable {
  key: string;
  label: string;
}

interface FormMultiSelectInputProps {
  value: string[];
  selectables: Selectable[];
  onChange: (value: string[]) => void;
  label?: string;
}

const ExecutionFilterStateSelection: FunctionComponent<FormMultiSelectInputProps> = props => {
  async function onAddOrRemoveHandler(val: Selectable) {
    const isSelected = props.value.includes(val.key);
    let newValues;
    if (isSelected) {
      newValues = props.value.filter(v => v !== val.key);
    } else {
      newValues = [...props.value, val.key];
    }
    if (props.onChange) props.onChange(newValues);
  }

  const selectionListRenderer: ItemRenderer<Selectable> = item => {
    return (
      <MenuItem
        key={item.key}
        onClick={ev => {
          ev.stopPropagation();
          onAddOrRemoveHandler(item);
        }}
        text={<Checkbox isChecked={props.value.includes(item.key)} label={item.label} onClick={() => {}} />}
      />
    );
  };

  function getDisplayText() {
    if (props.value.length === props.selectables.length) return i18n.t('action-page.executionStatus.all');
    if (props.value.length === 0) return i18n.t('action-page.executionStatus.none');

    const selectedSelectables = props.value?.map(v => props.selectables.find(selectable => selectable.key === v)) ?? [];
    return selectedSelectables.map(v => v?.label).join(', ');
  }

  return (
    <>
      <span className="lib-table-filter__label">{props.label}</span>

      <Select
        itemRenderer={selectionListRenderer}
        items={props.selectables}
        onItemSelect={event => onAddOrRemoveHandler(event)}
        noResults={<div>No results</div>}
        fill
        filterable={false}>
        <BpButton className="execution-state-filter" outlined rightIcon={<CaretDown />}>
          {getDisplayText()}
        </BpButton>
      </Select>
    </>
  );
};

export { ExecutionFilterStateSelection };
