import React, { type FunctionComponent } from 'react';

import { Plus } from '@blueprintjs/icons';

import i18n from 'i18n/i18n';
import { type ParamForReact } from 'interfaces/Interfaces';
import { Button } from 'lib/Button';
import { testAttribute } from 'util/Util';

import ParamEdit from './ParamEdit';

interface ParametersProps {
  params: ParamForReact[];
  isParamAndLevelEditable: boolean;
  usedParams: ParamForReact[];
  onParamChange: (param: ParamForReact) => void;
  onDeleteParam: (id: string) => void;
  showUniqueParamWarning: boolean;
  onAddParam: () => void;
}

const RuleSetParameters: FunctionComponent<ParametersProps> = (props: ParametersProps) => {
  return (
    <>
      <div className="dialog-title-2">{i18n.t('dialog.params')}</div>

      {props.isParamAndLevelEditable && (
        <div className="padding-bottom">{i18n.t('dialog.ruleset.create-param-long')}</div>
      )}

      {props.params.length <= 0 && i18n.t('dialog.no-params')}
      {props.params.length !== 0 && (
        <div
          className={
            props.isParamAndLevelEditable
              ? 'param-grid padding-bottom dunning-bold-title'
              : 'param-grid-view padding-bottom dunning-bold-title'
          }>
          <div>{i18n.t('dialog.ruleset.param')}</div>
          {props.isParamAndLevelEditable && <div />}

          <div>{i18n.t('dialog.ruleset.value')}</div>
        </div>
      )}

      {props.params?.map((param: ParamForReact) => {
        return (
          <ParamEdit
            key={param.reactId}
            param={param}
            isEditable={props.isParamAndLevelEditable}
            isInUse={!!props.usedParams.find(usedParam => usedParam.name === param.name)}
            onParamChange={param => props.onParamChange(param)}
            onDeleteParam={param => props.onDeleteParam(param)}
          />
        );
      })}

      {props.isParamAndLevelEditable && (
        <div className="right-text">
          <Button
            testId={testAttribute('kJuT', 'dialog.ruleset.add-param')}
            type="tertiary"
            id="add-icon"
            icon={<Plus />}
            onClick={() => props.onAddParam()}>
            {i18n.t('dialog.ruleset.add-param')}
          </Button>
        </div>
      )}

      {props.showUniqueParamWarning && <div className="red-text">{i18n.t('dialog.dupilcate-name-warning')}</div>}
    </>
  );
};

export default RuleSetParameters;
