import { type FunctionComponent, useState } from 'react';

import { FormGroup, InputGroup } from '@blueprintjs/core';

import i18n from 'i18n/i18n';

import { type BaseInputProps } from './InputInterfaces';
import { type ValidationResult } from './Validators';
import { handleNumberOnly, testAttribute } from '../../../../util/Util';

interface FormNumericInputProps extends BaseInputProps<number> {
  emptyValue?: number;
}

const FormNumericInput: FunctionComponent<FormNumericInputProps> = ({ emptyValue = -1, ...props }) => {
  const [value, setValue] = useState(props.value ?? emptyValue);
  const [error, setError] = useState<ValidationResult | null>(null);

  async function validateInput(input: number) {
    if (!props.validators) return null;
    const validationResult = await Promise.all(props.validators.map(validator => validator(input)));
    const firstError = validationResult.find(result => result?.error);
    if (firstError) {
      setError(firstError);
      return true;
    }
    setError(null);
    return false;
  }

  async function onChangeHandler(val: string) {
    const newValue = val?.length ? Number.parseFloat(val) : emptyValue;
    setValue(newValue);
    const hasError = await validateInput(newValue);
    if (props.onChange) props.onChange(newValue, !hasError);
  }

  return (
    <FormGroup
      disabled={props.disabled}
      label={
        props.required ? <span className="required-field">{i18n.t(props.label ?? '')}</span> : i18n.t(props.label ?? '')
      }
      labelFor={props.name}
      helperText={error ? <span className="red-text">{i18n.t(error.message ?? '')}</span> : undefined}>
      <InputGroup
        data-test-id={testAttribute('fni1', 'form-numeric.input')}
        disabled={props.disabled}
        placeholder={i18n.t(props.placeholder ?? '')}
        value={value === emptyValue ? '' : value.toString()}
        type="number"
        fill
        onChange={(event: any) => onChangeHandler(event.target.value)}
        onKeyDown={e => handleNumberOnly(e)}
        intent={error ? 'danger' : undefined}
      />
    </FormGroup>
  );
};

export default FormNumericInput;
