export const IconCheck = () => (
  <span className="pc-icon">
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3536 3.64645C14.5488 3.84171 14.5488 4.15829 14.3536 4.35355L6 12.7071L1.64645 8.35355C1.45118 8.15829 1.45118 7.84171 1.64645 7.64645C1.84171 7.45118 2.15829 7.45118 2.35355 7.64645L6 11.2929L13.6464 3.64645C13.8417 3.45118 14.1583 3.45118 14.3536 3.64645Z"
      />
    </svg>
  </span>
);
