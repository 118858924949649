import { type FunctionComponent } from 'react';

import { Tag as PcTag } from '@blueprintjs/core';

import './Tag.style.sass';

interface TagProps {
  value?: string;
  onRemove?: () => void;
}

const Tag: FunctionComponent<TagProps> = ({ value, onRemove, children }) => {
  return <PcTag onRemove={onRemove}>{value ?? children}</PcTag>;
};

export { Tag };
