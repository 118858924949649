import React from 'react';

import { ACTION_TYPE, CASE_ATTRIBUTE, ITEM_ATTRIBUTE, JWT_ROLES } from 'interfaces/enums';
import {
  Admin,
  Clerk,
  type DunningJWTPayload,
  type DunningLevel,
  type DunningLevelForReact,
  Editor,
  type JWT,
  type ParamForReact,
  type RuleForReact,
  type StringConst,
  SuperAdmin,
  type User,
} from 'interfaces/Interfaces';

import { ValidationResult } from '../components/DunningSelectionPage/DunningSelection/input/Validators';
import { getLanguageFractionalSeparator, getLanguageThousandsSeparator } from '../i18n/i18n';

export type UUID = string;

export type GROUP = string;
export type CONDITION = string;
export type INPUT = string;
export type ValidationObject = Record<GROUP, Record<CONDITION, Record<INPUT, ValidationResult[] | undefined>>>;

export function validateRuleObject(validations: ValidationObject | undefined): boolean {
  if (validations) {
    const flattenedInputValidations = Object.keys(validations)
      .map(key => {
        return validations[key];
      })
      .flatMap(group => {
        return Object.keys(group).flatMap(condition =>
          Object.keys(group[condition])
            .flatMap(inputIdx => group[condition][inputIdx])
            .filter(a => !!a),
        );
      });
    if (flattenedInputValidations.length > 0) return false;
  }
  return true;
}

export function validateHasForbiddenPunctuation(value: string): boolean {
  return !/^[^$+<=>^`"'\\|~]*$/.test(value);
}

export function validateHasEmojiPresentation(value: string): boolean {
  return !!/\p{Emoji_Presentation}/gu.test(value);
}

export function validateIsInteger(value: string): boolean {
  return !!/^[0-9]*$/.test(value);
}

export function validateIsEmptyString(value: string | number | undefined): boolean {
  return value === undefined || value.toString().trim() === '';
}

export function validateNumberIsGreaterOrEqualWithZero(value: string | number | undefined): boolean {
  return !(value === undefined || Number.isNaN(value) || value >= 0);
}

export function validateIsDecimal(value: string): boolean {
  return /^[0-9]*[\.|\,]?[0-9]*$/.test(value) && !Number.isNaN(parseFloat(value));
}

export function validateIsPositiveCurrency(value: string): boolean {
  return /^(?!0+$)(\d{1,3}(,\d{3})*|\d+)(\.\d{1,2})?$/.test(value);
}

export function validateIsString(value: string): boolean {
  return !validateIsInteger(value) && !validateIsDecimal(value);
}

export function countDunningLevelsUsageInRules(rules: RuleForReact[], dunningLevels: DunningLevelForReact[]) {
  const result: Record<string, number> = {};
  dunningLevels.forEach(dl => (result[dl.reactId] = countDunningLevelUsageInRules(rules, dl)));
  return result;
}

export function countDunningLevelUsageInRules(rules: RuleForReact[], dunningLevel: DunningLevel) {
  const rulesUsingDunningLevel = rules.filter(rule => {
    // In case of newly created dunning level, the name is empty.
    // Since we reference the dunning levels by name for now, we can't
    // differentiate between initial level and newly created. Here we just assume,
    // newly created rule sets are never used in any rule
    if (dunningLevel.name === '' && dunningLevel.level !== 0) return false;

    const groupSelectorsWithDunningLevel = rule.groupSelectors
      .filter(gs => gs.attrib == ITEM_ATTRIBUTE.LEVEL)
      .map(gs => gs.argument as StringConst)
      .filter(arg => arg.val === dunningLevel.name);

    const actionsWithDunningLevel = rule.actions
      .filter(action => action['@type'] === ACTION_TYPE.CHANGE_OF_STATUS)
      .filter(action => action.newStatus === dunningLevel.name);

    return actionsWithDunningLevel.length > 0 || groupSelectorsWithDunningLevel.length > 0;
  });
  return rulesUsingDunningLevel.length;
}

// TODO: Should be fixed on ticket JDS-843
export function resetNewDunningLevelIds(rules: RuleForReact[], dunningLevel: DunningLevelForReact[]) {
  rules.forEach(rule => {
    rule.actions
      .filter(action => action['@type'] === ACTION_TYPE.CHANGE_OF_STATUS)
      .forEach(action => {
        const levelId = generateUUID();
        action.newStatusId = levelId;
        const dunningLevelFounded = dunningLevel.find(level => level.name === action.newStatus);
        if (dunningLevelFounded != undefined) dunningLevelFounded.id = levelId;
      });
  });
  dunningLevel.forEach(level => {
    if (level.id === '') level.id = generateUUID();
  });
}

export function getParameterUsedInRules(rules: RuleForReact[], parameters: ParamForReact[]): ParamForReact[] {
  const foundParams: ParamForReact[] = [];

  rules.forEach(rule => {
    for (const caseCondition of rule.caseConditions) {
      if (caseCondition.argument['@type'] === 'NumericParam') {
        const { paramName } = caseCondition.argument;
        const foundParam = parameters.find(param => param.name === paramName);
        foundParam && foundParams.push(foundParam);
      }
    }
    for (const customerCaseCondition of rule.customerCaseConditions) {
      if (customerCaseCondition.argument['@type'] === 'NumericParam') {
        const { paramName } = customerCaseCondition.argument;
        const foundParam = parameters.find(param => param.name === paramName);
        foundParam && foundParams.push(foundParam);
      }
    }
    for (const groupSelector of rule.groupSelectors) {
      if (groupSelector.argument['@type'] === 'NumericParam') {
        const { paramName } = groupSelector.argument;
        const foundParam = parameters.find(param => param.name === paramName);
        foundParam && foundParams.push(foundParam);
      }
    }

    for (const aggCondition of rule.aggregConditionsInAllGroups) {
      if (aggCondition.argument['@type'] === 'NumericParam') {
        const { paramName } = aggCondition.argument;
        const foundParam = parameters.find(param => param.name === paramName);
        foundParam && foundParams.push(foundParam);
      }
    }

    for (const aggCondition of rule.aggregConditionsInThisGroup) {
      if (aggCondition.argument['@type'] === 'NumericParam') {
        const { paramName } = aggCondition.argument;
        const foundParam = parameters.find(param => param.name === paramName);
        foundParam && foundParams.push(foundParam);
      }
    }
  });

  return foundParams;
}

export function removeUndefined<T>(obj: T): T {
  for (const k in obj) if (obj[k] === undefined) delete obj[k];
  return obj;
}

export function replaceElementInArrayByReactId<T extends { reactId: string }>(array: T[], replacementElement: T) {
  const newArr = [...array];
  const index = array.findIndex(x => x.reactId === replacementElement.reactId);
  newArr[index] = replacementElement;
  return newArr;
}

export function decodeJWT<T>(jwt: string): JWT<T> {
  const [b64Header, b64Payload, b64Signature] = jwt.split('.');
  const [header, payload, signature] = [b64Header, b64Payload]
    .map(part => JSON.parse(window.atob(part)))
    .concat(b64Signature);
  return {
    header,
    payload,
    signature,
  };
}

export function getCurrentUser(defaultUser: User) {
  const jwtCRMIToken: any = (window as any).JwtForDunning;
  if (!jwtCRMIToken) return defaultUser;
  try {
    const { payload } = decodeJWT<DunningJWTPayload>(jwtCRMIToken);
    if (payload.roles.includes(JWT_ROLES.SUPER_ADMIN)) {
      return new SuperAdmin();
    }
    if (payload.roles.includes(JWT_ROLES.ADMIN)) {
      return new Admin();
    }
    if (payload.roles.includes(JWT_ROLES.EDITOR)) {
      return new Editor();
    }
    if (payload.roles.includes(JWT_ROLES.CLERK)) {
      return new Clerk();
    }
  } catch (e) {
    return defaultUser;
  }
  return defaultUser;
}

export function getFileName(disposition?: string, defaultName = 'export.csv'): string {
  if (!disposition) return defaultName;
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  let fileName: string | null = null;
  if (utf8FilenameRegex.test(disposition)) {
    const regexResult = utf8FilenameRegex.exec(disposition);
    if (regexResult) fileName = decodeURIComponent(regexResult[1] ?? '');
  } else {
    const filenameStart = disposition.toLowerCase().indexOf('filename=');
    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart);
      const matches = asciiFilenameRegex.exec(partialDisposition);
      if (matches != null && matches[2]) {
        fileName = matches[2];
      }
    }
  }
  return fileName ?? defaultName;
}

function slugify(text: string) {
  return text
    .toString()
    .normalize('NFKD')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\_/g, '-')
    .replace(/\-\-+/g, '-')
    .replace(/\-$/g, '');
}

export function testAttribute(identifier: string, translationKey: string, additionalInfo?: string) {
  if (!identifier.match(/^[a-zA-Z0-9]{4}$/)) {
    throw new Error('Malformated unique idenfier: it should fiz [a-zA-Z0-9]{4}');
  }
  if (!translationKey.match(/^[A-Za-z0-9\.-]+$/)) {
    throw new Error('Malformated translation key: it should fiz [a-zA-Z0-9]{4}');
  }
  return `${identifier}-${translationKey}${additionalInfo ? `-${slugify(additionalInfo)}` : ''}`;
}

export function generateUUID() {
  return crypto.randomUUID();
}

export const disabledCaseAttributes: CASE_ATTRIBUTE[] = [
  CASE_ATTRIBUTE.CREDIT_SCORE,
  CASE_ATTRIBUTE.FORECAST_ANNUAL_CONSUMPTION,
  CASE_ATTRIBUTE.PRODUCT_CODE,
];

export const isCaseAttributeDisabled = (condition: CASE_ATTRIBUTE | '') => {
  if (condition === '') return false;
  return disabledCaseAttributes.includes(condition);
};

export const generateClassNames = (...args: Array<string | boolean | null | undefined>): string =>
  args
    .filter(argument => {
      if (typeof argument === 'string') {
        return argument.trim();
      }
      return false;
    })
    .join(' ');

export const getCurrentDate = () => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return currentDate;
};

export function toFormattedDateString(dateInput: Date | null | undefined): string | null {
  if (dateInput === null || dateInput === undefined) {
    return null;
  }
  return `${dateInput.getFullYear()}-${String(dateInput.getMonth() + 1).padStart(2, '0')}-${String(
    dateInput.getDate(),
  ).padStart(2, '0')}`;
}

export const toDateStringWithoutTimeZone = (date: Date) => {
  if (date === null || date === undefined) {
    return null;
  }
  const offsetInMillis = date.getTimezoneOffset() * 60000;
  return new Date(date.valueOf() - offsetInMillis).toISOString().split('T')[0];
};

export function formatDuration(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  let result = ``;
  if (hours) result += `${hours}h `;
  if (minutes) result += `${minutes}m `;
  if (remainingSeconds) result += `${remainingSeconds}s`;
  return result;
}

/**
 * Higher order function to wrap a given function func. Caches the results according to the arguments used.
 * @param func
 */
export function cacheResults<T extends (...args: any[]) => any>(func: T) {
  const cacheMap = new Map<string, any>();

  return function (...args: any[]) {
    const key = JSON.stringify(args);
    if (cacheMap.has(key)) {
      return cacheMap.get(key);
    }
    const result = func(...args);
    cacheMap.set(key, result);
    return result;
  };
}

export function showCurrencyInput(attrib: ITEM_ATTRIBUTE | ''): boolean {
  return (
    attrib === ITEM_ATTRIBUTE.OPEN_AMOUNT ||
    attrib === ITEM_ATTRIBUTE.GROSS_AMOUNT ||
    attrib === ITEM_ATTRIBUTE.SETTLED_AMOUNT
  );
}

export function isNumericValue(input: string | undefined) {
  return input ? !isNaN(parseFloat(removeCurrencySign(input))) : false;
}

export function removeCurrencySign(input: string | undefined) {
  if (!input) {
    return '';
  }
  return Object(input) instanceof String && input.includes('€') ? input.replace(/[^\d.,]/g, '') : input;
}

export function handleNumberOnly(e: React.KeyboardEvent) {
  if (['e', 'E', '+', '-'].includes(e.key)) {
    e.preventDefault();
  }
}

/**
 * Removes thousands separators from a numeric string.
 * @param {string} [value] The numeric string with optional thousand separators.
 * @returns {string} The numeric string without thousands separators.
 */
export function removeThousandsSeparator(value?: string) {
  if (!value) return '';
  if (!validateIsPositiveCurrency(value)) return value;
  return value.replace(/,/g, '');
}

/**
 * Swaps custom language separators to default separators in a string.
 *
 * @param value The string possibly containing custom language separators.
 * @returns The string with custom language separators swapped to default separators.
 */
export function swapToDefaultSeparators(value: string): string {
  const fractionalSeparator = getLanguageFractionalSeparator().replace(/[.]/g, '\\$&');
  if (!value || fractionalSeparator === '.') return value;
  const thousandSeparator = getLanguageThousandsSeparator().replace(/[.]/g, '\\$&');

  return value
    .replace(new RegExp(`${thousandSeparator}`, 'g'), '_')
    .replace(new RegExp(`${fractionalSeparator}`, 'g'), '.')
    .replace(/_/g, ',');
}

/**
 * convert a string to Pascal case / "CUSTOMER_GROUP" => "CustomerGroup"
 * @param str
 */
export const toPascalCase = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/(_\w)/g, match => match[1].toUpperCase())
    .replace(/^./, match => match.toUpperCase());
};

export const getServerUrl = () =>
  (sessionStorage.getItem('url') ?? (window as any).FlexibleDunningServiceApiUrl) || process.env.REACT_APP_URL || '';
