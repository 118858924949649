import * as React from 'react';

import i18n, { getLanguageWithRegionTag } from 'i18n/i18n';
import { CONTRACT_TARGET_TYPE, EXECUTION_STATUS, YES_NO_SELECTION } from 'interfaces/enums';

import { ContractStateFilter, ContractStateOverview } from '../../generated-sources/openapi';
import { AbstractRuleSet, DunningLevelForReact } from '../../interfaces/Interfaces';
import { ColumnDefinition, ColumnModifier, type Filter, FilterType } from '../../lib/Table';
import { NumberFilterInput } from '../../lib/Table/TableFilter/Filters/NumberFilterInput';
import {
  ExecutionFilterDateRangeInput,
  ExecutionFilterMultiSelectInput,
  ExecutionFilterStateSelection,
} from '../DunningActionPage/ExecutionFilters';
import ExecutionStateIndicator from '../DunningActionPage/ExecutionStateIndicator/ExecutionStateIndicator';
import FormSelectInput from '../DunningSelectionPage/DunningSelection/input/FormSelectInput';
import { type Selectable } from '../DunningSelectionPage/DunningSelection/input/InputInterfaces';

import './ContractStatePage.style.sass';
import {LinkRenderer} from "../../lib/LinkRenderer";

export const defaultExecutionFilterStates = [
  EXECUTION_STATUS.SUCCESS,
  EXECUTION_STATUS.FAILED,
  EXECUTION_STATUS.PENDING,
  EXECUTION_STATUS.RETRYING,
  EXECUTION_STATUS.CANCELED,
];

export const contractTargetTypeSelections: Selectable[] = [
  {
    key: CONTRACT_TARGET_TYPE.ALL,
    label: i18n.t('dunning-selection.all'),
  },
  {
    key: CONTRACT_TARGET_TYPE.FINALLY_INVOICED,
    label: i18n.t('contract-state.finally-invoiced'),
  },
  {
    key: CONTRACT_TARGET_TYPE.CUSTOMIZED,
    label: i18n.t('contract-state.customized'),
  },
  {
    key: CONTRACT_TARGET_TYPE.ONGOING,
    label: i18n.t('contract-state.ongoing'),
  },
];

export const executionStatusSelections: Selectable[] = [
  {
    key: EXECUTION_STATUS.FAILED,
    label: i18n.t('action-page.filters.executionState.FAILED'),
  },
  {
    key: EXECUTION_STATUS.SUCCESS,
    label: i18n.t('action-page.filters.executionState.SUCCESSFUL'),
  },
  {
    key: EXECUTION_STATUS.PENDING,
    label: i18n.t('action-page.filters.executionState.PENDING'),
  },
  {
    key: EXECUTION_STATUS.RETRYING,
    label: i18n.t('action-page.filters.executionState.RETRYING'),
  },
  {
    key: EXECUTION_STATUS.CANCELED,
    label: i18n.t('action-page.filters.executionState.CANCELED'),
  },
];

export const skipExecutionSelections: Selectable[] = [
  {
    key: YES_NO_SELECTION.ALL,
    label: i18n.t('dunning-selection.all'),
  },
  {
    key: YES_NO_SELECTION.TRUE,
    label: i18n.t('select.true'),
  },
  {
    key: YES_NO_SELECTION.FALSE,
    label: i18n.t('select.false'),
  },
];

export function getSelectedFilters(filters?: Record<string, any>): ContractStateFilter {
  if (!filters) return {};
  return {
    lastExecutionStates: filters.executionState,
    contractId: filters.contractId,
    lastExecutionAfter: filters.lastExecutionDate
      ? filters.lastExecutionDate[0].toISOString().split('T')[0]
      : undefined,
    lastExecutionBefore: filters.lastExecutionDate
      ? filters.lastExecutionDate[1].toISOString().split('T')[0]
      : undefined,
    lastLetterSentAfter: filters.lastLetterSent ? filters.lastLetterSent[0].toISOString().split('T')[0] : undefined,
    lastLetterSentBefore: filters.lastLetterSent ? filters.lastLetterSent[1].toISOString().split('T')[0] : undefined,
    skipExecutionUntilAfter: filters.skipExecutionUntil
      ? filters.skipExecutionUntil[0].toISOString().split('T')[0]
      : undefined,
    skipExecutionUntilBefore: filters.skipExecutionUntil
      ? filters.skipExecutionUntil[1].toISOString().split('T')[0]
      : undefined,
    customerId: filters.customerId,
    dunningLevels: filters.dunningLevel?.map((f: { label: string }) => f.label),
    lastExecutionRuleSetIds: filters.ruleSetName?.map((f: { key: string }) => f.key),
    isFinallyInvoiced: isFinallyInvoiced(filters.contractTargetType),
    isCustomized: isCustomizedSelected(filters.contractTargetType),
    skipExecution: isSkipExecutionSelected(filters.skipExecution),
  };
}

function isFinallyInvoiced(selectedValue: Selectable) {
  if (selectedValue === undefined || selectedValue.key === CONTRACT_TARGET_TYPE.ALL) {
    return undefined;
  }
  if (selectedValue.key === CONTRACT_TARGET_TYPE.FINALLY_INVOICED) {
    return true;
  }
  if (selectedValue.key === CONTRACT_TARGET_TYPE.ONGOING) {
    return false;
  }
  return undefined;
}

function isCustomizedSelected(selectedValue: Selectable) {
  if (selectedValue === undefined || selectedValue.key === CONTRACT_TARGET_TYPE.ALL) {
    return undefined;
  }
  return selectedValue.key === CONTRACT_TARGET_TYPE.CUSTOMIZED;
}

function isSkipExecutionSelected(selectedValue: Selectable) {
  if (selectedValue === undefined || selectedValue.key === YES_NO_SELECTION.ALL) {
    return undefined;
  }
  return selectedValue.key === YES_NO_SELECTION.TRUE;
}

export function getBooleanValue(value: boolean | undefined): string {
  return value ?? value === undefined ? i18n.t('select.true') : i18n.t('select.false');
}

export function getContractTargetTypeColumnString(
  isFinalized: boolean | undefined,
  isCustomized: boolean | undefined,
): string {
  if (isCustomized ?? isCustomized !== undefined) {
    return i18n.t('contract-state.customized');
  }
  return isFinalized ?? isFinalized !== undefined
    ? i18n.t('contract-state.finally-invoiced')
    : i18n.t('contract-state.ongoing');
}

export function getColumnDefinition(): Array<ColumnDefinition<ContractStateOverview>> {
  return [
    {
      accessor: item => (item.customerId ? item.customerId : 0),
      headerName: i18n.t('action-page.customer-id'),
    },
    {
      accessor: item => (item.contractId ? item.contractId : 0),
      headerName: i18n.t('action-page.contract-id'),
      renderer: value =>
        value !== 0 ? (
          <LinkRenderer
            items={value.toString()}
            baseUrl={contractId => `${window.location.origin}/customers:contracts/details/${contractId}#account`}
          />
        ) : (
          '-'
        ),
    },
    {
      accessor: item => (item.activeRuleSetName ? item.activeRuleSetName : ''),
      headerName: i18n.t('contract-state.last-executed-procedure'),
    },
    {
      accessor: item => (item.lastExecutionState ? item.lastExecutionState : ''),
      headerName: i18n.t('contract-state.last-execution-state'),
      modifiers: [ColumnModifier.CENTERED],
      renderer: (value, item) => <ExecutionStateIndicator state={item.lastExecutionState} />,
    },
    {
      accessor: item =>
        item.lastExecutionTime ? new Date(item.lastExecutionTime).toLocaleString(getLanguageWithRegionTag()) : '',
      headerName: i18n.t('contract-state.last-execution-date'),
    },
    {
      accessor: item => (item.dunningLevel ? item.dunningLevel : ''),
      headerName: i18n.t('contract-state.highest-dunning-level'),
      modifiers: [ColumnModifier.CENTERED],
    },
    {
      accessor: item =>
        item.lastLetterSent ? new Date(item.lastLetterSent).toLocaleString(getLanguageWithRegionTag()) : '',
      headerName: i18n.t('contract-state.last-letter-sent'),
    },
    {
      accessor: item => getBooleanValue(item.isFinallyInvoiced),
      headerName: i18n.t('contract-state.finally-invoiced'),
    },
    {
      accessor: item => getContractTargetTypeColumnString(item.isFinallyInvoiced, item.isCustomized),
      headerName: i18n.t('contract-state.contract-target-type'),
    },
    {
      accessor: item => getBooleanValue(item.skipExecution),
      headerName: i18n.t('contract-state.skip-execution'),
    },
    {
      accessor: item =>
        item.skipExecutionUntil ? new Date(item.skipExecutionUntil).toLocaleString(getLanguageWithRegionTag()) : '-',
      headerName: i18n.t('contract-state.skip-execution-until'),
    },
  ];
}

export function getFilter(
  availableRuleSetNames: AbstractRuleSet[],
  availableDunningLevels: DunningLevelForReact[],
): Filter[] {
  return [
    {
      key: 'contractId',
      name: i18n.t('action-page.contract-id'),
      render: props => <NumberFilterInput {...props} />,
      type: FilterType.DYNAMIC,
    },
    {
      key: 'customerId',
      name: i18n.t('action-page.customer-id'),
      render: props => <NumberFilterInput {...props} />,
      type: FilterType.DYNAMIC,
    },
    {
      key: 'ruleSetName',
      name: i18n.t('contract-state.last-executed-procedure'),
      render: props => (
        <ExecutionFilterMultiSelectInput
          {...props}
          selectables={availableRuleSetNames.map(ruleSet => ({
            key: ruleSet.rulesetId,
            label: ruleSet.name,
          }))}
          createNewItem
        />
      ),
      valueRenderer: value => value.map((s: Selectable) => s.label).join(', '),
      type: FilterType.DYNAMIC,
    },
    {
      key: 'lastExecutionDate',
      name: i18n.t('contract-state.last-execution-date'),
      render: props => <ExecutionFilterDateRangeInput {...props} />,
      valueRenderer: value =>
        `${value[0].toLocaleDateString(i18n.language)} - ${value[1].toLocaleDateString(i18n.language)}`,
      type: FilterType.DYNAMIC,
    },
    {
      key: 'dunningLevel',
      name: i18n.t('contract-state.highest-dunning-level'),
      render: props => (
        <ExecutionFilterMultiSelectInput
          {...props}
          selectables={availableDunningLevels.map(dunningLevel => ({
            key: dunningLevel.id,
            label: dunningLevel.name,
          }))}
          createNewItem
        />
      ),
      valueRenderer: value => value.map((s: Selectable) => s.label).join(', '),
      type: FilterType.DYNAMIC,
    },
    {
      key: 'contractTargetType',
      name: i18n.t('contract-state.contract-target-type'),
      render: props => <FormSelectInput {...props} selectables={contractTargetTypeSelections} />,
      valueRenderer: value => value.label,
      type: FilterType.DYNAMIC,
    },
    {
      key: 'lastLetterSent',
      name: i18n.t('contract-state.last-letter-sent'),
      render: props => <ExecutionFilterDateRangeInput {...props} />,
      valueRenderer: value =>
        `${value[0].toLocaleDateString(i18n.language)} - ${value[1].toLocaleDateString(i18n.language)}`,
      type: FilterType.DYNAMIC,
    },
    {
      key: 'skipExecution',
      name: i18n.t('contract-state.skip-execution'),
      render: props => <FormSelectInput {...props} selectables={skipExecutionSelections} />,
      valueRenderer: value => value.label,
      type: FilterType.DYNAMIC,
    },
    {
      key: 'skipExecutionUntil',
      name: i18n.t('contract-state.skip-execution-until'),
      render: props => <ExecutionFilterDateRangeInput {...props} />,
      valueRenderer: value =>
        `${value[0].toLocaleDateString(i18n.language)} - ${value[1].toLocaleDateString(i18n.language)}`,
      type: FilterType.DYNAMIC,
    },
    {
      key: 'executionState',
      name: i18n.t('action-page.execution-state'),
      render: props => (
        <ExecutionFilterStateSelection
          selectables={executionStatusSelections}
          {...props}
          value={props.value ?? defaultExecutionFilterStates}
        />
      ),
      valueRenderer: value => value.map((v: string) => i18n.t(`action-page.filters.executionState.${v}`)).join(', '),
      type: FilterType.FIXED,
    }
  ];
}
