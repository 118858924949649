import { FunctionComponent, useContext, useState } from 'react';

import { Plus } from '@blueprintjs/icons';
import { UserContext } from 'App';

import RuleSetDialog from 'components/Dialog/RuleSet/RuleSetDialog';
import { TABLE_TITLE } from 'interfaces/enums';
import { BasicRuleSet, RIGHTS } from 'interfaces/Interfaces';
import { Button } from 'lib/Button';
import { testAttribute } from 'util/Util';

import MenuPopover from './MenuPopover/MenuPopover';
import i18n from '../../i18n/i18n';

import './OverviewHeader.style.sass';

// TODO(JDS-473): Only used in DunningOverwie
// https://dev-docu.int.power.cloud/guidelines/languages-and-database/javascript/react-coding-guidelines#structure-and-naming

interface OverviewHeaderProps {
  activeHeaders: TABLE_TITLE[];
  inactiveHeaders: TABLE_TITLE[];
  numberOfDunningProcedures: number;
  onUpdateTable: (ruleSetId: string) => void;
  onUpdateHeader: (active: TABLE_TITLE[], inactive: TABLE_TITLE[]) => void;
  subsequentRuleSets: BasicRuleSet[];
}

const OverviewHeader: FunctionComponent<OverviewHeaderProps> = (props: OverviewHeaderProps) => {
  const userContext = useContext(UserContext);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  function handleNewDunningProcedure() {
    setDialogOpen(true);
  }

  return (
    <>
      <div className="flex">
        <div className="margin-right-auto">
          {i18n.t('overview.procedure', {
            count: props.numberOfDunningProcedures,
          })}
        </div>
        {userContext.rights.includes(RIGHTS.CREATE_RULESET) && (
          <Button
            testId={testAttribute('kjd3', 'dialog.ruleset.create')}
            type="tertiary"
            id="add-procedure"
            icon={<Plus />}
            onClick={() => handleNewDunningProcedure()}>
            {i18n.t('dialog.ruleset.create')}
          </Button>
        )}
        <div>
          <MenuPopover
            activeHeaders={props.activeHeaders}
            inactiveHeaders={props.inactiveHeaders}
            onUpdateHeader={(active, inactive) => props.onUpdateHeader(active, inactive)}
          />
        </div>
      </div>
      {dialogOpen && (
        <RuleSetDialog
          dialogTitle={i18n.t('dialog.ruleset.create')}
          subsequentRuleSets={props.subsequentRuleSets}
          buttonTextSuccess={i18n.t('button.create')}
          onCloseDialog={() => setDialogOpen(false)}
          onConfirmRuleSetChange={ruleSetId => props.onUpdateTable(ruleSetId)}
          isNameAndDescriptionEditable
          isParamAndLevelEditable
        />
      )}
    </>
  );
};

export default OverviewHeader;
