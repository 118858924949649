import { type ColumnDefinition } from './ColumnDefinition';

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE',
}

export interface SortState {
  column: ColumnDefinition<any>;
  direction: SortDirection;
}
