/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ArchivedRuleSetDto,
  ErrorResponse,
  RuleAdviceDto,
  RuleCreateDto,
  RuleDescriptionUpdateDto,
  RuleDto,
  RuleNameUpdateDto,
  RuleOrderDto,
  RuleSetDto,
  RuleSetFilterDto,
  RuleSetInfoDto,
  RuleSetListDto,
  RuleSetUpdateDto,
  RuleUpdateDto,
} from '../models/index';
import {
    ArchivedRuleSetDtoFromJSON,
    ArchivedRuleSetDtoToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    RuleAdviceDtoFromJSON,
    RuleAdviceDtoToJSON,
    RuleCreateDtoFromJSON,
    RuleCreateDtoToJSON,
    RuleDescriptionUpdateDtoFromJSON,
    RuleDescriptionUpdateDtoToJSON,
    RuleDtoFromJSON,
    RuleDtoToJSON,
    RuleNameUpdateDtoFromJSON,
    RuleNameUpdateDtoToJSON,
    RuleOrderDtoFromJSON,
    RuleOrderDtoToJSON,
    RuleSetDtoFromJSON,
    RuleSetDtoToJSON,
    RuleSetFilterDtoFromJSON,
    RuleSetFilterDtoToJSON,
    RuleSetInfoDtoFromJSON,
    RuleSetInfoDtoToJSON,
    RuleSetListDtoFromJSON,
    RuleSetListDtoToJSON,
    RuleSetUpdateDtoFromJSON,
    RuleSetUpdateDtoToJSON,
    RuleUpdateDtoFromJSON,
    RuleUpdateDtoToJSON,
} from '../models/index';

export interface DeleteRequest {
    rulesetId: string;
}

export interface AddRuleRequest {
    rulesetId: string;
    ruleCreateDto?: RuleCreateDto;
}

export interface ArchiveRequest {
    rulesetId: string;
}

export interface CheckForAdvicesRequest {
    ruleDto?: RuleDto;
}

export interface CreateRequest {
    ruleSetDto?: RuleSetDto;
}

export interface DeleteRuleRequest {
    rulesetId: string;
    ruleId: string;
}

export interface GetByRuleSetIdRequest {
    rulesetId: string;
}

export interface GetRuleRequest {
    rulesetId: string;
    ruleId: string;
}

export interface GetRuleSetsRequest {
    ruleSetFilterDto?: RuleSetFilterDto;
}

export interface MakeRuleSetActiveRequest {
    rulesetId: string;
}

export interface OrderRulesRequest {
    rulesetId: string;
    ruleOrderDto: RuleOrderDto;
}

export interface UpdateInfoRequest {
    rulesetId: string;
    ruleSetUpdateDto: RuleSetUpdateDto;
}

export interface UpdateRuleRequest {
    rulesetId: string;
    ruleId: string;
    ruleUpdateDto: RuleUpdateDto;
}

export interface UpdateRuleDescriptionRequest {
    rulesetId: string;
    ruleId: string;
    ruleDescriptionUpdateDto: RuleDescriptionUpdateDto;
}

export interface UpdateRuleNameRequest {
    rulesetId: string;
    ruleId: string;
    ruleNameUpdateDto: RuleNameUpdateDto;
}

export interface UpdateRuleSetRequest {
    rulesetId: string;
    ruleSetUpdateDto: RuleSetUpdateDto;
}

/**
 * 
 */
export class RulesetApi extends runtime.BaseAPI {

    /**
     * Remove a ruleset
     * Remove
     */
    async _deleteRaw(requestParameters: DeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.rulesetId === null || requestParameters.rulesetId === undefined) {
            throw new runtime.RequiredError('rulesetId','Required parameter requestParameters.rulesetId was null or undefined when calling _delete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ruleset/{rulesetId}`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters.rulesetId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a ruleset
     * Remove
     */
    async _delete(requestParameters: DeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this._deleteRaw(requestParameters, initOverrides);
    }

    /**
     * Add a new empty rule to a ruleset
     * Create Rule
     */
    async addRuleRaw(requestParameters: AddRuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleDto>> {
        if (requestParameters.rulesetId === null || requestParameters.rulesetId === undefined) {
            throw new runtime.RequiredError('rulesetId','Required parameter requestParameters.rulesetId was null or undefined when calling addRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ruleset/{rulesetId}/rule`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters.rulesetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RuleCreateDtoToJSON(requestParameters.ruleCreateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleDtoFromJSON(jsonValue));
    }

    /**
     * Add a new empty rule to a ruleset
     * Create Rule
     */
    async addRule(requestParameters: AddRuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleDto> {
        const response = await this.addRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Changes the status of a ruleset to archived.
     * Archive
     */
    async archiveRaw(requestParameters: ArchiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArchivedRuleSetDto>> {
        if (requestParameters.rulesetId === null || requestParameters.rulesetId === undefined) {
            throw new runtime.RequiredError('rulesetId','Required parameter requestParameters.rulesetId was null or undefined when calling archive.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ruleset/{rulesetId}/archive`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters.rulesetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArchivedRuleSetDtoFromJSON(jsonValue));
    }

    /**
     * Changes the status of a ruleset to archived.
     * Archive
     */
    async archive(requestParameters: ArchiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArchivedRuleSetDto> {
        const response = await this.archiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches advices for the user on a given ruleset
     * Get advices for ruleset
     */
    async checkForAdvicesRaw(requestParameters: CheckForAdvicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RuleAdviceDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ruleset/rule/advices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RuleDtoToJSON(requestParameters.ruleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RuleAdviceDtoFromJSON));
    }

    /**
     * Fetches advices for the user on a given ruleset
     * Get advices for ruleset
     */
    async checkForAdvices(requestParameters: CheckForAdvicesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RuleAdviceDto>> {
        const response = await this.checkForAdvicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new (empty) ruleset
     * Create
     */
    async createRaw(requestParameters: CreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ruleset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RuleSetDtoToJSON(requestParameters.ruleSetDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetDtoFromJSON(jsonValue));
    }

    /**
     * Create a new (empty) ruleset
     * Create
     */
    async create(requestParameters: CreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetDto> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Rule
     */
    async deleteRuleRaw(requestParameters: DeleteRuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.rulesetId === null || requestParameters.rulesetId === undefined) {
            throw new runtime.RequiredError('rulesetId','Required parameter requestParameters.rulesetId was null or undefined when calling deleteRule.');
        }

        if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
            throw new runtime.RequiredError('ruleId','Required parameter requestParameters.ruleId was null or undefined when calling deleteRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ruleset/{rulesetId}/rule/{ruleId}`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters.rulesetId))).replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Rule
     */
    async deleteRule(requestParameters: DeleteRuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRuleRaw(requestParameters, initOverrides);
    }

    /**
     * In the service, a rule set can have successors when a contract goes from normal to final, a successor rule set is assigned to it in this case. 
     * Get all successor rule sets
     */
    async getAllSubsequentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ruleset/subsequent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetListDtoFromJSON(jsonValue));
    }

    /**
     * In the service, a rule set can have successors when a contract goes from normal to final, a successor rule set is assigned to it in this case. 
     * Get all successor rule sets
     */
    async getAllSubsequent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetListDto> {
        const response = await this.getAllSubsequentRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the archived rulesets.
     * Get archived Rulesets
     */
    async getArchivedRuleSetsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ArchivedRuleSetDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ruleset/archive`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ArchivedRuleSetDtoFromJSON));
    }

    /**
     * Retrieve the archived rulesets.
     * Get archived Rulesets
     */
    async getArchivedRuleSets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ArchivedRuleSetDto>> {
        const response = await this.getArchivedRuleSetsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a particular dunning ruleset
     * Get by ID
     */
    async getByRuleSetIdRaw(requestParameters: GetByRuleSetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetDto>> {
        if (requestParameters.rulesetId === null || requestParameters.rulesetId === undefined) {
            throw new runtime.RequiredError('rulesetId','Required parameter requestParameters.rulesetId was null or undefined when calling getByRuleSetId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ruleset/{rulesetId}`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters.rulesetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetDtoFromJSON(jsonValue));
    }

    /**
     * Get a particular dunning ruleset
     * Get by ID
     */
    async getByRuleSetId(requestParameters: GetByRuleSetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetDto> {
        const response = await this.getByRuleSetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a specific rule from a rule set
     * Get Rule
     */
    async getRuleRaw(requestParameters: GetRuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleDto>> {
        if (requestParameters.rulesetId === null || requestParameters.rulesetId === undefined) {
            throw new runtime.RequiredError('rulesetId','Required parameter requestParameters.rulesetId was null or undefined when calling getRule.');
        }

        if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
            throw new runtime.RequiredError('ruleId','Required parameter requestParameters.ruleId was null or undefined when calling getRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ruleset/{rulesetId}/rule/{ruleId}`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters.rulesetId))).replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleDtoFromJSON(jsonValue));
    }

    /**
     * Get a specific rule from a rule set
     * Get Rule
     */
    async getRule(requestParameters: GetRuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleDto> {
        const response = await this.getRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all defined rulesets without any filter
     * Get All
     */
    async getRuleSetsRaw(requestParameters: GetRuleSetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetListDto>> {
        const queryParameters: any = {};

        if (requestParameters.ruleSetFilterDto !== undefined) {
            queryParameters['ruleSetFilterDto'] = requestParameters.ruleSetFilterDto;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ruleset`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetListDtoFromJSON(jsonValue));
    }

    /**
     * Get all defined rulesets without any filter
     * Get All
     */
    async getRuleSets(requestParameters: GetRuleSetsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetListDto> {
        const response = await this.getRuleSetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Changes the status of a ruleset to active.
     * Activate ruleset
     */
    async makeRuleSetActiveRaw(requestParameters: MakeRuleSetActiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetDto>> {
        if (requestParameters.rulesetId === null || requestParameters.rulesetId === undefined) {
            throw new runtime.RequiredError('rulesetId','Required parameter requestParameters.rulesetId was null or undefined when calling makeRuleSetActive.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ruleset/{rulesetId}/activate`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters.rulesetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetDtoFromJSON(jsonValue));
    }

    /**
     * Changes the status of a ruleset to active.
     * Activate ruleset
     */
    async makeRuleSetActive(requestParameters: MakeRuleSetActiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetDto> {
        const response = await this.makeRuleSetActiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Changes the order of the rules
     * Change Order
     */
    async orderRulesRaw(requestParameters: OrderRulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetDto>> {
        if (requestParameters.rulesetId === null || requestParameters.rulesetId === undefined) {
            throw new runtime.RequiredError('rulesetId','Required parameter requestParameters.rulesetId was null or undefined when calling orderRules.');
        }

        if (requestParameters.ruleOrderDto === null || requestParameters.ruleOrderDto === undefined) {
            throw new runtime.RequiredError('ruleOrderDto','Required parameter requestParameters.ruleOrderDto was null or undefined when calling orderRules.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ruleset/{rulesetId}/order`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters.rulesetId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RuleOrderDtoToJSON(requestParameters.ruleOrderDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetDtoFromJSON(jsonValue));
    }

    /**
     * Changes the order of the rules
     * Change Order
     */
    async orderRules(requestParameters: OrderRulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetDto> {
        const response = await this.orderRulesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a ruleset (only the attributes of the ruleset, in particular the name)
     * Update Info
     */
    async updateInfoRaw(requestParameters: UpdateInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetInfoDto>> {
        if (requestParameters.rulesetId === null || requestParameters.rulesetId === undefined) {
            throw new runtime.RequiredError('rulesetId','Required parameter requestParameters.rulesetId was null or undefined when calling updateInfo.');
        }

        if (requestParameters.ruleSetUpdateDto === null || requestParameters.ruleSetUpdateDto === undefined) {
            throw new runtime.RequiredError('ruleSetUpdateDto','Required parameter requestParameters.ruleSetUpdateDto was null or undefined when calling updateInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ruleset/{rulesetId}/info`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters.rulesetId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RuleSetUpdateDtoToJSON(requestParameters.ruleSetUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetInfoDtoFromJSON(jsonValue));
    }

    /**
     * Update a ruleset (only the attributes of the ruleset, in particular the name)
     * Update Info
     */
    async updateInfo(requestParameters: UpdateInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetInfoDto> {
        const response = await this.updateInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a rule
     * Update Rule
     */
    async updateRuleRaw(requestParameters: UpdateRuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleDto>> {
        if (requestParameters.rulesetId === null || requestParameters.rulesetId === undefined) {
            throw new runtime.RequiredError('rulesetId','Required parameter requestParameters.rulesetId was null or undefined when calling updateRule.');
        }

        if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
            throw new runtime.RequiredError('ruleId','Required parameter requestParameters.ruleId was null or undefined when calling updateRule.');
        }

        if (requestParameters.ruleUpdateDto === null || requestParameters.ruleUpdateDto === undefined) {
            throw new runtime.RequiredError('ruleUpdateDto','Required parameter requestParameters.ruleUpdateDto was null or undefined when calling updateRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ruleset/{rulesetId}/rule/{ruleId}`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters.rulesetId))).replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RuleUpdateDtoToJSON(requestParameters.ruleUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleDtoFromJSON(jsonValue));
    }

    /**
     * Update a rule
     * Update Rule
     */
    async updateRule(requestParameters: UpdateRuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleDto> {
        const response = await this.updateRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sets the description of an active or archived rule
     * Update Description
     */
    async updateRuleDescriptionRaw(requestParameters: UpdateRuleDescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleDto>> {
        if (requestParameters.rulesetId === null || requestParameters.rulesetId === undefined) {
            throw new runtime.RequiredError('rulesetId','Required parameter requestParameters.rulesetId was null or undefined when calling updateRuleDescription.');
        }

        if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
            throw new runtime.RequiredError('ruleId','Required parameter requestParameters.ruleId was null or undefined when calling updateRuleDescription.');
        }

        if (requestParameters.ruleDescriptionUpdateDto === null || requestParameters.ruleDescriptionUpdateDto === undefined) {
            throw new runtime.RequiredError('ruleDescriptionUpdateDto','Required parameter requestParameters.ruleDescriptionUpdateDto was null or undefined when calling updateRuleDescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ruleset/{rulesetId}/rule/{ruleId}/description`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters.rulesetId))).replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RuleDescriptionUpdateDtoToJSON(requestParameters.ruleDescriptionUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleDtoFromJSON(jsonValue));
    }

    /**
     * Sets the description of an active or archived rule
     * Update Description
     */
    async updateRuleDescription(requestParameters: UpdateRuleDescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleDto> {
        const response = await this.updateRuleDescriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sets the name of an active or archived rule
     * Update Name
     */
    async updateRuleNameRaw(requestParameters: UpdateRuleNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleDto>> {
        if (requestParameters.rulesetId === null || requestParameters.rulesetId === undefined) {
            throw new runtime.RequiredError('rulesetId','Required parameter requestParameters.rulesetId was null or undefined when calling updateRuleName.');
        }

        if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
            throw new runtime.RequiredError('ruleId','Required parameter requestParameters.ruleId was null or undefined when calling updateRuleName.');
        }

        if (requestParameters.ruleNameUpdateDto === null || requestParameters.ruleNameUpdateDto === undefined) {
            throw new runtime.RequiredError('ruleNameUpdateDto','Required parameter requestParameters.ruleNameUpdateDto was null or undefined when calling updateRuleName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ruleset/{rulesetId}/rule/{ruleId}/name`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters.rulesetId))).replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RuleNameUpdateDtoToJSON(requestParameters.ruleNameUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleDtoFromJSON(jsonValue));
    }

    /**
     * Sets the name of an active or archived rule
     * Update Name
     */
    async updateRuleName(requestParameters: UpdateRuleNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleDto> {
        const response = await this.updateRuleNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the fields name, description, dunning levels and parameters of the given ruleset
     * Partial Update
     */
    async updateRuleSetRaw(requestParameters: UpdateRuleSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetDto>> {
        if (requestParameters.rulesetId === null || requestParameters.rulesetId === undefined) {
            throw new runtime.RequiredError('rulesetId','Required parameter requestParameters.rulesetId was null or undefined when calling updateRuleSet.');
        }

        if (requestParameters.ruleSetUpdateDto === null || requestParameters.ruleSetUpdateDto === undefined) {
            throw new runtime.RequiredError('ruleSetUpdateDto','Required parameter requestParameters.ruleSetUpdateDto was null or undefined when calling updateRuleSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ruleset/{rulesetId}`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters.rulesetId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: RuleSetUpdateDtoToJSON(requestParameters.ruleSetUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetDtoFromJSON(jsonValue));
    }

    /**
     * Update the fields name, description, dunning levels and parameters of the given ruleset
     * Partial Update
     */
    async updateRuleSet(requestParameters: UpdateRuleSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetDto> {
        const response = await this.updateRuleSetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
