import React, { FunctionComponent, useState } from 'react';

import { Icon, InputGroup, Tooltip } from '@blueprintjs/core';
import { DragHandleVertical, InfoSign, Trash } from '@blueprintjs/icons';

import i18n from 'i18n/i18n';
import { DunningLevelForReact } from 'interfaces/Interfaces';
import { Button } from 'lib/Button';
import {
  testAttribute,
  validateHasEmojiPresentation,
  validateHasForbiddenPunctuation,
  validateIsEmptyString,
} from 'util/Util';

import { DunningLevelSelectInput } from './DunningLevelSelectInput';

interface DunningLevelEditProps {
  dunningLevel: DunningLevelForReact;
  isInitialDunningLevel?: boolean;
  isEditable: boolean;
  numberOfRulesLevelIsUsedIn: number;
  onDunningLevelChange: (dunningLevel: DunningLevelForReact) => void;
  onDeleteDunningLevel: (level: number) => void;
  dunningLevels: DunningLevelForReact[];
}

const DunningLevelEdit: FunctionComponent<DunningLevelEditProps> = (props: DunningLevelEditProps) => {
  const [showNameInvalidWarning, setShowNameInvalidWarning] = useState<boolean>(!props.dunningLevel.isValid);
  const [showDescriptionInvalidWarning, setShowDescriptionInvalidWarning] = useState<boolean>(false);

  function validateName(newName: string) {
    if (validateHasForbiddenPunctuation(newName) || validateHasEmojiPresentation(newName)) {
      setShowNameInvalidWarning(true);
      return false;
    }
    if (!props.isInitialDunningLevel && validateIsEmptyString(newName)) {
      setShowNameInvalidWarning(true);
      return false;
    }
    setShowNameInvalidWarning(false);
    return true;
  }

  function validateDescription(newDescription: string) {
    if (validateHasForbiddenPunctuation(newDescription) || validateHasEmojiPresentation(newDescription)) {
      setShowDescriptionInvalidWarning(true);
      return false;
    }
    setShowDescriptionInvalidWarning(false);
    return true;
  }

  function onDescriptionChange(newDescription: string) {
    const isDescriptionValid = validateDescription(newDescription);
    const newDunningLevel = props.dunningLevel;
    newDunningLevel.description = newDescription;
    newDunningLevel.isValid = isDescriptionValid && validateName(newDunningLevel.name);
    props.onDunningLevelChange(newDunningLevel);
  }

  function onDunningLevelChange(dunningLevel: DunningLevelForReact) {
    const newDunningLevel = dunningLevel;
    newDunningLevel.isValid = validateName(newDunningLevel.name) && validateDescription(newDunningLevel.description);
    props.onDunningLevelChange(newDunningLevel);
  }

  return (
    <div>
      <div hidden={!props.isEditable}>
        <div
          className="level-grid padding-bottom"
          data-test-id={testAttribute('dle2', 'level-edit', `${props.dunningLevel.level}`)}>
          <div draggable>
            <Tooltip disabled={!props.isInitialDunningLevel} content={i18n.t('dialog.ruleset.init-level-no-edit')}>
              <Icon icon={props.isInitialDunningLevel ? <InfoSign /> : <DragHandleVertical />} />
            </Tooltip>
          </div>
          <div>{props.dunningLevel.level}</div>
          <div className="dunning-level-select">
            <Tooltip
              disabled={props.numberOfRulesLevelIsUsedIn === 0}
              content={i18n.t('dialog.ruleset.tip-level-used')}>
              <DunningLevelSelectInput
                data-test-id={testAttribute('dlsi', 'dunning-level-edit.select-level')}
                isInitialDunningLevel={props.isInitialDunningLevel}
                disabled={props.isInitialDunningLevel ?? props.numberOfRulesLevelIsUsedIn !== 0}
                availableLevels={props.dunningLevels}
                selectedLevel={props.dunningLevel}
                onChange={dunningLevelSelect => onDunningLevelChange(dunningLevelSelect)}
              />
            </Tooltip>
          </div>
          <div>
            <InputGroup
              id={`${props.dunningLevel.level}-level-name`}
              className="full-width"
              placeholder={i18n.t('dialog.enter-description')}
              value={props.isInitialDunningLevel ? i18n.t('dialog.ruleset.init-level') : props.dunningLevel.description}
              data-test-id={testAttribute('dle2', 'dialog.enter-description', `${props.dunningLevel.level}`)}
              disabled={props.isInitialDunningLevel}
              onChange={event => onDescriptionChange(event.target.value)}
            />
          </div>
          <div>
            {i18n.t('dialog.ruleset.rule', {
              count: props.numberOfRulesLevelIsUsedIn,
            })}
          </div>
          {!props.isInitialDunningLevel && props.numberOfRulesLevelIsUsedIn === 0 && (
            <Button
              type="tertiary"
              id={`${props.dunningLevel.level}-trash`}
              testId={testAttribute('kuhh', 'trash', `${props.dunningLevel.level}-trash`)}
              icon={<Trash />}
              onClick={() => props.onDeleteDunningLevel(props.dunningLevel.level)}
            />
          )}

          {/* Error Messages */}
          <div />
          <div />
          <div>{showNameInvalidWarning && <div className="red-text">{i18n.t('dialog.name-invalid')}</div>}</div>
          <div>
            {showDescriptionInvalidWarning && <div className="red-text">{i18n.t('dialog.description-invalid')}</div>}
          </div>
        </div>
      </div>

      {/* level-overview in view mode: */}
      <div hidden={props.isEditable}>
        <div className="level-grid-view bottom-line" data-testid="level-no-edit">
          <div id="level-value-no-edit">{props.dunningLevel.level}</div>
          <div id="level-name-no-edit">{props.dunningLevel.name}</div>
          <div id="level-description-no-edit">{props.dunningLevel.description}</div>
          <div id="level-used-in-no-edit">
            {i18n.t('dialog.ruleset.rule', {
              count: props.numberOfRulesLevelIsUsedIn,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DunningLevelEdit;
