import { FC, useContext, useEffect, useState } from 'react';

import { Icon } from '@blueprintjs/core';
import { ChevronDown, ChevronRight, DragHandleVertical } from '@blueprintjs/icons';
import { UserContext } from 'App';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { alertToast } from 'components/Toast/AlertToast';
import { CheckGreen } from 'icons/CheckGreen';
import { DefaultCheckGreen } from 'icons/DefaultCheckGreen';
import { DraftBlue } from 'icons/DraftBlue';
import { IndicatorIcon } from 'icons/IndicatorIcon';
import { STATE, TABLE_TITLE } from 'interfaces/enums';
import { AbstractRuleSet, BasicRuleSet, RIGHTS, RuleSetForReact } from 'interfaces/Interfaces';
import { orderRules } from 'services/ApiService';
import { testAttribute } from 'util/Util';

import { getRuleSetWithRule } from '../../../../services/LoadRuleSetWithRules';
import RuleSetIcons from '../RuleSetIcons/RuleSetIcons';
import RuleSetRowData from '../RuleSetRowData/RuleSetRowData';
import RulesOfRuleSetRow from '../RulesOfRuleSetRow/RulesOfRuleSetRow';

import '../RuleSetTable.style.sass';

interface RuleSetRowProps {
  ruleSet: AbstractRuleSet;
  tableHeaders: TABLE_TITLE[];
  isDefaultRuleSetRegular: boolean;
  isDefaultRuleSetFinallyInvoiced: boolean;
  highlightedRuleSetId: string;
  highlightedRuleId: string;
  expandedRuleSet: string;
  onUpdateTable: (rulesetId?: string, ruleId?: string) => void;
  onUpdateRuleSet: (ruleset: RuleSetForReact, ruleId?: string) => void;
  subsequentRuleSets: BasicRuleSet[];
  ruleSets: AbstractRuleSet[];
  isUsedInDunningSelectionConfiguration: boolean;
}

const RuleSetRow: FC<RuleSetRowProps> = (props: RuleSetRowProps) => {
  const userContext = useContext(UserContext);
  const [expanded, setExpanded] = useState<boolean>(props.ruleSet.rulesetId === props.expandedRuleSet);
  const [ruleSetForReact, setRuleSetForReact] = useState<RuleSetForReact>();
  const loadData = () => {
    getRuleSetWithRule(props.ruleSet.rulesetId)?.then(res => {
      setRuleSetForReact(res);
    });
  };

  useEffect(() => {
    setExpanded(props.ruleSet.rulesetId === props.expandedRuleSet);
    if (expanded) {
      loadData();
    }
  }, [props.expandedRuleSet]);

  function onChevronIconClick() {
    if (!expanded) {
      loadData();
    }
    setExpanded(!expanded);
  }

  function getIcon() {
    if (props.isDefaultRuleSetRegular || props.isDefaultRuleSetFinallyInvoiced) {
      return (
        <DefaultCheckGreen
          isRegular={props.isDefaultRuleSetRegular}
          isFinallyInvoiced={props.isDefaultRuleSetFinallyInvoiced}
        />
      );
    }
    if (props.ruleSet.state === STATE.ACTIVE) {
      return (
        <IndicatorIcon tooltipKey="dialog.ruleset.active-mode">
          <CheckGreen />
        </IndicatorIcon>
      );
    }
    if (props.ruleSet.state === STATE.EDIT) {
      return (
        <IndicatorIcon tooltipKey="dialog.ruleset.edit-mode">
          <DraftBlue />
        </IndicatorIcon>
      );
    }
  }

  async function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }
    if (ruleSetForReact) {
      const newRulesOrder = ruleSetForReact?.rules;
      const [removedRule] = newRulesOrder.splice(result.source.index, 1);
      newRulesOrder.splice(result.destination.index, 0, removedRule);

      const newOrder = newRulesOrder.map(rule => rule.id);

      await orderRules(ruleSetForReact.rulesetId, newOrder)
        .then(updatedRuleSet => {
          props.onUpdateRuleSet(new RuleSetForReact(updatedRuleSet));
        })
        .catch(error => alertToast(error));
    }
  }

  return (
    <>
      <tbody>
        <tr
          id={props.ruleSet.name || 'noName'}
          data-test-id={testAttribute('rows', 'ruleset', props.ruleSet.name)}
          className={props.ruleSet.rulesetId === props.highlightedRuleSetId ? 'highlighted-row' : undefined}>
          {/** Icon Cell */}
          <td id="chevron" className="icon" onClick={onChevronIconClick}>
            <Icon icon={expanded ? <ChevronDown /> : <ChevronRight />} style={{ float: 'right' }} />
          </td>

          {/** Icon Cell */}
          <td>
            {getIcon()}
            {/** Add icon depending on ruleSet status (edit, archive, check) */}
          </td>

          {/** Data Columns Cells */}
          <RuleSetRowData
            tableHeaders={props.tableHeaders}
            ruleSet={props.ruleSet}
            isDefaultRuleSetRegular={props.isDefaultRuleSetRegular}
            isDefaultRuleSetFinallyInvoiced={props.isDefaultRuleSetFinallyInvoiced}
          />

          {/** Icons Cell */}
          <RuleSetIcons
            ruleSet={props.ruleSet}
            ruleSets={props.ruleSets}
            subsequentRuleSets={props.subsequentRuleSets}
            onUpdateRuleSet={(ruleset, ruleId) => props.onUpdateRuleSet(ruleset, ruleId)}
            onUpdateTable={rulesetId => props.onUpdateTable(rulesetId)}
            isDefaultRuleSetRegular={props.isDefaultRuleSetRegular}
            isDefaultRuleSetFinallyInvoiced={props.isDefaultRuleSetFinallyInvoiced}
            isUsedInDunningSelectionConfiguration={props.isUsedInDunningSelectionConfiguration}
          />
        </tr>
      </tbody>

      {expanded &&
        ruleSetForReact && ( // expandable rule rows
          <DragDropContext onDragEnd={e => onDragEnd(e)}>
            <Droppable droppableId="droppable">
              {provided => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {ruleSetForReact.rules.map((rule, index) => {
                    return (
                      <Draggable
                        key={rule.id}
                        draggableId={rule.id}
                        index={index}
                        isDragDisabled={
                          !userContext.rights.includes(RIGHTS.CHANGE_ORDER_RULE) || ruleSetForReact.state !== STATE.EDIT
                        }>
                        {(provided, snapshot) => (
                          <tr
                            id={ruleSetForReact.name || 'noName'}
                            data-test-id={testAttribute('4v5b', 'rule', ruleSetForReact.name)}
                            className={rule.id === props.highlightedRuleId ? 'highlighted-row' : undefined}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            {snapshot.isDragging && (
                              <>
                                <td
                                  style={{
                                    border: '0px',
                                  }}
                                />
                                <td
                                  style={{
                                    border: '0px',
                                  }}
                                />
                                <td
                                  style={{
                                    border: '0px',
                                  }}
                                />
                                <td
                                  style={{
                                    border: '0px',
                                  }}>
                                  <Icon<HTMLSpanElement> draggable icon={<DragHandleVertical />} />
                                  {rule.name}
                                </td>
                              </>
                            )}
                            {!snapshot.isDragging && ruleSetForReact && (
                              <RulesOfRuleSetRow
                                key={rule.id}
                                ruleSet={ruleSetForReact}
                                rule={rule}
                                tableHeaders={props.tableHeaders}
                                onUpdateRuleSet={(newRuleId, ruleSet) => props.onUpdateRuleSet(ruleSet, newRuleId)}
                                onUpdateTable={() => props.onUpdateTable(rule.rulesetId)}
                              />
                            )}
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        )}
    </>
  );
};

export default RuleSetRow;
