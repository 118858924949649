import { SortDirection, type SortState } from './SortState';
import { Page } from '../../../interfaces/Interfaces';

export interface TableDatasource<T> {
  totalItemCount: number;
  current: T[];
  hasNext: boolean;
  hasFilters: boolean;
  hasSorting: boolean;
  loadMore: () => Promise<void>;
  sort: (sort: SortState[]) => Promise<void>;
  filter: (filter: Record<string, any>) => Promise<void>;
  getFilterState: () => Record<string, any>;
}

export class StaticDatasource<T> implements TableDatasource<T> {
  public current: T[];
  public hasNext = false;
  public totalItemCount: number;
  private sortState: SortState[] = [];
  private filterState: Record<string, any> = {};

  public get hasFilters() {
    return !!Object.keys(this.filterState).length;
  }

  public get hasSorting() {
    return !!this.sortState.length;
  }

  constructor(protected data: T[]) {
    this.current = this.data;
    this.totalItemCount = data.length;
  }

  getFilterState(): Record<string, any> {
    return this.filterState;
  }

  async loadMore() {}

  async sort(states: SortState[]) {
    this.sortState = states;
    this.current = this.justSort(this.justFilter(this.data, this.filterState), this.sortState);
  }

  async filter(filters: Record<string, any>) {
    this.filterState = filters;
    this.current = this.justSort(this.justFilter(this.data, this.filterState), this.sortState);
  }

  justSort(data: T[], states: SortState[]) {
    const comperator = (state: SortState) => (aItem: T, bItem: T) => {
      const a = state.column.accessor(aItem);
      const b = state.column.accessor(bItem);
      const inverted = state.direction === SortDirection.DESC;
      if (typeof a === 'number' && typeof b === 'number') {
        return (a - b) * (inverted ? -1 : 1);
      }
      return `${a}`.localeCompare(`${b}`) * (inverted ? 1 : -1);
    };
    let sorted = [...data];
    states.reverse().forEach(state => {
      sorted = sorted.sort(comperator(state));
    });
    return sorted;
  }

  justFilter(data: T[], filters: Record<string, any>) {
    let filtered = [...data];
    Object.entries(filters).forEach(([key, value]) => {
      filtered = filtered.filter((item: any) => {
        if (item[key]) {
          return item[key].includes(value);
        }
        return true;
      });
    });
    return filtered;
  }
}

export class SingleFetchDatasource<T> extends StaticDatasource<T> {
  constructor(private readonly fetch: () => Promise<T[]>) {
    super([]);
  }

  async loadMore() {
    this.data = await this.fetch();
    this.current = this.data;
    this.totalItemCount = this.data.length;
  }
}

export class PageableDatasource<T> implements TableDatasource<T> {
  current: T[] = [];
  totalItemCount: number;
  hasNext: boolean;
  private sortState: SortState[] = [];
  private filterState: Record<string, any> = {};
  private currentPage = 0;

  public get hasFilters() {
    return !!Object.keys(this.filterState).length;
  }

  public get hasSorting() {
    return !!this.sortState.length;
  }

  constructor(
    private readonly supplier: (
      page: number,
      pageSize: number,
      filters?: Record<string, any>,
      sort?: SortState[],
    ) => Promise<Page<T>>,
    private readonly pageSize = 50,
  ) {}

  getFilterState(): Record<string, any> {
    return this.filterState;
  }

  async filter(filter: Record<string, any>): Promise<void> {
    this.filterState = filter;
    this.current = [];
    const page = await this.supplier(0, this.getPageSize(), this.filterState, this.sortState);
    this.processPage(page);
  }

  async sort(sort: SortState[]): Promise<void> {
    this.sortState = sort;
    this.current = [];
    const page = await this.supplier(0, this.getPageSize(), this.filterState, this.sortState);
    this.processPage(page);
  }

  async loadMore(): Promise<void> {
    const page = await this.supplier(this.currentPage, this.getPageSize(), this.filterState, this.sortState);
    if (this.hasNext) {
      this.currentPage += 1;
    }
    this.processPage(page);
  }

  private getPageSize() {
    if (this.pageSize > 0) {
      return this.pageSize;
    }
    return 50;
  }

  private processPage(page: Page<T>) {
    this.totalItemCount = page.totalAmountOfItems;
    this.current = [...this.current, ...page.data];
    this.currentPage = page.pageNumber + 1;
    this.hasNext = this.totalItemCount > this.current.length && this.current.length == this.pageSize * this.currentPage;
  }

  static from<T>(datasource: PageableDatasource<T>): PageableDatasource<T> {
    const dest = new PageableDatasource(datasource.supplier, datasource.pageSize);
    dest.currentPage = datasource.currentPage;
    dest.current = datasource.current;
    dest.filterState = datasource.filterState;
    dest.sortState = datasource.sortState;
    return dest;
  }
}
