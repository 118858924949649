import {
  AGGREG_FUNCTION,
  ITEM_ATTRIBUTE,
  LICENSE_MODEL,
  NUMERIC_ARGUMENT_FUNCTION,
} from '../../../../../interfaces/enums';
import { RuleConditionDefinition, RuleConditionInputTypes } from '../RuleCondition/RuleConditionTypes';
import { AggregCondition, DunningLevelForReact } from '../../../../../interfaces/Interfaces';
import {
  RuleDefinitionComputedValue,
  RuleDefinitionStringValue,
} from '../RuleCondition/RuleInputs/RuleDefinitionBaseProps';
import i18n from '../../../../../i18n/i18n';
import { defaultInput, operatorSelect } from './RuleConditionHelper';
import { RuleValidators } from '../../Validation/RuleValidators';
import { useContext } from 'react';
import { LicenseContext } from '../../../../../App';
import { useOperatorsForAggregConditionAndLicense } from '../../../../../hooks/LicenseHooks';

const availableComputedPropertiesFactory = () => {
  const license = useContext(LicenseContext);
  const computedProps = [
    {
      key: new RuleDefinitionComputedValue(NUMERIC_ARGUMENT_FUNCTION.DoubleMonthlyRate),
      label: i18n.t(NUMERIC_ARGUMENT_FUNCTION.DoubleMonthlyRate),
    },
  ];
  if (license.appLicense.name == LICENSE_MODEL.UNLIMITED) {
    computedProps.push({
      key: new RuleDefinitionComputedValue(NUMERIC_ARGUMENT_FUNCTION.DisconnectionRequirement),
      label: i18n.t(NUMERIC_ARGUMENT_FUNCTION.DisconnectionRequirement),
    });
  }

  return computedProps;
};

const aggregatedFunctionInputFactory = () => ({
  type: RuleConditionInputTypes.SELECT,
  getter: (obj: AggregCondition) => new RuleDefinitionStringValue(obj.aggregFunction),
  setter: (obj: AggregCondition, val: any) => (obj.aggregFunction = val.toValue()),
  selectables: Object.keys(AGGREG_FUNCTION).map(func => ({ key: func, label: i18n.t('select.' + func) })),
  validators: [RuleValidators.aggregatedThis.required],
});

export const aggregatedRuleConditionDefinitionFactory: (dunningLevels: DunningLevelForReact[]) => {
  [key in ITEM_ATTRIBUTE]: RuleConditionDefinition;
} = dunningLevels => {
  const operators = useOperatorsForAggregConditionAndLicense();

  return {
    [ITEM_ATTRIBUTE.LEVEL]: {
      inputs: [
        aggregatedFunctionInputFactory(),
        operatorSelect(operators[ITEM_ATTRIBUTE.LEVEL]),
        defaultInput(
          RuleConditionInputTypes.INTEGER,
          [RuleValidators.aggregatedThis.required],
          undefined,
          availableComputedPropertiesFactory(),
        ),
      ],
    },
    [ITEM_ATTRIBUTE.GROSS_AMOUNT]: {
      inputs: [
        aggregatedFunctionInputFactory(),
        operatorSelect(operators[ITEM_ATTRIBUTE.GROSS_AMOUNT]),
        defaultInput(
          RuleConditionInputTypes.CURRENCY,
          [
            RuleValidators.aggregatedThis.required,
            RuleValidators.aggregatedThis.sanitized,
            RuleValidators.validCurrency,
          ],
          undefined,
          availableComputedPropertiesFactory(),
        ),
      ],
    },
    [ITEM_ATTRIBUTE.OPEN_AMOUNT]: {
      inputs: [
        aggregatedFunctionInputFactory(),
        operatorSelect(operators[ITEM_ATTRIBUTE.OPEN_AMOUNT]),
        defaultInput(
          RuleConditionInputTypes.CURRENCY,
          [
            RuleValidators.aggregatedThis.required,
            RuleValidators.aggregatedThis.sanitized,
            RuleValidators.validCurrency,
          ],
          undefined,
          availableComputedPropertiesFactory(),
        ),
      ],
    },
    [ITEM_ATTRIBUTE.SETTLED_AMOUNT]: {
      inputs: [
        aggregatedFunctionInputFactory(),
        operatorSelect(operators[ITEM_ATTRIBUTE.SETTLED_AMOUNT]),
        defaultInput(
          RuleConditionInputTypes.CURRENCY,
          [
            RuleValidators.aggregatedThis.required,
            RuleValidators.aggregatedThis.sanitized,
            RuleValidators.validCurrency,
          ],
          undefined,
          availableComputedPropertiesFactory(),
        ),
      ],
    },
    [ITEM_ATTRIBUTE.OVERDUE_CALENDAR_DAYS]: {
      inputs: [
        aggregatedFunctionInputFactory(),
        operatorSelect(operators[ITEM_ATTRIBUTE.OVERDUE_CALENDAR_DAYS]),
        defaultInput(
          RuleConditionInputTypes.INTEGER,
          [
            RuleValidators.aggregatedThis.required,
            RuleValidators.aggregatedThis.sanitized,
            RuleValidators.aggregatedThis.nonNegativeNumber,
          ],
          undefined,
          availableComputedPropertiesFactory(),
        ),
      ],
    },
    [ITEM_ATTRIBUTE.OVERDUE_WORKING_DAYS]: {
      inputs: [
        aggregatedFunctionInputFactory(),
        operatorSelect(operators[ITEM_ATTRIBUTE.OVERDUE_WORKING_DAYS]),
        defaultInput(
          RuleConditionInputTypes.INTEGER,
          [
            RuleValidators.aggregatedThis.required,
            RuleValidators.aggregatedThis.sanitized,
            RuleValidators.aggregatedThis.nonNegativeNumber,
          ],
          undefined,
          availableComputedPropertiesFactory(),
        ),
      ],
    },
  };
};
