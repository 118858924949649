/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecutionOutcomeDto } from './ExecutionOutcomeDto';
import {
    ExecutionOutcomeDtoFromJSON,
    ExecutionOutcomeDtoFromJSONTyped,
    ExecutionOutcomeDtoToJSON,
} from './ExecutionOutcomeDto';

/**
 * 
 * @export
 * @interface ExecutionOverviewDto
 */
export interface ExecutionOverviewDto {
    /**
     * The id of the execution
     * @type {string}
     * @memberof ExecutionOverviewDto
     */
    id?: string;
    /**
     * The id of the rule set using in the execution
     * @type {string}
     * @memberof ExecutionOverviewDto
     */
    ruleSetId?: string;
    /**
     * The date and time of the execution
     * @type {Date}
     * @memberof ExecutionOverviewDto
     */
    timestamp?: Date;
    /**
     * The customer id associated with the contract
     * @type {number}
     * @memberof ExecutionOverviewDto
     */
    customerId?: number;
    /**
     * The ids of the contracts involved in the execution
     * @type {Array<number>}
     * @memberof ExecutionOverviewDto
     */
    contractIds?: Array<number>;
    /**
     * The name of the rule set using in the execution
     * @type {string}
     * @memberof ExecutionOverviewDto
     */
    ruleSetName?: string;
    /**
     * The state of the execution
     * @type {string}
     * @memberof ExecutionOverviewDto
     */
    executionState?: ExecutionOverviewDtoExecutionStateEnum;
    /**
     * 
     * @type {Array<ExecutionOutcomeDto>}
     * @memberof ExecutionOverviewDto
     */
    outcomes?: Array<ExecutionOutcomeDto>;
    /**
     * 
     * @type {string}
     * @memberof ExecutionOverviewDto
     */
    highestChangeOfLevel?: string;
    /**
     * Last time the execution was retried
     * @type {Date}
     * @memberof ExecutionOverviewDto
     */
    lastModified?: Date;
    /**
     * Last user who retried the execution
     * @type {string}
     * @memberof ExecutionOverviewDto
     */
    lastModifiedUser?: string;
}


/**
 * @export
 */
export const ExecutionOverviewDtoExecutionStateEnum = {
    Pending: 'PENDING',
    Successful: 'SUCCESSFUL',
    Failed: 'FAILED',
    Retry: 'RETRY',
    Canceled: 'CANCELED'
} as const;
export type ExecutionOverviewDtoExecutionStateEnum = typeof ExecutionOverviewDtoExecutionStateEnum[keyof typeof ExecutionOverviewDtoExecutionStateEnum];


/**
 * Check if a given object implements the ExecutionOverviewDto interface.
 */
export function instanceOfExecutionOverviewDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecutionOverviewDtoFromJSON(json: any): ExecutionOverviewDto {
    return ExecutionOverviewDtoFromJSONTyped(json, false);
}

export function ExecutionOverviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecutionOverviewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ruleSetId': !exists(json, 'ruleSetId') ? undefined : json['ruleSetId'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'contractIds': !exists(json, 'contractIds') ? undefined : json['contractIds'],
        'ruleSetName': !exists(json, 'ruleSetName') ? undefined : json['ruleSetName'],
        'executionState': !exists(json, 'executionState') ? undefined : json['executionState'],
        'outcomes': !exists(json, 'outcomes') ? undefined : ((json['outcomes'] as Array<any>).map(ExecutionOutcomeDtoFromJSON)),
        'highestChangeOfLevel': !exists(json, 'highestChangeOfLevel') ? undefined : json['highestChangeOfLevel'],
        'lastModified': !exists(json, 'lastModified') ? undefined : (new Date(json['lastModified'])),
        'lastModifiedUser': !exists(json, 'lastModifiedUser') ? undefined : json['lastModifiedUser'],
    };
}

export function ExecutionOverviewDtoToJSON(value?: ExecutionOverviewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ruleSetId': value.ruleSetId,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'customerId': value.customerId,
        'contractIds': value.contractIds,
        'ruleSetName': value.ruleSetName,
        'executionState': value.executionState,
        'outcomes': value.outcomes === undefined ? undefined : ((value.outcomes as Array<any>).map(ExecutionOutcomeDtoToJSON)),
        'highestChangeOfLevel': value.highestChangeOfLevel,
        'lastModified': value.lastModified === undefined ? undefined : (value.lastModified.toISOString()),
        'lastModifiedUser': value.lastModifiedUser,
    };
}

