import { type FunctionComponent } from 'react';

interface FormInfoTextProps {
  text: string;
}

const FormInfoText: FunctionComponent<FormInfoTextProps> = ({ text }) => {
  return <p>{text}</p>;
};
export default FormInfoText;
