import { LICENSE_MODEL } from '../interfaces/enums';

export class AppLicense {
  featurePacks: FeaturePack[];
  name: LICENSE_MODEL;
}

export class AppLicenseContext {
  constructor(
    public readonly appLicense: AppLicense = new AppLicense(),
    public readonly loaded: boolean = false,
  ) {}

  isFeatureEnabled(featurePackName: string, feature: string) {
    if (this.loaded) {
      const foundFeaturePack = this.appLicense.featurePacks.find(pack => pack.name === featurePackName);
      const foundFeature =
        foundFeaturePack !== undefined ? foundFeaturePack.features.find(f => f.name === feature) : undefined;
      return foundFeature?.enabled;
    }
    return false;
  }
}

export enum LICENSE_FEATURE_PACK {
  CONTRACT_SELECTION_PACK = 'ContractSelection',
  RECEIVABLE_SELECTION_PACK = 'ReceivableSelection',
  ADDITIONAL_OPTIONS_PACK = 'AdditionalOptions',
  ACTIONS_PACK = 'Actions',
  RULESET_PACK = 'RuleSet',
  RULE_PACK = 'Rule',
  DUNNING_LEVEL_PACK = 'DunningLevel',
  CUSTOMER_SELECTION_PACK = 'CustomerSelection',
  DYNAMIC_CUSTOMER_SELECTION_PACK = 'DynamicCustomerSelection',
  DYNAMIC_CONTRACT_SELECTION_PACK = 'DynamicContractSelection',
}

export enum LICENSE_FEATURE {
  CALENDAR_DAYS_SINCE_LAST_EXECUTION = 'CalendarDaysSinceLastExecution',
  CALENDAR_DAYS_SINCE_LAST_NOTIFICATION = 'CalendarDaysSinceLastNotification',
  WORKING_DAYS_SINCE_LAST_EXECUTION = 'WorkingDaysSinceLastExecution',
  WORKING_DAYS_SINCE_LAST_NOTIFICATION = 'WorkingDaysSinceLastNotification',
  ADDITIONAL_VALUES = 'AdditionalValues',
  CALENDAR_DAYS_UNTIL_CONTRACT_ENDS = 'CalendarDaysUntilContractEnds',
  WORKING_DAYS_UNTIL_CONTRACT_ENDS = 'WorkingDaysUntilContractEnds',
  COMMUNICATION_PROFILE = 'CommunicationProfile',
  STATUS_OF_DISCONNECTION_PROCESS = 'StatusOfDisconnectionProcess',
  UN_ASSIGNED_PAYMENTS_AMOUNT = 'UnassignedPaymentsAmount',
  IMPORT_RULESET = 'ImportRuleSet',
  EXPORT_RULESET = 'ExportRuleSet',
  CHANGE_OF_LEVEL_DESC_ORDER = 'ChangeOfLevelDescOrder',
  RULESET_PARAMETERS = 'RuleSetParameters',
  COPY_RULESET = 'CopyRuleSet',
  COPY_RULE = 'CopyRule',
  TERMINATION = 'Termination',
  FEE = 'Fee',
  CHANGE_OF_STATUS = 'ChangeOfStatus',
  NOTIFICATION = 'Notification',
  DISCONNECTION_THREAT = 'DisconnectionThreat',
  DISCONNECTION_ANNOUNCEMENT = 'DisconnectionAnnouncement',
  DISCONNECTION = 'Disconnection',
  CONTRACT_TERMINATION = 'ContractTermination',
  DEBT_COLLECTION = 'DebtCollection',

  PRODUCT_CODE = 'ProductCode',
  FORECAST_ANNUAL_CONSUMPTION = 'ForecastAnnualConsumption',
  CREDIT_SCORE = 'CreditScore',
  CUSTOMER_GROUP = 'CustomerGroup',
  CUSTOMER_TYPE = 'CustomerType',
  CONTRACT_TYPE = 'CustomerType',
}

export class FeaturePack {
  features: Feature[];
  name: string;

  constructor(features: Feature[], name: string) {
    this.features = features;
    this.name = name;
  }
}

export class Feature {
  enabled: boolean;
  name: string;

  constructor(enabled: boolean, name: string) {
    this.enabled = enabled;
    this.name = name;
  }
}
