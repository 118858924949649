import { type FunctionComponent, useState } from 'react';
import { Card, Elevation, InputGroup, Tooltip } from '@blueprintjs/core';
import { IconInfo } from '../../icons/svg/IconInfo';
import { Button } from '../../lib/Button';

export const UuidHexConverter: FunctionComponent = props => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  const convert = (value: string) => {
    if (value.startsWith('0x')) {
      // Convert hex to UUID
      const hex = value.slice(2);
      const uuid = [hex.slice(0, 8), hex.slice(8, 12), hex.slice(12, 16), hex.slice(16, 20), hex.slice(20)].join('-');
      setOutput(uuid);
    } else {
      // Convert UUID to hex
      const hex = value.replace(/-/g, '').trim();
      setOutput(`0x${hex}`);
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setInput(value);
    convert(value);
  };

  return (
    <Card className="card" interactive={false} elevation={Elevation.TWO}>
      <h5 className="bp5-heading">UUID/Hex Converter</h5>
      <div className="row">
        <span>Input (UUID or HEX)</span>
        <InputGroup
          className="spacer"
          type="text"
          value={input}
          onChange={handleChange}
          placeholder="Enter UUID or 0x hex"
        />
      </div>
      <div className="row">
        <span>Output </span>
        <InputGroup
          style={{ cursor: 'pointer' }}
          className="spacer"
          disabled
          type="text"
          value={output}
          placeholder="Output"
        />
        <Tooltip content={'Click here to copy'}>
          <Button
            icon={<IconInfo />}
            type={'tertiary'}
            testId={'asd'}
            onClick={() => window.navigator.clipboard.writeText(output)}></Button>
        </Tooltip>
      </div>
    </Card>
  );
};

export default UuidHexConverter;
