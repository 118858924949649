import type { RuleTemplate, StringConst } from '../../../../../interfaces/Interfaces';
import { ACTION_TYPE, CASE_ATTRIBUTE } from '../../../../../interfaces/enums';
import { ValidationResult } from '../../../../DunningSelectionPage/DunningSelection/input/Validators';

export function validateConditionIfTerminationAction(ruleEdit: RuleTemplate): ValidationResult | null {
  if (ruleEdit.actions.filter(action => action['@type'] === ACTION_TYPE.CONTRACT_TERMINATION).length > 0) {
    const terminationConditions = ruleEdit.caseConditions.filter(
      condition => condition.attrib === CASE_ATTRIBUTE.TERMINATION,
    );
    const valid =
      terminationConditions.length > 0 &&
      terminationConditions.some(condition => (condition.argument as StringConst).val === 'false');

    if (terminationConditions.length === 0) {
      return {
        error: true,
        message: 'error.rule-editor.missing-contract-termination-cond',
      };
    }

    if (!valid) {
      return {
        error: true,
        message: 'error.rule-editor.contract-already-terminated-cond',
      };
    }
  }
  return null;
}
