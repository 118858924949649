import * as React from 'react';
import { type FunctionComponent, useEffect, useState } from 'react';

import { Card, Elevation, NonIdealState } from '@blueprintjs/core';
import { Search } from '@blueprintjs/icons';

import i18n from 'i18n/i18n';
import { AbstractRuleSet, DunningLevelForReact } from 'interfaces/Interfaces';
import { getAllDunningLevels, getBasicRuleSets, getContractStates } from 'services/ApiService';

import { BulkContractStateDownloadButton } from './BulkContractStateDownloadButton/BulkContractStateDownloadButton';
import { getColumnDefinition, getFilter, getSelectedFilters } from './ContractStateTypes';
import { type ContractStateFilter, RuleSetFilterDtoStatesEnum } from '../../generated-sources/openapi';
import { PageableDatasource } from '../../lib/Table';
import FilteredTable from '../../lib/Table/FilteredTable';
import { removeUndefined } from '../../util/Util';
import { alertToast } from '../Toast/AlertToast';

import './ContractStatePage.style.sass';

const ContractStatePage: FunctionComponent = () => {
  const [latestFilters, setLatestFilters] = useState<ContractStateFilter>();
  const [availableDunningLevels, setAvailableDunningLevels] = useState<DunningLevelForReact[]>([]);
  const [availableRuleSetNames, setAvailableRuleSetNames] = useState<AbstractRuleSet[]>([]);

  function loadDunningLevels() {
    getAllDunningLevels().then(dunningLevels => {
      setAvailableDunningLevels(dunningLevels.map(level => new DunningLevelForReact(level)));
    });
  }

  function loadRuleSets() {
    const ruleSetFilter = { states: [RuleSetFilterDtoStatesEnum.Active] };
    getBasicRuleSets(ruleSetFilter).then(ruleSets => setAvailableRuleSetNames(ruleSets));
  }

  useEffect(() => {
    loadDunningLevels();
    loadRuleSets();
  }, []);

  const fetchContractStatesPage = async (page: number, pageSize: number, filters?: Record<string, any>) => {
    setLatestFilters(getSelectedFilters(filters));
    try {
      const contractStateOverviewPage = await getContractStates(
        page,
        pageSize,
        removeUndefined(getSelectedFilters(filters)),
      );
      return {
        data: contractStateOverviewPage.data,
        totalAmountOfItems: contractStateOverviewPage.totalAmountOfItems,
        pageNumber: contractStateOverviewPage.pageNumber,
        pageSize: contractStateOverviewPage.pageSize,
      };
    } catch (e: any) {
      await alertToast(e);
      return {
        data: [],
        totalAmountOfItems: 0,
        pageNumber: 0,
        pageSize: 50,
      };
    }
  };

  const [datasource, setDatasource] = useState(new PageableDatasource(fetchContractStatesPage));

  return (
    <div className="contract-state-page">
      <div className="title">{i18n.t('title_contract_state')}</div>
      <Card className="card" interactive={false} elevation={Elevation.ONE}>
        <FilteredTable
          columns={getColumnDefinition()}
          dataSource={datasource}
          filter={getFilter(availableRuleSetNames, availableDunningLevels)}
          bulkActions={
            <BulkContractStateDownloadButton filter={latestFilters ?? {}} contractStateDatasource={datasource} />
          }
          noData={
            <NonIdealState
              className="flex"
              icon={<Search />}
              title={
                Object.keys(latestFilters ?? {}).length ? i18n.t('action-page.no-data') : i18n.t('action-page.no-state')
              }
            />
          }
        />
      </Card>
    </div>
  );
};

export default ContractStatePage;
