import { type FunctionComponent } from 'react';

import { Tooltip } from '@blueprintjs/core';

import i18n from 'i18n/i18n';
import { STATE, TABLE_TITLE } from 'interfaces/enums';
import { type AbstractRuleSet } from 'interfaces/Interfaces';

import '../RuleSetTable.style.sass';

interface RuleSetRowDataProps {
  tableHeaders: TABLE_TITLE[];
  ruleSet: AbstractRuleSet;
  isDefaultRuleSetRegular?: boolean;
  isDefaultRuleSetFinallyInvoiced?: boolean;
}

const MAX_TEXT_LENGTH = 50;

const RuleSetRowData: FunctionComponent<RuleSetRowDataProps> = (props: RuleSetRowDataProps) => {
  function isDisabled(): boolean {
    if (props.ruleSet.name) {
      return props.ruleSet.name.length <= MAX_TEXT_LENGTH;
    }
    return false;
  }

  function getStatusColumnText(state: STATE, isRegular?: boolean, isFinallyInvoiced?: boolean) {
    if (props.isDefaultRuleSetRegular ?? props.isDefaultRuleSetFinallyInvoiced) {
      if (isRegular && isFinallyInvoiced) {
        return `${i18n.t('dialog.ruleset.default-procedure-both')}, ${i18n.t('dialog.ruleset.active')}`;
      }
      if (isRegular) {
        return `${i18n.t('dialog.ruleset.default-procedure')}, ${i18n.t('dialog.ruleset.active')}`;
      }
      return `${i18n.t('dialog.ruleset.finally-invoiced-procedure')}, ${i18n.t('dialog.ruleset.active')}`;
    }
    if (state === STATE.ACTIVE) {
      return i18n.t('dialog.ruleset.active-mode');
    }
    if (state === STATE.EDIT) {
      return i18n.t('dialog.ruleset.edit-mode');
    }
  }

  return (
    <>
      {/** Name Cell */}
      <td colSpan={2}>
        <Tooltip disabled={isDisabled()} content={props.ruleSet.name || ''} placement="top">
          <span>
            {props.ruleSet.name?.slice(0, MAX_TEXT_LENGTH)}
            {props.ruleSet.name && props.ruleSet.name.length > MAX_TEXT_LENGTH && '...'}
          </span>
        </Tooltip>
      </td>

      {props.tableHeaders?.map(header => {
        if (header === TABLE_TITLE.DESCRIPTION) {
          return (
            <td key={header}>
              {props.ruleSet.description?.slice(0, MAX_TEXT_LENGTH) ?? '-'}
              {props.ruleSet.description && props.ruleSet.description.length > MAX_TEXT_LENGTH && '...'}
            </td>
          );
        }
        if (header === TABLE_TITLE.CREATED_ON) {
          return <td key={header}>{new Date(props.ruleSet.created).toLocaleDateString() || '-'}</td>;
        }
        if (header === TABLE_TITLE.STATUS) {
          return (
            <td key={header}>
              {getStatusColumnText(
                props.ruleSet.state,
                props.isDefaultRuleSetRegular,
                props.isDefaultRuleSetFinallyInvoiced,
              )}
            </td>
          );
        }
        if (header === TABLE_TITLE.LAST_EDITED_ON) {
          return <td key={header}>{new Date(props.ruleSet.updated).toLocaleDateString() || '-'}</td>;
        }
        if (header === TABLE_TITLE.LAST_EDITOR) {
          return <td key={header}>{props.ruleSet.lastEditor}</td>;
        }
        return <td key={header}>-</td>;
      })}
    </>
  );
};

export default RuleSetRowData;
