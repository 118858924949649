import { type FunctionComponent, useEffect, useState } from 'react';

import { Card, Elevation } from '@blueprintjs/core';
import { getBasicRuleSets } from '../../services/ApiService';
import { AbstractRuleSet } from '../../interfaces/Interfaces';

export const RuleSetWithId: FunctionComponent = props => {
  const [ruleSets, setRuleSets] = useState<AbstractRuleSet[]>([]);

  function loadRuleSets() {
    getBasicRuleSets({}).then(setRuleSets);
  }

  useEffect(() => {
    loadRuleSets();
  }, []);

  return (
    <Card className="card" interactive={false} elevation={Elevation.TWO}>
      <h5 className="bp5-heading">Rule sets with ids </h5>
      {ruleSets.map(ruleSet => (
        <div className="row">
          <span>{ruleSet.name}</span>
          <span>{ruleSet.rulesetId}</span>
        </div>
      ))}
    </Card>
  );
};

export default RuleSetWithId;
