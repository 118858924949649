import { type FunctionComponent, useEffect, useState } from 'react';

import { MenuItem } from '@blueprintjs/core';
import { type ItemRenderer, Select } from '@blueprintjs/select';

import i18n from 'i18n/i18n';
import { type BasicRuleSet } from 'interfaces/Interfaces';
import { Button } from 'lib/Button';

import { IconCaretDown } from '../../../icons/svg/IconCaretDown';
import { IconCross } from '../../../icons/svg/IconCross';
import { testAttribute } from '../../../util/Util';

interface RuleSetSubsequentProps {
  currentOption?: BasicRuleSet;
  isEditable: boolean;
  options: BasicRuleSet[];
  onRulesetSubsequentChange: (selectedSubsequent: any) => void;
}

const RuleSetSubsequentSelector: FunctionComponent<RuleSetSubsequentProps> = (props: RuleSetSubsequentProps) => {
  const [subsequentRuleSet, setSubsequentRuleSet] = useState(props.currentOption ?? null);

  useEffect(() => {
    const selected = props.options.find(s => s.rulesetId === props.currentOption?.rulesetId);
    if (selected) setSubsequentRuleSet(selected);
  }, [props.currentOption, props.options]);

  function onRulesetSelect(subsequent: BasicRuleSet) {
    setSubsequentRuleSet(subsequent);
    props.onRulesetSubsequentChange(subsequent);
  }

  function reset() {
    setSubsequentRuleSet(null);
    props.onRulesetSubsequentChange(null);
  }

  function getCurrentOptionDisplayText() {
    if (!subsequentRuleSet) return i18n.t('dialog.ruleset.please-select');
    return subsequentRuleSet.name;
  }

  const renderer: ItemRenderer<BasicRuleSet> = (element, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        id={`select-general-${element.rulesetId}`}
        data-test-id={testAttribute('rsl2', 'select-general', `${element.rulesetId}`)}
        active={modifiers.active}
        key={element.rulesetId}
        onClick={handleClick}
        text={element.name}
      />
    );
  };

  return (
    <div className="subsequent-ruleset">
      <Select<BasicRuleSet>
        data-test-id={testAttribute('fml2', 'ruleSet', 'selector')}
        items={props.options}
        itemRenderer={renderer}
        onItemSelect={subsequent => onRulesetSelect(subsequent)}
        popoverProps={{ position: 'bottom' }}
        disabled={!props.isEditable}
        filterable={false}
        activeItem={subsequentRuleSet}>
        <Button
          type="default"
          className="subsequent-reset-button"
          testId={testAttribute('fml2', 'caret-down', 'selector')}
          rightIcon={props.isEditable ? <IconCaretDown /> : undefined}
          disabled={!props.isEditable}>
          {getCurrentOptionDisplayText()}
        </Button>
      </Select>
      {props.isEditable && subsequentRuleSet && (
        <Button
          type="tertiary"
          className="subsequent-reset-button"
          testId={testAttribute('fml3', 'cross', 'reset')}
          icon={props.isEditable && subsequentRuleSet ? <IconCross /> : undefined}
          disabled={!props.isEditable && !subsequentRuleSet}
          onClick={() => reset()}
        />
      )}
    </div>
  );
};

export default RuleSetSubsequentSelector;
