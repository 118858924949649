import { type FunctionComponent, useCallback, useState } from 'react';

import { FormGroup, Position } from '@blueprintjs/core';
import { DateInput3 } from '@blueprintjs/datetime2';

import i18n from 'i18n/i18n';

import { BaseInputProps } from './InputInterfaces';
import { type ValidationResult } from './Validators';
import { testAttribute } from '../../../../util/Util';

class FormDateInputProps extends BaseInputProps<Date> {}

const FormDateInput: FunctionComponent<FormDateInputProps> = props => {
  const [value, setValue] = useState<Date | null>(props.value ? new Date(props.value) : null);
  const [error, setError] = useState<ValidationResult | null>(null);
  const formatDate = useCallback((date: Date) => date.toLocaleDateString(i18n.language), []);
  const parseDate = useCallback((str: string) => new Date(Date.parse(str)), []);

  async function validateInput(input: Date) {
    if (!props.validators) return false;
    const validationResult = await Promise.all(props.validators.map(validator => validator(input)));
    const firstError = validationResult.find(result => result?.error);
    if (firstError) {
      setError(firstError);
      return true;
    }
    setError(null);
    return false;
  }

  async function onChangeHandler(val: Date | null) {
    setValue(val);
    let hasError = false;
    if (val !== null) {
      hasError = await validateInput(val);
    }
    if (props.onChange) props.onChange(val, !hasError);
  }
  return (
    <FormGroup
      disabled={props.disabled}
      label={
        props.required ? <span className="required-field">{i18n.t(props.label ?? '')}</span> : i18n.t(props.label ?? '')
      }
      labelFor={props.name}
      helperText={error ? <span className="red-text">{i18n.t(error.message ?? '')}</span> : undefined}>
      <DateInput3
        data-test-id={testAttribute('fdi1', 'form-date-input.default-value')}
        className="date-input"
        disabled={props.disabled}
        formatDate={formatDate}
        placeholder=""
        parseDate={parseDate}
        defaultValue={value?.toString()}
        onChange={e => onChangeHandler(e !== null && e !== '' ? new Date(e) : null)}
        popoverProps={{ position: Position.BOTTOM }}
        minDate={props.additionalConfiguration?.minDate}
        maxDate={props.additionalConfiguration?.maxDate}
        outOfRangeMessage={props.additionalConfiguration?.outOfRangeMessage}
      />
    </FormGroup>
  );
};

export default FormDateInput;
