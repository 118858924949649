import { type FunctionComponent, useEffect, useState } from 'react';

import { Card, Elevation, Spinner } from '@blueprintjs/core';

import { alertToast } from 'components/Toast/AlertToast';
import { TABLE_TITLE } from 'interfaces/enums';

import RuleSetTable from './Table/RuleSetTable';
import i18n from '../../i18n/i18n';
import {
  type AbstractRuleSet,
  type BasicRuleSet,
  type DefaultRuleSet,
  type RuleSetForReact,
} from '../../interfaces/Interfaces';
import {
  getBasicRuleSets,
  getDefaultRuleSets,
  getDunningSelection,
  getSubsequentRuleSets,
} from '../../services/ApiService';
import { clearRuleSetCache } from '../../services/LoadRuleSetWithRules';
import OverviewHeader from '../OverviewHeader/OverviewHeader';

import '../../App.style.sass';

const DunningOverview: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [defaultRuleSets, setDefaultRuleSets] = useState<DefaultRuleSet[]>([]);
  const [changedRuleSetId, setChangedRuleSetId] = useState<string>('');
  const [changedRuleId, setChangedRuleId] = useState<string>('');
  const [expandedRuleSet, setExpandedRuleSet] = useState<string>('');
  const [numberOfRules, setNumberOfRules] = useState<number>(0);
  const [subsequentRuleSets, setSubsequentRuleSets] = useState<BasicRuleSet[]>([]);
  const [basicRuleSets, setBasicRuleSets] = useState<AbstractRuleSet[]>([]);
  const [activeHeaders, setActiveHeaders] = useState<TABLE_TITLE[]>([TABLE_TITLE.DESCRIPTION, TABLE_TITLE.CREATED_ON]);
  const [inactiveHeaders, setInactiveHeaders] = useState<TABLE_TITLE[]>([
    TABLE_TITLE.TRIGGERED_ACTION,
    TABLE_TITLE.LAST_EDITOR,
    TABLE_TITLE.STATUS,
    TABLE_TITLE.LAST_EDITED_ON,
  ]);
  const [dunningSelectionConfigurationRuleSets, setDunningSelectionConfigurationRuleSets] = useState<string[]>([]);

  async function loadData() {
    await getDefaultRuleSets()
      .then((ruleSets: DefaultRuleSet[]) => {
        setDefaultRuleSets(ruleSets);
      })
      .catch(error => alertToast(error));

    getBasicRuleSets()
      .then(ruleSets => {
        setBasicRuleSets(ruleSets);
        setNumberOfRules(ruleSets.length);
        setIsLoading(false);
      })
      .catch(error => alertToast(error));

    getDunningSelection().then(dunningSelection => {
      const procedures = [...dunningSelection.dunningProcedures, ...dunningSelection.terminationProcedures];
      const uniques = Array.from(new Set(procedures));
      setDunningSelectionConfigurationRuleSets(uniques);
    });
  }

  async function getRulesetSubsequent() {
    getSubsequentRuleSets()
      .then(rs => setSubsequentRuleSets(rs))
      .catch(alertToast);
  }

  useEffect(() => {
    const active = JSON.parse(localStorage.getItem('activeTitle') ?? '[]');
    setActiveHeaders(active);
    const inactive = Object.values(TABLE_TITLE).filter((title: TABLE_TITLE) => {
      return !active.includes(title) && title !== TABLE_TITLE.NAME;
    });
    setInactiveHeaders(inactive);
    setIsLoading(true);
    loadData();
    getRulesetSubsequent();
  }, []);

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function updateTable(ruleSetId?: string, ruleId?: string) {
    if (ruleSetId && ruleId) {
      setExpandedRuleSet(ruleSetId);
      setChangedRuleId(ruleId);
      setChangedRuleSetId('');
    } else if (ruleSetId && !ruleId) {
      setChangedRuleSetId(ruleSetId);
      setChangedRuleId('');
      setExpandedRuleSet(ruleSetId);
    } else {
      setExpandedRuleSet('');
      setChangedRuleId('');
      setChangedRuleSetId('');
    }

    setIsLoading(true);
    setBasicRuleSets([]);
    loadData().then(async () => {
      await delay(2000).then(() => {
        setChangedRuleSetId('');
        setChangedRuleId('');
      });
    });
    clearRuleSetCache();
    getRulesetSubsequent();
  }

  async function updateRuleSet(newRuleSet: RuleSetForReact, ruleId?: string) {
    const updatedRuleset = basicRuleSets.map(oldRuleSet => {
      return oldRuleSet.rulesetId === newRuleSet?.rulesetId ? newRuleSet : oldRuleSet;
    });

    setBasicRuleSets(updatedRuleset);
    setExpandedRuleSet(newRuleSet.rulesetId);
    setChangedRuleId(ruleId ?? '');
    setChangedRuleSetId('');

    await delay(2000).then(() => {
      setChangedRuleSetId('');
      setChangedRuleId('');
    });
  }

  return (
    <div>
      <div className="title">{i18n.t('title')}</div>
      <Card className="card" interactive={false} elevation={Elevation.ONE}>
        {isLoading && (
          <>
            <br />
            <div hidden={numberOfRules < 25}>
              {i18n.t('loading-ruleset', {
                count: numberOfRules,
              })}
            </div>
            <Spinner className="spinner" size={60} />
          </>
        )}
        {!isLoading && (
          <>
            <OverviewHeader
              subsequentRuleSets={subsequentRuleSets}
              numberOfDunningProcedures={basicRuleSets.length}
              activeHeaders={activeHeaders}
              inactiveHeaders={inactiveHeaders}
              onUpdateTable={ruleSetId => updateTable(ruleSetId)}
              onUpdateHeader={(active, inactive) => {
                setActiveHeaders(active);
                setInactiveHeaders(inactive);
              }}
            />
            <div>
              <RuleSetTable
                tableHeaders={activeHeaders}
                ruleSets={basicRuleSets}
                subsequentRuleSets={subsequentRuleSets}
                defaultRuleSets={defaultRuleSets}
                highlightedRuleSetId={changedRuleSetId}
                highlightedRuleId={changedRuleId}
                expandedRuleSet={expandedRuleSet}
                onUpdateTable={(ruleSetId, ruleId) => updateTable(ruleSetId, ruleId)}
                onUpdateRuleSet={(ruleSet, ruleId) => updateRuleSet(ruleSet, ruleId)}
                dunningSelectionConfigurationRuleSets={dunningSelectionConfigurationRuleSets}
              />
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default DunningOverview;
