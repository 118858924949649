import { Button, MenuItem } from '@blueprintjs/core';
import { CaretDown } from '@blueprintjs/icons';
import { type ItemRenderer, Select } from '@blueprintjs/select';

import './RuleCondtionTypeSelect.style.sass';
import i18n from '../../../../../../i18n/i18n';

interface ConditionTypeSelectProps<T> {
  conditionType: T | '';
  conditionTypes: { type: T; name: string }[];
  isEditable: boolean;
  onConditionTypeChange: (elem: T) => void;
}

export function RuleConditionTypeSelect<T>(props: ConditionTypeSelectProps<T>) {
  const conditionType = props.conditionTypes.find(type => type.type === props.conditionType);

  function onConditionTypeChange(newCondition: T) {
    if (props.conditionType !== newCondition) {
      props.onConditionTypeChange(newCondition);
    }
  }

  const renderList: ItemRenderer<{ type: T; name: string }> = (element, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        className="render"
        active={modifiers.active}
        key={element.name}
        onClick={handleClick}
        text={element.name}
      />
    );
  };

  return (
    <Select<{ type: T; name: string }>
      items={props.conditionTypes}
      itemRenderer={renderList}
      onItemSelect={newCondition => onConditionTypeChange(newCondition.type)}
      popoverProps={{ position: 'bottom' }}
      filterable={false}
      disabled={!props.isEditable}>
      <Button
        id="condition-type-button"
        className="rule-condition-type-select"
        minimal
        outlined={props.isEditable}
        rightIcon={props.isEditable ? <CaretDown /> : undefined}>
        {conditionType?.name ?? i18n.t('dialog.rule.please-select')}
      </Button>
    </Select>
  );
}

export default RuleConditionTypeSelect;
