import {
  RuleDefinitionOperatorValue,
  RuleDefinitionValue,
  ruleDefinitionValueFrom,
} from '../RuleCondition/RuleInputs/RuleDefinitionBaseProps';
import { RuleConditionInputDefinition, RuleConditionInputTypes } from '../RuleCondition/RuleConditionTypes';
import { CustomerCaseCondition } from '../../../../../interfaces/Interfaces';
import { OPERATOR } from '../../../../../interfaces/enums';
import { Validator } from '../../../../DunningSelectionPage/DunningSelection/input/Validators';
import { Selectable } from '../../../../DunningSelectionPage/DunningSelection/input/InputInterfaces';

export function conditionArgumentAccessors<T extends { argument: any }>() {
  return {
    getter: (obj: T) => ruleDefinitionValueFrom(obj.argument),
    setter: (obj: T, val: RuleDefinitionValue) => (obj.argument = val.toRuleArgument()),
  };
}

export const operatorSelect: (limitedOperator?: OPERATOR[]) => RuleConditionInputDefinition = (limitedOperator) => ({
  type: RuleConditionInputTypes.OPERATOR,
  getter: (obj: CustomerCaseCondition) => new RuleDefinitionOperatorValue(obj.cmp ?? OPERATOR.EQ),
  setter: (obj: CustomerCaseCondition, val: RuleDefinitionValue) => (obj.cmp = val.toValue()),
  inputProps: {
    availableOperators: limitedOperator
  }
});

export const defaultInput: (
  type: RuleConditionInputTypes,
  validators?: Validator[],
  selectables?: Selectable[],
  computedProperties?: { key: any; label: string }[],
) => RuleConditionInputDefinition = (type, validators, selectables, computedProperties) => ({
  type,
  ...conditionArgumentAccessors(),
  validators,
  selectables,
  computedProperties,
});
