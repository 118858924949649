import ReactDOM from 'react-dom';

import App from './App';

let currentLink = document.currentScript?.getAttribute('src');
currentLink = currentLink?.slice(0, currentLink.length - 9);

const head = document.getElementsByTagName('body')[0];

const link = document.createElement('link');
link.rel = 'stylesheet';
link.type = 'text/css';
link.href = `${currentLink}style.css`;
link.media = 'all';
head.appendChild(link);

window.addEventListener('load', () => {
  ReactDOM.render(<App />, document.getElementById('dunning-container'));
});
