import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import { Callout, Classes, Dialog, FormGroup, InputGroup, TextArea } from '@blueprintjs/core';
import { Error, InfoSign, Plus } from '@blueprintjs/icons';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { IfLicensed } from 'components/IfLicensed';
import { alertToast } from 'components/Toast/AlertToast';
import i18n from 'i18n/i18n';
import {
  BasicRuleSet,
  DunningLevelForReact,
  ParamForReact,
  RuleForReact,
  RuleSetForReact,
  RuleSetWithAssignmentCriteria,
} from 'interfaces/Interfaces';
import { Button } from 'lib/Button';
import { RadioSelect } from 'lib/RadioSelect';
import {
  countDunningLevelsUsageInRules,
  getParameterUsedInRules,
  testAttribute,
  validateHasEmojiPresentation,
  validateHasForbiddenPunctuation,
  validateIsEmptyString,
  validateRuleObject,
  ValidationObject,
} from 'util/Util';

import RuleSetDynamicCriteria from './Conditions/RuleSetDynamicCriteria';
import DunningLevelEdit from './DunningLevelEdit/DunningLevelEdit';
import RuleSetParameters from './ParamEdit/Parameters';
import RuleSetSubsequentSelector from './RuleSetSubsequentSelector';
import { LicenseContext } from '../../../App';
import { RuleSetAssignmentCriteriaForReact } from '../../../interfaces/DynamicCriteriaInterfaces';
import { LICENSE_FEATURE, LICENSE_FEATURE_PACK } from '../../../license/AppLicense';
import {
  createRuleSet,
  createRulesetDynamicCriteria,
  getAllDunningLevels,
  getRulesetDynamicCriteria,
  updateRuleSet,
  updateRulesetDynamicCriteria,
  updateRuleSetNameAndDescription,
} from '../../../services/ApiService';
import { getRuleSetWithRule } from '../../../services/LoadRuleSetWithRules';
import { ValidationResult } from '../../DunningSelectionPage/DunningSelection/input/Validators';
import { RuleValidationView } from '../Rule/RuleValidationView';

import '../Dialog.style.sass';

interface RuleSetDialogProps {
  dialogTitle: string;
  buttonTextSuccess: string;
  buttonTextClose?: string;
  isNameAndDescriptionEditable: boolean;
  isParamAndLevelEditable: boolean;
  onCloseDialog: () => void;
  onConfirmRuleSetChange: (ruleSetId: string) => void;
  subsequentRuleSets: BasicRuleSet[];
  ruleSetId?: string;
}

const RuleSetDialog: FunctionComponent<RuleSetDialogProps> = (props: RuleSetDialogProps) => {
  const [ruleSetForReact, setRuleSetForReact] = useState<RuleSetForReact>();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [params, setParams] = useState<ParamForReact[]>([]);
  const [dunningLevels, setDunningLevels] = useState<DunningLevelForReact[]>([]);
  const [rules, setRules] = useState<RuleForReact[]>([]);
  const [usedParams, setUsedParams] = useState<ParamForReact[]>([]);
  const [usedDunningLevels, setUsedDunningLevels] = useState<Record<string, number>>({});

  const [showEmptyNameWarning, setShowEmptyNameWarning] = useState<boolean>(false);
  const [isNameInvalid, setIsNameInvalid] = useState<boolean>(false);
  const [showUniqueParamWarning, setShowUniqueParamWarning] = useState<boolean>(false);
  const [isDescriptionInvalid, setIsDescriptionInvalid] = useState<boolean>(false);
  const [showUniqueDunningLevelWarning, setShowUniqueDunningLevelWarning] = useState<boolean>(false);
  const [isSuccessButtonDisabled, setIsSuccessButtonDisabled] = useState<boolean>(false);
  const [showUsedLevelChangeWarning, setShowUsedLevelChangeWarning] = useState<boolean>(false);
  const [isNameAndDescriptionEditable, setIsNameAndDescriptionEditable] = useState<boolean>(
    props.isNameAndDescriptionEditable,
  );
  const [showFileUpload, setShowFileUpload] = useState<boolean>(false);
  const [showManualEntry, setShowManualEntry] = useState<boolean>(true);
  const [showUploadErrorMessage, setShowUploadErrorMessage] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [subsequentRuleset, setSubsequentRuleset] = useState<BasicRuleSet>();
  const license = useContext(LicenseContext);

  const initialDunningLevel: DunningLevelForReact =
    ruleSetForReact?.dunningLevels.find(dunningLevel => dunningLevel.level === 0) ??
    new DunningLevelForReact({
      name: '',
      level: 0,
      description: '',
      id: '',
    });

  const initialRuleSetCriteria: RuleSetAssignmentCriteriaForReact = new RuleSetAssignmentCriteriaForReact({
    id: '',
    ruleSetId: '',
    caseConditions: [],
    customerCaseConditions: [],
  });
  const [ruleSetAssignmentCriteria, setRuleSetAssignmentCriteria] =
    useState<RuleSetAssignmentCriteriaForReact>(initialRuleSetCriteria);
  const [validations, setValidations] = useState<ValidationObject>();

  const [availableDunningLevels, setAvailableDunningLevels] = useState<DunningLevelForReact[]>([]);

  function loadRuleSetData() {
    if (props.ruleSetId !== undefined) {
      getRuleSetWithRule(props.ruleSetId)?.then(result => {
        setRuleSetForReact(result);
        setName(result.name ?? '');
        setDescription(result.description ?? '');
        setParams(result.parameters);
        setDunningLevels(
          result.dunningLevels.filter(dunningLevel => dunningLevel.level !== 0).sort((l1, l2) => l1.level - l2.level),
        );
        setRules(result.rules);
        const usedParameters = getParameterUsedInRules(result.rules, result.parameters);
        setUsedDunningLevels(countDunningLevelsUsageInRules(result.rules, result.dunningLevels));
        setUsedParams(usedParameters);
      });
    }
  }

  function loadDunningLevel() {
    getAllDunningLevels().then(dunningLevels => {
      setAvailableDunningLevels(dunningLevels.map(level => new DunningLevelForReact(level)));
    });
  }

  function loadRulesetDynamicCriteria() {
    if (props.ruleSetId !== undefined) {
      getRulesetDynamicCriteria(props.ruleSetId).then(criteria => {
        setRuleSetAssignmentCriteria(criteria);
      });
    }
  }

  useEffect(() => {
    loadRuleSetData();
    loadDunningLevel();
    loadRulesetDynamicCriteria();
  }, []);

  function reset() {
    setName(ruleSetForReact?.name ?? '');
    setShowEmptyNameWarning(validateIsEmptyString(ruleSetForReact?.name ?? ''));
    setDescription(ruleSetForReact?.description ?? '');
    setDunningLevels(
      ruleSetForReact?.dunningLevels
        .filter(dunningLevel => dunningLevel.level !== 0)
        .sort((l1, l2) => l1.level - l2.level) ?? [],
    );
    setParams(ruleSetForReact?.parameters ?? []);
    setRules(ruleSetForReact?.rules ?? []);
    setShowUploadErrorMessage(false);
    setFileName('');
    setRuleSetAssignmentCriteria(initialRuleSetCriteria);
  }

  function validateParamNameUnique(): boolean {
    for (const param of params) {
      if (params.filter(item => param.name === item.name).length > 1) {
        setShowUniqueParamWarning(true);
        return true;
      }
      if (param.name === '') {
        return true;
      }
    }
    setShowUniqueParamWarning(false);
    return false;
  }

  function validateDunningLevelNameUnique(): boolean {
    for (const dunningLevel of dunningLevels) {
      if (dunningLevels.filter(item => dunningLevel.name === item.name).length > 1) {
        setShowUniqueDunningLevelWarning(true);
        return true;
      }
      if (dunningLevel.name === '' && dunningLevel.level !== 0) {
        return true;
      }
    }
    setShowUniqueDunningLevelWarning(false);
    return false;
  }

  function isNameEmpty(): boolean {
    if (validateIsEmptyString(name)) {
      setShowEmptyNameWarning(true);
      return true;
    }
    setShowEmptyNameWarning(false);
    return false;
  }

  function areParamsValid(): boolean {
    return params.filter(param => !param.isValid).length === 0;
  }

  function areDunningLevelsValid(): boolean {
    return dunningLevels.filter(level => !level.isValid).length === 0;
  }

  function setRuleSetInCriteria(rulesetId: string) {
    const criteria = ruleSetAssignmentCriteria;
    criteria.ruleSetId = rulesetId;
    setRuleSetAssignmentCriteria(criteria);
  }

  function validateCriteria(): boolean {
    return validateRuleObject(validations);
  }

  function hasEmptyId(ruleSetAssignmentCriteria: RuleSetAssignmentCriteriaForReact) {
    return (
      ruleSetAssignmentCriteria.id === undefined ||
      ruleSetAssignmentCriteria.id === null ||
      ruleSetAssignmentCriteria.id.length < 1
    );
  }

  function hasAnyCondition(ruleSetAssignmentCriteria: RuleSetAssignmentCriteriaForReact) {
    return (
      ruleSetAssignmentCriteria.caseConditions.length > 0 || ruleSetAssignmentCriteria.customerCaseConditions.length > 0
    );
  }

  async function createOrUpdateRulesetDynamicCriteria(ruleSetAssignmentCriteria: RuleSetAssignmentCriteriaForReact) {
    if (hasAnyCondition(ruleSetAssignmentCriteria)) {
      if (hasEmptyId(ruleSetAssignmentCriteria)) {
        await createRulesetDynamicCriteria(ruleSetAssignmentCriteria);
      } else {
        await updateRulesetDynamicCriteria(ruleSetAssignmentCriteria);
      }
    }
  }
  async function handleSubmit() {
    setIsSuccessButtonDisabled(true);
    if (
      !validateDunningLevelNameUnique() &&
      !validateParamNameUnique() &&
      !isNameEmpty() &&
      !isNameInvalid &&
      !isDescriptionInvalid &&
      areParamsValid() &&
      areDunningLevelsValid() &&
      validateCriteria()
    ) {
      if (ruleSetForReact && params && dunningLevels) {
        const id = ruleSetForReact.rulesetId;
        if (props.isParamAndLevelEditable) {
          await updateRuleSet(id, name, description, params, dunningLevels, subsequentRuleset)
            .then(() => {
              props.onCloseDialog();
              props.onConfirmRuleSetChange(id);
              setRuleSetInCriteria(id);
              createOrUpdateRulesetDynamicCriteria(ruleSetAssignmentCriteria);
            })
            .catch(error => alertToast(error))
            .finally(() => setIsSuccessButtonDisabled(false));
        } else {
          await updateRuleSetNameAndDescription(id, name, description)
            .then(() => {
              props.onConfirmRuleSetChange(id);
              setRuleSetInCriteria(id);
              createOrUpdateRulesetDynamicCriteria(ruleSetAssignmentCriteria);
            })
            .catch(error => alertToast(error))
            .finally(() => setIsSuccessButtonDisabled(false));
        }
      } else {
        await createRuleSet(name, description, params, dunningLevels, rules, subsequentRuleset)
          .then(ruleset => {
            props.onConfirmRuleSetChange(ruleset.rulesetId);
            setRuleSetInCriteria(ruleset.rulesetId);
            createOrUpdateRulesetDynamicCriteria(ruleSetAssignmentCriteria);
          })
          .catch(error => alertToast(error))
          .finally(() => setIsSuccessButtonDisabled(false));
      }
    } else {
      setIsSuccessButtonDisabled(false);
    }
  }

  function onNameChange(newName: string) {
    setName(newName);
    if (validateIsEmptyString(newName)) {
      setShowEmptyNameWarning(true);
    } else {
      setShowEmptyNameWarning(false);
    }
    if (!validateHasForbiddenPunctuation(newName) && !validateHasEmojiPresentation(newName)) {
      setIsNameInvalid(false);
    } else {
      setIsNameInvalid(true);
    }
  }

  function onDescriptionChange(newDescription: string) {
    setDescription(newDescription);
    if (!validateHasForbiddenPunctuation(newDescription) && !validateHasEmojiPresentation(newDescription)) {
      setIsDescriptionInvalid(false);
    } else {
      setIsDescriptionInvalid(true);
    }
  }

  function deleteParam(reactId: string) {
    const newArr = [...params];
    const index = params.findIndex(x => x.reactId === reactId);
    newArr.splice(index, 1);
    setParams(newArr);
  }

  function addParam() {
    const emptyParam: ParamForReact = new ParamForReact();
    setParams([...params, emptyParam]);
    setShowUniqueParamWarning(false);
  }

  function onParamChange(param: ParamForReact) {
    const newArr = [...params];
    const index = params.findIndex(x => x.reactId === param.reactId);
    newArr[index] = param;

    setParams(newArr);
  }

  function getLevel() {
    const levels = dunningLevels.map(l => l.level);
    const level = Math.max(...levels) + 1;
    return level > 0 ? level : 1;
  }

  function addDunningLevel() {
    const emptyDunningLevel: DunningLevelForReact = new DunningLevelForReact();
    emptyDunningLevel.level = getLevel();
    emptyDunningLevel.isValid = false;
    setDunningLevels([...dunningLevels, emptyDunningLevel]);
    setShowUniqueDunningLevelWarning(false);
  }

  function deleteDunningLevel(level: number) {
    const newArr = [...dunningLevels];
    const index = dunningLevels.findIndex(dunningLevel => dunningLevel.level === level);
    newArr.splice(index, 1);
    for (let i = index; i < dunningLevels.length - 1; i++) {
      newArr[i].level = i + 1;
    }
    setDunningLevels(newArr);
  }

  function onDunningLevelChange(dunningLevel: DunningLevelForReact) {
    const newArr = [...dunningLevels];
    const index = dunningLevels.findIndex(x => x.level === dunningLevel.level);
    newArr[index] = dunningLevel;
    setDunningLevels(newArr);
  }

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      // if it is dragged outside droppable component
      return;
    }

    const newOrder: DunningLevelForReact[] = JSON.parse(JSON.stringify(dunningLevels));
    const [removed] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, removed);
    let number = 1;
    newOrder.forEach(dunningLevel => (dunningLevel.level = number++));
    setShowUsedLevelChangeWarning(false);

    newOrder.forEach(ordered => {
      const oldOrder = dunningLevels.find(dl => dl.reactId === ordered.reactId);
      if (usedDunningLevels[ordered.reactId] > 0 && ordered.level !== oldOrder?.level) {
        setShowUsedLevelChangeWarning(true);
      }
    });

    setDunningLevels(newOrder);
  }

  function parseJsonFile(fileList: FileList | null) {
    if (fileList) {
      const file = fileList[0];
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        try {
          const result = reader.result as string;
          const parsedData = JSON.parse(result);
          const { assignmentCriteria, ...ruleSetData } = parsedData;
          const uploadedRuleSet = new RuleSetWithAssignmentCriteria(ruleSetData);
          if (assignmentCriteria) {
            // Handle the assignmentCriteria if it exists
            assignmentCriteria.ruleSetId = '';
            assignmentCriteria.id = '';
            setRuleSetAssignmentCriteria(assignmentCriteria);
          }

          setName(uploadedRuleSet.name || '');
          setShowEmptyNameWarning(validateIsEmptyString(uploadedRuleSet.name || ''));
          setDescription(uploadedRuleSet.description ?? '');
          setDunningLevels(
            uploadedRuleSet.dunningLevels ? uploadedRuleSet.dunningLevels.filter(level => level.level !== 0) : [],
          );

          setParams(uploadedRuleSet.parameters || []);
          setRules(uploadedRuleSet.rules);
          setUsedDunningLevels(countDunningLevelsUsageInRules(uploadedRuleSet.rules, uploadedRuleSet.dunningLevels));
          const usedParameters = getParameterUsedInRules(uploadedRuleSet.rules, uploadedRuleSet.parameters);
          setUsedParams(usedParameters);
          setShowUploadErrorMessage(false);
          setFileName(file.name);
          setShowFileUpload(true);
          setShowManualEntry(true);
        } catch (error) {
          setShowUploadErrorMessage(true);
        }
      };

      reader.onerror = () => {
        setShowUploadErrorMessage(true);
      };
    }
  }

  function toggleEntryType(index: string) {
    setShowFileUpload(index === 'upload');
    setShowManualEntry(index === 'manual');
    reset();
  }

  function onChangeSubsequentRuleSet(rs: BasicRuleSet) {
    setSubsequentRuleset(rs);
  }

  const onConditionChangeHandlerFactory =
    (setter: (data: any, object: any) => void) =>
    (newConditions: any, validations: Record<string, Record<string, ValidationResult[] | undefined>>) => {
      setRuleSetAssignmentCriteria(prevCriteria => {
        const copy = { ...prevCriteria };
        setter(newConditions, copy);
        return copy;
      });
      setValidations(old => {
        const copy = { ...old };
        setter(validations, copy);
        return copy;
      });
    };

  const onCustomerCaseConditionsChange = onConditionChangeHandlerFactory(
    (data, object) => (object.customerCaseConditions = data),
  );

  const onCaseConditionsChange = onConditionChangeHandlerFactory((data, object) => (object.caseConditions = data));

  return (
    <Dialog className="dialog medium" isOpen canOutsideClickClose={false} onClose={() => props.onCloseDialog()}>
      <div className="dialog-title">{props.dialogTitle}</div>
      <div className={Classes.DIALOG_BODY}>
        {/* **************************************************** */}
        {/* *****************  Error Message ******************* */}
        {/* **************************************************** */}

        {!ruleSetForReact &&
          license.isFeatureEnabled(LICENSE_FEATURE_PACK.RULESET_PACK, LICENSE_FEATURE.IMPORT_RULESET) && (
            <RadioSelect
              testId={`${testAttribute('653a', 'dialog.ruleset.manual')}-`}
              options={[
                {
                  key: 'manual',
                  label: i18n.t('dialog.ruleset.manual'),
                },
                {
                  key: 'upload',
                  label: i18n.t('dialog.ruleset.upload'),
                },
              ]}
              checkedOptionKey={showFileUpload ? 'upload' : 'manual'}
              onSelect={index => toggleEntryType(index)}
            />
          )}
        {!validateCriteria() && <RuleValidationView errorMessage="" validations={validations} />}
        {showFileUpload && (
          <FormGroup
            label={<span className="required-field">{i18n.t('dialog.ruleset.file')}</span>}
            data-test-id={testAttribute('fg3a', 'dialog.ruleset.file')}
            labelFor="dunning-file-upload"
            intent={showUploadErrorMessage ? 'danger' : undefined}
            helperText={
              showUploadErrorMessage && <span className="red-text">{i18n.t('dialog.ruleset.upload-error')}</span>
            }>
            <input
              className="dunning-file-upload"
              id="dunning-file-upload"
              type="file"
              accept=".json"
              onChange={event => parseJsonFile(event.target.files)}
              data-test-id={testAttribute('fg3a', 'dunning-file-upload')}
            />
            <InputGroup
              id="file-input"
              data-test-id={testAttribute('fi3a', 'file-input')}
              readOnly
              placeholder={i18n.t('dialog.ruleset.upload-placeholder')}
              value={fileName}
              rightElement={
                <Button testId={testAttribute('GbFn', 'dialog.ruleset.search')}>
                  <label className="pointer" htmlFor="dunning-file-upload">
                    {i18n.t('dialog.ruleset.search')}
                  </label>
                </Button>
              }
            />
          </FormGroup>
        )}

        {(!showFileUpload || showManualEntry) && (
          <>
            {rules.length > 0 && !ruleSetForReact && (
              <Callout intent="primary" icon={<InfoSign />}>
                {i18n.t('dialog.ruleset.upload-rules', {
                  count: rules.length,
                })}
              </Callout>
            )}
            {/* Ruleset name and description */}
            {isNameAndDescriptionEditable && (
              <>
                <FormGroup
                  data-test-id={testAttribute('fn3a', 'dialog.name-required')}
                  label={<span className="required-field">{i18n.t('dialog.ruleset.name')}</span>}
                  labelFor="name-input"
                  helperText={
                    showEmptyNameWarning ? (
                      <span className="red-text">{i18n.t('dialog.name-mandatory')}</span>
                    ) : undefined
                  }>
                  <InputGroup
                    id="name-input"
                    className="short"
                    autoFocus
                    placeholder={i18n.t('dialog.enter-name')}
                    data-test-id={testAttribute('gft5', 'dialog.enter-name')}
                    maxLength={255}
                    value={name}
                    onChange={event => onNameChange(event.target.value)}
                    intent={showEmptyNameWarning ? 'danger' : undefined}
                  />
                  {isNameInvalid && <span className="red-text">{i18n.t('dialog.name-invalid')}</span>}
                </FormGroup>
                <FormGroup
                  label={i18n.t('dialog.ruleset.description')}
                  labelFor="description-input"
                  data-test-id={testAttribute('fd3a', 'description-input')}
                  helperText={
                    isDescriptionInvalid ? (
                      <span className="red-text">{i18n.t('dialog.description-invalid')}</span>
                    ) : undefined
                  }>
                  <TextArea
                    id="description-input"
                    className="full-width"
                    data-test-id={testAttribute('f5xx', 'dialog.enter-description')}
                    maxLength={255}
                    onChange={event => onDescriptionChange(event.target.value)}
                    value={description}
                    placeholder={i18n.t('dialog.enter-description')}
                  />
                </FormGroup>
              </>
            )}
            {!isNameAndDescriptionEditable && (
              <>
                <div className="param-grid-view bottom-line">
                  <div className="highlighted-text">{i18n.t('name')}</div>
                  <div>{name}</div>
                </div>
                <div className="param-grid-view bottom-line">
                  <div className="highlighted-text">{i18n.t('description')}</div>
                  <div>{description}</div>
                </div>
              </>
            )}

            {/* **************************************************** */}
            {/** ******************  Blue callout ******************** */}
            {/* **************************************************** */}
            {isNameAndDescriptionEditable && !props.isParamAndLevelEditable && (
              <Callout intent="primary" icon={<Error />}>
                {i18n.t('dialog.rule.rule-edit-active-callout')}
              </Callout>
            )}

            {/* *********************************************** */}
            {/* ********* subsequent final invoice ruleset ************ */}
            {/* *********************************************** */}
            <div className="dialog-title-2">{i18n.t('dialog.subsequent-procedure')}</div>
            {ruleSetForReact && !ruleSetForReact.subsequentRuleset && !props.isParamAndLevelEditable ? (
              i18n.t('dialog.ruleset.no-subsequent-ruleset')
            ) : (
              <RuleSetSubsequentSelector
                options={props.subsequentRuleSets}
                currentOption={ruleSetForReact?.subsequentRuleset}
                isEditable={props.isParamAndLevelEditable}
                onRulesetSubsequentChange={sub => onChangeSubsequentRuleSet(new BasicRuleSet(sub.rulesetId, sub.name))}
              />
            )}
            <>
              {/* Warning level title */}
              <div className="dialog-title-2">{i18n.t('dialog.ruleset.dunning-title')}</div>

              {/* Warning level list header */}
              {showUsedLevelChangeWarning && <Callout intent="warning">{i18n.t('dialog.ruleset.level-used')}</Callout>}

              <div>
                <div
                  className={
                    props.isParamAndLevelEditable
                      ? 'level-grid dunning-bold-title'
                      : 'level-grid-view dunning-bold-title'
                  }>
                  <div>{i18n.t('dialog.level')}</div>
                  {props.isParamAndLevelEditable && <div />}
                  <div className="required-field">{i18n.t('name')}</div>
                  <div>{i18n.t('description')}</div>
                  <div> {i18n.t('dialog.ruleset.used-in')}</div>
                </div>
              </div>

              {/* *********************************************** */}
              {/* ------------ Initial dunning level ------------ */}
              {/* *********************************************** */}

              <DunningLevelEdit
                dunningLevel={initialDunningLevel}
                isInitialDunningLevel
                isEditable={props.isParamAndLevelEditable}
                onDunningLevelChange={(dunningLevel: DunningLevelForReact) => onDunningLevelChange(dunningLevel)}
                numberOfRulesLevelIsUsedIn={0}
                onDeleteDunningLevel={() => undefined}
                dunningLevels={availableDunningLevels}
              />

              <DragDropContext onDragEnd={dropResult => onDragEnd(dropResult)}>
                <Droppable droppableId="droppable">
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {dunningLevels?.map((dunningLevel: DunningLevelForReact, index) => {
                        return (
                          <Draggable
                            key={dunningLevel.reactId}
                            draggableId={dunningLevel.reactId}
                            index={index}
                            isDragDisabled={!props.isParamAndLevelEditable}>
                            {provided => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <DunningLevelEdit
                                  key={dunningLevel.level}
                                  dunningLevel={dunningLevel}
                                  isEditable={props.isParamAndLevelEditable}
                                  numberOfRulesLevelIsUsedIn={usedDunningLevels[dunningLevel.reactId] ?? 0}
                                  onDunningLevelChange={(dunningLevel: DunningLevelForReact) =>
                                    onDunningLevelChange(dunningLevel)
                                  }
                                  onDeleteDunningLevel={level => deleteDunningLevel(level)}
                                  dunningLevels={availableDunningLevels}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {props.isParamAndLevelEditable && (
                <div className="right-text">
                  <Button
                    testId={testAttribute('MB78', 'dialog.ruleset.add-level')}
                    type="tertiary"
                    id="add-dunning-level"
                    icon={<Plus />}
                    onClick={() => addDunningLevel()}>
                    {i18n.t('dialog.ruleset.add-level')}
                  </Button>
                </div>
              )}
              {showUniqueDunningLevelWarning && (
                <div className="red-text">{i18n.t('dialog.dupilcate-name-warning')}</div>
              )}

              {/* *********************************************** */}
              {/* ------------------ Parameters ----------------- */}
              {/* *********************************************** */}

              {license.isFeatureEnabled(LICENSE_FEATURE_PACK.RULESET_PACK, LICENSE_FEATURE.RULESET_PARAMETERS) && (
                <RuleSetParameters
                  params={params}
                  isParamAndLevelEditable={props.isParamAndLevelEditable}
                  usedParams={usedParams}
                  onParamChange={param => onParamChange(param)}
                  onDeleteParam={param => deleteParam(param)}
                  showUniqueParamWarning={showUniqueParamWarning}
                  onAddParam={() => addParam()}
                />
              )}

              {/* *********************************************** */}
              {/* -------------- Dynamic Criteria---------------- */}
              {/* *********************************************** */}
              <IfLicensed unlimited>
                <RuleSetDynamicCriteria
                  params={params}
                  ruleSetAssignmentCriteria={ruleSetAssignmentCriteria}
                  dunningLevels={dunningLevels}
                  isParamAndLevelEditable={props.isParamAndLevelEditable}
                  onCaseConditionsChange={onCaseConditionsChange}
                  onCustomerCaseConditionsChange={onCustomerCaseConditionsChange}
                />
              </IfLicensed>
            </>
          </>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {!isNameAndDescriptionEditable && ruleSetForReact && (
            <Button
              testId={testAttribute('R2D2', 'button.edit')}
              id="switch-button"
              className="button margin-right-auto"
              onClick={() => setIsNameAndDescriptionEditable(true)}>
              {i18n.t('button.edit')}
            </Button>
          )}

          <Button
            id="cancel-button"
            className="button"
            testId={testAttribute('C3PO', 'button.cancel')}
            onClick={() => props.onCloseDialog()}>
            {props.buttonTextClose ?? i18n.t('button.cancel')}
          </Button>
          {(props.isParamAndLevelEditable || isNameAndDescriptionEditable) && (
            <Button
              testId={testAttribute('fml1', 'save-button')}
              id="save-button"
              className="button"
              type="primary"
              disabled={isSuccessButtonDisabled}
              onClick={() => handleSubmit()}>
              {props.buttonTextSuccess}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default RuleSetDialog;
