import { MenuItem } from '@blueprintjs/core';
import { CaretDown } from '@blueprintjs/icons';
import { Select } from '@blueprintjs/select';

import i18n from 'i18n/i18n';
import { Button } from 'lib/Button';
import { testAttribute } from 'util/Util';

import { type Filter, FilterType } from '../types/FilterModel';

export interface FilterRowProps {
  filters: Filter[];
  values: Record<string, any>;
  onChange: (key: string, newValue: any) => void;
  onSelectionChange: (key: string) => void;
  selection: string;
}

function TableFilterRow({ filters, onChange, values, onSelectionChange, selection }: FilterRowProps) {
  const filter = filters.find(filter => filter.key === selection);

  function onSelectionChangeHandler(res: Filter) {
    onSelectionChange(res.key);
  }

  const listItemRenderer = (element: Filter, modifiers: any) => (
    <MenuItem
      id={`select-general-${element.name}`}
      active={modifiers.active}
      key={element.name}
      onClick={() => onSelectionChangeHandler(element)}
      text={element.name}
    />
  );
  return (
    <>
      <div className="lib-table-filter__labeled-box">
        <span className="lib-table-filter__label">{i18n.t('action-page.filters.by')}</span>
        <Select<Filter>
          activeItem={filter}
          items={filters.filter(f => f.type === FilterType.DYNAMIC)}
          itemRenderer={listItemRenderer}
          onItemSelect={onSelectionChangeHandler}
          filterable={false}
          fill
          popoverProps={{ position: 'bottom' }}>
          <Button testId={testAttribute('dalw', 'caret-down')} type="default" rightIcon={<CaretDown />}>
            <span className="lib-table-filter__button-text">{filter?.name}</span>
          </Button>
        </Select>
      </div>
      <div className="lib-table-filter__input lib-table-filter__labeled-box">
        {filter?.render({
          value: values[filter.key],
          onChange: newValue => onChange(filter.key, newValue),
          label: filter.name,
        })}
      </div>
    </>
  );
}

export default TableFilterRow;
