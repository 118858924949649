import { useContext } from 'react';

import { LicenseContext } from 'App';

import {
  ACTION_TYPE,
  CASE_ATTRIBUTE,
  CUSTOMER_ATTRIBUTE,
  ITEM_ATTRIBUTE,
  LICENSE_MODEL,
  OPERATOR,
} from 'interfaces/enums';

import { LICENSE_FEATURE_PACK } from '../license/AppLicense';
import { isCaseAttributeDisabled, toPascalCase } from '../util/Util';

// TODO: refactor based on license
export const useCaseAttributesForLicense = () => {
  const license = useContext(LicenseContext);
  if (license.appLicense.name === LICENSE_MODEL.LIGHT) {
    return [
      CASE_ATTRIBUTE.COMMUNICATION_PROFILE,
      CASE_ATTRIBUTE.BALANCE_CONTRACT_AMOUNT,
      CASE_ATTRIBUTE.WORKING_DAYS_SINCE_LAST_NOTIFICATION,
      CASE_ATTRIBUTE.WORKING_DAYS_SINCE_LAST_EXECUTION,
      CASE_ATTRIBUTE.CALENDAR_DAYS_SINCE_LAST_NOTIFICATION,
      CASE_ATTRIBUTE.CALENDAR_DAYS_SINCE_LAST_EXECUTION,
    ];
  }
  return Object.values(CASE_ATTRIBUTE).filter(caseAttr => !isCaseAttributeDisabled(caseAttr));
};

export const useItemAttributesForGroupSelectorAndLicense = () => {
  const license = useContext(LicenseContext);
  return license.appLicense.name === LICENSE_MODEL.LIGHT
    ? [ITEM_ATTRIBUTE.LEVEL, ITEM_ATTRIBUTE.OVERDUE_CALENDAR_DAYS, ITEM_ATTRIBUTE.OVERDUE_WORKING_DAYS]
    : Object.values(ITEM_ATTRIBUTE);
};

export const useItemAttributesForAggregatedConditionAndLicense = () => {
  const license = useContext(LicenseContext);
  return license.appLicense.name === LICENSE_MODEL.LIGHT
    ? [ITEM_ATTRIBUTE.OPEN_AMOUNT]
    : [ITEM_ATTRIBUTE.OPEN_AMOUNT, ITEM_ATTRIBUTE.GROSS_AMOUNT, ITEM_ATTRIBUTE.SETTLED_AMOUNT];
};

export const useOperatorsForGroupSelectorAndLicense = () => {
  const license = useContext(LicenseContext);
  return {
    [ITEM_ATTRIBUTE.LEVEL]: license.appLicense.name === LICENSE_MODEL.LIGHT ? [OPERATOR.EQ] : Object.values(OPERATOR),
    [ITEM_ATTRIBUTE.OVERDUE_CALENDAR_DAYS]:
      license.appLicense.name === LICENSE_MODEL.LIGHT ? [OPERATOR.GE, OPERATOR.GT] : Object.values(OPERATOR),
    [ITEM_ATTRIBUTE.OVERDUE_WORKING_DAYS]:
      license.appLicense.name === LICENSE_MODEL.LIGHT ? [OPERATOR.GE, OPERATOR.GT] : Object.values(OPERATOR),
  } as Record<Partial<ITEM_ATTRIBUTE>, any>;
};

export const useOperatorsForAggregConditionAndLicense = () => {
  const license = useContext(LicenseContext);
  return {
    [ITEM_ATTRIBUTE.OPEN_AMOUNT]:
      license.appLicense.name === LICENSE_MODEL.LIGHT ? [OPERATOR.GE, OPERATOR.GT] : Object.values(OPERATOR),
  } as Record<Partial<ITEM_ATTRIBUTE>, any>;
};

export const useActionsForLicense = () => {
  const license = useContext(LicenseContext);
  const actions =
    license.appLicense.featurePacks
      .find(feature => feature.name === LICENSE_FEATURE_PACK.ACTIONS_PACK)
      ?.features.filter(feature => feature.enabled) ?? [];
  const actionsByLicense = Object.values(ACTION_TYPE);
  return actionsByLicense.filter(item => actions.find(feat => item === feat.name));
};

export const useAttributesForLicense = (
  featurePack: string,
  attributeType: 'CUSTOMER_ATTRIBUTE' | 'CASE_ATTRIBUTE',
) => {
  const license = useContext(LicenseContext);
  const attributes =
    license.appLicense.featurePacks
      .find(feature => feature.name === featurePack)
      ?.features.filter(feature => feature.enabled) ?? [];
  const attributesByLicense = Object.values(
    attributeType === 'CUSTOMER_ATTRIBUTE' ? CUSTOMER_ATTRIBUTE : CASE_ATTRIBUTE,
  );
  return attributesByLicense.filter(item => attributes.find(feat => toPascalCase(item) === feat.name));
};
