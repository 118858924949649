import { type FunctionComponent, useCallback, useEffect, useState } from 'react';

import { Position } from '@blueprintjs/core';
import { DateInput3 } from '@blueprintjs/datetime2';

import i18n from 'i18n/i18n';

import { testAttribute } from '../../../util/Util';

interface ValidationResult {
  error: boolean;
  message?: string;
}

type Validator<T = any> = (value: T) => (ValidationResult | null) | Promise<ValidationResult | null>;

class ExecutionFilterDateInputProps {
  value?: Date[];
  placeholder?: string;
  validators?: Array<Validator<Date[]>>;
  onChange?: (value: Date[] | null | undefined, valid?: boolean) => void;
  additionalText?: string;
  label?: string;
}

const ExecutionFilterDateRangeInput: FunctionComponent<ExecutionFilterDateInputProps> = props => {
  const [value, setValue] = useState<Date[]>(
    props.value ?? [new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000), new Date()],
  );
  const [error, setError] = useState<ValidationResult | null>(null);
  const formatDate = useCallback((date: Date) => date.toLocaleDateString(i18n.language), []);
  const parseDate = useCallback((str: string) => new Date(Date.parse(str)), []);

  useEffect(() => {
    if (!props.value && props.onChange) props.onChange(value);
  }, [props, value]);

  async function validateInput(input: Date[]) {
    if (!props.validators) return false;
    const validationResult = await Promise.all(props.validators.map(validator => validator(input)));
    const firstError = validationResult.find(result => result?.error);
    if (firstError) {
      setError(firstError);
      return true;
    }
    setError(null);
    return false;
  }

  async function onChangeStartHandler(val: Date | null) {
    const newValue = [val ?? value[0], value[1]];
    setValue(newValue);
    let hasError = false;
    if (val !== null) {
      hasError = await validateInput(newValue);
    }
    if (props.onChange) props.onChange(newValue, !hasError);
  }

  async function onChangeEndHandler(val: Date | null) {
    const newValue = [value[0], val ?? value[1]];
    setValue(newValue);
    let hasError = false;
    if (val !== null) {
      hasError = await validateInput(newValue);
    }
    if (props.onChange) props.onChange(newValue, !hasError);
  }

  return (
    <>
      <span className="lib-table-filter__label">{props.label}</span>
      <div className="flex-row fill">
        <DateInput3
          data-test-id={testAttribute('efdr', 'execution-filter.date-1')}
          formatDate={formatDate}
          placeholder=""
          parseDate={parseDate}
          defaultValue={value[0].toLocaleDateString(i18n.language)}
          onChange={e => onChangeStartHandler(e !== null ? new Date(e) : null)}
          popoverProps={{ position: Position.BOTTOM }}
          fill
          maxDate={new Date()}
        />
        <DateInput3
          data-test-id={testAttribute('efdr', 'execution-filter.date-2')}
          formatDate={formatDate}
          placeholder=""
          parseDate={parseDate}
          defaultValue={value[1].toLocaleDateString(i18n.language)}
          onChange={e => onChangeEndHandler(e !== null ? new Date(e) : null)}
          popoverProps={{ position: Position.BOTTOM }}
          fill
          maxDate={new Date()}
        />
      </div>
    </>
  );
};

export { ExecutionFilterDateRangeInput };
