import i18n from '../../../../../i18n/i18n';
import { ACTION_TYPE, DAY_TYPE } from '../../../../../interfaces/enums';
import { ActionForReact, DunningLevelForReact } from '../../../../../interfaces/Interfaces';
import { Selectable } from '../../../../DunningSelectionPage/DunningSelection/input/InputInterfaces';
import { RuleValidators } from '../../Validation/RuleValidators';
import {
  RuleConditionDefinition,
  RuleConditionInputDefinition,
  RuleConditionInputTypes,
} from '../RuleCondition/RuleConditionTypes';
import {
  RuleDefinitionBooleanValue,
  RuleDefinitionNumericValue,
  RuleDefinitionStringValue,
} from '../RuleCondition/RuleInputs/RuleDefinitionBaseProps';

export const actionDefinitionFactory: (dunningLevels: DunningLevelForReact[]) => {
  [key in ACTION_TYPE]: RuleConditionDefinition;
} = dunningLevels => {
  const settlementDaysInput: RuleConditionInputDefinition = {
    type: RuleConditionInputTypes.INTEGER,
    getter: obj => new RuleDefinitionNumericValue(obj.settlementDays ?? undefined),
    setter: (obj, value) => {
      obj.settlementDays = value.toValue();
    },
    inputProps: { disableVariables: true },
    validators: [RuleValidators.action.greaterOrEqualsZero],
  };
  const dayTypeInput: RuleConditionInputDefinition = {
    type: RuleConditionInputTypes.SELECT,
    label: i18n.t('dialog.rule.nr-of-days'),
    getter: obj => new RuleDefinitionStringValue(obj.settlementDayType ?? DAY_TYPE.CALENDAR_DAYS),
    setter: (obj, value) => (obj.settlementDayType = value.toValue()),
    selectables: Object.values(DAY_TYPE).map(
      key =>
        ({
          key,
          label: i18n.t(`select.${key}`),
        }) as Selectable,
    ),
    visible: (obj: ActionForReact, isEditable) => isEditable || obj.settlementDays !== undefined,
    validators: [RuleValidators.action.required],
  };

  return {
    [ACTION_TYPE.DISCONNECTION_ANNOUNCEMENT]: {
      inputs: [
        dayTypeInput,
        settlementDaysInput,
        {
          type: RuleConditionInputTypes.BOOLEAN,
          label: `${i18n.t('dialog.rule.offer-instalment-plan')}`,
          getter: obj => new RuleDefinitionBooleanValue(obj.installmentPlan ?? false),
          setter: (obj, value) => (obj.installmentPlan = value.toValue()),
        },
      ],
    },
    [ACTION_TYPE.DISCONNECTION_THREAT]: {
      inputs: [
        dayTypeInput,
        settlementDaysInput,
        {
          type: RuleConditionInputTypes.BOOLEAN,
          label: `${i18n.t('dialog.rule.offer-instalment-plan')}`,
          getter: obj => new RuleDefinitionBooleanValue(obj.installmentPlan ?? false),
          setter: (obj, value) => (obj.installmentPlan = value.toValue()),
        },
      ],
    },
    [ACTION_TYPE.NOTIFICATION]: {
      inputs: [
        {
          type: RuleConditionInputTypes.STRING,
          getter: obj => new RuleDefinitionStringValue(obj.type ?? ''),
          setter: (obj: ActionForReact, value) => (obj.type = value.toValue()),
          validators: [RuleValidators.action.required],
        },
        dayTypeInput,
        settlementDaysInput,
      ],
    },
    [ACTION_TYPE.FEE]: {
      inputs: [
        {
          type: RuleConditionInputTypes.CURRENCY,
          getter: obj => new RuleDefinitionNumericValue(obj.amount),
          setter: (obj, value) => (obj.amount = value.toValue()),
          validators: [RuleValidators.action.required, RuleValidators.validCurrency],
          removeParameterPanel : true
        },
      ],
    },
    [ACTION_TYPE.CHANGE_OF_STATUS]: {
      inputs: [
        {
          type: RuleConditionInputTypes.SELECT,
          getter: obj => new RuleDefinitionStringValue(obj.newStatusId ?? ''),
          setter: (obj, value) => (obj.newStatusId = value.toValue()),
          validators: [RuleValidators.action.required],
          selectables: dunningLevels.map(dl => ({
            key: dl.id,
            label: dl.name.length ? dl.name : i18n.t('dialog.ruleset.init-level'),
          })),
        },
        {
          type: RuleConditionInputTypes.BOOLEAN,
          label: `${i18n.t('dialog.rule.apply-to-all-receivables')}`,
          getter: obj => new RuleDefinitionBooleanValue(obj.applyToAllReceivables ?? false),
          setter: (obj, value) => (obj.applyToAllReceivables = value.toValue()),
        },
      ],
    },
    [ACTION_TYPE.DISCONNECTION]: { inputs: [] },
    [ACTION_TYPE.CONTRACT_TERMINATION]: { inputs: [] },
    [ACTION_TYPE.DEBT_COLLECTION]: {
      inputs: [
        {
          type: RuleConditionInputTypes.BOOLEAN,
          label: `${i18n.t('dialog.rule.autoHandover')}`,
          getter: obj => new RuleDefinitionBooleanValue(obj.autoHandover ?? false),
          setter: (obj, value) => (obj.autoHandover = value.toValue()),
        },
      ],
    },
  };
};
