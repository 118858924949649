import { useContext, useMemo } from 'react';

import i18n, { getLanguageWithRegionTag } from '../../../i18n/i18n';
import { EXECUTION_STATUS } from '../../../interfaces/enums';
import { type ExecutionForReact, type ExecutionOutcome, RIGHTS } from '../../../interfaces/Interfaces';
import { type ColumnDefinition, ColumnModifier, StaticDatasource, Table } from '../../../lib/Table';
import ExecutionStateIndicator from '../ExecutionStateIndicator/ExecutionStateIndicator';
import { UserContext } from '../../../App';

function ExecutionOutcomeDetails(props: { item: ExecutionForReact }) {
  const wasRetriedExecution = !!props.item.lastModified;
  const userContext = useContext(UserContext);

  function getRuleName(ruleName: string): string {
    if (ruleName === '' || ruleName === undefined) return i18n.t('action-page.details.rule-archived');
    return ruleName;
  }

  const outcomeTableCols: Array<ColumnDefinition<ExecutionOutcome>> = useMemo(
    () => [
      {
        accessor: item => item.executionState,
        renderer: (value, item) => <ExecutionStateIndicator state={item.executionState} />,
        headerName: '',
        modifiers: [ColumnModifier.FIT_CONTENT],
      },
      {
        accessor: item => item.actionInfo,
        headerName: i18n.t('action-page.details.action'),
      },
      {
        accessor: item => item.contractIds.join(', '),
        headerName: i18n.t('action-page.details.contracts'),
      },
      {
        accessor: item => getRuleName(item.ruleName),
        headerName: i18n.t('action-page.details.rule'),
      },
      ...(userContext.rights.includes(RIGHTS.EXTENDED_ACTION_INFO)
        ? ([
            {
              accessor: item => item.receivableIds.join(','),
              headerName: i18n.t('action-page.details.receivableIds'),
            },
          ] as Array<ColumnDefinition<ExecutionOutcome>>)
        : []),
      ...(wasRetriedExecution
        ? ([
            {
              accessor: item => item.lastModified,
              renderer: value =>
                (typeof value === 'string' && new Date(value).toLocaleString(getLanguageWithRegionTag())) || '-',
              headerName: i18n.t('action-page.details.retried-at'),
            },
            {
              accessor: item => item.lastModifiedUser || '-',
              headerName: i18n.t('action-page.details.retried-by'),
            },
          ] as Array<ColumnDefinition<ExecutionOutcome>>)
        : []),
    ],
    [wasRetriedExecution],
  );

  return (
    <div className="execution-outcome-details">
      {props.item.executionState == EXECUTION_STATUS.CANCELED && (
        <>
          <b>{i18n.t('action-page.details.cancelled')}</b>
          <div className="cancellation-details">
            <div>
              <span>{i18n.t('action-page.details.cancellation-date')} </span>
              <span>{i18n.t('action-page.details.cancellation-user')} </span>
            </div>
            <div>
              <span>{new Date(props.item.lastModified).toLocaleString(getLanguageWithRegionTag())}</span>
              <span>{props.item.lastModifiedUser}</span>
            </div>
          </div>
        </>
      )}
      {wasRetriedExecution && (
        <div className="retry-info">
          {i18n.t('action-page.details.retry-info', {
            date: new Date(props.item.lastModified).toLocaleString(getLanguageWithRegionTag()),
            user: props.item.lastModifiedUser,
          })}
        </div>
      )}
      <b>{i18n.t('action-page.details.title')}</b>
      <Table variant="light" dataSource={new StaticDatasource(props.item.outcomes)} columns={outcomeTableCols} />
    </div>
  );
}

export default ExecutionOutcomeDetails;
