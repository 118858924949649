import React, { type FunctionComponent, useEffect, useState } from 'react';

import { Card, Elevation, InputGroup, MenuItem, Tooltip } from '@blueprintjs/core';

import { testAttribute } from '../../util/Util';
import { IconInfo } from '../../icons/svg/IconInfo';
import { Button } from '../../lib/Button';
import i18n from '../../i18n/i18n';
import { AbstractRuleSet, BasicRuleSet } from '../../interfaces/Interfaces';
import { getBasicRuleSets, getHeaderConfig } from '../../services/ApiService';
import { type ItemRenderer, Select } from '@blueprintjs/select';
import { IconCaretDown } from '../../icons/svg/IconCaretDown';
import {
  Configuration,
  ContractRulesetCustomizationApi,
  RuleSetFilterDtoStatesEnum,
} from '../../generated-sources/openapi';

interface AssignContractProps {}

export const ContractCustomization: FunctionComponent<AssignContractProps> = props => {
  const [displayText, setDisplayText] = useState<string | undefined>(undefined);
  const [ruleSets, setRuleSets] = useState<AbstractRuleSet[]>([]);
  const [contractId, setContractId] = useState<number | undefined>();
  const [selectedRuleSet, setSelectedRuleSet] = useState<BasicRuleSet>();

  function loadRuleSets() {
    const ruleSetFilterDto = {
      states: [RuleSetFilterDtoStatesEnum.Active],
    };
    getBasicRuleSets(ruleSetFilterDto).then((data: AbstractRuleSet[]) => {
      setRuleSets(data);
      setSelectedRuleSet(data[0]);
    });
  }

  function getCurrentOptionDisplayText() {
    if (!selectedRuleSet) return i18n.t('dialog.ruleset.please-select');
    return selectedRuleSet.name;
  }

  const renderer: ItemRenderer<BasicRuleSet> = (element, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        id={`select-general-${element.rulesetId}`}
        data-test-id={testAttribute('dm01', 'select-general', `${element.rulesetId}`)}
        active={modifiers.active}
        key={element.rulesetId}
        onClick={handleClick}
        text={element.name}
      />
    );
  };

  useEffect(() => {
    loadRuleSets();
  }, []);

  function handleRuleSetChange(subsequent: BasicRuleSet) {
    setSelectedRuleSet(subsequent);
  }

  function getApi() {
    return new ContractRulesetCustomizationApi(new Configuration(getHeaderConfig()));
  }

  const handleAssignment = () => {
    setDisplayText(undefined)
    getApi()
      .setRuleSetId1({
        contractId: contractId ?? -1,
        ruleSetIdWithTimeSliceDto: { ruleSetId: selectedRuleSet?.rulesetId, validFrom: new Date() },
      })
      .catch(async e => setDisplayText(JSON.stringify(await e.response.json(), null, 2)));
  };

  const fetchAssignment = () => {
    setDisplayText(undefined)
    getApi()
      .getActiveRuleSetCustomization({
        contractId: contractId ?? -1,
      })
      .then(res => setDisplayText(JSON.stringify(res, null, 2)))
      .catch(async e => setDisplayText(JSON.stringify(await e.response.json(), null, 2)));
  };

  return (
    <Card className="card" interactive={false} elevation={Elevation.TWO}>
      <h5 className="bp5-heading">Ruleset customization</h5>
      <div className="row">
        <InputGroup
          type="number"
          placeholder="Contract Id"
          onChange={value => setContractId(Number.parseInt(value.target.value))}
        />

        <Select<BasicRuleSet>
          data-test-id={testAttribute('dmg0', 'select')}
          filterable={false}
          itemRenderer={renderer}
          items={ruleSets}
          onItemSelect={subsequent => handleRuleSetChange(subsequent)}>
          <Button
            type="default"
            className="subsequent-reset-button"
            testId={testAttribute('dm02', 'customization', 'selector')}
            rightIcon={<IconCaretDown />}>
            <span style={{ width: '200px', display: 'block' }}>{getCurrentOptionDisplayText()}</span>
          </Button>
        </Select>

        <span className="spacer"></span>
        <Button testId={testAttribute('adgf', 'contract.assign')} onClick={handleAssignment}>
          Assign
        </Button>
        <Button testId={testAttribute('adas', 'contract.fetch')} onClick={fetchAssignment}>
          Fetch current
        </Button>
        <Tooltip content={`Allows limited interaction with the rule set customization feature. Might be broken ...`}>
          <IconInfo />
        </Tooltip>
      </div>
      {!!displayText && (
        <div className="row">
          <pre>{displayText}</pre>
        </div>
      )}
    </Card>
  );
};

export default ContractCustomization;
