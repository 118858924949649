export const IconArchive = () => (
  <span className="pc-icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 1C0.447715 1 0 1.44772 0 2V4H16V2C16 1.44772 15.5523 1 15 1H1ZM15 2H1V3H15V2Z"
      />
      <path d="M6 8.5C6 8.22386 6.22386 8 6.5 8H9.5C9.77614 8 10 8.22386 10 8.5C10 8.77614 9.77614 9 9.5 9H6.5C6.22386 9 6 8.77614 6 8.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 14V5H15V14C15 14.5523 14.5523 15 14 15H2C1.44772 15 1 14.5523 1 14ZM2 6H14V14H2V6Z"
      />
    </svg>
  </span>
);
