import { type FunctionComponent } from 'react';

import { UserContext } from 'App';
import { NavLink } from 'react-router-dom';

import i18n from 'i18n/i18n';
import { RIGHTS } from 'interfaces/Interfaces';
import { testAttribute } from 'util/Util';

import './NavBar.style.sass';

const NavBar: FunctionComponent = () => {
  function getNavItem(link: string, title: string) {
    return (
      <NavLink
        data-test-id={testAttribute('navm', 'nav.item', link)}
        to={link}
        end
        className="dunning-nav-container"
        // eslint-disable-next-line react/no-children-prop
        children={({ isActive }) => (
          <>
            <div className="nav-text">{title}</div>
            <div className={isActive ? 'arrow-up' : 'arrow-up-transparent'} />
          </>
        )}
      />
    );
  }

  return (
    <UserContext.Consumer>
      {user => (
        <div className="dunning-nav">
          {getNavItem('/#', i18n.t('title'))}
          {getNavItem('actions', i18n.t('title_actions'))}
          {getNavItem('contract-state', i18n.t('title_contract_state'))}
          {user.rights.includes(RIGHTS.ACCESS_DUNNING_SELECTION) &&
            getNavItem('dunning-selection', i18n.t('title_selection'))}
          {user.rights.includes(RIGHTS.ACCESS_ARCHIVE) && getNavItem('archive', i18n.t('title_archive'))}
          {user.rights.includes(RIGHTS.ACCESS_SETTINGS) && getNavItem('settings', i18n.t('title_settings'))}
          {user.rights.includes(RIGHTS.ACCESS_ADMIN_PAGE) && getNavItem('admin', i18n.t('title_admin'))}
        </div>
      )}
    </UserContext.Consumer>
  );
};

export default NavBar;
