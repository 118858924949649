import { ReactNode, useEffect, useState } from 'react';
import {
  ArgumentTypeAll,
  NumericComputedValue,
  NumericConst,
  NumericParam,
  RULE_ARG_TYPES,
  StringConst,
} from '../../../../../../interfaces/Interfaces';
import { NUMERIC_ARGUMENT_FUNCTION, OPERATOR } from '../../../../../../interfaces/enums';
import { ValidationResult, Validator } from '../../../../../DunningSelectionPage/DunningSelection/input/Validators';

export interface RuleDefinitionBaseProps {
  value?: RuleDefinitionValue;
  onChange: (value: RuleDefinitionValue, validation?: ValidationResult[]) => void;
  label?: ReactNode;
  isEditable: boolean;
  inputProps?: Record<any, any>;
  availableVariables?: { key: any; label: string }[];
  validators?: Validator[];
}

export abstract class RuleDefinitionValue {
  abstract toRuleArgument(): ArgumentTypeAll;

  abstract toValue(): any;
}

export function ruleDefinitionValueFrom(arg: ArgumentTypeAll) {
  const converters: { [type in RULE_ARG_TYPES]: (arg: any) => RuleDefinitionValue } = {
    [RULE_ARG_TYPES.NumericComputedValue]: (arg: NumericComputedValue) => new RuleDefinitionComputedValue(arg.name),
    [RULE_ARG_TYPES.NumericParam]: (arg: NumericParam) => new RuleDefinitionParamValue(arg.paramName),
    [RULE_ARG_TYPES.StringConst]: (arg: StringConst) => new RuleDefinitionStringValue(arg.val),
    [RULE_ARG_TYPES.NumericConst]: (arg: NumericConst) => new RuleDefinitionNumericValue(arg.val),
  };
  return converters[arg['@type']](arg);
}

export class RuleDefinitionStringValue extends RuleDefinitionValue {
  constructor(private value: string) {
    super();
  }

  toRuleArgument(): ArgumentTypeAll {
    return new StringConst(this.value);
  }

  toValue(): any {
    return this.value;
  }
}

export class RuleDefinitionNumericValue extends RuleDefinitionValue {
  constructor(private value: string | number | undefined) {
    super();
  }

  toRuleArgument(): ArgumentTypeAll {
    return new NumericConst((this.value ?? '') + '');
  }

  toValue(): any {
    return this.value;
  }
}

export class RuleDefinitionBooleanValue extends RuleDefinitionValue {
  constructor(private value: boolean) {
    super();
  }

  toRuleArgument(): ArgumentTypeAll {
    return new StringConst(String(this.value));
  }

  toValue(): boolean {
    return this.value;
  }
}

export class RuleDefinitionOperatorValue extends RuleDefinitionValue {
  constructor(private value: OPERATOR) {
    super();
  }

  toRuleArgument(): ArgumentTypeAll {
    throw new Error('No argument type');
  }

  toValue(): OPERATOR {
    return this.value;
  }
}

export class RuleDefinitionParamValue extends RuleDefinitionValue {
  constructor(private value: string) {
    super();
  }

  toRuleArgument(): ArgumentTypeAll {
    return new NumericParam(this.value);
  }

  toValue(): any {
    return this.value;
  }
}

export class RuleDefinitionComputedValue extends RuleDefinitionValue {
  constructor(private value: NUMERIC_ARGUMENT_FUNCTION) {
    super();
  }

  toRuleArgument(): ArgumentTypeAll {
    return new NumericComputedValue(this.value);
  }

  toValue(): any {
    return this.value;
  }
}

export function isStringConst(arg: ArgumentTypeAll | undefined): arg is StringConst {
  if (!arg || !arg['@type']) return false;
  return arg['@type'] == RULE_ARG_TYPES.StringConst;
}

export function isNumericConst(arg: ArgumentTypeAll | undefined): arg is NumericConst {
  if (!arg || !arg['@type']) return false;
  return arg['@type'] == RULE_ARG_TYPES.NumericConst;
}

export function useValidation<T>(
  onChange: (value: T, validation?: ValidationResult[]) => void,
  value?: T,
  validators?: Validator<T>[],
): [(value: any) => void, ValidationResult | null] {
  const [error, setError] = useState<ValidationResult | null>(null);

  const wrapperFunc = async (value: T) => {
    if (!validators) {
      return onChange(value);
    }
    const validationResult = (await Promise.all(validators.map(validator => validator(value)))).filter(
      res => res !== null,
    ) as ValidationResult[];
    const firstError = validationResult.find(result => result?.error);
    setError(firstError ?? null);
    onChange(value, validationResult);
  };

  useEffect(() => {
    if (value) {
      wrapperFunc(value);
    }
  }, []);

  return [wrapperFunc, error];
}
