import { RuleDefinitionValue } from '../../RuleDefinition/RuleCondition/RuleInputs/RuleDefinitionBaseProps';

export const requiredValidatorProvider = (message: string) => (value: RuleDefinitionValue) => {
  if (
    value?.toValue() === undefined ||
    value?.toValue() === null ||
    (value?.toValue()?.trim && value?.toValue()?.trim() === '')
  ) {
    return {
      error: true,
      message,
    };
  }
  return null;
};
