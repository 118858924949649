export interface ValidationResult {
  error: boolean;
  message?: string;
}

export type Validator<T = any> = (value: T) => (ValidationResult | null) | Promise<ValidationResult | null>;

export const Validators = {
  required: (value: any) => {
    if (value === null || value === undefined || value === '') {
      return {
        error: true,
        message: 'dunning-selection.error.required_field',
      };
    }
    return null;
  },
  gtMinusOne: (value: number) =>
    value >= -1
      ? null
      : {
          error: true,
          message: 'dunning-selection.error.number_too_small',
        },
  gtDecimal: (value: string) => {
    if (value === '') return null;
    return parseFloat(value) >= -1
      ? /^[0-9]*[\.|\,]?[0-9]*$/.test(value) && !Number.isNaN(parseFloat(value))
        ? null
        : {
            error: true,
            message: 'dunning-selection.error.decimal_not_valid',
          }
      : {
          error: true,
          message: 'dunning-selection.error.negative_number',
        };
  },
  gtInteger: (value: string) => {
    if (parseFloat(value) === -1) return null;
    return parseFloat(value) > -1
      ? /^[0-9]*$/.test(value) && !Number.isNaN(parseFloat(value))
        ? null
        : {
            error: true,
            message: 'dunning-selection.error.number_integer',
          }
      : {
          error: true,
          message: 'dunning-selection.error.negative_number',
        };
  },
  afterCurrentDate: (value: Date | any) => {
    if (value === null) {
      return null;
    }
    if (!(value instanceof Date)) {
      console.error('Object to validate is not a date!', value);
      return { error: true };
    }
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (currentDate.getTime() > value.getTime()) {
      return {
        error: true,
        message: 'dunning-selection.error.date_before_current',
      };
    }
    return null;
  },
};
