import { type FunctionComponent, type ReactElement, useEffect, useState } from 'react';

import { Collapse, Icon, Spinner } from '@blueprintjs/core';
import { ChevronDown, ChevronRight } from '@blueprintjs/icons';
import { Trans } from 'react-i18next';

import i18n from 'i18n/i18n';
import { DEFAULT_RULESET_TYPE } from 'interfaces/enums';
import { type RuleSetForReact, RuleSetReplacementLog } from 'interfaces/Interfaces';
import { Checkbox } from 'lib/Checkbox';

import {
  getFinallyInvoicedDefaultRuleSet,
  getRegularDefaultRuleSet,
  getRuleSet,
  setDefaultRuleSet,
  simulateReplaceRuleset,
} from '../../../services/ApiService';
import BasicDialog from '../BasicDialog/BasicDialog';

import '../Dialog.style.sass';

interface MakeDefaultDialogProps {
  ruleSet: RuleSetForReact;
  isDefaultRegular: boolean;
  isDefaultFinallyInvoiced: boolean;
  onCloseDialog: () => void;
  onConfirm: () => void;
}

const MakeDefaultDialog: FunctionComponent<MakeDefaultDialogProps> = (props: MakeDefaultDialogProps) => {
  enum MakeDefaultDialogState {
    INIT,
    PREVIEW,
    RUNNING,
    DONE,
    ERROR,
  }

  const [defaultRuleSetNameRegular, setDefaultRuleSetNameRegular] = useState<string>('');
  const [defaultRuleSetNameFinallyInvoiced, setDefaultRuleSetNameFinallyInvoiced] = useState<string>('');
  const [replacementInfoRegular, setReplacementInfoRegular] = useState<RuleSetReplacementLog>(
    new RuleSetReplacementLog(),
  );
  const [replacementInfoFinallyInvoiced, setReplacementInfoFinallyInvoiced] = useState<RuleSetReplacementLog>(
    new RuleSetReplacementLog(),
  );
  const [replacementExecutionInfo, setReplacementExecutionInfo] = useState<RuleSetReplacementLog>(
    new RuleSetReplacementLog(),
  );
  const [isCheckedAsRegular, setIsCheckedAsRegular] = useState<boolean>(props.isDefaultRegular);
  const [nonePickedAsDefault, setNonePickedAsDefault] = useState<boolean>(false);
  const [isCheckedAsFinallyInvoiced, setIsCheckedAsFinallyInvoiced] = useState<boolean>(props.isDefaultFinallyInvoiced);
  const [dialogState, setDialogState] = useState<MakeDefaultDialogState>(MakeDefaultDialogState.INIT);
  const [collapseRegularOpen, setCollapseRegularOpen] = useState<boolean>(false);
  const [collapseFinallyInvoicedOpen, setCollapseFinallyInvoicedOpen] = useState<boolean>(false);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      const defaultRulesetRegularId = await getRegularDefaultRuleSet();
      const oldRuleSetRegular = await getRuleSet(defaultRulesetRegularId.id);
      setDefaultRuleSetNameRegular(oldRuleSetRegular.name || '');

      const replacementInfoRegularConst: RuleSetReplacementLog = await simulateReplaceRuleset(
        props.ruleSet.rulesetId,
        DEFAULT_RULESET_TYPE.REGULAR,
      );
      if (replacementInfoRegularConst) setReplacementInfoRegular(replacementInfoRegularConst);

      const defaultRuleSetIdFinallyInvoiced = await getFinallyInvoicedDefaultRuleSet();
      const oldRuleSetFinallyInvoiced = await getRuleSet(defaultRuleSetIdFinallyInvoiced.id);
      setDefaultRuleSetNameFinallyInvoiced(oldRuleSetFinallyInvoiced.name || '');

      const replacementInfoFinallyInvoicedConst: RuleSetReplacementLog = await simulateReplaceRuleset(
        props.ruleSet.rulesetId,
        DEFAULT_RULESET_TYPE.FINALLY_INVOICED,
      );

      if (replacementInfoFinallyInvoicedConst) setReplacementInfoFinallyInvoiced(replacementInfoFinallyInvoicedConst);

      setDialogState(MakeDefaultDialogState.PREVIEW);
    } catch (error) {
      setDialogState(MakeDefaultDialogState.ERROR);
    }
  }

  async function execute() {
    setDialogState(MakeDefaultDialogState.RUNNING);
    try {
      if (
        isCheckedAsRegular &&
        isCheckedAsFinallyInvoiced &&
        !props.isDefaultRegular &&
        !props.isDefaultFinallyInvoiced
      ) {
        const response = await setDefaultRuleSet(props.ruleSet, DEFAULT_RULESET_TYPE.ALL);
        setReplacementExecutionInfo(response);
      } else if (isCheckedAsRegular && !props.isDefaultRegular) {
        const response = await setDefaultRuleSet(props.ruleSet, DEFAULT_RULESET_TYPE.REGULAR);
        setReplacementExecutionInfo(response);
      } else if (isCheckedAsFinallyInvoiced && !props.isDefaultFinallyInvoiced) {
        const response = await setDefaultRuleSet(props.ruleSet, DEFAULT_RULESET_TYPE.FINALLY_INVOICED);
        setReplacementExecutionInfo(response);
      } else {
        setNonePickedAsDefault(true);
      }
      setDialogState(MakeDefaultDialogState.DONE);
    } catch (error) {
      setDialogState(MakeDefaultDialogState.ERROR);
    }
  }

  function getAdditionalInfoElement(replacementInfo: RuleSetReplacementLog, isRegular?: boolean): ReactElement {
    return (
      <>
        {replacementInfo.levelReplacements.length > 0 && (
          <div className="padding-bottom">
            <div
              className="pointer"
              onClick={() =>
                isRegular
                  ? setCollapseRegularOpen(!collapseRegularOpen)
                  : setCollapseFinallyInvoicedOpen(!collapseFinallyInvoicedOpen)
              }>
              <Icon
                icon={
                  (isRegular ? collapseRegularOpen : collapseFinallyInvoicedOpen) ? <ChevronDown /> : <ChevronRight />
                }
              />
              {i18n.t('dialog.additional-info')}
            </div>
            <Collapse isOpen={isRegular ? collapseRegularOpen : collapseFinallyInvoicedOpen}>
              <div className="default-replacement-grid">
                <div className="highlighted-text bottom-line">{i18n.t('dialog.ruleset.old-level')}</div>
                <div className="highlighted-text bottom-line">{i18n.t('dialog.ruleset.new-level')}</div>
                <div className="highlighted-text bottom-line">{i18n.t('dialog.ruleset.receivable-count-text')}</div>

                {replacementInfo?.levelReplacements.map(levelReplacement => {
                  return (
                    <>
                      <div key={levelReplacement.oldLevelName}>
                        {levelReplacement.oldLevelName} ({levelReplacement.oldLevelValue})
                      </div>
                      <div key={levelReplacement.newLevelName}>
                        {levelReplacement.newLevelName} ({levelReplacement.newLevelValue})
                      </div>
                      <div key={levelReplacement.numberOfAffectedReceivables}>
                        {levelReplacement.numberOfAffectedReceivables}
                      </div>
                    </>
                  );
                })}
              </div>
            </Collapse>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {dialogState !== MakeDefaultDialogState.DONE && dialogState !== MakeDefaultDialogState.ERROR && (
        <BasicDialog
          title={i18n.t('dialog.ruleset.set-default')}
          mainText={<Trans i18nKey="dialog.ruleset.set-default-preview" />}
          confirmButtonText={i18n.t('button.set-default')}
          onCloseDialog={() => props.onCloseDialog()}
          disableConfirmButton={dialogState !== MakeDefaultDialogState.PREVIEW}
          disableCancelButton={dialogState === MakeDefaultDialogState.RUNNING}
          onConfirm={() => execute()}>
          {dialogState === MakeDefaultDialogState.INIT && (
            <>
              <Spinner className="spinner" size={60} />
              <div className="info-text">{i18n.t('dialog.please-wait-default')}</div>
              <br />
            </>
          )}
          {dialogState === MakeDefaultDialogState.PREVIEW && (
            <>
              <div className="padding-bottom">{i18n.t('dialog.ruleset.set-default-pick')}</div>
              <Checkbox
                label={i18n.t('dialog.ruleset.default-procedure')}
                isChecked={isCheckedAsRegular}
                disabled={props.isDefaultRegular}
                onClick={() => setIsCheckedAsRegular(!isCheckedAsRegular)}
              />

              <Checkbox
                label={i18n.t('dialog.ruleset.finally-invoiced-procedure')}
                isChecked={isCheckedAsFinallyInvoiced}
                disabled={props.isDefaultFinallyInvoiced}
                onClick={() => setIsCheckedAsFinallyInvoiced(!isCheckedAsFinallyInvoiced)}
              />

              <div className="dialog-title-2">{i18n.t('dialog.ruleset.replacement-preview-heading')}</div>
              {!props.isDefaultRegular && (
                <div>
                  <Trans i18nKey="dialog.ruleset.replacement-preview-ongoing" />
                  <div className="small-table-grid">
                    <div className="highlighted-text bottom-line">{i18n.t('dialog.ruleset.old-name')}</div>
                    <div className="bottom-line">{defaultRuleSetNameRegular}</div>
                    <div className="highlighted-text bottom-line">
                      {i18n.t('dialog.ruleset.number-of-affected-contracts')}
                    </div>
                    <div className="bottom-line">{replacementInfoRegular?.numberOfAffectedContracts}</div>
                  </div>
                  {getAdditionalInfoElement(replacementInfoRegular, true)}
                  <br />
                </div>
              )}

              {!props.isDefaultFinallyInvoiced && (
                <div>
                  <Trans i18nKey="dialog.ruleset.replacement-preview-finally" />
                  <div className="small-table-grid">
                    <div className="highlighted-text bottom-line">{i18n.t('dialog.ruleset.old-name')}</div>
                    <div className="bottom-line">{defaultRuleSetNameFinallyInvoiced}</div>
                    <div className="highlighted-text ">{i18n.t('dialog.ruleset.number-of-affected-contracts')}</div>
                    <div>{replacementInfoFinallyInvoiced?.numberOfAffectedContracts}</div>
                  </div>
                  {getAdditionalInfoElement(replacementInfoFinallyInvoiced)}
                </div>
              )}
            </>
          )}
          {dialogState === MakeDefaultDialogState.RUNNING && (
            <>
              <Spinner className="spinner" size={60} />
              {isCheckedAsRegular && (
                <>
                  <div className="info-text">
                    {i18n.t('dialog.changing-ruleset-info', {
                      count: replacementInfoRegular?.numberOfAffectedContracts,
                    })}
                  </div>
                  <br />
                </>
              )}
              {isCheckedAsFinallyInvoiced && (
                <>
                  <div className="info-text">
                    {i18n.t('dialog.changing-ruleset-info-finally', {
                      count: replacementInfoFinallyInvoiced?.numberOfAffectedContracts,
                    })}
                  </div>
                  <br />
                </>
              )}
            </>
          )}
        </BasicDialog>
      )}

      {dialogState === MakeDefaultDialogState.DONE && (
        <BasicDialog
          title={i18n.t('dialog.ruleset.set-default')}
          mainText={
            nonePickedAsDefault ? (
              i18n.t('dialog.changing-ruleset-none')
            ) : (
              <Trans i18nKey="dialog.ruleset.replacement-completed" />
            )
          }
          cancelButtonText={i18n.t('ok')}
          onCloseDialog={() => props.onConfirm()}
          onConfirm={() => undefined}
        />
      )}

      {dialogState === MakeDefaultDialogState.ERROR && (
        <BasicDialog
          title={i18n.t('dialog.ruleset.set-default')}
          mainText={i18n.t('error.default')}
          cancelButtonText={i18n.t('ok')}
          onCloseDialog={() => props.onConfirm()}
          onConfirm={() => undefined}
        />
      )}
    </>
  );
};

export default MakeDefaultDialog;
