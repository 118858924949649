/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkExecutionStatsDto
 */
export interface BulkExecutionStatsDto {
    /**
     * Number of outcomes that will be restarted
     * @type {number}
     * @memberof BulkExecutionStatsDto
     */
    executionOutcomesToRetry?: number;
}

/**
 * Check if a given object implements the BulkExecutionStatsDto interface.
 */
export function instanceOfBulkExecutionStatsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BulkExecutionStatsDtoFromJSON(json: any): BulkExecutionStatsDto {
    return BulkExecutionStatsDtoFromJSONTyped(json, false);
}

export function BulkExecutionStatsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkExecutionStatsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'executionOutcomesToRetry': !exists(json, 'executionOutcomesToRetry') ? undefined : json['executionOutcomesToRetry'],
    };
}

export function BulkExecutionStatsDtoToJSON(value?: BulkExecutionStatsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'executionOutcomesToRetry': value.executionOutcomesToRetry,
    };
}

