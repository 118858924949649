import { FunctionComponent, useState } from 'react';

import { RadioSelect } from 'lib/RadioSelect';
import { testAttribute } from 'util/Util';

import { type BaseInputProps, type Selectable } from './InputInterfaces';

interface FormRadioInputProps extends BaseInputProps<Selectable> {
  selectables: Selectable[];
  text?: string;
}

const FormRadioInput: FunctionComponent<FormRadioInputProps> = props => {
  const [value, setValue] = useState<Selectable | undefined>(props.value ?? props.selectables[0]);

  function onChangeHandler(val: string) {
    const selected = props.selectables.find(v => v.key === val);
    setValue(selected);
    if (props.onChange) props.onChange(selected);
  }

  return (
    <div>
      <p className="radio-title">{props.text}</p>
      <RadioSelect
        testId={`${testAttribute('ch5g', 'radio-select')}-`}
        options={props.selectables}
        disabled={props.disabled}
        checkedOptionKey={value?.key}
        onSelect={key => onChangeHandler(key)}
      />
    </div>
  );
};

export default FormRadioInput;
