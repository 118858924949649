import * as React from 'react';
import { type FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';

import { Card, Elevation, NonIdealState } from '@blueprintjs/core';
import { Search } from '@blueprintjs/icons';

import i18n, { getLanguageWithRegionTag } from 'i18n/i18n';
import { ACTION_TYPE, EXECUTION_STATUS } from 'interfaces/enums';
import {
  AbstractRuleSet,
  DunningLevelForReact,
  type ExecutionFilter,
  ExecutionForReact,
  type ExecutionOutcome,
  RIGHTS,
} from 'interfaces/Interfaces';
import {
  bulkRetryExecutionOutcome,
  getAllDunningLevels,
  getBasicRuleSets,
  getBulkRetryStatus,
  getExecutions,
} from 'services/ApiService';

import { BulkDownloadButton } from './BulkDownloadButton/BulkDownloadButton';
import BulkRetryButton from './BulkRetryButton/BulkRetryButton';
import ExecutionOutcomeDetails from './ExecutionOutcomeDetails/ExecutionOutcomeDetails';
import ExecutionStateIndicator from './ExecutionStateIndicator/ExecutionStateIndicator';
import {
  ExecutionFilterDateRangeInput,
  ExecutionFilterMultiSelectInput,
  ExecutionFilterStateSelection,
} from './ExecutionFilters';
import { RuleSetFilterDtoStatesEnum } from '../../generated-sources/openapi';
import { IconRedo } from '../../icons/svg/IconRedo';
import { Button } from '../../lib/Button';
import { type ColumnDefinition, ColumnModifier, type Filter, FilterType, PageableDatasource } from '../../lib/Table';
import FilteredTable from '../../lib/Table/FilteredTable';
import { NumberFilterInput } from '../../lib/Table/TableFilter/Filters/NumberFilterInput';
import { removeUndefined, testAttribute } from '../../util/Util';
import BasicDialog from '../Dialog/BasicDialog/BasicDialog';
import { type Selectable } from '../DunningSelectionPage/DunningSelection/input/InputInterfaces';
import { alertToast } from '../Toast/AlertToast';

import './DunningActionPage.style..sass';
import { UserContext } from '../../App';
import {LinkRenderer} from "../../lib/LinkRenderer";

const defaultExecutionFilterStates = [
  EXECUTION_STATUS.SUCCESS,
  EXECUTION_STATUS.FAILED,
  EXECUTION_STATUS.PENDING,
  EXECUTION_STATUS.RETRYING,
  EXECUTION_STATUS.CANCELED,
];
const DunningActionsPage: FunctionComponent = () => {
  const [latestFilters, setLatestFilters] = useState<ExecutionFilter>();
  const [redoDialogOpen, setRedoDialogOpen] = useState<boolean>(false);
  const [selectedOutcome, setSelectedOutcome] = useState<ExecutionOutcome | undefined>(undefined);
  const [selectedExecution, setSelectedExecution] = useState<ExecutionForReact | undefined>(undefined);
  const [isRetryRunningDialogOpen, setRetryRunningDialogOpen] = useState(false);
  const [availableDunningLevels, setAvailableDunningLevels] = useState<DunningLevelForReact[]>([]);
  const [availableRuleSetNames, setAvailableRuleSetNames] = useState<AbstractRuleSet[]>([]);

  const userContext = useContext(UserContext);

  function loadDunningLevels() {
    getAllDunningLevels().then(dunningLevels => {
      setAvailableDunningLevels(dunningLevels.map(level => new DunningLevelForReact(level)));
    });
  }

  function loadRuleSets() {
    const ruleSetFilter = { states: [RuleSetFilterDtoStatesEnum.Active] };
    getBasicRuleSets(ruleSetFilter).then(ruleSets => setAvailableRuleSetNames(ruleSets));
  }

  useEffect(() => {
    loadDunningLevels();
    loadRuleSets();
  }, []);

  function filtersFromRecord(filters?: Record<string, any>): ExecutionFilter {
    if (!filters) return { states: defaultExecutionFilterStates };
    return {
      states: filters.executionState,
      contractId: filters.contractId,
      executedAfter: filters.executionDate ? filters.executionDate[0].toISOString().split('T')[0] : undefined,
      executedBefore: filters.executionDate ? filters.executionDate[1].toISOString().split('T')[0] : undefined,
      customerId: filters.customerId,
      actionTypes: filters.actionType?.map((f: { key: string }) => f.key),
      dunningLevels: filters.dunningLevel?.map((f: { label: string }) => f.label),
      ruleSetNames: filters.ruleSetName?.map((f: { label: string }) => f.label),
    };
  }

  const fetchExecutionPage = async (page: number, pageSize: number, filters?: Record<string, any>) => {
    const options: any = {
      page,
      pageSize,
      ...removeUndefined(filtersFromRecord(filters)),
    };
    setLatestFilters(filtersFromRecord(filters));
    try {
      const executions = await getExecutions(options);
      return {
        data: executions.data.map(execution => new ExecutionForReact(execution)),
        totalAmountOfItems: executions.totalAmountOfItems,
        pageNumber: executions.pageNumber,
        pageSize: executions.pageSize,
      };
    } catch (e: any) {
      alertToast(e);
      return {
        data: [],
        totalAmountOfItems: 0,
        pageNumber: 0,
        pageSize: 50,
      };
    }
  };

  const [datasource, setDatasource] = useState(new PageableDatasource(fetchExecutionPage));

  function executeExecutionRetry(exec?: ExecutionForReact) {
    if (!exec) return;
    const failedOutcomes = exec.outcomes.filter(o => o.executionState === EXECUTION_STATUS.FAILED);
    bulkRetryExecutionOutcome({ outcomeIds: [...failedOutcomes.map(o => o.id)] })
      .catch(alertToast)
      .then(() => {
        exec.executionState = EXECUTION_STATUS.RETRYING;
        exec.lastModified = new Date().toISOString();
        exec.lastModifiedUser = i18n.t('action-page.you');
        failedOutcomes.forEach(o => {
          o.executionState = EXECUTION_STATUS.RETRYING;
          o.lastModified = new Date().toISOString();
          o.lastModifiedUser = i18n.t('action-page.you');
        });
      })
      .finally(() => setRedoDialogOpen(false));
  }

  function executeOutcomeRetry(outcome?: ExecutionOutcome, exec?: ExecutionForReact) {
    if (!outcome && exec) return executeExecutionRetry(exec);
    if (!outcome || !exec) return;
    bulkRetryExecutionOutcome({ outcomeIds: [outcome.id] })
      .catch(alertToast)
      .then(() => {
        exec.executionState = EXECUTION_STATUS.RETRYING;
        outcome.executionState = EXECUTION_STATUS.RETRYING;
      })
      .finally(() => setRedoDialogOpen(false));
  }

  function executeBulkRetry() {
    bulkRetryExecutionOutcome(latestFilters ?? {})
      .catch(alertToast)
      .then(async () => {
        const newDs = PageableDatasource.from(datasource);
        await newDs.filter({ executionState: [EXECUTION_STATUS.RETRYING, EXECUTION_STATUS.PENDING] });
        setDatasource(newDs);
      });
  }

  async function handleOpenRedoDialog(exec: ExecutionForReact, outcome?: ExecutionOutcome) {
    const status = await getBulkRetryStatus();
    if (status.isRunning) {
      setRetryRunningDialogOpen(true);
    } else {
      setSelectedOutcome(outcome);
      setSelectedExecution(exec);
      setRedoDialogOpen(true);
    }
  }

  function noPropagation(cb: () => void) {
    return (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      cb();
    };
  }

  const cols: Array<ColumnDefinition<ExecutionForReact>> = useMemo(
    () => [
      {
        accessor: item => item.executionState,
        renderer: (value, item) => <ExecutionStateIndicator state={item.executionState} />,
        headerName: '',
        modifiers: [ColumnModifier.FIT_CONTENT, ColumnModifier.SMALL],
      },
      ...(userContext.rights.includes(RIGHTS.EXTENDED_ACTION_INFO)
        ? ([
          {
            accessor: item => item.id,
            headerName: i18n.t('action-page.details.id'),
          },
        ] as Array<ColumnDefinition<ExecutionForReact>>)
        : []),
      {
        accessor: item => item.customerId,
        headerName: i18n.t('action-page.customer-id'),
      },
      {
        accessor: item => item.contractIds.join(', '),
        headerName: i18n.t('action-page.contract-id'),
        renderer: value => (
          <LinkRenderer
            items={value.toString()}
            baseUrl={contractId => `${window.location.origin}/customers:contracts/details/${contractId}#account`}
          />
        ),
      },
      {
        accessor: item => item.timestamp,
        renderer: value => typeof value === 'string' && new Date(value).toLocaleString(getLanguageWithRegionTag()),
        headerName: i18n.t('action-page.executed'),
      },
      {
        accessor: item => item.ruleSetName,
        headerName: i18n.t('action-page.procedure'),
      },
      {
        accessor: item => item.executionState,
        renderer: (state, execution) => (
          <>
            {state === EXECUTION_STATUS.FAILED && (
              <Button
                testId={testAttribute('jfbn', 'action-page.retry')}
                type="tertiary"
                onClick={noPropagation(() => handleOpenRedoDialog(execution))}
                icon={<IconRedo />}
              />
            )}
          </>
        ),
        headerName: '',
        modifiers: [ColumnModifier.FIT_CONTENT],
      },
    ],
    [],
  );

  const filter: Filter[] = [
    {
      key: 'contractId',
      name: i18n.t('action-page.contr-id'),
      render: props => <NumberFilterInput {...props} />,
      type: FilterType.DYNAMIC,
    },
    {
      key: 'customerId',
      name: i18n.t('action-page.customer-id'),
      render: props => <NumberFilterInput {...props} />,
      type: FilterType.DYNAMIC,
    },
    {
      key: 'executionDate',
      name: i18n.t('action-page.execution-date'),
      render: props => <ExecutionFilterDateRangeInput {...props} />,
      valueRenderer: value =>
        `${value[0].toLocaleDateString(i18n.language)} - ${value[1].toLocaleDateString(i18n.language)}`,
      type: FilterType.DYNAMIC,
    },
    {
      key: 'actionType',
      name: i18n.t('action-page.actionType'),
      render: props => (
        <ExecutionFilterMultiSelectInput
          {...props}
          selectables={Object.values(ACTION_TYPE)
            .filter(a => a !== ACTION_TYPE.CHANGE_OF_STATUS)
            .map(a => ({ key: a, label: i18n.t(`select.${a}`) }))}
        />
      ),
      valueRenderer: value => value.map((s: Selectable) => s.label).join(', '),
      type: FilterType.DYNAMIC,
    },
    {
      key: 'dunningLevel',
      name: i18n.t('action-page.dunningLevel'),
      render: props => (
        <ExecutionFilterMultiSelectInput
          {...props}
          selectables={availableDunningLevels.map(dunningLevel => ({
            key: dunningLevel.id,
            label: dunningLevel.name,
          }))}
          createNewItem
        />
      ),
      valueRenderer: value => value.map((s: Selectable) => s.label).join(', '),
      type: FilterType.DYNAMIC,
    },
    {
      key: 'ruleSetName',
      name: i18n.t('action-page.procedure'),
      render: props => (
        <ExecutionFilterMultiSelectInput
          {...props}
          selectables={availableRuleSetNames.map(ruleSet => ({
            key: ruleSet.rulesetId,
            label: ruleSet.name,
          }))}
          createNewItem
        />
      ),
      valueRenderer: value => value.map((s: Selectable) => s.label).join(', '),
      type: FilterType.DYNAMIC,
    },
    {
      key: 'executionState',
      name: i18n.t('action-page.execution-state'),
      render: props => (
        <ExecutionFilterStateSelection
          selectables={[
            {
              key: EXECUTION_STATUS.FAILED,
              label: i18n.t('action-page.filters.executionState.FAILED'),
            },
            {
              key: EXECUTION_STATUS.SUCCESS,
              label: i18n.t('action-page.filters.executionState.SUCCESSFUL'),
            },
            {
              key: EXECUTION_STATUS.PENDING,
              label: i18n.t('action-page.filters.executionState.PENDING'),
            },
            {
              key: EXECUTION_STATUS.RETRYING,
              label: i18n.t('action-page.filters.executionState.RETRYING'),
            },
            {
              key: EXECUTION_STATUS.CANCELED,
              label: i18n.t('action-page.filters.executionState.CANCELED'),
            },
          ]}
          {...props}
          value={props.value ?? defaultExecutionFilterStates}
        />
      ),
      valueRenderer: value => value.map((v: string) => i18n.t(`action-page.filters.executionState.${v}`)).join(', '),
      type: FilterType.FIXED,
    },
  ];

  return (
    <div className="dunning-action-page">
      <div className="title">{i18n.t('title_actions')}</div>
      <Card className="card" interactive={false} elevation={Elevation.ONE}>
        <FilteredTable
          columns={cols}
          dataSource={datasource}
          filter={filter}
          expansionRenderer={item => <ExecutionOutcomeDetails item={item} />}
          bulkActions={
            <>
              <BulkDownloadButton filter={latestFilters ?? {}} executionDatasource={datasource} />
              <BulkRetryButton filters={latestFilters ?? {}} onConfirmedClick={() => executeBulkRetry()} />
            </>
          }
          noData={
            <NonIdealState
              className="flex"
              icon={<Search />}
              title={
                Object.keys(latestFilters ?? {}).length ? i18n.t('action-page.no-data') : i18n.t('action-page.no-state')
              }
            />
          }
        />
      </Card>

      {isRetryRunningDialogOpen && (
        <BasicDialog
          title={i18n.t('action-page.bulk-retry-running-dialog.title')}
          cancelButtonText={i18n.t('ok')}
          disableConfirmButton
          onConfirm={() => setRetryRunningDialogOpen(false)}
          onCloseDialog={() => setRetryRunningDialogOpen(false)}>
          {i18n.t('action-page.bulk-retry-running-dialog.content')}
        </BasicDialog>
      )}
      {redoDialogOpen && (
        <BasicDialog
          title={i18n.t('dialog.execution.retry')}
          mainText={i18n.t('dialog.execution.retry-text')}
          blueCallout={selectedOutcome?.actionInfo}
          confirmButtonText={i18n.t('dialog.execution.retry')}
          onCloseDialog={() => setRedoDialogOpen(false)}
          onConfirm={() => executeOutcomeRetry(selectedOutcome, selectedExecution)}
        />
      )}
    </div>
  );
};

export default DunningActionsPage;
