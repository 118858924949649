/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RuleSetInfoDto } from './RuleSetInfoDto';
import {
    RuleSetInfoDtoFromJSON,
    RuleSetInfoDtoFromJSONTyped,
    RuleSetInfoDtoToJSON,
} from './RuleSetInfoDto';

/**
 * 
 * @export
 * @interface ContractRuleSetCustomizationResponseDto
 */
export interface ContractRuleSetCustomizationResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ContractRuleSetCustomizationResponseDto
     */
    contractId?: number;
    /**
     * 
     * @type {RuleSetInfoDto}
     * @memberof ContractRuleSetCustomizationResponseDto
     */
    associatedRuleSet?: RuleSetInfoDto;
    /**
     * 
     * @type {Date}
     * @memberof ContractRuleSetCustomizationResponseDto
     */
    validFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ContractRuleSetCustomizationResponseDto
     */
    validUntil?: Date;
}

/**
 * Check if a given object implements the ContractRuleSetCustomizationResponseDto interface.
 */
export function instanceOfContractRuleSetCustomizationResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContractRuleSetCustomizationResponseDtoFromJSON(json: any): ContractRuleSetCustomizationResponseDto {
    return ContractRuleSetCustomizationResponseDtoFromJSONTyped(json, false);
}

export function ContractRuleSetCustomizationResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractRuleSetCustomizationResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contractId': !exists(json, 'contractId') ? undefined : json['contractId'],
        'associatedRuleSet': !exists(json, 'associatedRuleSet') ? undefined : RuleSetInfoDtoFromJSON(json['associatedRuleSet']),
        'validFrom': !exists(json, 'validFrom') ? undefined : (new Date(json['validFrom'])),
        'validUntil': !exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
    };
}

export function ContractRuleSetCustomizationResponseDtoToJSON(value?: ContractRuleSetCustomizationResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contractId': value.contractId,
        'associatedRuleSet': RuleSetInfoDtoToJSON(value.associatedRuleSet),
        'validFrom': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
    };
}

