import React, { type FunctionComponent } from 'react';

import { MenuItem } from '@blueprintjs/core';
import { Plus } from '@blueprintjs/icons';
import { type ItemPredicate, type ItemRenderer, MultiSelect } from '@blueprintjs/select';

import i18n from '../../../i18n/i18n';
import { generateUUID } from '../../../util/Util';

interface Selectable {
  key: string;
  label: string;
}

interface ExecutionFilterMultiSelectInputProps {
  selectables: Selectable[];
  value?: Selectable[];
  placeholder?: string;
  onChange?: (value: Selectable[]) => void;
  label?: string;
  createNewItem?: boolean;
}

const ExecutionFilterMultiSelectInput: FunctionComponent<ExecutionFilterMultiSelectInputProps> = props => {
  const value = props.value ?? [];
  const unSelectedSelectables = props.selectables.filter(s => !props.value?.map(s => s.key).includes(s.key));

  async function onAddHandler(val: Selectable) {
    const newValues = [...value, val];
    if (props.onChange) props.onChange(newValues);
  }

  async function onRemoveHandler(val: Selectable) {
    const newValues = value.filter(v => v.key !== val.key);
    if (props.onChange) props.onChange(newValues);
  }

  const filterList: ItemPredicate<Selectable> = (query, operator, _index, exactMatch) => {
    const normalizedTitle = operator.label.toString().toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    }
    return operator.label.toString().toLowerCase().includes(query.toLowerCase());
  };

  const selectionListRenderer: ItemRenderer<Selectable> = item => {
    return (
      <MenuItem
        key={item.key}
        text={item.label}
        onClick={() => {
          onAddHandler(item);
        }}
      />
    );
  };

  function createNewItem(query: string): Selectable {
    return {
      key: generateUUID(),
      label: query,
    };
  }

  function renderNewItem(query: string, active: boolean, handleClick: React.MouseEventHandler<HTMLElement>) {
    return (
      <MenuItem
        icon={<Plus />}
        text={i18n.t('action-page.addItem', { item: query })}
        onClick={handleClick}
        shouldDismissPopover={false}
      />
    );
  }

  const onKeyPressHandler = (item: Selectable, event?: React.SyntheticEvent<HTMLElement, Event> | undefined) => {
    if (event?.type.toLowerCase() === 'keyup') {
      event.preventDefault();
      const itemInList = props.selectables.find(existingItem => existingItem.key === item.key);
      if (!itemInList) {
        onAddHandler(item);
      }
    } else {
      onAddHandler(item);
    }
  };

  return (
    <>
      <span className="lib-table-filter__label">{props.label}</span>
      <MultiSelect
        fill
        resetOnSelect={props.createNewItem ? true : undefined}
        resetOnQuery={props.createNewItem ? false : undefined}
        itemPredicate={filterList}
        selectedItems={value}
        itemRenderer={selectionListRenderer}
        items={unSelectedSelectables}
        createNewItemFromQuery={props.createNewItem ? createNewItem : undefined}
        createNewItemRenderer={props.createNewItem ? renderNewItem : undefined}
        onItemSelect={onKeyPressHandler}
        tagRenderer={item => item.label}
        onRemove={item => onRemoveHandler(item)}
        noResults={<div>No results</div>}
      />
    </>
  );
};

export { ExecutionFilterMultiSelectInput };
