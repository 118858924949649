import { useEffect, useRef, useState } from 'react';

import { type ColumnDefinition } from './types/ColumnDefinition';
import { SortDirection } from './types/SortState';
import { StaticDatasource, type TableDatasource } from './types/TableDatasource';
import {
  type TableCellRenderer,
  type TableHeaderCellRenderer,
  type TableRowExpansionRenderer,
  type TableRowRenderer,
} from './types/TableRenderer';
import TableHeaderCell from './TableHeaderCell';
import TableRow from './TableRow';

import './Table.style.scss';

export interface TableTranslationProps {}

export interface TableProps<T> {
  i18n?: TableTranslationProps;
  columns: Array<ColumnDefinition<T>>;
  rows?: T[];
  dataSource?: TableDatasource<T>;
  sortable?: boolean;
  expansionRenderer?: TableRowExpansionRenderer<T>;
  rowRenderer?: TableRowRenderer<T>;
  cellRenderer?: TableCellRenderer<T>;
  headerCellRenderer?: TableHeaderCellRenderer<T>;
  endOfTableCallback?: () => void;
  variant?: 'normal' | 'light';
}

interface InternalColumnDefinition {
  definition: ColumnDefinition<any>;
  sort: SortDirection;
}

function Table<T>({
  dataSource,
  rows,
  columns,
  expansionRenderer,
  rowRenderer,
  cellRenderer,
  headerCellRenderer,
  endOfTableCallback,
  variant = 'normal',
}: TableProps<T>) {
  const datasource = dataSource ?? new StaticDatasource(rows ?? []);
  const [cols, setCols] = useState<InternalColumnDefinition[]>([]);
  const endOfPageRef = useRef(null);

  useEffect(() => {
    setCols(prev =>
      columns.map(c => ({
        definition: c,
        sort: prev.find(existing => existing.definition === c)?.sort ?? SortDirection.NONE,
      })),
    );
  }, [columns, setCols]);

  async function updateSortBy(columnDef: ColumnDefinition<any>) {
    const existing = cols.find(c => c.definition === columnDef);
    if (existing) {
      if (existing.sort === SortDirection.NONE) {
        existing.sort = SortDirection.ASC;
      } else if (existing.sort === SortDirection.DESC) {
        existing.sort = SortDirection.NONE;
      } else if (existing.sort === SortDirection.ASC) existing.sort = SortDirection.DESC;
      await datasource.sort(
        cols
          .filter(c => c.sort !== SortDirection.NONE)
          .map(c => ({
            column: c.definition,
            direction: c.sort,
          })),
      );
      setCols([...cols]);
    }
  }

  const handleObserver: IntersectionObserverCallback = async entries => {
    const target = entries[0];
    if (target.isIntersecting && endOfTableCallback) {
      endOfTableCallback();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '300px',
      threshold: 0,
    });
    if (endOfPageRef.current) observer.observe(endOfPageRef.current);
    return () => {
      observer.disconnect();
    };
  }, [handleObserver]);

  return (
    <div className="lib-table__wrapper">
      <table className={'lib-table ' + `lib-table--${variant}`}>
        <thead className="lib-table__table-header-row">
          <tr>
            {expansionRenderer && (
              <TableHeaderCell
                column={{
                  headerName: '',
                  accessor: I => I,
                }}
              />
            )}
            {cols.map((col, idx) =>
              headerCellRenderer ? (
                headerCellRenderer(col.definition, col.sort, updateSortBy)
              ) : (
                <TableHeaderCell column={col.definition} direction={col.sort} key={idx} sortBy={() => updateSortBy} />
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {datasource.current.map((row, idx) =>
            rowRenderer ? (
              rowRenderer(row, columns, idx, expansionRenderer, cellRenderer)
            ) : (
              <TableRow
                cellRenderer={cellRenderer}
                expansionRenderer={expansionRenderer}
                columns={columns}
                rowItem={row}
                key={idx}
              />
            ),
          )}
          <tr
            ref={endOfPageRef}
            style={{
              minHeight: '1px',
              display: 'block',
            }}
          />
        </tbody>
      </table>
    </div>
  );
}

export default Table;
