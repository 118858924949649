import {
  AggregConditionDtoFromJSON,
  CaseConditionDtoFromJSON,
  CustomerCaseConditionDtoFromJSON,
  DunningSelectionConfigurationDto,
  DunningSelectionConfigurationDtoContractStatesEnum,
  DunningSelectionConfigurationDtoExecutionTypeEnum,
  DunningSelectionConfigurationDtoPaymentTypeEnum,
  ExecutionFilterDto,
  ExecutionFilterDtoStatesEnum,
  GroupSelectorDtoFromJSON,
  GroupSelectorFromJSON,
  OrderRulesRequest,
  RuleCreateDto,
  RuleDto,
  RuleUpdateDto,
  SystemPropertyDto,
} from '../generated-sources/openapi';
import {
  Action,
  DunningSelectionConfiguration,
  ExecutionFilter,
  Rule,
  RuleForReact,
  RuleTemplate,
  SystemProperty,
  SystemPropertyType,
} from '../interfaces/Interfaces';
import { toFormattedDateString } from '../util/Util';

function enumFromStringValue<T>(enm: Record<string, T>, value: string): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value) ? (value as unknown as T) : undefined;
}

export function toSystemPropertyDto(systemProperty: SystemProperty) {
  const result = {} as SystemPropertyDto;
  if (systemProperty.property !== undefined) {
    result.property = systemProperty.property;
  }
  if (systemProperty.value !== undefined) {
    result.value = systemProperty.value;
  }
  if (systemProperty.type !== undefined) {
    const propertyType = enumFromStringValue(SystemPropertyType, systemProperty.type.valueOf());
    if (propertyType !== undefined) {
      result.type = propertyType;
    }
  }
  if (systemProperty.requiredField !== undefined) {
    result.requiredField = systemProperty.requiredField;
  }
  return result;
}

export function toExecutionFilterDto(filter: ExecutionFilter) {
  const result = {} as ExecutionFilterDto;
  if (filter.contractId !== undefined) {
    result.contractId = Number(filter.contractId);
  }
  if (filter.customerId !== undefined) {
    result.customerId = Number(filter.customerId);
  }
  if (filter.executedAfter !== undefined) {
    result.executedAfter = new Date(filter.executedAfter);
  }
  if (filter.executedBefore !== undefined) {
    result.executedBefore = new Date(filter.executedBefore);
  }
  if (filter.states !== undefined) {
    const tempArray: ExecutionFilterDtoStatesEnum[] = new Array(4);
    for (const state of filter.states) {
      const enumFromStringValue1 = enumFromStringValue(ExecutionFilterDtoStatesEnum, state);
      if (enumFromStringValue1 != null) {
        tempArray.push(enumFromStringValue1);
      }
    }
    result.states = tempArray;
  }
  if (filter.actionTypes !== undefined) {
    result.actionTypes = filter.actionTypes;
  }
  if (filter.outcomeIds !== undefined) {
    result.outcomeIds = filter.outcomeIds;
  }
  if (filter.dunningLevels !== undefined) {
    result.dunningLevels = filter.dunningLevels;
  }
  if (filter.ruleSetNames !== undefined) {
    result.ruleSetNames = filter.ruleSetNames;
  }
  return result;
}

export function toExecutionFilterQuery(source: ExecutionFilter) {
  const obj = toExecutionFilterDto(source);
  return {
    ...obj,
    executedAfter: toFormattedDateString(obj.executedAfter) ?? undefined,
    executedBefore: toFormattedDateString(obj.executedBefore) ?? undefined,
    states: obj.states?.map(s => s.toString()).join(','),
    actionTypes: obj.actionTypes?.map(s => s.toString()).join(','),
    ruleSetNames: obj.ruleSetNames?.map(ruleSetName => ruleSetName.toString()),
  };
}

export function toDunningSelectionConfigurationDto(selectionConfiguration: DunningSelectionConfiguration) {
  const result = {} as DunningSelectionConfigurationDto;
  if (selectionConfiguration.active !== undefined) {
    result.active = selectionConfiguration.active;
  }
  if (selectionConfiguration.executionType !== undefined) {
    const executionTypeEnum = enumFromStringValue(
      DunningSelectionConfigurationDtoExecutionTypeEnum,
      selectionConfiguration.executionType,
    );
    if (executionTypeEnum !== undefined) {
      result.executionType = executionTypeEnum;
    }
  }
  if (selectionConfiguration.planedExecutionDate !== undefined) {
    result.planedExecutionDate = new Date(selectionConfiguration.planedExecutionDate);
  }
  if (selectionConfiguration.paymentType !== undefined) {
    const paymentTypeEnum = enumFromStringValue(
      DunningSelectionConfigurationDtoPaymentTypeEnum,
      selectionConfiguration.paymentType,
    );
    if (paymentTypeEnum !== undefined) {
      result.paymentType = paymentTypeEnum;
    }
  }
  if (selectionConfiguration.contractStates !== undefined) {
    const tempSet = new Set<DunningSelectionConfigurationDtoContractStatesEnum>();
    for (const state of selectionConfiguration.contractStates) {
      const stateEnum = enumFromStringValue(DunningSelectionConfigurationDtoContractStatesEnum, state);
      if (stateEnum !== undefined) {
        tempSet.add(stateEnum);
      }
    }
    result.contractStates = tempSet;
  }
  if (selectionConfiguration.dunningProcedures !== undefined) {
    const tempSet = new Set<string>();
    for (const procedure of selectionConfiguration.dunningProcedures) {
      tempSet.add(procedure);
    }
    result.dunningProcedures = tempSet;
  }
  if (selectionConfiguration.rules !== undefined) {
    const tempSet = new Set<string>();
    for (const rule of selectionConfiguration.rules) {
      tempSet.add(rule);
    }
    result.rules = tempSet;
  }
  if (selectionConfiguration.minOpenAmount !== undefined) {
    result.minOpenAmount = selectionConfiguration.minOpenAmount;
  }
  if (selectionConfiguration.maxNotifications !== undefined) {
    result.maxNotifications = selectionConfiguration.maxNotifications;
  }
  if (selectionConfiguration.maxTerminations !== undefined) {
    result.maxTerminations = selectionConfiguration.maxTerminations;
  }
  if (selectionConfiguration.maxThreats !== undefined) {
    result.maxThreats = selectionConfiguration.maxThreats;
  }
  if (selectionConfiguration.maxAnnouncements !== undefined) {
    result.maxAnnouncements = selectionConfiguration.maxAnnouncements;
  }
  if (selectionConfiguration.maxDisconnection !== undefined) {
    result.maxDisconnection = selectionConfiguration.maxDisconnection;
  }
  if (selectionConfiguration.terminationProcedures !== undefined) {
    const tempSet = new Set<string>();
    for (const procedure of selectionConfiguration.terminationProcedures) {
      tempSet.add(procedure);
    }
    result.terminationProcedures = tempSet;
  }
  if (selectionConfiguration.dueDateLimitation !== null) {
    result.dueDateLimitation = new Date(selectionConfiguration.dueDateLimitation);
  }
  return result;
}

export function toOrderRulesRequest(rulesetId: string, orderedRuleIds: string[]) {
  const result = {} as OrderRulesRequest;
  if (orderedRuleIds !== undefined) {
    result.ruleOrderDto = { orderedRuleIds };
  }
  if (rulesetId !== undefined) {
    result.rulesetId = rulesetId;
  }
  return result;
}

function addRuleBasePropertiesFromRule(result: any, rule: Rule): void {
  if (rule.executePerContract !== undefined) {
    result.executePerContract = rule.executePerContract;
  }
  if (rule.name !== undefined) {
    result.name = rule.name;
  }
  if (rule.description !== undefined) {
    result.description = rule.description;
  }
  if (rule.orderValue !== undefined) {
    result.orderValue = rule.orderValue;
  }
  if (rule.groupSelectors !== undefined) {
    result.groupSelectors = rule.groupSelectors
      .map(k => JSON.stringify(k))
      .map(k => JSON.parse(k))
      .map(k => GroupSelectorDtoFromJSON(k));
  }
  if (rule.caseConditions !== undefined) {
    result.caseConditions = rule.caseConditions
      .map(k => JSON.stringify(k))
      .map(k => JSON.parse(k))
      .map(k => CaseConditionDtoFromJSON(k));
  }
  if (rule.customerCaseConditions !== undefined) {
    result.customerCaseConditions = rule.customerCaseConditions
      .map(k => JSON.stringify(k))
      .map(k => JSON.parse(k))
      .map(k => CustomerCaseConditionDtoFromJSON(k));
  }
  if (rule.aggregConditions !== undefined) {
    result.aggregConditions = rule.aggregConditions
      .map(k => JSON.stringify(k))
      .map(k => JSON.parse(k))
      .map(k => AggregConditionDtoFromJSON(k));
  }
  if (rule.actions !== undefined) {
    result.actions = rule.actions.map(action => new Action(action));
  }
}

export function toRuleCreateDtofromRule(rule: Rule): RuleCreateDto {
  const result = {} as RuleCreateDto;
  addRuleBasePropertiesFromRule(result, rule);
  return result;
}

export function toRuleDtofromRule(ruleSetId: string | undefined, rule: Rule) {
  const result = {} as RuleDto;
  if (ruleSetId !== undefined) {
    result.rulesetId = ruleSetId;
  }
  addRuleBasePropertiesFromRule(result, rule);
  return result;
}

export function toRuleUpdateDtofromRule(rule: Rule): RuleUpdateDto {
  const result = {} as RuleUpdateDto;
  addRuleBasePropertiesFromRule(result, rule);
  return result;
}

function addRuleBasePropertiesFromRuleTemplate(result: any, rule: RuleTemplate): void {
  if (rule.executePerContract !== undefined) {
    result.executePerContract = rule.executePerContract;
  }
  if (rule.name !== undefined) {
    result.name = rule.name;
  }
  if (rule.description !== undefined) {
    result.description = rule.description;
  }
  if (rule.groupSelectors !== undefined) {
    result.groupSelectors = rule.groupSelectors
      .map(k => JSON.stringify(k))
      .map(k => JSON.parse(k))
      .map(k => GroupSelectorDtoFromJSON(k));
  }
  if (rule.caseConditions !== undefined) {
    result.caseConditions = rule.caseConditions
      .map(k => JSON.stringify(k))
      .map(k => JSON.parse(k))
      .map(k => CaseConditionDtoFromJSON(k));
  }
  if (rule.customerCaseConditions !== undefined) {
    result.customerCaseConditions = rule.customerCaseConditions
      .map(k => JSON.stringify(k))
      .map(k => JSON.parse(k))
      .map(k => CustomerCaseConditionDtoFromJSON(k));
  }
  if (rule.aggregConditionsInThisGroup !== undefined) {
    result.aggregConditions = rule.aggregConditionsInThisGroup
      .map(k => JSON.stringify(k))
      .map(k => JSON.parse(k))
      .map(k => AggregConditionDtoFromJSON(k));

    for (const aggregCondition of result.aggregConditions) {
      aggregCondition.customGrouping = rule.groupSelectors
        .map(k => JSON.stringify(k))
        .map(k => JSON.parse(k))
        .map(k => GroupSelectorFromJSON(k));
    }
  }
  if (rule.aggregConditionsInAllGroups !== undefined) {
    const aggregConditionDtoS = rule.aggregConditionsInAllGroups
      .map(k => JSON.stringify(k))
      .map(k => JSON.parse(k))
      .map(k => AggregConditionDtoFromJSON(k));
    if (result.aggregConditions != null) {
      aggregConditionDtoS.forEach(k => result.aggregConditions.push(k));
    } else {
      result.aggregConditions = aggregConditionDtoS;
    }
  }
  if (rule.actions !== undefined) {
    result.actions = rule.actions.map(action => new Action(action));
  }
}

export function toRuleCreateDto(orderValue: number | undefined, rule: RuleTemplate): RuleCreateDto {
  const result = {} as RuleCreateDto;
  if (orderValue !== undefined) {
    result.orderValue = orderValue;
  }
  addRuleBasePropertiesFromRuleTemplate(result, rule);
  return result;
}

export function toRuleDto(ruleSetId: string | undefined, orderValue: number | undefined, rule: RuleTemplate) {
  const result = {} as RuleDto;
  if (orderValue !== undefined) {
    result.orderValue = orderValue;
  }
  if (ruleSetId !== undefined) {
    result.rulesetId = ruleSetId;
  }
  addRuleBasePropertiesFromRuleTemplate(result, rule);
  return result;
}

export function toRuleDtoFromRuleForeReact(rule: RuleForReact) {
  const result = {} as any;
  if (rule.executePerContract !== undefined) {
    result.executePerContract = rule.executePerContract;
  }
  if (rule.id !== undefined) {
    result.id = rule.id;
  }
  if (rule.rulesetId !== undefined) {
    result.rulesetId = rule.rulesetId;
  }
  if (rule.created !== undefined) {
    result.created = new Date(rule.created);
  }
  if (rule.updated !== undefined) {
    result.updated = new Date(rule.updated);
  }
  if (rule.name !== undefined) {
    result.name = rule.name;
  }
  if (rule.description !== undefined) {
    result.description = rule.description;
  }
  if (rule.orderValue !== undefined) {
    result.orderValue = rule.orderValue;
  }
  if (rule.groupSelectors !== undefined) {
    result.groupSelectors = rule.groupSelectors
      .map(k => JSON.stringify(k))
      .map(k => JSON.parse(k))
      .map(k => GroupSelectorDtoFromJSON(k));
  }
  if (rule.caseConditions !== undefined) {
    result.caseConditions = rule.caseConditions
      .map(k => JSON.stringify(k))
      .map(k => JSON.parse(k))
      .map(k => CaseConditionDtoFromJSON(k));
  }
  if (rule.customerCaseConditions !== undefined) {
    result.customerCaseConditions = rule.customerCaseConditions
      .map(k => JSON.stringify(k))
      .map(k => JSON.parse(k))
      .map(k => CustomerCaseConditionDtoFromJSON(k));
  }
  if (rule.aggregConditionsInThisGroup !== undefined) {
    result.aggregConditions = rule.aggregConditionsInThisGroup
      .map(k => JSON.stringify(k))
      .map(k => JSON.parse(k))
      .map(k => AggregConditionDtoFromJSON(k));
  }
  if (rule.aggregConditionsInAllGroups !== undefined) {
    const aggregConditionDtoS = rule.aggregConditionsInAllGroups
      .map(k => JSON.stringify(k))
      .map(k => JSON.parse(k))
      .map(k => AggregConditionDtoFromJSON(k));
    if (result.aggregConditions != null) {
      aggregConditionDtoS.forEach(k => result.aggregConditions.push(k));
    } else {
      result.aggregConditions = aggregConditionDtoS;
    }
  }
  if (rule.actions !== undefined) {
    result.actions = rule.actions.map(action => new Action(action));
  }
  return result;
}
