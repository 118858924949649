import { type FunctionComponent } from 'react';

import { Classes, Dialog } from '@blueprintjs/core';

import i18n from '../../../i18n/i18n';
import { type RuleAdvice } from '../../../interfaces/Interfaces';
import { Button } from '../../../lib/Button';
import { testAttribute } from '../../../util/Util';

import '../Dialog.style.sass';

interface RuleAdviceDialogProps {
  advices: RuleAdvice[];
  title: string;
  open: boolean;
  buttonTextSuccess: string;
  onCloseDialog: () => void;
  onConfirm: () => void;
}

const RuleAdviceDialog: FunctionComponent<RuleAdviceDialogProps> = (props: RuleAdviceDialogProps) => {
  return (
    <Dialog
      data-test-id={testAttribute('bgh2', 'rule-advice-dialog')}
      isOpen={props.open}
      canOutsideClickClose={false}
      className="dialog">
      <div className="dialog-title">{props.title}</div>
      <div className={Classes.DIALOG_BODY}>
        <ul>
          {props.advices?.map(advice => {
            return (
              <li className="item" key={advice.advice}>
                {i18n.t(advice.advice)}
              </li>
            );
          })}
        </ul>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            id="cancel-button"
            type="tertiary"
            testId={testAttribute('axxx', 'cancel-button')}
            className="button"
            onClick={() => props.onCloseDialog()}>
            {i18n.t('button.cancel')}
          </Button>
          <Button
            id="save-button"
            className="button"
            testId={testAttribute('axxp', 'confirm-button')}
            type="primary"
            onClick={() => props.onConfirm()}>
            {props.buttonTextSuccess}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
export { RuleAdviceDialog };
