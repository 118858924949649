import React, { useEffect, useState } from 'react';

import { Minus } from '@blueprintjs/icons';

import { IconPlus } from 'icons/svg/IconPlus';
import { IconReset } from 'icons/svg/IconReset';
import { IconSearch } from 'icons/svg/IconSearch';
import { Button } from 'lib/Button';

import TableFilterRow from './TableFilterRow';
import { type Filter, FilterType } from '../types/FilterModel';

import './TableFilter.style.scss';

export interface TableFilterTranslationProps {
  addFilter?: string;
  resetFilters?: string;
  search?: string;
}

interface TableFilterProps {
  i18n?: TableFilterTranslationProps;
  values: Record<string, any>;
  filterDefinitions: Filter[];
  onChange: (values: Record<string, any>) => void;
}

function TableFilter({ filterDefinitions: filters, onChange, values, i18n }: TableFilterProps) {
  const [filterRows, setFilterRows] = useState<string[]>([filters[0].key]);
  const [filterValues, setFilterValues] = useState<Record<string, any>>({});

  useEffect(() => {
    setFilterValues(values);
  }, [setFilterValues, values]);

  function updateFilterValue(key: string, value: any) {
    setFilterValues(prev => ({
      ...prev,
      [key]: value,
    }));
  }

  function applyFilters() {
    onChange(
      [...filterRows, ...filters.filter(f => f.type === FilterType.FIXED).map(f => f.key)]
        .filter(row => filterValues[row])
        .reduce(
          (acc, row) => ({
            ...acc,
            [row]: filterValues[row],
          }),
          {},
        ),
    );
  }

  function resetFilters() {
    setFilterRows([filters[0].key]);
    onChange({});
  }

  function updateFilterSelection(idx: number, key: string) {
    filterRows[idx] = key;
    setFilterRows([...filterRows]);
  }

  function addFilterRow() {
    const unusedFilter = filters.find(filter => !filterRows.includes(filter.key));
    if (unusedFilter) setFilterRows([...filterRows, unusedFilter.key]);
  }

  function removeFilterRow() {
    filterRows.pop();
    setFilterRows([...filterRows]);
  }

  return (
    <form className="lib-table-filter" onKeyPress={e => e.key.toLowerCase() === 'enter' && applyFilters()}>
      <div className="lib-table-filter__rows">
        {filterRows.map((row, idx) => (
          <div key={row} className="lib-table-filter__row">
            <TableFilterRow
              selection={row}
              onSelectionChange={key => updateFilterSelection(idx, key)}
              filters={filters.filter(filter => !filterRows.includes(filter.key) || filterRows[idx] === filter.key)}
              onChange={updateFilterValue}
              values={filterValues}
              data-test-id="123"
            />
            {idx === 0 &&
              filters
                .filter(f => f.type === FilterType.FIXED)
                .map(filter => (
                  <div className="lib-table-filter__labeled-box" key={filter.key}>
                    {filter.render({
                      value: filterValues[filter.key],
                      onChange: newValue => updateFilterValue(filter.key, newValue),
                      label: filter.name,
                    })}
                  </div>
                ))}
            <Button
              testId="123"
              icon={idx === 0 ? <IconPlus /> : <Minus />}
              type="tertiary"
              disabled={
                idx === 0 &&
                filterRows.length === filters.length - filters.filter(f => f.type === FilterType.FIXED).length
              }
              onClick={() => (idx === 0 ? addFilterRow() : removeFilterRow())}>
              {i18n?.addFilter}
            </Button>
          </div>
        ))}
      </div>

      <div className="lib-table-filter__actions">
        <Button testId="123" type="secondary" icon={<IconReset />} onClick={() => resetFilters()}>
          {i18n?.resetFilters}
        </Button>
        <Button testId="123" type="primary" icon={<IconSearch />} onClick={() => applyFilters()}>
          {i18n?.search}
        </Button>
      </div>
    </form>
  );
}

export default TableFilter;
