/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregConditionDto } from './AggregConditionDto';
import {
    AggregConditionDtoFromJSON,
    AggregConditionDtoFromJSONTyped,
    AggregConditionDtoToJSON,
} from './AggregConditionDto';
import type { CaseConditionDto } from './CaseConditionDto';
import {
    CaseConditionDtoFromJSON,
    CaseConditionDtoFromJSONTyped,
    CaseConditionDtoToJSON,
} from './CaseConditionDto';
import type { CustomerCaseConditionDto } from './CustomerCaseConditionDto';
import {
    CustomerCaseConditionDtoFromJSON,
    CustomerCaseConditionDtoFromJSONTyped,
    CustomerCaseConditionDtoToJSON,
} from './CustomerCaseConditionDto';
import type { FlagConditionDto } from './FlagConditionDto';
import {
    FlagConditionDtoFromJSON,
    FlagConditionDtoFromJSONTyped,
    FlagConditionDtoToJSON,
} from './FlagConditionDto';
import type { GroupSelectorDto } from './GroupSelectorDto';
import {
    GroupSelectorDtoFromJSON,
    GroupSelectorDtoFromJSONTyped,
    GroupSelectorDtoToJSON,
} from './GroupSelectorDto';

/**
 * 
 * @export
 * @interface RuleDto
 */
export interface RuleDto {
    /**
     * 
     * @type {string}
     * @memberof RuleDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RuleDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof RuleDto
     */
    orderValue?: number;
    /**
     * 
     * @type {Array<FlagConditionDto>}
     * @memberof RuleDto
     */
    flagConditions?: Array<FlagConditionDto>;
    /**
     * 
     * @type {Array<GroupSelectorDto>}
     * @memberof RuleDto
     */
    groupSelectors: Array<GroupSelectorDto>;
    /**
     * 
     * @type {Array<CaseConditionDto>}
     * @memberof RuleDto
     */
    caseConditions: Array<CaseConditionDto>;
    /**
     * 
     * @type {Array<CustomerCaseConditionDto>}
     * @memberof RuleDto
     */
    customerCaseConditions: Array<CustomerCaseConditionDto>;
    /**
     * 
     * @type {Array<AggregConditionDto>}
     * @memberof RuleDto
     */
    aggregConditions: Array<AggregConditionDto>;
    /**
     * 
     * @type {object}
     * @memberof RuleDto
     */
    actions: object;
    /**
     * 
     * @type {Date}
     * @memberof RuleDto
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RuleDto
     */
    updated?: Date;
    /**
     * Indicates if the rule should be executed for each contract individually
     * @type {boolean}
     * @memberof RuleDto
     */
    executePerContract?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RuleDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleDto
     */
    rulesetId?: string;
}

/**
 * Check if a given object implements the RuleDto interface.
 */
export function instanceOfRuleDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "groupSelectors" in value;
    isInstance = isInstance && "caseConditions" in value;
    isInstance = isInstance && "customerCaseConditions" in value;
    isInstance = isInstance && "aggregConditions" in value;
    isInstance = isInstance && "actions" in value;

    return isInstance;
}

export function RuleDtoFromJSON(json: any): RuleDto {
    return RuleDtoFromJSONTyped(json, false);
}

export function RuleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'orderValue': !exists(json, 'orderValue') ? undefined : json['orderValue'],
        'flagConditions': !exists(json, 'flagConditions') ? undefined : ((json['flagConditions'] as Array<any>).map(FlagConditionDtoFromJSON)),
        'groupSelectors': ((json['groupSelectors'] as Array<any>).map(GroupSelectorDtoFromJSON)),
        'caseConditions': ((json['caseConditions'] as Array<any>).map(CaseConditionDtoFromJSON)),
        'customerCaseConditions': ((json['customerCaseConditions'] as Array<any>).map(CustomerCaseConditionDtoFromJSON)),
        'aggregConditions': ((json['aggregConditions'] as Array<any>).map(AggregConditionDtoFromJSON)),
        'actions': json['actions'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'executePerContract': !exists(json, 'executePerContract') ? undefined : json['executePerContract'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'rulesetId': !exists(json, 'rulesetId') ? undefined : json['rulesetId'],
    };
}

export function RuleDtoToJSON(value?: RuleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'orderValue': value.orderValue,
        'flagConditions': value.flagConditions === undefined ? undefined : ((value.flagConditions as Array<any>).map(FlagConditionDtoToJSON)),
        'groupSelectors': ((value.groupSelectors as Array<any>).map(GroupSelectorDtoToJSON)),
        'caseConditions': ((value.caseConditions as Array<any>).map(CaseConditionDtoToJSON)),
        'customerCaseConditions': ((value.customerCaseConditions as Array<any>).map(CustomerCaseConditionDtoToJSON)),
        'aggregConditions': ((value.aggregConditions as Array<any>).map(AggregConditionDtoToJSON)),
        'actions': value.actions,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'executePerContract': value.executePerContract,
        'id': value.id,
        'rulesetId': value.rulesetId,
    };
}

