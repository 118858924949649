export const IconDownload = () => (
  <span className="pc-icon">
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 0C8.77614 0 9 0.223858 9 0.5V10.7929L12.1464 7.64645C12.3417 7.45118 12.6583 7.45118 12.8536 7.64645C13.0488 7.84171 13.0488 8.15829 12.8536 8.35355L8.5 12.7071L4.14645 8.35355C3.95118 8.15829 3.95118 7.84171 4.14645 7.64645C4.34171 7.45118 4.65829 7.45118 4.85355 7.64645L8 10.7929V0.5C8 0.223858 8.22386 0 8.5 0Z"
        fill="#030307"
      />
      <path
        d="M15 11.5V15L2 15L2 11.5C2 11.2239 1.77614 11 1.5 11C1.22386 11 1 11.2239 1 11.5V15C1 15.5523 1.44772 16 2 16H15C15.5523 16 16 15.5523 16 15V11.5C16 11.2239 15.7761 11 15.5 11C15.2239 11 15 11.2239 15 11.5Z"
        fill="#030307"
      />
    </svg>
  </span>
);
