import React, { type FunctionComponent, ReactNode } from 'react';
import { RuleDefinitionBaseProps } from './RuleDefinitionBaseProps';

interface RuleDefinitionNoProps extends RuleDefinitionBaseProps {
  label?: ReactNode;
}

const RuleDefinitionNoInput: FunctionComponent<RuleDefinitionNoProps> = ({ label }) => {
  return <>{label}</>;
};

export default RuleDefinitionNoInput;
