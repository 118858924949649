/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContractStateOverview } from './ContractStateOverview';
import {
    ContractStateOverviewFromJSON,
    ContractStateOverviewFromJSONTyped,
    ContractStateOverviewToJSON,
} from './ContractStateOverview';

/**
 * 
 * @export
 * @interface PaginatedContractStateOverview
 */
export interface PaginatedContractStateOverview {
    /**
     * 
     * @type {Array<ContractStateOverview>}
     * @memberof PaginatedContractStateOverview
     */
    data?: Array<ContractStateOverview>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedContractStateOverview
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedContractStateOverview
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedContractStateOverview
     */
    totalAmountOfItems?: number;
}

/**
 * Check if a given object implements the PaginatedContractStateOverview interface.
 */
export function instanceOfPaginatedContractStateOverview(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaginatedContractStateOverviewFromJSON(json: any): PaginatedContractStateOverview {
    return PaginatedContractStateOverviewFromJSONTyped(json, false);
}

export function PaginatedContractStateOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedContractStateOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(ContractStateOverviewFromJSON)),
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'totalAmountOfItems': !exists(json, 'totalAmountOfItems') ? undefined : json['totalAmountOfItems'],
    };
}

export function PaginatedContractStateOverviewToJSON(value?: PaginatedContractStateOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(ContractStateOverviewToJSON)),
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'totalAmountOfItems': value.totalAmountOfItems,
    };
}

