import { FunctionComponent } from 'react';

import { Classes, Dialog } from '@blueprintjs/core';

import i18n from 'i18n/i18n';
import { Button } from 'lib/Button';
import { testAttribute } from 'util/Util';

interface DialogBoxProps {
  showDialog: boolean;
  cancelNavigation: any;
  confirmNavigation: any;
}

export const DialogBox: FunctionComponent<DialogBoxProps> = ({ showDialog, cancelNavigation, confirmNavigation }) => {
  return (
    <Dialog className="dialog" isOpen={showDialog} canOutsideClickClose={false}>
      <div className="dialog-title" style={{ fontWeight: 'bold' }}>
        {i18n.t('dunning-selection.unsaved_changes_title')}
      </div>
      <div className={Classes.DIALOG_BODY}>
        <div> {i18n.t('dunning-selection.unsaved_changes')}</div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            id="cancel-button"
            testId={testAttribute('ghJ1', 'dunning-selection.cancel')}
            type="secondary"
            onClick={cancelNavigation}>
            {i18n.t('dunning-selection.cancel')}
          </Button>
          <Button
            id="save-button"
            testId={testAttribute('ghJ1', 'dunning-selection.confirm')}
            type="primary"
            onClick={confirmNavigation}>
            {i18n.t('dunning-selection.confirm')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
export default DialogBox;
