/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ForecastActionDto } from './ForecastActionDto';
import {
    ForecastActionDtoFromJSON,
    ForecastActionDtoFromJSONTyped,
    ForecastActionDtoToJSON,
} from './ForecastActionDto';

/**
 * 
 * @export
 * @interface ForecastDto
 */
export interface ForecastDto {
    /**
     * 
     * @type {ForecastActionDto}
     * @memberof ForecastDto
     */
    withoutDunningSelection?: ForecastActionDto;
    /**
     * 
     * @type {ForecastActionDto}
     * @memberof ForecastDto
     */
    withDunningSelection?: ForecastActionDto;
    /**
     * 
     * @type {Date}
     * @memberof ForecastDto
     */
    forecastedDate?: Date;
}

/**
 * Check if a given object implements the ForecastDto interface.
 */
export function instanceOfForecastDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ForecastDtoFromJSON(json: any): ForecastDto {
    return ForecastDtoFromJSONTyped(json, false);
}

export function ForecastDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForecastDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'withoutDunningSelection': !exists(json, 'withoutDunningSelection') ? undefined : ForecastActionDtoFromJSON(json['withoutDunningSelection']),
        'withDunningSelection': !exists(json, 'withDunningSelection') ? undefined : ForecastActionDtoFromJSON(json['withDunningSelection']),
        'forecastedDate': !exists(json, 'forecastedDate') ? undefined : (new Date(json['forecastedDate'])),
    };
}

export function ForecastDtoToJSON(value?: ForecastDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'withoutDunningSelection': ForecastActionDtoToJSON(value.withoutDunningSelection),
        'withDunningSelection': ForecastActionDtoToJSON(value.withDunningSelection),
        'forecastedDate': value.forecastedDate === undefined ? undefined : (value.forecastedDate.toISOString().substring(0,10)),
    };
}

