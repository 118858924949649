import React, { type FunctionComponent, useState } from 'react';

import { Icon, InputGroup, Tooltip } from '@blueprintjs/core';
import { Equals, Trash } from '@blueprintjs/icons';

import i18n from 'i18n/i18n';
import { type ParamForReact } from 'interfaces/Interfaces';
import { Button } from 'lib/Button';
import {
  testAttribute,
  validateHasEmojiPresentation,
  validateHasForbiddenPunctuation,
  validateIsDecimal,
  validateIsEmptyString,
} from 'util/Util';

interface ParamEditProps {
  param: ParamForReact;
  isEditable: boolean;
  isInUse: boolean;
  onParamChange: (param: ParamForReact) => void;
  onDeleteParam: (id: string) => void;
}

const ParamEdit: FunctionComponent<ParamEditProps> = (props: ParamEditProps) => {
  const [showValueInvalidWarning, setShowValueInvalidWarning] = useState<boolean>(false);
  const [showNameInvalidWarning, setShowNameInvalidWarning] = useState<boolean>(false);

  function validateName(newName: string) {
    if (
      validateHasForbiddenPunctuation(newName) ||
      validateHasEmojiPresentation(newName) ||
      validateIsEmptyString(newName)
    ) {
      setShowNameInvalidWarning(true);
      return false;
    }
    setShowNameInvalidWarning(false);
    return true;
  }

  function validateValue(newValue: string) {
    if (validateIsDecimal(newValue)) {
      if (newValue.split(/[.,]+/)[0].length > 10) {
        setShowValueInvalidWarning(true);
        return false;
      }
      setShowValueInvalidWarning(false);
      return true;
    }
    setShowValueInvalidWarning(true);
    return false;
  }

  function onValueChange(newValue: string) {
    if (/^\d+(\,\d*)?$/) {
      newValue = newValue.replace(',', '.');
    }
    const isValueValid = validateValue(newValue);
    const newParam = props.param;
    newParam.value = parseFloat(newValue);
    newParam.isValid = isValueValid && validateName(newParam.name);
    props.onParamChange(newParam);
  }

  function onNameChange(newName: string) {
    const isNameValid = validateName(newName);
    const newParam = props.param;
    newParam.name = newName;
    newParam.isValid = isNameValid && validateValue(newParam.value.toString());
    props.onParamChange(newParam);
  }

  return (
    <>
      {props.isEditable && (
        <div
          className="param-grid padding-bottom"
          data-testid="editable-element"
          data-test-id={testAttribute('pe22', 'editable-element', `${props.param.name}`)}>
          <Tooltip disabled={!props.isInUse} content={i18n.t('dialog.ruleset.tip-param-used')}>
            <InputGroup
              id={`${props.param.name}-add-name`}
              className="full-width"
              placeholder={i18n.t('dialog.ruleset.param-name')}
              autoFocus={props.param.name === ''}
              value={props.param.name}
              disabled={props.isInUse}
              onBlur={() => validateName(props.param.name)}
              intent={showNameInvalidWarning ? 'danger' : undefined}
              onChange={event => onNameChange(event.target.value)}
              data-test-id={testAttribute('pe22', 'dialog.ruleset.param-name', `${props.param.name}`)}
            />
          </Tooltip>

          <Icon className="param-icon" icon={<Equals />} />

          <InputGroup
            id={`${props.param.name}-add-value`}
            className="full-width"
            placeholder={i18n.t('dialog.ruleset.param-value')}
            defaultValue={props.param.value.toString()}
            onBlur={event => validateValue(event.target.value)}
            intent={showValueInvalidWarning ? 'danger' : undefined}
            onChange={event => onValueChange(event.target.value)}
            data-test-id={testAttribute('pe22', 'dialog.ruleset.param-value', `${props.param.name}`)}
          />
          {!props.isInUse && (
            <Button
              type="tertiary"
              id={`${props.param.name}-trash`}
              testId={testAttribute('rofl', 'trash', props.param.name)}
              icon={<Trash />}
              onClick={() => props.onDeleteParam(props.param.reactId)}
            />
          )}
          {showNameInvalidWarning && <div className="red-text">{i18n.t('dialog.description-invalid')}</div>}
          <div />
          {showValueInvalidWarning && <div className="red-text">{i18n.t('dialog.value-invalid')}</div>}
          <div />
        </div>
      )}

      {!props.isEditable && (
        <div className="param-grid-view bottom-line" data-testid="not-editable-element">
          <div className="highlighted-text">{props.param.name}</div>
          <div>{props.param.value}</div>
        </div>
      )}
    </>
  );
};

export default ParamEdit;
