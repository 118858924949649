import { type ReactNode, useEffect, useState } from 'react';

import { Divider, Spinner } from '@blueprintjs/core';

import { Tag } from 'lib/Tag';

import TableFilter, { type TableFilterTranslationProps } from './TableFilter/TableFilter';
import { type Filter } from './types/FilterModel';
import { StaticDatasource, type TableDatasource } from './types/TableDatasource';
import Table, { type TableProps } from './Table';

import './Table.style.scss';

interface FilteredTableTranslationProps extends TableFilterTranslationProps {
  entry?: string;
  entries?: string;
  searchResult?: string;
}

const defaultI18N: FilteredTableTranslationProps = {
  entry: 'Entry',
  entries: 'Entries',
  resetFilters: 'Reset filters',
  search: 'Search',
  searchResult: 'Search results',
  addFilter: 'Filter',
};

interface FilteredTableProps<T> extends TableProps<T> {
  i18n?: FilteredTableTranslationProps;
  filter: Filter[];
  noData?: ReactNode;
  noVisibleData?: ReactNode;
  bulkActions?: ReactNode;
}

export function FilteredTable<T>({
  filter,
  rows,
  dataSource,
  noData,
  noVisibleData,
  i18n = defaultI18N,
  bulkActions,
  ...props
}: FilteredTableProps<T>) {
  const [datasource, setDatasource] = useState<TableDatasource<any>>(() => {
    if (dataSource) return dataSource;
    if (rows) return new StaticDatasource(rows);
    throw new Error('No datasource defined');
  });
  const [appliedFilters, setAppliedFilters] = useState<Record<string, any>>([]);
  const [loadingState, setLoadingState] = useState<boolean>(false);

  useEffect(() => {
    setLoadingState(true);
    datasource.loadMore().then(() => {
      setLoadingState(false);
    });
  }, [datasource]);
  useEffect(() => {
    if (!dataSource && rows) setDatasource(new StaticDatasource(rows));
    if (dataSource) {
      setDatasource(dataSource);
      setAppliedFilters(dataSource.getFilterState());
    }
  }, [setDatasource, dataSource, rows]);

  function updateFilters(filters: Record<string, any>) {
    setLoadingState(true);
    datasource.filter(filters).then(() => {
      setAppliedFilters(filters);
      setLoadingState(false);
    });
  }

  function removeFilter(key: string) {
    setLoadingState(true);
    delete appliedFilters[key];
    datasource.filter(appliedFilters).then(() => {
      setAppliedFilters({ ...appliedFilters });
      setLoadingState(false);
    });
  }

  async function reachedEndOfTable() {
    if (!loadingState && datasource.hasNext) {
      setLoadingState(true);
      await datasource.loadMore();
      setLoadingState(false);
    }
  }

  function getEntriesInfoText() {
    if (loadingState) return '';
    return datasource.current.length === datasource.totalItemCount || !datasource.hasFilters
      ? `${datasource.totalItemCount} ${i18n.entries}`
      : `${datasource.totalItemCount} ${i18n.entries} / ${datasource.current.length} ${i18n.searchResult}`;
  }

  return (
    <>
      {filter && (
        <>
          <TableFilter
            data-test-id="123"
            i18n={i18n}
            values={appliedFilters}
            onChange={updateFilters}
            filterDefinitions={filter}
          />
          <Divider />
        </>
      )}
      <div className="lib-table__subnav">
        {getEntriesInfoText()}
        <div className="lib-table__subnav__tags">
          {filter &&
            Object.keys(appliedFilters).map(key => {
              const f = filter?.find(fil => fil.key === key);
              return (
                <Tag key={key} onRemove={() => removeFilter(key)}>
                  {f?.name}: {f?.valueRenderer ? f.valueRenderer(appliedFilters[key]) : appliedFilters[key]}
                </Tag>
              );
            })}
        </div>
        {bulkActions}
      </div>

      {datasource.current.length > 0 && (
        <Table {...props} dataSource={datasource} endOfTableCallback={() => reachedEndOfTable()} />
      )}
      {datasource.totalItemCount !== 0 && !loadingState && datasource.current.length === 0 && noVisibleData && (
        <div className="lib-table__loading-wrapper">{noVisibleData}</div>
      )}
      {datasource.totalItemCount === 0 && !loadingState && noData && (
        <div className="lib-table__loading-wrapper">{noData}</div>
      )}
      {loadingState && (
        <div className="lib-table__loading-wrapper">
          <Spinner />
        </div>
      )}
    </>
  );
}

export default FilteredTable;
