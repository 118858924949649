import {
  CaseCondition,
  CaseConditionForReact,
  CustomerCaseCondition,
  CustomerCaseConditionForReact,
} from './Interfaces';

export class RuleSetAssignmentCriteria {
  id: string;
  ruleSetId: string;
  caseConditions: CaseCondition[];
  customerCaseConditions: CustomerCaseCondition[];

  constructor(ruleCriteria: RuleSetAssignmentCriteriaForReact) {
    this.id = ruleCriteria.id;
    this.ruleSetId = ruleCriteria.ruleSetId;
    this.caseConditions = [];
    for (const caseCondition of ruleCriteria.caseConditions || []) {
      this.caseConditions.push(new CaseCondition(caseCondition));
    }

    this.customerCaseConditions = [];
    for (const customerCaseCondition of ruleCriteria.customerCaseConditions || []) {
      this.customerCaseConditions.push(new CustomerCaseCondition(customerCaseCondition));
    }
  }
}

export class RuleSetAssignmentCriteriaForReact {
  id: string;
  ruleSetId: string;
  caseConditions: CaseConditionForReact[];
  customerCaseConditions: CustomerCaseConditionForReact[];

  constructor(ruleCriteria: RuleSetAssignmentCriteria) {
    this.id = ruleCriteria.id;
    this.ruleSetId = ruleCriteria.ruleSetId;
    this.caseConditions = [];
    for (const caseCondition of ruleCriteria.caseConditions || []) {
      this.caseConditions.push(new CaseConditionForReact(caseCondition));
    }

    this.customerCaseConditions = [];
    for (const customerCaseCondition of ruleCriteria.customerCaseConditions || []) {
      this.customerCaseConditions.push(new CustomerCaseConditionForReact(customerCaseCondition));
    }
  }
}
