import React, { useEffect, useState } from 'react';

import { HashRouter, Route, Routes } from 'react-router-dom';

import DunningActionsPage from 'components/DunningActionPage/DunningActionsPage';
import DunningSelectionPage from 'components/DunningSelectionPage';
import NavBar from 'components/NavBar/NavBar';
import RuleSetArchivePage from 'components/RuleSetArchivePage/RuleSetArchivePage';
import { Clerk, RIGHTS, type User } from 'interfaces/Interfaces';
import { getLicenseModel } from 'services/ApiService';
import { getCurrentUser } from 'util/Util';

import AdminPage from './components/Dev/AdminPage';
import DunningOverview from './components/DunningOverview/DunningOverview';
import SettingsPage from './components/SettingsPage/SettingsPage';
import { AppLicenseContext } from './license/AppLicense';

import './App.style.sass';
import ContractStatePage from "./components/ContractStatePage/ContractStatePage";

const user: User = getCurrentUser(new Clerk());

export const UserContext = React.createContext(user);
export const LicenseContext = React.createContext<AppLicenseContext>(new AppLicenseContext());

function App() {
  const [userRole, setUserRole] = useState(user);
  const [license, setLicense] = useState<AppLicenseContext>(new AppLicenseContext());

  useEffect(() => {
    getLicenseModel().then(licenseModel => {
      setLicense(new AppLicenseContext(licenseModel, true));
    });
  }, []);

  return (
    <UserContext.Provider value={userRole}>
      <LicenseContext.Provider value={license}>
        <div className="App">
          <HashRouter>
            <NavBar />
            <Routes>
              <Route path="*" element={<DunningOverview />} />
              <Route path="actions" element={<DunningActionsPage />} />
              <Route path="contract-state" element={<ContractStatePage />} />

              {user.rights.includes(RIGHTS.ACCESS_DUNNING_SELECTION) && (
                <Route path="dunning-selection" element={<DunningSelectionPage />} />
              )}
              {user.rights.includes(RIGHTS.ACCESS_ADMIN_PAGE) && (
                <Route
                  path="admin"
                  element={
                    <AdminPage
                      changeUserRole={setUserRole}
                      changeLicense={appLicense => setLicense(new AppLicenseContext(appLicense, license.loaded))}
                    />
                  }
                />
              )}
              {user.rights.includes(RIGHTS.ACCESS_SETTINGS) && <Route path="settings" element={<SettingsPage />} />}
              {user.rights.includes(RIGHTS.ACCESS_ARCHIVE) && <Route path="archive" element={<RuleSetArchivePage />} />}
            </Routes>
          </HashRouter>
        </div>
      </LicenseContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
