import { type FunctionComponent, useState } from 'react';

import { Card, Dialog, DialogBody, Elevation, InputGroup, Tooltip } from '@blueprintjs/core';

import { getServerUrl, testAttribute } from '../../util/Util';
import { IconInfo } from '../../icons/svg/IconInfo';
import { Button } from '../../lib/Button';

interface ConfigProps {}

export const BackendConfig: FunctionComponent<ConfigProps> = props => {
  const [serverUrlInput, setServerUrlInput] = useState<string>(getServerUrl);
  const [dialogContent, setDialogContent] = useState<string | undefined>();

  function getJwtInfo(): any {
    return sessionStorage.getItem('jwt') ?? 'default (valid)';
  }

  const [jwtInfo, setJwtInfo] = useState(getJwtInfo());

  const handleEditUrl = (event: any) => {
    const url = event.target.value;
    setServerUrlInput(url);
  };

  const toggleJwtValidity = () => {
    if (sessionStorage.getItem('jwt')) {
      sessionStorage.removeItem('jwt');
    } else {
      sessionStorage.setItem('jwt', 'invalid');
    }
    setJwtInfo(getJwtInfo());
  };

  const handleUseUrl = () => {
    sessionStorage.setItem('url', serverUrlInput);
    window.location.reload();
  };

  const handleUseFlexibleDunningServiceApiUrl = () => {
    sessionStorage.setItem('url', (window as any).FlexibleDunningServiceApiUrl);
    window.location.reload();
  };

  const fetchAndDisplay = async (url: string) => {
    try {
      const text = await fetch(url).then(res => res.text());
      setDialogContent(text);
    } catch (e) {
      window.open(url, '_blank');
    }
  };

  return (
    <Card className="card" interactive={false} elevation={Elevation.TWO}>
      <Dialog onClose={() => setDialogContent(undefined)} isOpen={!!dialogContent}>
        <DialogBody>
          <pre>{dialogContent}</pre>
        </DialogBody>
      </Dialog>
      <h5 className="bp5-heading">Backend Configuration </h5>
      <div className="row">
        <span>Server URL:</span>
        <InputGroup
          data-test-id={testAttribute('465a', 'backend-config.server-url')}
          type="text"
          value={serverUrlInput}
          onChange={handleEditUrl}
          size={55}
        />
        <span className="spacer" />
        <Button testId={testAttribute('cff7', 'backend-config.set-server-url')} onClick={handleUseUrl}>
          Use URL
        </Button>
        <Tooltip
          content={`Allows you to set the base URL for this Dunning UI.
          This in only done for the current client and is no global configuration!`}>
          <IconInfo />
        </Tooltip>
      </div>
      <div className="row">
        <span>FlexibleDunningServiceApiUrl</span>
        <b>{(window as any).FlexibleDunningServiceApiUrl ?? 'unset -> Probably running outside RCS'}</b>
        <span className="spacer" />
        <Button
          testId={testAttribute('g1f7', 'backend-config.flexible-button-url')}
          onClick={handleUseFlexibleDunningServiceApiUrl}>
          Use FlexibleDunningServiceApiUrl
        </Button>
        <Tooltip
          content={`Only works when integrated in RCS!
          Applies the current RCS configuration for the base url in the current client.`}>
          <IconInfo />
        </Tooltip>
      </div>
      <div className="row">
        <span>JWT is:</span> <b>{jwtInfo}</b>
        <span className="spacer" />
        <Button testId={testAttribute('c1f7', 'backend-config.toggle-jwt-valid')} onClick={toggleJwtValidity}>
          Toggle JWT validity
        </Button>
        <Tooltip content={`Lets you invalidate the JWT for the current client.`}>
          <IconInfo />
        </Tooltip>
      </div>
      <div className="row">
        <span>Open Swagger UI under:</span>
        <b>{`${getServerUrl()}swagger-ui/index.html`}</b>
        <span className="spacer"></span>

        <a
          onClick={() => {
            navigator.clipboard.writeText((window as any).JwtForDunning);
          }}
          target="_blank"
          href={`${getServerUrl()}swagger-ui/index.html`}>
          <Button testId={testAttribute('adn1', 'backend-config.open-swagger')}>Open Swagger UI & Copy JWT</Button>
        </a>
        <Tooltip
          content={`Opens the swagger UI. Might not be enabled. Set the env. variable "SPRING_PROFILES_ACTIVE"
          to "swagger" during the deployment of the dunning service to enable it`}>
          <IconInfo />
        </Tooltip>
      </div>
      <div className="row">
        Get actuator health info
        <span className="spacer" />
        <Button
          testId={testAttribute('cjf7', 'backend-config.open-actuator')}
          onClick={() => fetchAndDisplay(`${getServerUrl()}actuator/health`)}>
          Fetch
        </Button>
        <Tooltip content={`Fetches the health actuator endpoint.`}>
          <IconInfo />
        </Tooltip>
      </div>
    </Card>
  );
};

export default BackendConfig;
