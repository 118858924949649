import { Callout } from '@blueprintjs/core';
import { Error } from '@blueprintjs/icons';
import i18n from 'i18next';

import { ValidationResult } from '../../DunningSelectionPage/DunningSelection/input/Validators';

type GROUP = string;
type CONDITION = string;
type INPUT = string;

export interface RuleValidationViewProps {
  validations?: Record<GROUP, Record<CONDITION, Record<INPUT, ValidationResult[] | undefined>>>;
  errorMessage: string;
}

export function RuleValidationView({ validations, errorMessage }: RuleValidationViewProps) {
  function getFirstError() {
    if (!validations) return undefined;
    const flat = Object.keys(validations)
      .map(key => validations[key])
      .flatMap(group => {
        return Object.keys(group).flatMap(condition =>
          Object.keys(group[condition]).flatMap(inputIdx => group[condition][inputIdx]),
        );
      })
      .filter(a => !!a);
    return flat[0];
  }

  const firstError = getFirstError();
  return (
    <>
      {(firstError ?? errorMessage) && (
        <Callout intent="danger" icon={<Error />}>
          {errorMessage || i18n.t(firstError?.message ?? '')}
        </Callout>
      )}
    </>
  );
}
