import { defaultInput, operatorSelect } from './RuleConditionHelper';
import i18n from '../../../../../i18n/i18n';
import { CUSTOMER_ATTRIBUTE, OPERATOR } from '../../../../../interfaces/enums';
import { RuleValidators } from '../../Validation/RuleValidators';
import { RuleConditionDefinition, RuleConditionInputTypes } from '../RuleCondition/RuleConditionTypes';

export const customerRuleConditionDefinitionFactory: () => {
  [key in CUSTOMER_ATTRIBUTE]: RuleConditionDefinition;
} = () => {
  return {
    [CUSTOMER_ATTRIBUTE.CALENDAR_DAYS_SINCE_LAST_EXECUTION]: {
      validators: [],
      inputs: [
        operatorSelect(),
        defaultInput(RuleConditionInputTypes.INTEGER, [
          RuleValidators.customer.required,
          RuleValidators.customer.nonNegativeNumber,
        ]),
      ],
    },
    [CUSTOMER_ATTRIBUTE.CALENDAR_DAYS_SINCE_LAST_NOTIFICATION]: {
      inputs: [
        operatorSelect(),
        defaultInput(RuleConditionInputTypes.INTEGER, [
          RuleValidators.customer.required,
          RuleValidators.customer.nonNegativeNumber,
        ]),
      ],
    },
    [CUSTOMER_ATTRIBUTE.WORKING_DAYS_SINCE_LAST_EXECUTION]: {
      inputs: [
        operatorSelect(),
        defaultInput(RuleConditionInputTypes.INTEGER, [
          RuleValidators.customer.required,
          RuleValidators.customer.nonNegativeNumber,
        ]),
      ],
    },
    [CUSTOMER_ATTRIBUTE.WORKING_DAYS_SINCE_LAST_NOTIFICATION]: {
      inputs: [
        operatorSelect(),
        defaultInput(RuleConditionInputTypes.INTEGER, [
          RuleValidators.customer.required,
          RuleValidators.customer.nonNegativeNumber,
        ]),
      ],
    },
    [CUSTOMER_ATTRIBUTE.CUSTOMER_GROUP]: {
      inputs: [
        operatorSelect([OPERATOR.EQ, OPERATOR.NEQ]),
        defaultInput(RuleConditionInputTypes.STRING, [RuleValidators.case.required, RuleValidators.case.sanitized]),
      ],
    },
    [CUSTOMER_ATTRIBUTE.CUSTOMER_TYPE]: {
      inputs: [
        operatorSelect([OPERATOR.EQ, OPERATOR.NEQ]),
        defaultInput(
          RuleConditionInputTypes.SELECT,
          [RuleValidators.case.required],
          [
            {
              key: 'B2B',
              label: i18n.t('select.B2B-business'),
            },
            {
              key: 'B2C',
              label: i18n.t('select.B2C-consumer'),
            },
          ],
        ),
      ],
    },
  };
};
