/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LevelMigrationDto } from './LevelMigrationDto';
import {
    LevelMigrationDtoFromJSON,
    LevelMigrationDtoFromJSONTyped,
    LevelMigrationDtoToJSON,
} from './LevelMigrationDto';

/**
 * 
 * @export
 * @interface RuleSetReplacementDto
 */
export interface RuleSetReplacementDto {
    /**
     * The old default ruleset
     * @type {string}
     * @memberof RuleSetReplacementDto
     */
    fromRuleset?: string;
    /**
     * The new default ruleset
     * @type {string}
     * @memberof RuleSetReplacementDto
     */
    toRuleset?: string;
    /**
     * The targeted RuleSet. It can be REGULAR, FINALLY_INVOICED or ALL
     * @type {string}
     * @memberof RuleSetReplacementDto
     */
    contractTarget?: RuleSetReplacementDtoContractTargetEnum;
    /**
     * 
     * @type {Array<LevelMigrationDto>}
     * @memberof RuleSetReplacementDto
     */
    levelMigrations?: Array<LevelMigrationDto>;
}


/**
 * @export
 */
export const RuleSetReplacementDtoContractTargetEnum = {
    Regular: 'REGULAR',
    FinallyInvoiced: 'FINALLY_INVOICED',
    All: 'ALL'
} as const;
export type RuleSetReplacementDtoContractTargetEnum = typeof RuleSetReplacementDtoContractTargetEnum[keyof typeof RuleSetReplacementDtoContractTargetEnum];


/**
 * Check if a given object implements the RuleSetReplacementDto interface.
 */
export function instanceOfRuleSetReplacementDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RuleSetReplacementDtoFromJSON(json: any): RuleSetReplacementDto {
    return RuleSetReplacementDtoFromJSONTyped(json, false);
}

export function RuleSetReplacementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetReplacementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fromRuleset': !exists(json, 'fromRuleset') ? undefined : json['fromRuleset'],
        'toRuleset': !exists(json, 'toRuleset') ? undefined : json['toRuleset'],
        'contractTarget': !exists(json, 'contractTarget') ? undefined : json['contractTarget'],
        'levelMigrations': !exists(json, 'levelMigrations') ? undefined : ((json['levelMigrations'] as Array<any>).map(LevelMigrationDtoFromJSON)),
    };
}

export function RuleSetReplacementDtoToJSON(value?: RuleSetReplacementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fromRuleset': value.fromRuleset,
        'toRuleset': value.toRuleset,
        'contractTarget': value.contractTarget,
        'levelMigrations': value.levelMigrations === undefined ? undefined : ((value.levelMigrations as Array<any>).map(LevelMigrationDtoToJSON)),
    };
}

