import { type FunctionComponent, useEffect, useState } from 'react';

import { FormGroup, InputGroup, TextArea } from '@blueprintjs/core';

import i18n from 'i18n/i18n';
import { testAttribute, validateHasEmojiPresentation, validateHasForbiddenPunctuation } from 'util/Util';
import { ValidationResult } from '../../../DunningSelectionPage/DunningSelection/input/Validators';

interface RuleInformationProps {
  isEditable?: boolean;
  name: string;
  description: string;
  onDescriptionChange: (
    newDescription: string,
    validation: Record<any, Record<any, ValidationResult[] | undefined>>,
  ) => void;
  onNameChange: (newName: string, validation: Record<any, Record<any, ValidationResult[] | undefined>>) => void;
}

const RuleDialog: FunctionComponent<RuleInformationProps> = ({
  name,
  description,
  onNameChange,
  onDescriptionChange,
  isEditable = false,
}) => {
  const [isNameInvalid, setIsNameInvalid] = useState<boolean>(false);
  const [isDescriptionInvalid, setIsDescriptionInvalid] = useState<boolean>(false);

  function onNameChangeHandler(newName: string) {
    const isValid = !validateHasForbiddenPunctuation(newName) && !validateHasEmojiPresentation(newName);
    setIsNameInvalid(!isValid);
    onNameChange(newName, isValid ? { name: {} } : { name: { 0: [{ error: true, message: '' }] } });
  }

  function onDescriptionChangeHandler(newDescription: string) {
    const isValid = !validateHasForbiddenPunctuation(newDescription) && !validateHasEmojiPresentation(newDescription);
    setIsDescriptionInvalid(!isValid);
    onDescriptionChange(newDescription, isValid ? { name: {} } : { name: { 0: [{ error: true, message: '' }] } });
  }

  useEffect(() => {
    onDescriptionChangeHandler(description);
    onNameChangeHandler(name);
  }, []);

  function isNameEmpty() {
    return name.length === 0;
  }

  return (
    <>
      {isEditable && (
        <>
          <FormGroup
            label={<span className="required-field">{i18n.t('dialog.rule.name')}</span>}
            labelFor="name-input"
            helperText={
              isNameEmpty() ? <span className="red-text">{i18n.t('dialog.name-mandatory')}</span> : undefined
            }>
            <InputGroup
              className="short place"
              id="name-input"
              placeholder={i18n.t('dialog.enter-name')}
              data-test-id={testAttribute('rlsd', 'dialog.rule.name')}
              value={name}
              onChange={event => onNameChangeHandler(event.target.value)}
              intent={isNameEmpty() ? 'danger' : 'none'}
            />
            {isNameInvalid && <span className="red-text">{i18n.t('dialog.name-invalid')}</span>}
          </FormGroup>
          <FormGroup
            label={i18n.t('dialog.rule.description')}
            labelFor="description-input"
            helperText={
              isDescriptionInvalid ? (
                <span className="red-text">{i18n.t('dialog.description-invalid')}</span>
              ) : undefined
            }>
            <TextArea
              id="description-input"
              data-test-id={testAttribute('rlsd', 'dialog.rule.description')}
              className="full-width"
              growVertically={false}
              maxLength={255}
              onChange={event => onDescriptionChangeHandler(event.target.value)}
              value={description}
              placeholder={i18n.t('dialog.enter-description')}
            />
          </FormGroup>
        </>
      )}
      {!isEditable && (
        <div className="padding-bottom">
          <div className="param-grid-view bottom-line">
            <div>{i18n.t('name')}</div>
            <div>{name}</div>
          </div>
          <div className="param-grid-view bottom-line">
            <div>{i18n.t('description')}</div>
            <div>{description}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default RuleDialog;
