import { type FunctionComponent, useContext, useState } from 'react';

import { FormGroup, Icon, InputGroup } from '@blueprintjs/core';
import { DragHandleHorizontal, Duplicate, Edit, SearchTemplate, Trash } from '@blueprintjs/icons';
import { LicenseContext, UserContext } from 'App';
import { Trans } from 'react-i18next';

import BasicDialog from 'components/Dialog/BasicDialog/BasicDialog';
import RuleDialog from 'components/Dialog/Rule/RuleDialog';
import { alertToast } from 'components/Toast/AlertToast';
import i18n from 'i18n/i18n';
import { STATE, TABLE_TITLE } from 'interfaces/enums';
import { Button } from 'lib/Button';
import { copyRule, deleteRule } from 'services/ApiService';
import {
  testAttribute,
  validateHasEmojiPresentation,
  validateHasForbiddenPunctuation,
  validateIsEmptyString,
} from 'util/Util';

import { RIGHTS, type RuleForReact, type RuleSetForReact } from '../../../../interfaces/Interfaces';
import { LICENSE_FEATURE, LICENSE_FEATURE_PACK } from '../../../../license/AppLicense';

interface RulesOfRuleSetRowProps {
  rule: RuleForReact;
  ruleSet: RuleSetForReact;
  tableHeaders: TABLE_TITLE[];
  onUpdateRuleSet: (ruleId: string, ruleSet: RuleSetForReact) => void;
  onUpdateTable: () => void;
}

const MAX_TEXT_LENGTH = 50;

const RulesOfRuleSetRow: FunctionComponent<RulesOfRuleSetRowProps> = (props: RulesOfRuleSetRowProps) => {
  const userContext = useContext(UserContext);

  const [dialogViewProcedureOpen, setDialogViewProcedureOpen] = useState<boolean>(false);
  const [dialogOnDeleteOpen, setDialogOnDeleteOpen] = useState<boolean>(false);
  const [dialogOnEditOpen, setDialogOnEditOpen] = useState<boolean>(false);
  const [dialogOnCopyOpen, setDialogOnCopyOpen] = useState<boolean>(false);
  const [copyRuleName, setCopyRuleName] = useState<string>(`${props.rule.name} ${i18n.t('copy')}`);
  const [showValidationErrorMessage, setShowValidationErrorMessage] = useState<boolean>(false);
  const [showNameDuplicateErrorMessage, setShowNameDuplicateErrorMessage] = useState<boolean>(false);

  const license = useContext(LicenseContext);

  function onDeleteClick() {
    setDialogOnDeleteOpen(false);
    deleteRule(props.rule.rulesetId, props.rule.id).then(() => {
      props.onUpdateTable();
    });
  }

  function onConfirmCopy() {
    if (showValidationErrorMessage || showNameDuplicateErrorMessage) {
    } else {
      const rule = { ...props.rule };
      rule.name = copyRuleName;
      copyRule(rule, rule.rulesetId)
        .then(rule => {
          props.ruleSet.rules.push(rule);
          props.ruleSet.rules.sort((a, b) => {
            return a.orderValue - b.orderValue;
          });
          setDialogOnCopyOpen(false);
          props.onUpdateRuleSet(rule.id, props.ruleSet);
        })
        .catch(error => alertToast(error));
      setShowNameDuplicateErrorMessage(false);
    }
  }

  function onCopiedNameChange(newName: string) {
    setCopyRuleName(newName);
    if (props.ruleSet.rules.filter(oldRule => newName === oldRule.name).length > 0) {
      setShowNameDuplicateErrorMessage(true);
    } else {
      setShowNameDuplicateErrorMessage(false);
    }
    if (
      !validateIsEmptyString(newName) &&
      !validateHasEmojiPresentation(newName) &&
      !validateHasForbiddenPunctuation(newName)
    ) {
      setShowValidationErrorMessage(false);
    } else {
      setShowValidationErrorMessage(true);
    }
  }

  function onCancelCopy() {
    setDialogOnCopyOpen(false);
    setShowValidationErrorMessage(false);
    setShowNameDuplicateErrorMessage(false);
    setCopyRuleName(`${props.rule.name} ${i18n.t('copy')}`);
  }

  function onCopyClick() {
    if (props.ruleSet.rules.filter(oldRule => copyRuleName === oldRule.name).length > 0) {
      setShowNameDuplicateErrorMessage(true);
    } else {
      setShowNameDuplicateErrorMessage(false);
    }
    setDialogOnCopyOpen(true);
  }

  return (
    <>
      <td />

      <td className="align-right" key={`ruleIdCircleList${props.rule.id}`}>
        {!userContext.rights.includes(RIGHTS.CHANGE_ORDER_RULE) ||
          (props.ruleSet.state === STATE.EDIT && <Icon<HTMLSpanElement> draggable icon={<DragHandleHorizontal />} />)}
      </td>
      <td key={`ruleNameList${props.rule.id}`}>{props.rule.name}</td>
      <td />
      {props.tableHeaders.map(title => {
        if (title === TABLE_TITLE.DESCRIPTION) {
          return (
            <td key={title}>
              {props.rule.description?.slice(0, MAX_TEXT_LENGTH) ?? '-'}
              {props.rule.description && props.rule.description.length > MAX_TEXT_LENGTH && '...'}
            </td>
          );
        }
        if (title === TABLE_TITLE.CREATED_ON) {
          return <td key={title}>{new Date(props.rule.created).toLocaleDateString() || '-'}</td>;
        }
        if (title === TABLE_TITLE.LAST_EDITED_ON) {
          return <td key={title}>{new Date(props.rule.updated).toLocaleDateString() || '-'}</td>;
        }
        if (title === TABLE_TITLE.STATUS) {
          return <td key={title}>{i18n.t(props.ruleSet.state) ?? '-'}</td>;
        }
        return <td key={title}>-</td>;
      })}

      <td className="align-right">
        {userContext.rights.includes(RIGHTS.VIEW_DETAIL_RULE) && props.ruleSet.state !== STATE.EDIT && (
          <Button
            testId={testAttribute('nqgf', 'button.view-details', props.rule.name)}
            tooltip={i18n.t('button.view-details')}
            type="tertiary"
            icon={<SearchTemplate />}
            id="view-icon"
            onClick={() => setDialogViewProcedureOpen(true)}
          />
        )}

        {userContext.rights.includes(RIGHTS.VIEW_DETAIL_RULE) &&
          props.ruleSet.state === STATE.EDIT &&
          !userContext.rights.includes(RIGHTS.UPDATE_RULE) && (
            <Button
              testId={testAttribute('ddaf', 'button.view-details', props.rule.name)}
              tooltip={i18n.t('button.view-details')}
              type="tertiary"
              id="view-icon"
              icon={<SearchTemplate />}
              onClick={() => setDialogViewProcedureOpen(true)}
            />
          )}

        {userContext.rights.includes(RIGHTS.UPDATE_RULE) && (
          <Button
            testId={testAttribute('vfga', 'button.edit-rule', props.rule.name)}
            tooltip={i18n.t('button.edit-rule')}
            id="edit-icon"
            type="tertiary"
            icon={<Edit />}
            onClick={() => setDialogOnEditOpen(true)}
          />
        )}

        {userContext.rights.includes(RIGHTS.CREATE_RULESET) &&
          license.isFeatureEnabled(LICENSE_FEATURE_PACK.RULE_PACK, LICENSE_FEATURE.COPY_RULE) && (
            <Button
              testId={testAttribute('1234', 'button.duplicate-rule', props.rule.name)}
              tooltip={i18n.t('button.duplicate-rule')}
              type="tertiary"
              id="copy-rule"
              icon={<Duplicate />}
              onClick={() => onCopyClick()}
            />
          )}

        {userContext.rights.includes(RIGHTS.DELETE_RULE) && props.ruleSet.state === STATE.EDIT && (
          <Button
            testId={testAttribute('1238', 'button.delete-rule', props.rule.name)}
            tooltip={i18n.t('button.delete-rule')}
            type="tertiary"
            id="trash-icon"
            icon={<Trash />}
            onClick={() => setDialogOnDeleteOpen(true)}
          />
        )}

        {/* ---------------- ------- ---------------- */}
        {/* ---------------- Dialogs ---------------- */}
        {/* ---------------- ------- ---------------- */}
        {dialogViewProcedureOpen && (
          <RuleDialog
            ruleSet={props.ruleSet}
            rule={props.rule}
            title={i18n.t('dialog.rule.edit')}
            buttonTextSuccess={i18n.t('button.save')}
            buttonTextClose={i18n.t('button.close')}
            isNameAndDescriptionEditable={props.ruleSet.state === STATE.ACTIVE}
            isRuleEditorEditable={false}
            onCloseDialog={() => setDialogViewProcedureOpen(false)}
            onConfirmRuleChange={ruleSet => {
              setDialogViewProcedureOpen(false);
              props.onUpdateRuleSet(props.rule.id, ruleSet);
            }}
          />
        )}

        {dialogOnEditOpen && (
          <RuleDialog
            ruleSet={props.ruleSet}
            rule={props.rule}
            title={i18n.t('dialog.rule.edit')}
            buttonTextSuccess={i18n.t('button.save')}
            isNameAndDescriptionEditable
            isRuleEditorEditable
            onCloseDialog={() => setDialogOnEditOpen(false)}
            onConfirmRuleChange={ruleSet => {
              setDialogOnEditOpen(false);
              props.onUpdateRuleSet(props.rule.id, ruleSet);
            }}
          />
        )}

        {dialogOnDeleteOpen && ( // Delete rule from ruleset
          <BasicDialog
            title={i18n.t('dialog.rule.delete')}
            mainText={<Trans i18nKey="dialog.rule.delete-text" values={{ name: props.rule.name }} />}
            onCloseDialog={() => setDialogOnDeleteOpen(false)}
            onConfirm={() => onDeleteClick()}
            confirmButtonText={i18n.t('button.delete')}
          />
        )}

        {dialogOnCopyOpen && (
          <BasicDialog
            title={i18n.t('dialog.rule.copy-title')}
            confirmButtonText={i18n.t('button.create-copy')}
            disableConfirmButton={showValidationErrorMessage || showNameDuplicateErrorMessage}
            onCloseDialog={() => onCancelCopy()}
            onConfirm={() => onConfirmCopy()}>
            <FormGroup label={<span className="required-field">{i18n.t('name')}</span>} labelFor="name-input">
              <InputGroup
                data-test-id={testAttribute('rows', 'rules-0f-ruleset-rows.input-group', props.ruleSet.name)}
                id="name-input"
                className="grey"
                autoFocus
                placeholder={i18n.t('dialog.enter-name')}
                value={copyRuleName}
                intent={showValidationErrorMessage || showNameDuplicateErrorMessage ? 'danger' : undefined}
                onChange={event => onCopiedNameChange(event.target.value)}
              />
              {showValidationErrorMessage && <div className="red-text">{i18n.t('dialog.name-invalid')}</div>}
              {showNameDuplicateErrorMessage && <div className="red-text">{i18n.t('dialog.name-double')}</div>}
            </FormGroup>
          </BasicDialog>
        )}
      </td>
    </>
  );
};

export default RulesOfRuleSetRow;
