import React, { type FunctionComponent } from 'react';

import { InputGroup } from '@blueprintjs/core';

import i18n from 'i18n/i18n';

import {
  testAttribute,
  validateHasEmojiPresentation,
  validateHasForbiddenPunctuation,
  validateIsEmptyString,
} from '../../../../../../util/Util';
import { RuleDefinitionBaseProps, RuleDefinitionStringValue, useValidation } from './RuleDefinitionBaseProps';

interface RuleDefinitionIntegerProps extends RuleDefinitionBaseProps {}

const RuleDefinitionStringInput: FunctionComponent<RuleDefinitionIntegerProps> = ({
  value,
  onChange,
  label,
  isEditable,
  inputProps,
  validators,
}) => {
  const [validatedOnChange, error] = useValidation(onChange, value, validators);
  if (!value || !(value instanceof RuleDefinitionStringValue)) return <></>;

  if (!isEditable) {
    return (
      <>
        <span>{label}</span>
        <span>{value.toValue()}</span>
      </>
    );
  }

  return (
    <>
      {label}
      <InputGroup
        id="action-input-notification"
        data-test-id={testAttribute('adi2', 'action-definition-string-input')}
        className="input-width"
        intent={
          validateIsEmptyString(value.toValue() ?? '') ||
          validateHasForbiddenPunctuation(value.toValue() ?? '') ||
          validateHasEmojiPresentation(value.toValue() ?? '')
            ? 'danger'
            : 'none'
        }
        {...inputProps}
        placeholder={i18n.t('dialog.rule.add-value')}
        value={value.toValue()}
        onChange={event => validatedOnChange(new RuleDefinitionStringValue(event.target.value))}
      />
    </>
  );
};

export default RuleDefinitionStringInput;
