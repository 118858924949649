import { type FunctionComponent, useEffect, useState } from 'react';

import { FormGroup, MenuItem, TagInput } from '@blueprintjs/core';
import { type ItemPredicate, type ItemRenderer, MultiSelect } from '@blueprintjs/select';

import i18n from 'i18n/i18n';
import { Tag } from 'lib/Tag/Tag';

import { type BaseInputProps, type Selectable } from './InputInterfaces';
import { type ValidationResult } from './Validators';

interface FormMultiSelectInputProps extends BaseInputProps<Selectable[]> {
  selectables: Selectable[];
}

const FormMultiSelectInput: FunctionComponent<FormMultiSelectInputProps> = props => {
  const [value, setValue] = useState<Selectable[]>([]);
  const [error, setError] = useState<ValidationResult | null>(null);

  useEffect(() => {
    const selected = props.selectables.filter(s => props.value?.map(s => s.key).includes(s.key));
    if (selected) setValue(selected);
  }, [props.selectables, props.value]);

  const unselectedSelectables = props.selectables.filter(s => !value.includes(s));

  async function validateInput(input: Selectable[]) {
    if (!props.validators) return null;
    const validationResult = await Promise.all(props.validators.map(validator => validator(input)));
    const firstError = validationResult.find(result => result?.error);
    if (firstError) {
      setError(firstError);
      return true;
    }
    setError(null);
    return false;
  }

  async function onAddHandler(val: Selectable) {
    const newValues = [...value, val];
    setValue(newValues);
    const hasError = await validateInput(newValues);
    if (props.onChange) props.onChange(newValues, !hasError);
  }

  async function onRemoveHandler(val: Selectable) {
    const newValues = value.filter(v => v.key !== val.key);
    setValue(newValues);
    const hasError = await validateInput(newValues);
    if (props.onChange) props.onChange(newValues, !hasError);
  }

  const filterList: ItemPredicate<Selectable> = (query, operator, _index, exactMatch) => {
    const normalizedTitle = operator.label.toString().toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    }
    return operator.label.toString().toLowerCase().includes(query.toLowerCase());
  };

  const selectionListRenderer: ItemRenderer<Selectable> = item => {
    return (
      <MenuItem
        key={item.key}
        text={item.label}
        onClick={() => {
          onAddHandler(item);
        }}
      />
    );
  };

  return (
    <FormGroup
      disabled={props.disabled}
      label={
        props.required ? <span className="required-field">{i18n.t(props.label ?? '')}</span> : i18n.t(props.label ?? '')
      }
      labelFor={props.name}
      helperText={error ? <span className="red-text">{i18n.t(error.message ?? '')}</span> : undefined}
      className="fill">
      {props.disabled ? (
        <TagInput
          values={value.map(v => (
            <Tag value={v.label} />
          ))}
          disabled
        />
      ) : (
        <MultiSelect
          fill
          itemPredicate={filterList}
          selectedItems={value}
          itemRenderer={selectionListRenderer}
          items={unselectedSelectables}
          onItemSelect={event => onAddHandler(event)}
          tagRenderer={item => <Tag value={item.label} />}
          onRemove={item => onRemoveHandler(item)}
          noResults={<div>No results</div>}
        />
      )}
    </FormGroup>
  );
};

export default FormMultiSelectInput;
