/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RuleParameterDto } from './RuleParameterDto';
import {
    RuleParameterDtoFromJSON,
    RuleParameterDtoFromJSONTyped,
    RuleParameterDtoToJSON,
} from './RuleParameterDto';
import type { RuleSetDunningLevelDto } from './RuleSetDunningLevelDto';
import {
    RuleSetDunningLevelDtoFromJSON,
    RuleSetDunningLevelDtoFromJSONTyped,
    RuleSetDunningLevelDtoToJSON,
} from './RuleSetDunningLevelDto';
import type { RuleSetInfoDto } from './RuleSetInfoDto';
import {
    RuleSetInfoDtoFromJSON,
    RuleSetInfoDtoFromJSONTyped,
    RuleSetInfoDtoToJSON,
} from './RuleSetInfoDto';

/**
 * 
 * @export
 * @interface RuleSetUpdateDto
 */
export interface RuleSetUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof RuleSetUpdateDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleSetUpdateDto
     */
    description?: string;
    /**
     * 
     * @type {Array<RuleParameterDto>}
     * @memberof RuleSetUpdateDto
     */
    parameters?: Array<RuleParameterDto>;
    /**
     * 
     * @type {Array<RuleSetDunningLevelDto>}
     * @memberof RuleSetUpdateDto
     */
    dunningLevels?: Array<RuleSetDunningLevelDto>;
    /**
     * 
     * @type {RuleSetInfoDto}
     * @memberof RuleSetUpdateDto
     */
    subsequentRuleset?: RuleSetInfoDto;
}

/**
 * Check if a given object implements the RuleSetUpdateDto interface.
 */
export function instanceOfRuleSetUpdateDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RuleSetUpdateDtoFromJSON(json: any): RuleSetUpdateDto {
    return RuleSetUpdateDtoFromJSONTyped(json, false);
}

export function RuleSetUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'parameters': !exists(json, 'parameters') ? undefined : ((json['parameters'] as Array<any>).map(RuleParameterDtoFromJSON)),
        'dunningLevels': !exists(json, 'dunningLevels') ? undefined : ((json['dunningLevels'] as Array<any>).map(RuleSetDunningLevelDtoFromJSON)),
        'subsequentRuleset': !exists(json, 'subsequentRuleset') ? undefined : RuleSetInfoDtoFromJSON(json['subsequentRuleset']),
    };
}

export function RuleSetUpdateDtoToJSON(value?: RuleSetUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'parameters': value.parameters === undefined ? undefined : ((value.parameters as Array<any>).map(RuleParameterDtoToJSON)),
        'dunningLevels': value.dunningLevels === undefined ? undefined : ((value.dunningLevels as Array<any>).map(RuleSetDunningLevelDtoToJSON)),
        'subsequentRuleset': RuleSetInfoDtoToJSON(value.subsequentRuleset),
    };
}

