/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RuleSetDunningLevelDto } from './RuleSetDunningLevelDto';
import {
    RuleSetDunningLevelDtoFromJSON,
    RuleSetDunningLevelDtoFromJSONTyped,
    RuleSetDunningLevelDtoToJSON,
} from './RuleSetDunningLevelDto';

/**
 * 
 * @export
 * @interface LevelMigrationDto
 */
export interface LevelMigrationDto {
    /**
     * 
     * @type {RuleSetDunningLevelDto}
     * @memberof LevelMigrationDto
     */
    fromLevel?: RuleSetDunningLevelDto;
    /**
     * 
     * @type {RuleSetDunningLevelDto}
     * @memberof LevelMigrationDto
     */
    toLevel?: RuleSetDunningLevelDto;
}

/**
 * Check if a given object implements the LevelMigrationDto interface.
 */
export function instanceOfLevelMigrationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LevelMigrationDtoFromJSON(json: any): LevelMigrationDto {
    return LevelMigrationDtoFromJSONTyped(json, false);
}

export function LevelMigrationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LevelMigrationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fromLevel': !exists(json, 'fromLevel') ? undefined : RuleSetDunningLevelDtoFromJSON(json['fromLevel']),
        'toLevel': !exists(json, 'toLevel') ? undefined : RuleSetDunningLevelDtoFromJSON(json['toLevel']),
    };
}

export function LevelMigrationDtoToJSON(value?: LevelMigrationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fromLevel': RuleSetDunningLevelDtoToJSON(value.fromLevel),
        'toLevel': RuleSetDunningLevelDtoToJSON(value.toLevel),
    };
}

