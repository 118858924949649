import React, { FunctionComponent, useContext, useState } from 'react';

import { FormGroup, InputGroup } from '@blueprintjs/core';
import { Duplicate, Edit, Plus, SearchTemplate } from '@blueprintjs/icons';
import { LicenseContext, UserContext } from 'App';
import { ResponseError } from 'generated-sources/openapi';
import { Trans } from 'react-i18next';

import BasicDialog from 'components/Dialog/BasicDialog/BasicDialog';
import MakeDefaultDialog from 'components/Dialog/MakeDefaultDialog/MakeDefaultDialog';
import RuleDialog from 'components/Dialog/Rule/RuleDialog';
import RuleSetDialog from 'components/Dialog/RuleSet/RuleSetDialog';
import MoreIconSelect from 'components/Select/MoreIconSelect';
import { alertToast } from 'components/Toast/AlertToast';
import i18n from 'i18n/i18n';
import { ERROR_CODE, ErrorMessage, STATE } from 'interfaces/enums';
import {
  AbstractRuleSet,
  BasicRuleSet,
  RIGHTS,
  RuleSet,
  RuleSetExecutionsSummary,
  RuleSetForReact,
  RuleSetWithAssignmentCriteria,
} from 'interfaces/Interfaces';
import { Button } from 'lib/Button';
import {
  activateRuleSet,
  archiveRuleSet,
  createRuleSet,
  createRulesetDynamicCriteria,
  deleteRuleSet,
  findRuleSetCustomizations,
  getExecutionsFromRuleSet,
  getRuleSet,
  getRulesetDynamicCriteria,
  migrateRuleSet,
} from 'services/ApiService';
import {
  testAttribute,
  validateHasEmojiPresentation,
  validateHasForbiddenPunctuation,
  validateIsEmptyString,
} from 'util/Util';

import { RuleSetAssignmentCriteriaForReact } from '../../../../interfaces/DynamicCriteriaInterfaces';
import { LICENSE_FEATURE, LICENSE_FEATURE_PACK } from '../../../../license/AppLicense';
import {
  clearRuleSetCache,
  clearRuleSetCacheById,
  getRuleSetWithRule,
} from '../../../../services/LoadRuleSetWithRules';
import RuleSetMigrationDialog from '../../../Dialog/RuleSetMigration/RuleSetMigrationDialog';

import '../RuleSetTable.style.sass';

interface RuleSetIconsProps {
  ruleSet: AbstractRuleSet;
  isDefaultRuleSetRegular: boolean;
  isDefaultRuleSetFinallyInvoiced: boolean;
  onUpdateRuleSet: (ruleSet: RuleSetForReact, ruleId?: string) => void;
  onUpdateTable: (ruleSetId?: string, ruleId?: string) => void;
  subsequentRuleSets: BasicRuleSet[];
  ruleSets: AbstractRuleSet[];
  isUsedInDunningSelectionConfiguration: boolean;
}

export const RuleSetIcons: FunctionComponent<RuleSetIconsProps> = (props: RuleSetIconsProps) => {
  const userContext = useContext(UserContext);

  const [dialogViewRuleSetOpen, setDialogViewRuleSetOpen] = useState<boolean>(false);
  const [dialogEditRuleSetNameAndDescriptionOpen, setDialogEditRuleSetNameAndDescriptionOpen] =
    useState<boolean>(false);

  const [disabledSelectItems, setDisabledSelectItems] = useState<any>({});
  const [dialogEditRuleSetOpen, setDialogEditRuleSetOpen] = useState<boolean>(false);
  const [dialogEditRuleOpen, setDialogEditRuleOpen] = useState<boolean>(false);
  const [dialogOnDeleteOpen, setDialogOnDeleteOpen] = useState<boolean>(false);
  const [dialogOnActivateOpen, setDialogOnActivateOpen] = useState<boolean>(false);
  const [dialogOnMigrateOpen, setDialogOnMigrateOpen] = useState<boolean>(false);
  const [dialogOnArchiveOpen, setDialogOnArchiveOpen] = useState<boolean>(false);
  const [dialogOnRuleSetBeingInUseOpen, setDialogOnRuleSetBeingInUseOpen] = useState<boolean>(false);
  const [dialogMakeDefaultOpen, setDialogMakeDefaultOpen] = useState<boolean>(false);
  const [dialogOnCopyOpen, setDialogOnCopyOpen] = useState<boolean>(false);
  const [dialogOnArchiveHasContractOpen, setDialogOnArchiveHasContractOpen] = useState<boolean>(false);
  const [dialogOnArchiveIsDefaultOpen, setDialogOnArchiveIsDefaultOpen] = useState<boolean>(false);
  const [
    dialogOnArchiveIsUsedInDunningSelectionConfigurationOpen,
    setDialogOnArchiveIsUsedInDunningSelectionConfigurationOpen,
  ] = useState<boolean>(false);
  const [dialogOnArchiveIsSubsequentOpen, setDialogOnArchiveIsSubsequentOpen] = useState<boolean>(false);
  const [dialogOnRuleSetExecutionsOpen, setDialogOnRuleSetExecutionsOpen] = useState<boolean>(false);
  const [assignedContracts, setAssignedContracts] = useState<number[]>([]);
  const [rulesetExecutionsSummary, setRulesetExecutionsSummary] = useState<RuleSetExecutionsSummary>();
  const [copyRuleSetName, setCopyRuleSetName] = useState<string>(`${props.ruleSet.name} ${i18n.t('copy')}`);
  const [showValidationErrorMessage, setShowValidationErrorMessage] = useState<boolean>(false);
  const [ruleSetForReact, setRuleSetForReact] = useState<RuleSetForReact>();
  const license = useContext(LicenseContext);
  const loadData = () => {
    getRuleSetWithRule(props.ruleSet.rulesetId)?.then(res => {
      setRuleSetForReact(res);
    });
  };
  const loadDisabledSelectItems = () => {
    findRuleSetCustomizations({
      ruleSetIds: [props.ruleSet.rulesetId],
      validOn: new Date(),
    }).then(customizations => {
      setDisabledSelectItems({
        migrate: customizations.length === 0,
        archive: customizations.length !== 0 || props.isDefaultRuleSetRegular || props.isDefaultRuleSetFinallyInvoiced,
      });
    });
  };

  function onArchiveClick() {
    if (props.isDefaultRuleSetRegular || props.isDefaultRuleSetFinallyInvoiced) {
      setDialogOnArchiveIsDefaultOpen(true);
      return;
    }
    if (props.isUsedInDunningSelectionConfiguration) {
      setDialogOnArchiveIsUsedInDunningSelectionConfigurationOpen(true);
      return;
    }
    if (props.ruleSets && props.ruleSets.length > 0) {
      const subRuleset = props.ruleSets.filter(
        ruleset => ruleset.subsequentId && ruleset.subsequentId === props.ruleSet.rulesetId,
      );
      if (subRuleset.length > 0) {
        setDialogOnArchiveIsSubsequentOpen(true);
        return;
      }
    }

    getExecutionsFromRuleSet(props.ruleSet.rulesetId)
      .then(ruleSetExecutionsSummary => {
        setRulesetExecutionsSummary(ruleSetExecutionsSummary);
        const { summary } = ruleSetExecutionsSummary;

        if (summary.failed > 0 || summary.retry > 0 || summary.pending > 0) {
          setDialogOnRuleSetExecutionsOpen(true);
        } else {
          setDialogOnArchiveOpen(true);
        }
      })
      .catch(error => alertToast(error));
  }

  function onConfirmArchive() {
    archiveRuleSet(props.ruleSet.rulesetId)
      .then(() => props.onUpdateTable())
      .catch(error => handleArchiveError(error));
    clearRuleSetCacheById(props.ruleSet.rulesetId);
  }

  function onConfirmDelete() {
    deleteRuleSet(props.ruleSet.rulesetId)
      .then(() => props.onUpdateTable())
      .catch(error => alertToast(error));
    clearRuleSetCacheById(props.ruleSet.rulesetId);
  }

  function onConfirmMigration(oldRuleSetId: string, newRuleSetId?: string) {
    if (!newRuleSetId) return;

    migrateRuleSet(oldRuleSetId, newRuleSetId)
      .then(() => props.onUpdateTable())
      .catch(error => alertToast(error));
    setDialogOnMigrateOpen(false);
  }

  function onConfirmActivate() {
    activateRuleSet(props.ruleSet.rulesetId)
      .then(() => props.onUpdateTable())
      .catch(error => alertToast(error));
    clearRuleSetCacheById(props.ruleSet.rulesetId);
  }

  function onConfirmCopy() {
    if (!showValidationErrorMessage) {
      getRuleSetWithRule(props.ruleSet.rulesetId)?.then(ruleSetForReact => {
        createRuleSet(
          copyRuleSetName,
          ruleSetForReact.description ?? '',
          ruleSetForReact.parameters,
          ruleSetForReact.dunningLevels,
          ruleSetForReact.rules,
        )
          .then(ruleset => {
            copyRulesetDynamicCriteria(props.ruleSet.rulesetId, ruleset.rulesetId);
            setShowValidationErrorMessage(false);
            props.onUpdateTable(ruleset.rulesetId);
          })
          .catch(error => alertToast(error));
      });
      clearRuleSetCache();
    }
  }

  async function copyRulesetDynamicCriteria(oldRuleRestId: string, newRuleSetId: string) {
    getRulesetDynamicCriteria(oldRuleRestId).then(criteria => {
      const dynamicCriteria = { ...criteria };
      dynamicCriteria.id = '';
      dynamicCriteria.ruleSetId = newRuleSetId;
      createRulesetDynamicCriteria(dynamicCriteria);
    });
  }

  function onCopiedNameChange(newName: string) {
    setCopyRuleSetName(newName);
    if (
      !validateIsEmptyString(newName) &&
      !validateHasEmojiPresentation(newName) &&
      !validateHasForbiddenPunctuation(newName)
    ) {
      setShowValidationErrorMessage(false);
    } else {
      setShowValidationErrorMessage(true);
    }
  }

  function onCancelCopy() {
    setDialogOnCopyOpen(false);
    setShowValidationErrorMessage(false);
    setCopyRuleSetName(`${props.ruleSet.name} ${i18n.t('copy')}`);
  }

  async function onExport() {
    try {
      const ruleSetForReact = await getRuleSet(props.ruleSet.rulesetId);
      let assignmentCriteriaForReact: RuleSetAssignmentCriteriaForReact | undefined;

      try {
        assignmentCriteriaForReact = await getRulesetDynamicCriteria(props.ruleSet.rulesetId);
      } catch (error) {
        // If fetching criteria fails, assignmentCriteria remains undefined
      }

      const ruleSetWithAssignmentCriteria = new RuleSetWithAssignmentCriteria(
        new RuleSet(ruleSetForReact),
        assignmentCriteriaForReact,
      );
      const file = new Blob([JSON.stringify(ruleSetWithAssignmentCriteria)], {
        type: 'application/json',
      });

      const a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.download = `${props.ruleSet.name || 'exportedDunningProcedure'}.json`;
      a.click();
    } catch (error) {
      console.error('Error exporting rule set:', error);
    }
  }

  async function handleArchiveError(errorMessage: ResponseError) {
    const ruleError: ErrorMessage = await errorMessage.response.json();
    if (ruleError.title === ERROR_CODE.RULE_SET_IS_ASSOCIATED_WITH_CONTRACT) {
      setDialogOnRuleSetBeingInUseOpen(true);
      setDialogOnArchiveOpen(false);
      return;
    }

    alertToast(errorMessage);
  }

  return (
    <>
      <td className="align-right">
        {userContext.rights.includes(RIGHTS.ADD_RULE) && props.ruleSet.state === STATE.EDIT && (
          <Button
            testId={testAttribute('wer2', 'button.add-rule', props.ruleSet.name)}
            tooltip={i18n.t('button.add-rule')}
            type="tertiary"
            id="add-rule"
            icon={<Plus />}
            onClick={() => {
              loadData();
              setDialogEditRuleOpen(true);
            }}
          />
        )}

        {userContext.rights.includes(RIGHTS.VIEW_DETAIL_RULESET) &&
          props.ruleSet.state === STATE.EDIT &&
          !userContext.rights.includes(RIGHTS.EDIT_RULESET) && (
            <Button
              testId={testAttribute('der2', 'button.view-details', props.ruleSet.name)}
              tooltip={i18n.t('button.view-details')}
              type="tertiary"
              id="view"
              icon={<SearchTemplate />}
              onClick={() => setDialogViewRuleSetOpen(true)}
            />
          )}

        {userContext.rights.includes(RIGHTS.VIEW_DETAIL_RULESET) && props.ruleSet.state === STATE.ACTIVE && (
          <Button
            testId={testAttribute('w2r2', 'button.view-details', props.ruleSet.name)}
            tooltip={i18n.t('button.view-details')}
            type="tertiary"
            id="view"
            icon={<SearchTemplate />}
            onClick={() => setDialogViewRuleSetOpen(true)}
          />
        )}

        {userContext.rights.includes(RIGHTS.EDIT_RULESET) && props.ruleSet.state === STATE.ACTIVE && (
          <Button
            testId={testAttribute('w2rl', 'button.edit-ruleset', props.ruleSet.name)}
            tooltip={i18n.t('button.edit-ruleset')}
            type="tertiary"
            id="edit"
            icon={<Edit />}
            onClick={() => setDialogEditRuleSetNameAndDescriptionOpen(true)}
          />
        )}

        {userContext.rights.includes(RIGHTS.EDIT_RULESET) && props.ruleSet.state === STATE.EDIT && (
          <Button
            testId={testAttribute('w2rl', 'button.edit-ruleset', props.ruleSet.name)}
            tooltip={i18n.t('button.edit-ruleset')}
            type="tertiary"
            id="edit"
            icon={<Edit />}
            onClick={() => setDialogEditRuleSetOpen(true)}
          />
        )}

        {userContext.rights.includes(RIGHTS.CREATE_RULESET) &&
          license.isFeatureEnabled(LICENSE_FEATURE_PACK.RULESET_PACK, LICENSE_FEATURE.COPY_RULESET) && (
            <Button
              testId={testAttribute('drk2', 'button.duplicate-ruleset', props.ruleSet.name)}
              tooltip={i18n.t('button.duplicate-ruleset')}
              type="tertiary"
              id="copy"
              icon={<Duplicate />}
              onClick={() => setDialogOnCopyOpen(true)}
            />
          )}

        <MoreIconSelect
          onExpand={() => loadDisabledSelectItems()}
          disabled={disabledSelectItems}
          allowedTransitions={props.ruleSet.allowedTransitions}
          isDefaultRuleSet={props.isDefaultRuleSetFinallyInvoiced && props.isDefaultRuleSetRegular}
          onDeleteClick={() => setDialogOnDeleteOpen(true)}
          onActivateClick={() => setDialogOnActivateOpen(true)}
          onMigrateClick={() => setDialogOnMigrateOpen(true)}
          onArchiveClick={() => onArchiveClick()}
          onMakeDefaultClick={() => {
            loadData();
            setDialogMakeDefaultOpen(true);
          }}
          onExportClick={() => onExport()}
        />
      </td>

      {/* ---------------- ------- ---------------- */}
      {/* ---------------- Dialogs ---------------- */}
      {/* ---------------- ------- ---------------- */}
      {dialogViewRuleSetOpen && (
        <RuleSetDialog
          ruleSetId={props.ruleSet.rulesetId}
          subsequentRuleSets={props.subsequentRuleSets}
          dialogTitle={i18n.t('title')}
          buttonTextSuccess={i18n.t('button.save')}
          buttonTextClose={i18n.t('button.close')}
          onCloseDialog={() => setDialogViewRuleSetOpen(false)}
          onConfirmRuleSetChange={id => {
            setDialogViewRuleSetOpen(false);
            props.onUpdateTable(id);
          }}
          isNameAndDescriptionEditable={false}
          isParamAndLevelEditable={false}
        />
      )}

      {dialogEditRuleSetNameAndDescriptionOpen && (
        <RuleSetDialog
          ruleSetId={props.ruleSet.rulesetId}
          subsequentRuleSets={props.subsequentRuleSets}
          dialogTitle={i18n.t('title')}
          buttonTextSuccess={i18n.t('button.save')}
          buttonTextClose={i18n.t('button.close')}
          onCloseDialog={() => setDialogEditRuleSetNameAndDescriptionOpen(false)}
          onConfirmRuleSetChange={id => {
            setDialogEditRuleSetNameAndDescriptionOpen(false);
            props.onUpdateTable(id);
          }}
          isNameAndDescriptionEditable
          isParamAndLevelEditable={false}
        />
      )}

      {dialogEditRuleSetOpen && (
        <RuleSetDialog
          ruleSetId={props.ruleSet.rulesetId}
          subsequentRuleSets={props.subsequentRuleSets}
          dialogTitle={i18n.t('dialog.ruleset.edit')}
          buttonTextSuccess={i18n.t('button.save')}
          onCloseDialog={() => setDialogEditRuleSetOpen(false)}
          onConfirmRuleSetChange={id => {
            setDialogEditRuleSetOpen(false);
            props.onUpdateTable(id);
          }}
          isNameAndDescriptionEditable
          isParamAndLevelEditable
        />
      )}

      {dialogEditRuleOpen && ruleSetForReact && (
        <RuleDialog
          ruleSet={ruleSetForReact}
          title={i18n.t('dialog.rule.add')}
          buttonTextSuccess={i18n.t('button.add')}
          onCloseDialog={() => setDialogEditRuleOpen(false)}
          onConfirmRuleChange={(ruleset, ruleId) => {
            setDialogEditRuleOpen(false);
            props.onUpdateRuleSet(ruleset, ruleId);
          }}
          isNameAndDescriptionEditable
          isRuleEditorEditable
        />
      )}

      {dialogOnDeleteOpen && (
        <BasicDialog
          title={i18n.t('dialog.ruleset.delete')}
          mainText={<Trans i18nKey="dialog.ruleset.delete-text" values={{ name: props.ruleSet.name }} />}
          onCloseDialog={() => setDialogOnDeleteOpen(false)}
          onConfirm={() => onConfirmDelete()}
          cancelButtonText={i18n.t('button.cancel')}
          confirmButtonText={i18n.t('button.delete')}
        />
      )}

      {dialogOnActivateOpen && (
        <BasicDialog
          title={i18n.t('dialog.ruleset.activate')}
          mainText={<Trans i18nKey="dialog.ruleset.activate-text" values={{ name: props.ruleSet.name }} />}
          orangeCallout={i18n.t('dialog.ruleset.activate-warning')}
          confirmButtonText={i18n.t('button.set-active')}
          onCloseDialog={() => setDialogOnActivateOpen(false)}
          onConfirm={() => onConfirmActivate()}
        />
      )}

      {dialogOnMigrateOpen && (
        <RuleSetMigrationDialog
          ruleSet={props.ruleSet}
          onCloseDialog={() => setDialogOnMigrateOpen(false)}
          onConfirmRuleSetMigration={(ruleSetId?: string) => onConfirmMigration(props.ruleSet.rulesetId, ruleSetId)}
        />
      )}

      {dialogOnArchiveOpen && (
        <BasicDialog
          title={i18n.t('dialog.ruleset.archive')}
          mainText={<Trans i18nKey="dialog.ruleset.archive-text" values={{ name: props.ruleSet.name }} />}
          orangeCallout={i18n.t('dialog.ruleset.archive-warning')}
          confirmButtonText={i18n.t('button.set-archived')}
          onCloseDialog={() => setDialogOnArchiveOpen(false)}
          onConfirm={() => onConfirmArchive()}
        />
      )}

      {dialogOnRuleSetBeingInUseOpen && (
        <BasicDialog
          title={i18n.t('dialog.ruleset.archive')}
          mainText={
            <Trans
              i18nKey="archive.validation.associated-contracts"
              values={{
                name: props.ruleSet.name,
              }}
            />
          }
          cancelButtonText={i18n.t('button.close')}
          onCloseDialog={() => setDialogOnRuleSetBeingInUseOpen(false)}
          onConfirm={() => undefined}
        />
      )}

      {dialogOnArchiveHasContractOpen && (
        <BasicDialog
          title={i18n.t('dialog.ruleset.archive')}
          mainText={
            <Trans
              i18nKey="dialog.ruleset.no-archive-text"
              values={{
                name: props.ruleSet.name,
                count: assignedContracts.length,
              }}
            />
          }
          // blueCallout={i18n.t("dialog.ruleset.archive-info")}
          cancelButtonText={i18n.t('button.close')}
          onCloseDialog={() => setDialogOnArchiveHasContractOpen(false)}
          onConfirm={() => undefined}>
          {assignedContracts && assignedContracts.length > 0 && (
            <div className="small-table-grid">
              <div className="highlighted-text ">{`${i18n.t('dialog.ruleset.assigned-contracts')}:`}</div>
              <div>{assignedContracts.length}</div>
            </div>
          )}
        </BasicDialog>
      )}
      {dialogOnRuleSetExecutionsOpen && (
        <BasicDialog
          title={i18n.t('dialog.ruleset.archive')}
          mainText={
            <Trans
              i18nKey="archive.validation.not-finished-executions"
              values={{
                name: props.ruleSet.name,
              }}
            />
          }
          cancelButtonText={i18n.t('button.close')}
          onCloseDialog={() => setDialogOnRuleSetExecutionsOpen(false)}
          onConfirm={() => undefined}>
          <div className="small-table-grid">
            <div className="highlighted-text ">{`${i18n.t('dialog.execution.failed')}:`}</div>
            <div>{rulesetExecutionsSummary?.summary?.failed ?? 0}</div>
            <div className="highlighted-text ">{`${i18n.t('dialog.execution.pending')}:`}</div>
            <div>{rulesetExecutionsSummary?.summary?.pending ?? 0}</div>
            <div className="highlighted-text ">{`${i18n.t('dialog.execution.retry')}:`}</div>
            <div>{rulesetExecutionsSummary?.summary?.retry ?? 0}</div>
          </div>
        </BasicDialog>
      )}
      {dialogOnArchiveIsSubsequentOpen && (
        <BasicDialog
          title={i18n.t('dialog.ruleset.archive')}
          mainText={
            <Trans
              i18nKey="dialog.ruleset.no-archive-subsequent-text"
              values={{
                name: props.ruleSet.name,
              }}
            />
          }
          // blueCallout={i18n.t("dialog.ruleset.archive-info")}
          cancelButtonText={i18n.t('button.close')}
          onCloseDialog={() => setDialogOnArchiveIsSubsequentOpen(false)}
          onConfirm={() => undefined}
        />
      )}

      {dialogOnArchiveIsDefaultOpen && (
        <BasicDialog
          title={i18n.t('dialog.ruleset.archive')}
          mainText={<Trans i18nKey="dialog.ruleset.archive-default" values={{ name: props.ruleSet.name }} />}
          cancelButtonText={i18n.t('button.close')}
          onCloseDialog={() => setDialogOnArchiveIsDefaultOpen(false)}
          onConfirm={() => undefined}
        />
      )}

      {dialogOnArchiveIsUsedInDunningSelectionConfigurationOpen && (
        <BasicDialog
          title={i18n.t('dialog.ruleset.archive')}
          mainText={
            <Trans
              i18nKey="dialog.ruleset.archive-procedure-used-in-dunning-selection"
              values={{ name: props.ruleSet.name }}
            />
          }
          cancelButtonText={i18n.t('button.close')}
          onCloseDialog={() => setDialogOnArchiveIsUsedInDunningSelectionConfigurationOpen(false)}
          onConfirm={() => undefined}
        />
      )}

      {dialogOnCopyOpen && (
        <BasicDialog
          title={i18n.t('dialog.ruleset.copy-title')}
          confirmButtonText={i18n.t('button.create-copy')}
          disableConfirmButton={showValidationErrorMessage}
          onCloseDialog={() => onCancelCopy()}
          onConfirm={() => onConfirmCopy()}>
          <FormGroup label={<span className="required-field">{i18n.t('name')}</span>} labelFor="name-input">
            <InputGroup
              data-test-id={testAttribute('rsic', 'ruleset-icon.input-group')}
              id="name-input"
              className="grey"
              autoFocus
              placeholder={i18n.t('dialog.enter-name')}
              value={copyRuleSetName}
              intent={showValidationErrorMessage ? 'danger' : undefined}
              onChange={event => onCopiedNameChange(event.target.value)}
            />
            {showValidationErrorMessage && <div className="red-text">{i18n.t('dialog.name-invalid')}</div>}
          </FormGroup>
        </BasicDialog>
      )}

      {dialogMakeDefaultOpen && ruleSetForReact && (
        <MakeDefaultDialog
          ruleSet={ruleSetForReact}
          onCloseDialog={() => setDialogMakeDefaultOpen(false)}
          onConfirm={() => props.onUpdateTable()}
          isDefaultRegular={props.isDefaultRuleSetRegular}
          isDefaultFinallyInvoiced={props.isDefaultRuleSetFinallyInvoiced}
        />
      )}
    </>
  );
};
export default RuleSetIcons;
