import { type FunctionComponent, useContext, useState } from 'react';

import { Card, Elevation, Icon, Tooltip } from '@blueprintjs/core';

import { testAttribute } from '../../util/Util';
import { IconInfo } from '../../icons/svg/IconInfo';
import { Button } from '../../lib/Button';
import i18n from '../../i18n/i18n';
import { CaretDown, Translate } from '@blueprintjs/icons';
import { LicenseContext, UserContext } from '../../App';
import { Admin, Clerk, Editor, SuperAdmin } from '../../interfaces/Interfaces';
import type { AppLicense } from '../../license/AppLicense';
import { LICENSE_MODEL } from '../../interfaces/enums';
import { licenseOptionDSL, licenseOptionDSU } from './AppLicenseOptions';

interface ConfigProps {
  changeUserRole: (role: any) => void;
  changeLicense: (license: AppLicense) => void;
}

export const FrontendConfig: FunctionComponent<ConfigProps> = props => {
  const [_, setRandomStateToTriggerRerender] = useState<any>();

  const userContext = useContext(UserContext);
  const licenseContext = useContext(LicenseContext);

  function handleChangeLanguage() {
    if (i18n.language === 'de') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('de');
    }
    setRandomStateToTriggerRerender(Math.random());
  }

  function changeRole(role: string) {
    if (role === 'Superadmin') {
      props.changeUserRole(new SuperAdmin());
    } else if (role === 'Admin') {
      props.changeUserRole(new Admin());
    } else if (role === 'Editor') {
      props.changeUserRole(new Editor());
    } else {
      props.changeUserRole(new Clerk());
    }
  }

  return (
    <Card className="card" interactive={false} elevation={Elevation.TWO}>
      <h5 className="bp5-heading">Frontend Configuration </h5>
      <div className="row">
        <span>Current Language</span>
        <b>{i18n.language}</b>
        <span className="spacer" />
        <Button
          icon={<Translate />}
          testId={testAttribute('hja1', 'frontend-config.toggle-language')}
          onClick={handleChangeLanguage}>
          Switch Language
        </Button>
        <Tooltip content={`Toggles the language between german and english. Might not update the navigation ... sorry`}>
          <IconInfo />
        </Tooltip>
      </div>
      <div className="row">
        <span>Current Role</span>
        <b>{userContext.role}</b>
        <span className="spacer" />

        <div className="bp5-html-select .modifier">
          <select
            data-test-id={testAttribute('adf7', 'frontend-config.select-role')}
            onChange={e => changeRole(e.target.value)}
            value={userContext.role}>
            <option value="Superadmin">Superadmin</option>
            <option value="Admin">Admin</option>
            <option value="Editor">Editor</option>
            <option value="Clerk">Clerk</option>
          </select>
          <Icon icon={<CaretDown />} />
        </div>

        <Tooltip
          content={`Allows you to change the role in the frontend.
          This does not affect backend validations, only what is displayed in the UI. To reset, reload the page`}>
          <IconInfo />
        </Tooltip>
      </div>
      <div className="row">
        <span>Current License Type:</span>
        <b>{licenseContext.appLicense.name}</b>
        <span className="spacer"></span>
        <div className="bp5-html-select">
          <select
            data-test-id={testAttribute('76as', 'frontend-config.select-license')}
            onChange={e =>
              props.changeLicense(e.target.value === LICENSE_MODEL.LIGHT ? licenseOptionDSL : licenseOptionDSU)
            }
            value={licenseContext.appLicense.name}>
            <option value={LICENSE_MODEL.LIGHT}>DSL (Dunning Service Light)</option>
            <option value={LICENSE_MODEL.UNLIMITED}>DSU (Dunning Service Unlimited)</option>
          </select>
          <Icon icon={<CaretDown />} />
        </div>
        <Tooltip
          content={`Allows you to change the license type in the frontend.
          This only changes what is displayed in the UI,
          not what is validated in the backend. To reset, reload the page`}>
          <IconInfo />
        </Tooltip>
      </div>
      <div className="row">
        <span>Clear Session Storage</span>
        <span className="spacer"></span>

        <Button
          testId={testAttribute('adp2', 'clear.session.storage')}
          onClick={() => {
            sessionStorage.clear();
            window.location.reload();
          }}>
          Clear
        </Button>
        <Tooltip content={`Clears the session storage of the client.`}>
          <IconInfo />
        </Tooltip>
      </div>
    </Card>
  );
};

export default FrontendConfig;
