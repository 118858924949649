/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FeaturePack } from './FeaturePack';
import {
    FeaturePackFromJSON,
    FeaturePackFromJSONTyped,
    FeaturePackToJSON,
} from './FeaturePack';

/**
 * 
 * @export
 * @interface LicenseDto
 */
export interface LicenseDto {
    /**
     * License type short name
     * @type {string}
     * @memberof LicenseDto
     */
    name?: string;
    /**
     * Group of features (Feature Pack)
     * @type {Array<FeaturePack>}
     * @memberof LicenseDto
     */
    featurePacks?: Array<FeaturePack>;
}

/**
 * Check if a given object implements the LicenseDto interface.
 */
export function instanceOfLicenseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LicenseDtoFromJSON(json: any): LicenseDto {
    return LicenseDtoFromJSONTyped(json, false);
}

export function LicenseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'featurePacks': !exists(json, 'featurePacks') ? undefined : ((json['featurePacks'] as Array<any>).map(FeaturePackFromJSON)),
    };
}

export function LicenseDtoToJSON(value?: LicenseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'featurePacks': value.featurePacks === undefined ? undefined : ((value.featurePacks as Array<any>).map(FeaturePackToJSON)),
    };
}

