/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SystemPropertyDto
 */
export interface SystemPropertyDto {
    /**
     * The property key identifier
     * @type {string}
     * @memberof SystemPropertyDto
     */
    property?: string;
    /**
     * The value for the property
     * @type {string}
     * @memberof SystemPropertyDto
     */
    value?: string;
    /**
     * The type that value is: INTEGER, STRING or BOOLEAN
     * @type {string}
     * @memberof SystemPropertyDto
     */
    type?: SystemPropertyDtoTypeEnum;
    /**
     * True if the field should have a value, False vice versa
     * @type {boolean}
     * @memberof SystemPropertyDto
     */
    requiredField: boolean;
}


/**
 * @export
 */
export const SystemPropertyDtoTypeEnum = {
    String: 'STRING',
    Integer: 'INTEGER',
    Decimal: 'DECIMAL',
    Boolean: 'BOOLEAN'
} as const;
export type SystemPropertyDtoTypeEnum = typeof SystemPropertyDtoTypeEnum[keyof typeof SystemPropertyDtoTypeEnum];


/**
 * Check if a given object implements the SystemPropertyDto interface.
 */
export function instanceOfSystemPropertyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "requiredField" in value;

    return isInstance;
}

export function SystemPropertyDtoFromJSON(json: any): SystemPropertyDto {
    return SystemPropertyDtoFromJSONTyped(json, false);
}

export function SystemPropertyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemPropertyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'property': !exists(json, 'property') ? undefined : json['property'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'requiredField': json['requiredField'],
    };
}

export function SystemPropertyDtoToJSON(value?: SystemPropertyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'property': value.property,
        'value': value.value,
        'type': value.type,
        'requiredField': value.requiredField,
    };
}

