/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DefaultRuleSetDto,
  ErrorResponse,
  RuleSetReplacementDto,
  RuleSetReplacementLogDto,
} from '../models/index';
import {
    DefaultRuleSetDtoFromJSON,
    DefaultRuleSetDtoToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    RuleSetReplacementDtoFromJSON,
    RuleSetReplacementDtoToJSON,
    RuleSetReplacementLogDtoFromJSON,
    RuleSetReplacementLogDtoToJSON,
} from '../models/index';

export interface CheckAssignmentRequest {
    defaultRuleSetDto: DefaultRuleSetDto;
}

export interface GetDefaultRuleSetByTargetRequest {
    contractsTarget: GetDefaultRuleSetByTargetContractsTargetEnum;
}

export interface SetRequest {
    defaultRuleSetDto: DefaultRuleSetDto;
}

/**
 * 
 */
export class DefaultRulesetApi extends runtime.BaseAPI {

    /**
     * Send a request to assign a default rule set, but do not assign it. In response, the service will return summary information about the changes that will occur during the assignment. 
     * Simulate assignment
     */
    async checkAssignmentRaw(requestParameters: CheckAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetReplacementLogDto>> {
        if (requestParameters.defaultRuleSetDto === null || requestParameters.defaultRuleSetDto === undefined) {
            throw new runtime.RequiredError('defaultRuleSetDto','Required parameter requestParameters.defaultRuleSetDto was null or undefined when calling checkAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/default-ruleset/check`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DefaultRuleSetDtoToJSON(requestParameters.defaultRuleSetDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetReplacementLogDtoFromJSON(jsonValue));
    }

    /**
     * Send a request to assign a default rule set, but do not assign it. In response, the service will return summary information about the changes that will occur during the assignment. 
     * Simulate assignment
     */
    async checkAssignment(requestParameters: CheckAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetReplacementLogDto> {
        const response = await this.checkAssignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the default ruleset by contract target type: REGULAR or FINALLY_INVOICED
     * Get by contract type
     */
    async getDefaultRuleSetByTargetRaw(requestParameters: GetDefaultRuleSetByTargetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DefaultRuleSetDto>> {
        if (requestParameters.contractsTarget === null || requestParameters.contractsTarget === undefined) {
            throw new runtime.RequiredError('contractsTarget','Required parameter requestParameters.contractsTarget was null or undefined when calling getDefaultRuleSetByTarget.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/default-ruleset/{contractsTarget}`.replace(`{${"contractsTarget"}}`, encodeURIComponent(String(requestParameters.contractsTarget))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultRuleSetDtoFromJSON(jsonValue));
    }

    /**
     * Get the default ruleset by contract target type: REGULAR or FINALLY_INVOICED
     * Get by contract type
     */
    async getDefaultRuleSetByTarget(requestParameters: GetDefaultRuleSetByTargetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DefaultRuleSetDto> {
        const response = await this.getDefaultRuleSetByTargetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all existing default rule sets for all contract types
     * Get all
     */
    async getDefaultRuleSetsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DefaultRuleSetDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/default-ruleset`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DefaultRuleSetDtoFromJSON));
    }

    /**
     * Get all existing default rule sets for all contract types
     * Get all
     */
    async getDefaultRuleSets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DefaultRuleSetDto>> {
        const response = await this.getDefaultRuleSetsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set the default ruleset for regular or finally invoiced contracts
     * Assign default ruleset
     */
    async setRaw(requestParameters: SetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetReplacementDto>> {
        if (requestParameters.defaultRuleSetDto === null || requestParameters.defaultRuleSetDto === undefined) {
            throw new runtime.RequiredError('defaultRuleSetDto','Required parameter requestParameters.defaultRuleSetDto was null or undefined when calling set.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/default-ruleset`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DefaultRuleSetDtoToJSON(requestParameters.defaultRuleSetDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetReplacementDtoFromJSON(jsonValue));
    }

    /**
     * Set the default ruleset for regular or finally invoiced contracts
     * Assign default ruleset
     */
    async set(requestParameters: SetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetReplacementDto> {
        const response = await this.setRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetDefaultRuleSetByTargetContractsTargetEnum = {
    Regular: 'REGULAR',
    FinallyInvoiced: 'FINALLY_INVOICED',
    All: 'ALL'
} as const;
export type GetDefaultRuleSetByTargetContractsTargetEnum = typeof GetDefaultRuleSetByTargetContractsTargetEnum[keyof typeof GetDefaultRuleSetByTargetContractsTargetEnum];
