export const IconRedo = () => (
  <span className="pc-icon">
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6464 1.14645C11.8417 0.951184 12.1583 0.951184 12.3536 1.14645L15.7071 4.5L12.3536 7.85355C12.1583 8.04882 11.8417 8.04882 11.6464 7.85355C11.4512 7.65829 11.4512 7.34171 11.6464 7.14645L13.7929 5H5C2.79086 5 1 6.79086 1 9C1 11.2091 2.79086 13 5 13H9.5C9.77614 13 10 13.2239 10 13.5C10 13.7761 9.77614 14 9.5 14H5C2.23858 14 0 11.7614 0 9C0 6.23858 2.23858 4 5 4H13.7929L11.6464 1.85355C11.4512 1.65829 11.4512 1.34171 11.6464 1.14645Z"
        fill="#030307"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 13.5C15 14.3284 14.3284 15 13.5 15C12.6716 15 12 14.3284 12 13.5C12 12.6716 12.6716 12 13.5 12C14.3284 12 15 12.6716 15 13.5ZM14 13.5C14 13.7761 13.7761 14 13.5 14C13.2239 14 13 13.7761 13 13.5C13 13.2239 13.2239 13 13.5 13C13.7761 13 14 13.2239 14 13.5Z"
        fill="#030307"
      />
    </svg>
  </span>
);
