import { type FunctionComponent } from 'react';
import BackendConfig from './BackendConfig';
import { type AppLicense } from '../../license/AppLicense';

import './AdminPage.style.sass';
import '../../App.style.sass'; //I'm quite confused why this is necessary but apparently this is required to override the bp5 styles, so ... i'll leave it here ...
import FrontendConfig from './FrontendConfig';
import GeneralInformation from './GeneralInformation';
import ContractCustomization from './ContractCustomization';
import RuleSetWithId from './RulesetWithIds';
import UuidHexConverter from './UuidHexConverter';

interface AdminPageProps {
  changeUserRole: (role: any) => void;
  changeLicense: (license: AppLicense) => void;
}

export const AdminPage: FunctionComponent<AdminPageProps> = (props: AdminPageProps) => {
  return (
    <div className="admin-page">
      <h1>Configuration</h1>
      <div className="flex-container">
        <BackendConfig />
        <FrontendConfig changeUserRole={props.changeUserRole} changeLicense={props.changeLicense} />
      </div>
      <h1 className="section">Miscellaneous</h1>
      <div className="flex-container">
        <GeneralInformation />
        <ContractCustomization />
        <RuleSetWithId />
        <UuidHexConverter />
      </div>
    </div>
  );
};

export default AdminPage;
