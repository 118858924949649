import { type FunctionComponent, useEffect, useState } from 'react';

import { Button, FormGroup, MenuItem } from '@blueprintjs/core';
import { Search } from '@blueprintjs/icons';
import { type ItemPredicate, type ItemRenderer, type ItemsEqualProp, Select } from '@blueprintjs/select';

import i18n from 'i18n/i18n';
import { IconCaretDown } from 'icons/svg/IconCaretDown';

import { type BaseInputProps, type Selectable } from './InputInterfaces';
import { type ValidationResult } from './Validators';

interface FormSelectInputProps extends BaseInputProps<Selectable> {
  selectables: Selectable[];
  filterable?: boolean;
}

const FormSelectInput: FunctionComponent<FormSelectInputProps> = props => {
  const [value, setValue] = useState(props.value ?? props.selectables[0]);
  const [error, setError] = useState<ValidationResult | null>(null);

  useEffect(() => {
    const selected = props.selectables.find(s => s.key === props.value?.key);
    if (selected) setValue(selected);
  }, [props.selectables, props.value]);

  async function validateInput(input: Selectable) {
    if (!props.validators) return null;
    const validationResult = await Promise.all(props.validators.map(validator => validator(input)));
    const firstError = validationResult.find(result => result?.error);
    if (firstError) {
      setError(firstError);
      return true;
    }
    setError(null);
    return false;
  }

  async function onChangeHandler(val: Selectable) {
    setValue(val);
    const hasError = await validateInput(val);
    if (props.onChange) props.onChange(val, !hasError);
  }

  const filterList: ItemPredicate<Selectable> = (query, operator, _index, exactMatch) => {
    const normalizedTitle = operator.label.toString().toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    }
    return operator.label.toString().toLowerCase().includes(query.toLowerCase());
  };
  const renderList: ItemRenderer<Selectable> = (element, { handleClick, modifiers }) => {
    return (
      <MenuItem
        className="render"
        active={modifiers.active}
        key={element.key}
        onClick={handleClick}
        text={element.label}
      />
    );
  };
  const equalProp: ItemsEqualProp<Selectable> = (s1, s2) => s1.key === s2.key;

  return (
    <FormGroup
      disabled={props.disabled}
      label={
        props.required ? <span className="required-field">{i18n.t(props.label ?? '')}</span> : i18n.t(props.label ?? '')
      }
      labelFor={props.name}
      helperText={error ? <span className="red-text">{i18n.t(error.message ?? '')}</span> : undefined}>
      <Select<Selectable>
        disabled={props.disabled}
        itemPredicate={filterList}
        inputProps={{
          leftElement: <Search />,
        }}
        activeItem={value}
        items={props.selectables}
        itemRenderer={renderList}
        itemsEqual={equalProp}
        onItemSelect={res => onChangeHandler(res)}
        filterable={props.filterable}
        popoverProps={{ position: 'bottom' }}>
        <Button fill outlined text={value?.label} rightIcon={<IconCaretDown />} />
      </Select>
    </FormGroup>
  );
};

export default FormSelectInput;
