import * as React from 'react';
import { type FunctionComponent } from 'react';

import { Button as BpButton, Intent, Tooltip } from '@blueprintjs/core';

import './Button.sass';

interface ButtonProps {
  type?: 'primary' | 'secondary' | 'tertiary' | 'default';
  icon?: any;
  id?: string;
  tooltip?: string;
  disabled?: boolean;
  className?: string;
  testId: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  rightIcon?: any;
  fill?: boolean;
}

const Button: FunctionComponent<ButtonProps> = ({
  type = 'primary',
  icon,
  children,
  onClick,
  tooltip,
  disabled,
  id,
  className,
  rightIcon,
  testId,
  fill = false,
}) => {
  const intent = type === 'tertiary' ? Intent.NONE : Intent.PRIMARY;
  const outlined = type === 'secondary' || type === 'default';
  let button = (
    <BpButton
      data-test-id={testId}
      id={id}
      className={`pc-button ${type}${className ? ` ${className}` : ''}`}
      intent={intent}
      outlined={outlined}
      icon={icon}
      onClick={onClick}
      disabled={disabled}
      rightIcon={rightIcon}
      fill={fill}>
      {children}
    </BpButton>
  );

  if (tooltip) {
    button = <Tooltip content={tooltip}>{button}</Tooltip>;
  }

  return button;
};

export { Button };
