import { FunctionComponent } from 'react';

import { Spinner } from '@blueprintjs/core';

import i18n from 'i18n/i18n';
import { IconDownload } from 'icons/svg/IconDownload';
import { DunningSelectionForecast } from 'interfaces/Interfaces';
import { Button } from 'lib/Button';
import { testAttribute } from 'util/Util';

import { DunningForecastTable } from './DunningForecastTable';
import { IconRefresh } from '../../../icons/svg/IconRefresh';

interface DunningForecastProps {
  forecast?: DunningSelectionForecast;
  selectionHasChanged?: boolean;
  forecastLoading?: boolean;
  downloadLoading?: boolean;
  refresh: () => void;
  download: () => void;
  refreshingTime?: string;
}

const DunningForecast: FunctionComponent<DunningForecastProps> = ({
  forecast,
  refresh,
  selectionHasChanged,
  forecastLoading,
  download,
  downloadLoading,
  refreshingTime,
}) => {
  return (
    <div className="dunning-forecast">
      <div className="dunning-forecast__group" title="">
        <h3 className="card-title">{i18n.t('dunning-selection.forecast.title')}</h3>
        <p className="card-description">{i18n.t('dunning-selection.forecast.header-description')}</p>
        {!forecastLoading ? (
          <div className="dunning-forecast__display">
            <div className="dunning-forecast__reload">
              <Button
                testId={testAttribute('xcy1', 'dunning-selection.forecast.refresh')}
                type="secondary"
                icon={<IconRefresh />}
                disabled={!selectionHasChanged && !forecastLoading}
                onClick={() => refresh()}>
                {i18n.t('dunning-selection.forecast.refresh')}
              </Button>
              <span className="last-update">
                {i18n.t('dunning-selection.forecast.last-update', { date: refreshingTime ?? '' })}
              </span>
            </div>
            {forecast && <DunningForecastTable forecast={forecast} />}
            <Button
              testId={testAttribute('xcy1', 'dunning-selection.forecast.download')}
              icon={downloadLoading ? <Spinner size={16} /> : <IconDownload />}
              type="tertiary"
              onClick={() => download()}
              disabled={downloadLoading}>
              {i18n.t('dunning-selection.forecast.download')}
            </Button>
          </div>
        ) : (
          <div className="dunning-forecast__spinner">
            <Spinner />
            <div>{i18n.t('dunning-selection.forecast.loading')}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export { DunningForecast };
