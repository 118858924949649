import { type FunctionComponent, type ReactNode, useState } from 'react';

import { Callout, Classes, Dialog } from '@blueprintjs/core';
import { Error, InfoSign } from '@blueprintjs/icons';

import i18n from 'i18n/i18n';
import { Button } from 'lib/Button';
import { testAttribute } from 'util/Util';

interface BasicDialogProps {
  title: string;
  mainText?: ReactNode;
  blueCallout?: string;
  orangeCallout?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  disableConfirmButton?: boolean;
  disableCancelButton?: boolean;
  onCloseDialog: () => void;
  onConfirm: () => void;
  children?: ReactNode;
}

export const BasicDialog: FunctionComponent<BasicDialogProps> = (props: BasicDialogProps) => {
  const [isSuccessButtonDisabled, setIsSuccessButtonDisabled] = useState<boolean>(false);

  function onSuccessButtonClick() {
    setIsSuccessButtonDisabled(true);
    props.onConfirm();
  }

  return (
    <Dialog className="dialog" isOpen canOutsideClickClose={false} onClose={() => props.onCloseDialog()}>
      <div className="dialog-title"> {props.title}</div>
      <div className={Classes.DIALOG_BODY}>
        <div>{props.mainText}</div>
        {props.orangeCallout && (
          <div className="padding-top">
            <Callout intent="warning" icon={<Error />}>
              {props.orangeCallout}
            </Callout>
          </div>
        )}
        {props.blueCallout && (
          <div className="padding-top">
            <Callout intent="primary" icon={<InfoSign />}>
              {props.blueCallout}
            </Callout>
          </div>
        )}
        {props.children}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            id="cancel-button"
            testId={testAttribute('amB2', 'button.cancel')}
            type="secondary"
            className="button"
            disabled={props.disableCancelButton}
            onClick={() => props.onCloseDialog()}>
            {props.cancelButtonText ?? i18n.t('button.cancel')}
          </Button>
          {props.confirmButtonText && (
            <Button
              id="save-button"
              testId={testAttribute('xmB2', 'button.save')}
              type="primary"
              onClick={() => onSuccessButtonClick()}
              disabled={props.disableConfirmButton ?? isSuccessButtonDisabled}>
              {props.confirmButtonText}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};
export default BasicDialog;
