import {
  Condition,
  DunningLevelForReact,
  RuleSetForReact,
  RuleTemplate,
  StringConst,
} from '../../../../../interfaces/Interfaces';
import { CASE_ATTRIBUTE, ITEM_ATTRIBUTE } from '../../../../../interfaces/enums';
import { ValidationResult } from '../../../../DunningSelectionPage/DunningSelection/input/Validators';

function getHighestLevel(dunningLevels: DunningLevelForReact[], conditions: Condition[], attribute: any) {
  return dunningLevels.reduce((highest, level) => {
    if (
      conditions.some(selector => selector.attrib === attribute && level.id === (selector.argument as StringConst).val)
    ) {
      return Math.max(highest, level.level);
    }
    return highest;
  }, -Infinity);
}

export function validateChangeLevelDescOrder(
  ruleEdit: RuleTemplate,
  ruleSet: RuleSetForReact,
): ValidationResult | null {
  const highestGroupLevel = getHighestLevel(ruleSet.dunningLevels, ruleEdit.groupSelectors, ITEM_ATTRIBUTE.LEVEL);
  const highestCaseLevel = getHighestLevel(ruleSet.dunningLevels, ruleEdit.caseConditions, CASE_ATTRIBUTE.LEVEL);

  let smallestActionLevel = Math.min(
    ...ruleSet.dunningLevels
      .filter(level => ruleEdit.actions.find(action => level.id === action.newStatusId))
      .map(level => level.level),
  );
  if (smallestActionLevel === Infinity) {
    return null;
  }
  if (
    (highestGroupLevel !== -Infinity && smallestActionLevel <= highestGroupLevel) ||
    (highestCaseLevel !== -Infinity && smallestActionLevel <= highestCaseLevel)
  ) {
    return {
      error: true,
      message: 'dialog.rule.level-desc-order',
    };
  }

  return null;
}
