/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GroupSelector
 */
export interface GroupSelector {
    /**
     * 
     * @type {string}
     * @memberof GroupSelector
     */
    attrib: GroupSelectorAttribEnum;
    /**
     * 
     * @type {string}
     * @memberof GroupSelector
     */
    cmp: GroupSelectorCmpEnum;
    /**
     * 
     * @type {object}
     * @memberof GroupSelector
     */
    argument: object;
}


/**
 * @export
 */
export const GroupSelectorAttribEnum = {
    Level: 'LEVEL',
    OverdueWorkingDays: 'OVERDUE_WORKING_DAYS',
    OverdueCalendarDays: 'OVERDUE_CALENDAR_DAYS',
    OpenAmount: 'OPEN_AMOUNT',
    GrossAmount: 'GROSS_AMOUNT',
    SettledAmount: 'SETTLED_AMOUNT'
} as const;
export type GroupSelectorAttribEnum = typeof GroupSelectorAttribEnum[keyof typeof GroupSelectorAttribEnum];

/**
 * @export
 */
export const GroupSelectorCmpEnum = {
    Eq: 'EQ',
    Neq: 'NEQ',
    Gt: 'GT',
    Ge: 'GE',
    Lt: 'LT',
    Le: 'LE'
} as const;
export type GroupSelectorCmpEnum = typeof GroupSelectorCmpEnum[keyof typeof GroupSelectorCmpEnum];


/**
 * Check if a given object implements the GroupSelector interface.
 */
export function instanceOfGroupSelector(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attrib" in value;
    isInstance = isInstance && "cmp" in value;
    isInstance = isInstance && "argument" in value;

    return isInstance;
}

export function GroupSelectorFromJSON(json: any): GroupSelector {
    return GroupSelectorFromJSONTyped(json, false);
}

export function GroupSelectorFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupSelector {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attrib': json['attrib'],
        'cmp': json['cmp'],
        'argument': json['argument'],
    };
}

export function GroupSelectorToJSON(value?: GroupSelector | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attrib': value.attrib,
        'cmp': value.cmp,
        'argument': value.argument,
    };
}

