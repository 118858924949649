export const IconFlag = () => (
  <span className="pc-icon">
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.03794 1.30861C2.01349 1.36756 2 1.43221 2 1.5V15.5C2 15.7761 2.22386 16 2.5 16C2.77614 16 3 15.7761 3 15.5V9.72982C3.37362 9.45074 4.24235 9 5.5 9C6.32719 9 6.95652 9.40527 7.72265 9.91603L7.76145 9.9419C8.4872 10.426 9.34779 11 10.5 11C12.2299 11 13.4246 10.2826 13.8536 9.85355L14 9.70711V0.292893L13.1464 1.14645C12.9088 1.38411 11.9701 2 10.5 2C9.67281 2 9.04348 1.59473 8.27735 1.08397L8.23855 1.0581C7.5128 0.57402 6.65221 0 5.5 0C3.7701 0 2.57545 0.717444 2.14645 1.14645C2.09851 1.19439 2.06234 1.24964 2.03794 1.30861ZM3 1.72982C3.37362 1.45074 4.24235 1 5.5 1C6.32719 1 6.95652 1.40527 7.72265 1.91603L7.76145 1.9419C8.4872 2.42598 9.34779 3 10.5 3C11.546 3 12.3963 2.73769 13 2.43544V9.27018C12.6264 9.54926 11.7577 10 10.5 10C9.67281 10 9.04348 9.59473 8.27735 9.08397L8.23855 9.0581C7.5128 8.57402 6.65221 8 5.5 8C4.45399 8 3.60368 8.26231 3 8.56456V1.72982Z"
      />
    </svg>
  </span>
);
