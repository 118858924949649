import { type FunctionComponent } from 'react';

import { Tooltip } from '@blueprintjs/core';

import i18n from 'i18n/i18n';

import './Icon.style.sass';

export interface IndicatorIconProps {
  tooltipKey?: string;
}

export const IndicatorIcon: FunctionComponent<IndicatorIconProps> = ({ tooltipKey, children }) => (
  <Tooltip content={tooltipKey && i18n.t(tooltipKey)} placement="top">
    {children}
  </Tooltip>
);
