/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CaseConditionDto
 */
export interface CaseConditionDto {
    /**
     * 
     * @type {string}
     * @memberof CaseConditionDto
     */
    attrib: CaseConditionDtoAttribEnum;
    /**
     * 
     * @type {string}
     * @memberof CaseConditionDto
     */
    attribExtension?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseConditionDto
     */
    cmp: CaseConditionDtoCmpEnum;
    /**
     * 
     * @type {object}
     * @memberof CaseConditionDto
     */
    argument: object;
}


/**
 * @export
 */
export const CaseConditionDtoAttribEnum = {
    UnassignedPaymentsAmount: 'UNASSIGNED_PAYMENTS_AMOUNT',
    CalendarDaysUntilContractEnds: 'CALENDAR_DAYS_UNTIL_CONTRACT_ENDS',
    WorkingDaysUntilContractEnds: 'WORKING_DAYS_UNTIL_CONTRACT_ENDS',
    CalendarDaysSinceLastExecution: 'CALENDAR_DAYS_SINCE_LAST_EXECUTION',
    WorkingDaysSinceLastExecution: 'WORKING_DAYS_SINCE_LAST_EXECUTION',
    AdditionalValues: 'ADDITIONAL_VALUES',
    CalendarDaysSinceLastNotification: 'CALENDAR_DAYS_SINCE_LAST_NOTIFICATION',
    WorkingDaysSinceLastNotification: 'WORKING_DAYS_SINCE_LAST_NOTIFICATION',
    DisconnectionStatus: 'DISCONNECTION_STATUS',
    CommunicationProfile: 'COMMUNICATION_PROFILE',
    Termination: 'TERMINATION',
    ContractType: 'CONTRACT_TYPE',
    Level: 'LEVEL',
    Energy: 'ENERGY',
    BalanceContractAmount: 'BALANCE_CONTRACT_AMOUNT',
    ProductCode: 'PRODUCT_CODE',
    ForecastAnnualConsumption: 'FORECAST_ANNUAL_CONSUMPTION',
    CreditScore: 'CREDIT_SCORE'
} as const;
export type CaseConditionDtoAttribEnum = typeof CaseConditionDtoAttribEnum[keyof typeof CaseConditionDtoAttribEnum];

/**
 * @export
 */
export const CaseConditionDtoCmpEnum = {
    Eq: 'EQ',
    Neq: 'NEQ',
    Gt: 'GT',
    Ge: 'GE',
    Lt: 'LT',
    Le: 'LE'
} as const;
export type CaseConditionDtoCmpEnum = typeof CaseConditionDtoCmpEnum[keyof typeof CaseConditionDtoCmpEnum];


/**
 * Check if a given object implements the CaseConditionDto interface.
 */
export function instanceOfCaseConditionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attrib" in value;
    isInstance = isInstance && "cmp" in value;
    isInstance = isInstance && "argument" in value;

    return isInstance;
}

export function CaseConditionDtoFromJSON(json: any): CaseConditionDto {
    return CaseConditionDtoFromJSONTyped(json, false);
}

export function CaseConditionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaseConditionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attrib': json['attrib'],
        'attribExtension': !exists(json, 'attribExtension') ? undefined : json['attribExtension'],
        'cmp': json['cmp'],
        'argument': json['argument'],
    };
}

export function CaseConditionDtoToJSON(value?: CaseConditionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attrib': value.attrib,
        'attribExtension': value.attribExtension,
        'cmp': value.cmp,
        'argument': value.argument,
    };
}

