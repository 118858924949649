/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSkipExecutionDto
 */
export interface UpdateSkipExecutionDto {
    /**
     * If the execution of the contract should be skipped from now or not
     * @type {boolean}
     * @memberof UpdateSkipExecutionDto
     */
    skipExecution?: boolean;
}

/**
 * Check if a given object implements the UpdateSkipExecutionDto interface.
 */
export function instanceOfUpdateSkipExecutionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateSkipExecutionDtoFromJSON(json: any): UpdateSkipExecutionDto {
    return UpdateSkipExecutionDtoFromJSONTyped(json, false);
}

export function UpdateSkipExecutionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSkipExecutionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'skipExecution': !exists(json, 'skipExecution') ? undefined : json['skipExecution'],
    };
}

export function UpdateSkipExecutionDtoToJSON(value?: UpdateSkipExecutionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'skipExecution': value.skipExecution,
    };
}

