/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RuleDto } from './RuleDto';
import {
    RuleDtoFromJSON,
    RuleDtoFromJSONTyped,
    RuleDtoToJSON,
} from './RuleDto';
import type { RuleParameterDto } from './RuleParameterDto';
import {
    RuleParameterDtoFromJSON,
    RuleParameterDtoFromJSONTyped,
    RuleParameterDtoToJSON,
} from './RuleParameterDto';
import type { RuleSetDunningLevelDto } from './RuleSetDunningLevelDto';
import {
    RuleSetDunningLevelDtoFromJSON,
    RuleSetDunningLevelDtoFromJSONTyped,
    RuleSetDunningLevelDtoToJSON,
} from './RuleSetDunningLevelDto';
import type { RuleSetInfoDto } from './RuleSetInfoDto';
import {
    RuleSetInfoDtoFromJSON,
    RuleSetInfoDtoFromJSONTyped,
    RuleSetInfoDtoToJSON,
} from './RuleSetInfoDto';

/**
 * 
 * @export
 * @interface RuleSetDto
 */
export interface RuleSetDto {
    /**
     * 
     * @type {string}
     * @memberof RuleSetDto
     */
    rulesetId?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleSetDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleSetDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleSetDto
     */
    state?: RuleSetDtoStateEnum;
    /**
     * 
     * @type {Array<RuleDto>}
     * @memberof RuleSetDto
     */
    rules?: Array<RuleDto>;
    /**
     * 
     * @type {Set<string>}
     * @memberof RuleSetDto
     */
    allowedTransitions?: Set<RuleSetDtoAllowedTransitionsEnum>;
    /**
     * 
     * @type {Array<RuleParameterDto>}
     * @memberof RuleSetDto
     */
    parameters?: Array<RuleParameterDto>;
    /**
     * 
     * @type {Array<RuleSetDunningLevelDto>}
     * @memberof RuleSetDto
     */
    dunningLevels?: Array<RuleSetDunningLevelDto>;
    /**
     * 
     * @type {Date}
     * @memberof RuleSetDto
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RuleSetDto
     */
    updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof RuleSetDto
     */
    lastEditor?: string;
    /**
     * 
     * @type {RuleSetInfoDto}
     * @memberof RuleSetDto
     */
    subsequentRuleset?: RuleSetInfoDto;
    /**
     * 
     * @type {string}
     * @memberof RuleSetDto
     */
    readonly id?: string;
}


/**
 * @export
 */
export const RuleSetDtoStateEnum = {
    Active: 'ACTIVE',
    Edit: 'EDIT',
    Deleted: 'DELETED'
} as const;
export type RuleSetDtoStateEnum = typeof RuleSetDtoStateEnum[keyof typeof RuleSetDtoStateEnum];

/**
 * @export
 */
export const RuleSetDtoAllowedTransitionsEnum = {
    AddRule: 'ADD_RULE',
    DeleteRule: 'DELETE_RULE',
    UpdateRule: 'UPDATE_RULE',
    MakeActive: 'MAKE_ACTIVE',
    Delete: 'DELETE',
    SetToDefault: 'SET_TO_DEFAULT',
    Archive: 'ARCHIVE',
    ChangeName: 'CHANGE_NAME',
    ChangeDescription: 'CHANGE_DESCRIPTION',
    UpdateRuleSet: 'UPDATE_RULE_SET',
    ChangeRuleName: 'CHANGE_RULE_NAME',
    ChangeRuleDescription: 'CHANGE_RULE_DESCRIPTION'
} as const;
export type RuleSetDtoAllowedTransitionsEnum = typeof RuleSetDtoAllowedTransitionsEnum[keyof typeof RuleSetDtoAllowedTransitionsEnum];


/**
 * Check if a given object implements the RuleSetDto interface.
 */
export function instanceOfRuleSetDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RuleSetDtoFromJSON(json: any): RuleSetDto {
    return RuleSetDtoFromJSONTyped(json, false);
}

export function RuleSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rulesetId': !exists(json, 'rulesetId') ? undefined : json['rulesetId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'rules': !exists(json, 'rules') ? undefined : ((json['rules'] as Array<any>).map(RuleDtoFromJSON)),
        'allowedTransitions': !exists(json, 'allowedTransitions') ? undefined : json['allowedTransitions'],
        'parameters': !exists(json, 'parameters') ? undefined : ((json['parameters'] as Array<any>).map(RuleParameterDtoFromJSON)),
        'dunningLevels': !exists(json, 'dunningLevels') ? undefined : ((json['dunningLevels'] as Array<any>).map(RuleSetDunningLevelDtoFromJSON)),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'lastEditor': !exists(json, 'lastEditor') ? undefined : json['lastEditor'],
        'subsequentRuleset': !exists(json, 'subsequentRuleset') ? undefined : RuleSetInfoDtoFromJSON(json['subsequentRuleset']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function RuleSetDtoToJSON(value?: RuleSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rulesetId': value.rulesetId,
        'name': value.name,
        'description': value.description,
        'state': value.state,
        'rules': value.rules === undefined ? undefined : ((value.rules as Array<any>).map(RuleDtoToJSON)),
        'allowedTransitions': value.allowedTransitions === undefined ? undefined : Array.from(value.allowedTransitions as Set<any>),
        'parameters': value.parameters === undefined ? undefined : ((value.parameters as Array<any>).map(RuleParameterDtoToJSON)),
        'dunningLevels': value.dunningLevels === undefined ? undefined : ((value.dunningLevels as Array<any>).map(RuleSetDunningLevelDtoToJSON)),
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'lastEditor': value.lastEditor,
        'subsequentRuleset': RuleSetInfoDtoToJSON(value.subsequentRuleset),
    };
}

