import { type FunctionComponent, ReactNode } from 'react';

import { Icon } from '@blueprintjs/core';
import { SmallTick } from '@blueprintjs/icons';

import './Checkbox.style.sass';

interface DunningCheckboxProps {
  isChecked?: boolean;
  label?: string | ReactNode;
  disabled?: boolean;
  onClick?: (checked: boolean) => void;
}

const Checkbox: FunctionComponent<DunningCheckboxProps> = (props: DunningCheckboxProps) => {
  function getClassNames(): string {
    let classes = props.disabled ? 'dunning-checkbox-disabled ' : ' ';
    classes += props.isChecked ? 'dunning-checkbox-checked' : 'dunning-checkbox-unchecked';
    return classes;
  }

  return (
    <div
      className="dunning-checkbox-container pointer"
      onClick={() => !props.disabled && props.onClick && props.onClick(!props.isChecked)}>
      <div className={getClassNames()} />
      {props.isChecked && <Icon className="dunning-check-tick" icon={<SmallTick />} />}
      <div className={props.disabled ? 'dunning-checkbox-disabled' : ''}>{props.label}</div>
    </div>
  );
};

export { Checkbox };
