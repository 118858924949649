import React, { FunctionComponent, useEffect, useState } from 'react';

import { Classes, Dialog, MenuItem } from '@blueprintjs/core';
import { type ItemRenderer, Select } from '@blueprintjs/select';

import { Button } from 'lib/Button';

import { RuleSetFilterDtoStatesEnum } from '../../../generated-sources/openapi';
import i18n from '../../../i18n/i18n';
import { IconCaretDown } from '../../../icons/svg/IconCaretDown';
import { AbstractRuleSet, BasicRuleSet } from '../../../interfaces/Interfaces';
import { getBasicRuleSets } from '../../../services/ApiService';
import { testAttribute } from '../../../util/Util';
import {Trans} from "react-i18next";

interface RuleSetMigrationDialogProps {
  ruleSet: AbstractRuleSet;
  onCloseDialog: () => void;
  onConfirmRuleSetMigration: (ruleSetId?: string) => void;
}

const RuleSetMigrationDialog: FunctionComponent<RuleSetMigrationDialogProps> = (props: RuleSetMigrationDialogProps) => {
  const [ruleSets, setRuleSets] = useState<AbstractRuleSet[]>([]);
  const [selectedRuleSet, setSelectedRuleSet] = useState<BasicRuleSet>();

  function loadRuleSets() {
    const ruleSetFilterDto = {
      states: [RuleSetFilterDtoStatesEnum.Active],
      excludeDefault: true
    };
    getBasicRuleSets(ruleSetFilterDto).then((data: AbstractRuleSet[]) => {
      const abstractRuleSets = data.filter((ruleSet: AbstractRuleSet) => ruleSet.rulesetId !== props.ruleSet.rulesetId);
      setRuleSets(abstractRuleSets);
      setSelectedRuleSet(abstractRuleSets[0]);
    });
  }

  function getCurrentOptionDisplayText() {
    if (!selectedRuleSet) return i18n.t('dialog.ruleset.please-select');
    return selectedRuleSet.name;
  }

  const renderer: ItemRenderer<BasicRuleSet> = (element, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        id={`select-general-${element.rulesetId}`}
        data-test-id={testAttribute('dm01', 'select-general', `${element.rulesetId}`)}
        active={modifiers.active}
        key={element.rulesetId}
        onClick={handleClick}
        text={element.name}
      />
    );
  };

  useEffect(() => {
    loadRuleSets();
  }, []);

  function handleRuleSetChange(subsequent: BasicRuleSet) {
    setSelectedRuleSet(subsequent);
  }

  return (
    <Dialog className="dialog medium" isOpen canOutsideClickClose={false} onClose={() => props.onCloseDialog()}>
      <div className="dialog-title">{i18n.t('dialog.ruleset.migrate-title-text')}</div>
      <div className={Classes.DIALOG_BODY}>
        <div className="dialog-content">
          <p>
            <Trans i18nKey="dialog.ruleset.migrate-text" values={{ name: props.ruleSet.name }} />
          </p>
          <div className="dialog-content">
            <Select<BasicRuleSet>
              data-test-id={testAttribute('dmg0', 'select')}
              filterable={false}
              itemRenderer={renderer}
              items={ruleSets}
              onItemSelect={subsequent => handleRuleSetChange(subsequent)}>
              <Button
                type="default"
                className="subsequent-reset-button"
                testId={testAttribute('dm02', 'caret-down', 'selector')}
                rightIcon={<IconCaretDown />}>
                {getCurrentOptionDisplayText()}
              </Button>
            </Select>
          </div>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            id="cancel-button"
            testId={testAttribute('dmg1', 'button.cancel')}
            className="button"
            onClick={() => props.onCloseDialog()}>
            {i18n.t('button.cancel')}
          </Button>
          <Button
            testId={testAttribute('dmg2', 'save-button')}
            className="button primary"
            onClick={() => props.onConfirmRuleSetMigration(selectedRuleSet?.rulesetId)}>
            {i18n.t('dialog.ruleset.migrate-action-button')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default RuleSetMigrationDialog;
