import { Icon } from '@blueprintjs/core';

import { IconReset } from './svg/IconReset';

import './Icon.style.sass';

export const RetryBlue = () => (
  <div className="retry-blue">
    <Icon icon={<IconReset />} />
  </div>
);
