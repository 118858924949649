import React, { FunctionComponent, useContext } from 'react';

import { MenuItem } from '@blueprintjs/core';
import { Import, More, TickCircle, Trash } from '@blueprintjs/icons';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { LicenseContext, UserContext } from 'App';

import i18n from 'i18n/i18n';
import { IconArchive } from 'icons/svg/IconArchive';
import { IconFlag } from 'icons/svg/IconFlag';
import { ALLOWED_TRANSITIONS } from 'interfaces/enums';
import { RIGHTS } from 'interfaces/Interfaces';
import { Button } from 'lib/Button';
import { testAttribute } from 'util/Util';

import { LICENSE_FEATURE, LICENSE_FEATURE_PACK } from '../../license/AppLicense';
import { IconMigrate } from '../../icons/svg/IconMigrate';

// TODO(JDS-473): Whole select folter is only used inside the components/Table/RuleSetIcons
// https://dev-docu.int.power.cloud/guidelines/languages-and-database/javascript/react-coding-guidelines#structure-and-naming

interface MoreIconSelectProps {
  onExpand: () => void;
  allowedTransitions: ALLOWED_TRANSITIONS[];
  disabled: {
    migrate?: boolean;
    archive?: boolean;
  };
  isDefaultRuleSet: boolean;
  onDeleteClick: () => void;
  onActivateClick: () => void;
  onArchiveClick: () => void;
  onMigrateClick: () => void;
  onMakeDefaultClick: () => void;
  onExportClick: () => void;
}

const MoreIconSelect: FunctionComponent<MoreIconSelectProps> = (props: MoreIconSelectProps) => {
  const userContext = useContext(UserContext);
  const license = useContext(LicenseContext);

  class SelectableItem {
    name: string;

    icon?: any;

    disabled?: boolean = false;

    onClick: () => void;
  }

  const ActivateItem: SelectableItem = {
    name: i18n.t('dialog.ruleset.activate'),
    icon: <TickCircle />,
    onClick(): void {
      props.onActivateClick();
    },
  };

  const DeleteItem: SelectableItem = {
    name: i18n.t('dialog.ruleset.delete'),
    icon: <Trash />,
    onClick(): void {
      props.onDeleteClick();
    },
  };

  const MigrateItem: SelectableItem = {
    name: i18n.t('dialog.ruleset.migrate-contracts-text'),
    icon: <IconMigrate />,
    disabled: props.disabled?.migrate,
    onClick(): void {
      props.onMigrateClick();
    },
  };

  const ArchiveItem: SelectableItem = {
    name: i18n.t('dialog.ruleset.archive'),
    icon: <IconArchive />,
    disabled: props.disabled?.archive,
    onClick(): void {
      props.onArchiveClick();
    },
  };

  const MakeDefaultItem: SelectableItem = {
    name: i18n.t('dialog.ruleset.set-default'),
    icon: <IconFlag />,
    onClick(): void {
      props.onMakeDefaultClick();
    },
  };

  const ExportItem: SelectableItem = {
    name: i18n.t('dialog.ruleset.export'),
    icon: <Import />,
    onClick(): void {
      props.onExportClick();
    },
  };

  function getList(): SelectableItem[] {
    const list: SelectableItem[] = [];
    if (
      userContext.rights.includes(RIGHTS.ARCHIVE_RULESET) &&
      props.allowedTransitions.includes(ALLOWED_TRANSITIONS.ARCHIVE)
    ) {
      list.push(ArchiveItem);
    }
    if (
      userContext.rights.includes(RIGHTS.EDIT_RULESET) &&
      props.allowedTransitions.includes(ALLOWED_TRANSITIONS.ARCHIVE)
    ) {
      list.push(MigrateItem);
    }
    if (
      userContext.rights.includes(RIGHTS.MAKE_ACTIVE_RULESET) &&
      props.allowedTransitions.includes(ALLOWED_TRANSITIONS.MAKE_ACTIVE)
    ) {
      list.push(ActivateItem);
    }
    if (
      userContext.rights.includes(RIGHTS.DELETE_RULESET) &&
      props.allowedTransitions.includes(ALLOWED_TRANSITIONS.DELETE)
    ) {
      list.push(DeleteItem);
    }

    if (
      userContext.rights.includes(RIGHTS.SET_TO_DEFAULT_RULESET) &&
      props.allowedTransitions?.includes(ALLOWED_TRANSITIONS.SET_TO_DEFAULT) &&
      !props.isDefaultRuleSet
    ) {
      list.push(MakeDefaultItem);
    }
    if (license.isFeatureEnabled(LICENSE_FEATURE_PACK.RULESET_PACK, LICENSE_FEATURE.EXPORT_RULESET)) {
      list.push(ExportItem);
    }

    return list;
  }

  const renderMoreList: ItemRenderer<SelectableItem> = (element, { modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        id={`item ${element.icon}`}
        icon={element.icon}
        active={modifiers.active}
        disabled={element.disabled}
        key={element.name}
        onClick={element.onClick}
        text={element.name}
      />
    );
  };

  return (
    <>
      {getList().length !== 0 && (
        <Select<SelectableItem>
          items={getList()}
          itemRenderer={renderMoreList}
          onItemSelect={elem => elem.onClick}
          popoverProps={{
            onOpening: props.onExpand,
          }}
          filterable={false}>
          {/* children become the popover target; render value here */}
          <Button
            testId={testAttribute('44gh', 'button.more')}
            tooltip={i18n.t('button.more')}
            type="tertiary"
            id="more-icon"
            icon={<More />}
            onClick={() => undefined}
          />
        </Select>
      )}
    </>
  );
};

export default MoreIconSelect;
