/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CaseConditionDto } from './CaseConditionDto';
import {
    CaseConditionDtoFromJSON,
    CaseConditionDtoFromJSONTyped,
    CaseConditionDtoToJSON,
} from './CaseConditionDto';
import type { CustomerCaseConditionDto } from './CustomerCaseConditionDto';
import {
    CustomerCaseConditionDtoFromJSON,
    CustomerCaseConditionDtoFromJSONTyped,
    CustomerCaseConditionDtoToJSON,
} from './CustomerCaseConditionDto';

/**
 * 
 * @export
 * @interface RuleSetAssignmentCriteriaCreateDto
 */
export interface RuleSetAssignmentCriteriaCreateDto {
    /**
     * 
     * @type {Array<CaseConditionDto>}
     * @memberof RuleSetAssignmentCriteriaCreateDto
     */
    caseConditions: Array<CaseConditionDto>;
    /**
     * 
     * @type {Array<CustomerCaseConditionDto>}
     * @memberof RuleSetAssignmentCriteriaCreateDto
     */
    customerCaseConditions: Array<CustomerCaseConditionDto>;
}

/**
 * Check if a given object implements the RuleSetAssignmentCriteriaCreateDto interface.
 */
export function instanceOfRuleSetAssignmentCriteriaCreateDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "caseConditions" in value;
    isInstance = isInstance && "customerCaseConditions" in value;

    return isInstance;
}

export function RuleSetAssignmentCriteriaCreateDtoFromJSON(json: any): RuleSetAssignmentCriteriaCreateDto {
    return RuleSetAssignmentCriteriaCreateDtoFromJSONTyped(json, false);
}

export function RuleSetAssignmentCriteriaCreateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetAssignmentCriteriaCreateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caseConditions': ((json['caseConditions'] as Array<any>).map(CaseConditionDtoFromJSON)),
        'customerCaseConditions': ((json['customerCaseConditions'] as Array<any>).map(CustomerCaseConditionDtoFromJSON)),
    };
}

export function RuleSetAssignmentCriteriaCreateDtoToJSON(value?: RuleSetAssignmentCriteriaCreateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caseConditions': ((value.caseConditions as Array<any>).map(CaseConditionDtoToJSON)),
        'customerCaseConditions': ((value.customerCaseConditions as Array<any>).map(CustomerCaseConditionDtoToJSON)),
    };
}

