/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LevelReplacementLog } from './LevelReplacementLog';
import {
    LevelReplacementLogFromJSON,
    LevelReplacementLogFromJSONTyped,
    LevelReplacementLogToJSON,
} from './LevelReplacementLog';

/**
 * 
 * @export
 * @interface RuleSetReplacementLogDto
 */
export interface RuleSetReplacementLogDto {
    /**
     * The number of contracts that were using the given ruleset and were modified to now use the new ruleset
     * @type {number}
     * @memberof RuleSetReplacementLogDto
     */
    numberOfAffectedContracts?: number;
    /**
     * 
     * @type {Array<LevelReplacementLog>}
     * @memberof RuleSetReplacementLogDto
     */
    levelReplacements?: Array<LevelReplacementLog>;
    /**
     * Whether this was really executed or just a dry run
     * @type {boolean}
     * @memberof RuleSetReplacementLogDto
     */
    dryRun?: boolean;
}

/**
 * Check if a given object implements the RuleSetReplacementLogDto interface.
 */
export function instanceOfRuleSetReplacementLogDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RuleSetReplacementLogDtoFromJSON(json: any): RuleSetReplacementLogDto {
    return RuleSetReplacementLogDtoFromJSONTyped(json, false);
}

export function RuleSetReplacementLogDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetReplacementLogDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberOfAffectedContracts': !exists(json, 'numberOfAffectedContracts') ? undefined : json['numberOfAffectedContracts'],
        'levelReplacements': !exists(json, 'levelReplacements') ? undefined : ((json['levelReplacements'] as Array<any>).map(LevelReplacementLogFromJSON)),
        'dryRun': !exists(json, 'dryRun') ? undefined : json['dryRun'],
    };
}

export function RuleSetReplacementLogDtoToJSON(value?: RuleSetReplacementLogDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberOfAffectedContracts': value.numberOfAffectedContracts,
        'levelReplacements': value.levelReplacements === undefined ? undefined : ((value.levelReplacements as Array<any>).map(LevelReplacementLogToJSON)),
        'dryRun': value.dryRun,
    };
}

