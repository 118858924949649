import { type FunctionComponent, useContext, useEffect, useState } from 'react';

import i18n from 'i18n/i18n';
import { CONTRACT_STATE, PAYMENT_TYPE, STATE } from 'interfaces/enums';
import {
  type AbstractRuleSet,
  type DunningSelectionConfiguration,
  RIGHTS,
  RuleSetForReact,
} from 'interfaces/Interfaces';
import { Switch } from 'lib/Switch';

import { type Selectable } from './input/InputInterfaces';
import { Validators } from './input/Validators';
import DunningSelectionForm, { type FormGroupingInterface } from './DunningSelectionForm';
import { UserContext } from '../../../App';
import { getRuleSet } from '../../../services/ApiService';
import { cacheResults, getCurrentDate, testAttribute, toDateStringWithoutTimeZone } from '../../../util/Util';

enum GROUPS {
  EXECUTION = 'execution',
  SELECTION = 'selection',
  LIMIT = 'limit',
  TERMINATION = 'termination',
}

enum FIELDS {
  EXECUTION_TURNAROUND = 'execution',
  PLANED_EXECUTION = 'planed_execution',
  PAYMENT_TYPE = 'payment_type',
  CONTRACT_STATE = 'contract_state',
  DUNNING_PROCEDURE = 'dunning_procedure',
  MAX_NOTIFICATIONS = 'max_notifications',
  MAX_TERMINATIONS = 'max_terminations',
  MAX_THREATS = 'max_threats',
  MAX_ANNOUNCEMENTS = 'max_announcements',
  MAX_DISCONNECTION = 'max_disconnection',
  TERMINATION = 'termination',
  RULES = 'rules',
  MIN_OPEN_AMOUNT = 'min_open_amount',
  DUE_DATE_LIMITATION = 'due_date_limitation',
}

const formFieldDefinitionFactory: () => FormGroupingInterface[] = () => [
  {
    key: GROUPS.EXECUTION,
    title: i18n.t('dunning-selection.execution-group'),
    fields: [
      {
        type: 'radio-buttons',
        label: i18n.t('dunning-selection.execution-rotation'),
        name: FIELDS.EXECUTION_TURNAROUND,
        text: i18n.t('dunning-selection.info-execution-selection'),
        selectables: [
          {
            key: 'PERMANENT',
            label: i18n.t('dunning-selection.permanent'),
          },
          {
            key: 'ONCE',
            label: i18n.t('dunning-selection.once'),
          },
          {
            key: 'WORKING_DAYS',
            label: i18n.t('dunning-selection.working_days'),
          },
        ],
      },
      {
        type: 'date-picker',
        label: i18n.t('dunning-selection.planed-execution'),
        name: FIELDS.PLANED_EXECUTION,
        validators: [Validators.afterCurrentDate],
        additionalConfiguration: {
          minDate: getCurrentDate(),
          outOfRangeMessage: i18n.t('dunning-selection.error.date_before_current'),
        },
      },
    ],
  },
  {
    key: GROUPS.SELECTION,
    title: i18n.t('dunning-selection.selection'),
    fields: [
      {
        type: 'numeric',
        validators: [Validators.gtDecimal],
        label: i18n.t('dunning-selection.min_open_amount'),
        name: FIELDS.MIN_OPEN_AMOUNT,
        emptyValue: '',
      },
      {
        type: 'selection',
        label: i18n.t('dunning-selection.payment-type'),
        name: FIELDS.PAYMENT_TYPE,
        selectables: Object.values(PAYMENT_TYPE).map(key => ({
          key,
          label: i18n.t(`dunning-selection.${key.toLowerCase()}`),
        })),
      },
      {
        type: 'multi-select',
        label: i18n.t('dunning-selection.contract-state'),
        name: FIELDS.CONTRACT_STATE,
        selectables: Object.values(CONTRACT_STATE).map(key => ({
          key,
          label: i18n.t(`dunning-selection.${key.toLowerCase()}`),
        })),
      },
      {
        type: 'multi-select',
        label: i18n.t('dunning-selection.dunning-procedure'),
        name: FIELDS.DUNNING_PROCEDURE,
        selectables: [],
      },
      {
        type: 'multi-select',
        label: i18n.t('dunning-selection.dunning-rules'),
        name: FIELDS.RULES,
        selectables: [],
      },
    ],
  },
  {
    key: GROUPS.LIMIT,
    title: i18n.t('dunning-selection.limit-group'),
    fields: [
      {
        type: 'numeric',
        validators: [Validators.gtInteger],
        label: i18n.t('dunning-selection.max-dunnings'),
        name: FIELDS.MAX_NOTIFICATIONS,
      },
      {
        type: 'numeric',
        validators: [Validators.gtInteger],
        label: i18n.t('dunning-selection.max-terminiations'),
        name: FIELDS.MAX_TERMINATIONS,
      },
      {
        type: 'numeric',
        validators: [Validators.gtInteger],
        label: i18n.t('dunning-selection.max-threats'),
        name: FIELDS.MAX_THREATS,
      },
      {
        type: 'numeric',
        validators: [Validators.gtInteger],
        label: i18n.t('dunning-selection.max-announcements'),
        name: FIELDS.MAX_ANNOUNCEMENTS,
      },
      {
        type: 'numeric',
        validators: [Validators.gtInteger],
        label: i18n.t('dunning-selection.max-disconnections'),
        name: FIELDS.MAX_DISCONNECTION,
      },
      {
        type: 'date-picker',
        label: i18n.t('dunning-selection.due-date-limitation'),
        name: FIELDS.DUE_DATE_LIMITATION,
      },
    ],
  },
  {
    key: GROUPS.TERMINATION,
    title: i18n.t('dunning-selection.termination-group'),
    fields: [
      {
        type: 'info-text',
        additionalText: i18n.t('dunning-selection.info-terminations'),
        name: '',
        label: '',
      },
      {
        type: 'multi-select',
        label: i18n.t('dunning-selection.termination'),
        name: FIELDS.TERMINATION,
        selectables: [],
      },
    ],
  },
];

const formDataMapper = (config?: DunningSelectionConfiguration) => ({
  [GROUPS.EXECUTION]: {
    [FIELDS.EXECUTION_TURNAROUND]: {
      valid: true,
      value: { key: config?.executionType ?? 'ONCE' },
    },
    [FIELDS.PLANED_EXECUTION]: {
      valid: true,
      value: config?.planedExecutionDate ? new Date(config.planedExecutionDate) : new Date(),
    },
  },
  [GROUPS.SELECTION]: {
    [FIELDS.PAYMENT_TYPE]: {
      valid: true,
      value: { key: config?.paymentType ?? PAYMENT_TYPE.ALL },
    },
    [FIELDS.CONTRACT_STATE]: {
      valid: true,
      value: config?.contractStates?.map(s => ({ key: s })) ?? [],
    },
    [FIELDS.DUNNING_PROCEDURE]: {
      valid: true,
      value: config?.dunningProcedures?.map(s => ({ key: s })) ?? [],
    },
    [FIELDS.MIN_OPEN_AMOUNT]: {
      valid: true,
      value: config?.minOpenAmount ?? '',
    },
    [FIELDS.RULES]: {
      valid: true,
      value: config?.rules?.map(s => ({ key: s })) ?? [],
    },
  },
  [GROUPS.LIMIT]: {
    [FIELDS.MAX_NOTIFICATIONS]: {
      valid: true,
      value: config?.maxNotifications ?? -1,
    },
    [FIELDS.MAX_TERMINATIONS]: {
      valid: true,
      value: config?.maxTerminations ?? -1,
    },
    [FIELDS.MAX_THREATS]: {
      valid: true,
      value: config?.maxThreats ?? -1,
    },
    [FIELDS.MAX_ANNOUNCEMENTS]: {
      valid: true,
      value: config?.maxAnnouncements ?? -1,
    },
    [FIELDS.MAX_DISCONNECTION]: {
      valid: true,
      value: config?.maxDisconnection ?? -1,
    },
    [FIELDS.DUE_DATE_LIMITATION]: {
      valid: true,
      value: config?.dueDateLimitation ? new Date(config.dueDateLimitation) : null,
    },
  },
  [GROUPS.TERMINATION]: {
    [FIELDS.TERMINATION]: {
      valid: true,
      value: config?.terminationProcedures?.map(s => ({ key: s })) ?? [],
    },
  },
});

const configurationMapper = (active: boolean, form?: FormValues) => {
  if (!form) return { active } as DunningSelectionConfiguration;
  return {
    active,
    executionType: form[GROUPS.EXECUTION][FIELDS.EXECUTION_TURNAROUND].value?.key,
    planedExecutionDate: toDateStringWithoutTimeZone(form[GROUPS.EXECUTION][FIELDS.PLANED_EXECUTION].value),
    paymentType: form[GROUPS.SELECTION][FIELDS.PAYMENT_TYPE].value?.key,
    contractStates: form[GROUPS.SELECTION][FIELDS.CONTRACT_STATE].value?.map((v: Selectable) => v.key),
    dunningProcedures: form[GROUPS.SELECTION][FIELDS.DUNNING_PROCEDURE].value?.map((v: Selectable) => v.key),
    minOpenAmount: form[GROUPS.SELECTION][FIELDS.MIN_OPEN_AMOUNT].value,
    rules: form[GROUPS.SELECTION][FIELDS.RULES].value?.map((v: Selectable) => v.key),
    maxNotifications: form[GROUPS.LIMIT][FIELDS.MAX_NOTIFICATIONS].value,
    maxTerminations: form[GROUPS.LIMIT][FIELDS.MAX_TERMINATIONS].value,
    maxThreats: form[GROUPS.LIMIT][FIELDS.MAX_THREATS].value,
    maxAnnouncements: form[GROUPS.LIMIT][FIELDS.MAX_ANNOUNCEMENTS].value,
    maxDisconnection: form[GROUPS.LIMIT][FIELDS.MAX_DISCONNECTION].value,
    dueDateLimitation: toDateStringWithoutTimeZone(form[GROUPS.LIMIT][FIELDS.DUE_DATE_LIMITATION].value),
    terminationProcedures: form[GROUPS.TERMINATION][FIELDS.TERMINATION].value?.map((v: Selectable) => v.key),
  } as DunningSelectionConfiguration;
};

type FormValues = Record<string, Record<string, { valid: boolean; value: any }>>;

interface DunningSelectionPageProps {
  onValidChange: (isValid: boolean) => void;
  onSelectionChange: (configuration: DunningSelectionConfiguration) => void;
  dunningSelection: DunningSelectionConfiguration;
  ruleSets: AbstractRuleSet[];
}

const DunningSelection: FunctionComponent<DunningSelectionPageProps> = ({
  dunningSelection,
  ruleSets,
  onSelectionChange,
  onValidChange,
}) => {
  const [form, setForm] = useState(formFieldDefinitionFactory);
  const [formData, setFormData] = useState<FormValues>();
  const [ruleSetProvider, _] = useState<(rulesetId: string) => RuleSetForReact>(() => cacheResults(getRuleSet));
  const userContext = useContext(UserContext);
  function getFieldBy(groupKey: GROUPS, fieldKey: FIELDS) {
    const dunningProcedureField = form
      .find(group => group.key === groupKey)
      ?.fields.find(field => field.name === fieldKey);
    if (!dunningProcedureField) throw new Error();
    return dunningProcedureField;
  }

  function updateDisabledStates() {
    const newForm: FormGroupingInterface[] = form.map(group => ({
      ...group,
      fields: group.fields.map(field => ({
        ...field,
        disabled: !dunningSelection.active || !userContext.rights.includes(RIGHTS.UPDATE_DUNNING_SELECTION),
      })),
    }));

    if (dunningSelection?.executionType) {
      const isOnce = dunningSelection.executionType === 'ONCE';
      newForm
        .find(group => group.key === GROUPS.EXECUTION)
        ?.fields.filter(field => field.name !== FIELDS.EXECUTION_TURNAROUND)
        .forEach(field => (field.disabled = !isOnce || !dunningSelection.active));
    }
    setForm(newForm);
  }

  useEffect(() => {
    (async () => {
      const selectableRuleSets: Selectable[] = ruleSets
        .filter(rs => rs.state === STATE.ACTIVE)
        .map(ruleset => ({
          key: ruleset.rulesetId,
          label: ruleset.name,
        }));
      const selectableRulesPromise = ruleSets
        .filter(rs => rs.state === STATE.ACTIVE)
        .filter(
          rs =>
            !dunningSelection.dunningProcedures?.length || dunningSelection.dunningProcedures?.includes(rs.rulesetId),
        )
        .map(rs => ruleSetProvider(rs.rulesetId));

      const completeRuleSets = await Promise.all(selectableRulesPromise);
      getFieldBy(GROUPS.SELECTION, FIELDS.RULES).selectables = completeRuleSets
        .flatMap(rs => rs.rules)
        .map(r => ({
          key: r.id,
          label: r.name,
        }));

      getFieldBy(GROUPS.SELECTION, FIELDS.DUNNING_PROCEDURE).selectables = selectableRuleSets;
      getFieldBy(GROUPS.TERMINATION, FIELDS.TERMINATION).selectables = selectableRuleSets;
      setForm([...form]);
      setFormData(formDataMapper(dunningSelection));
      updateDisabledStates();
    })();
  }, [ruleSets, dunningSelection, onSelectionChange, onValidChange]);

  function formIsValid(formValues?: FormValues) {
    return (
      (formValues &&
        !Object.values(formValues).some(formGroup => Object.values(formGroup).some(fieldValue => !fieldValue.valid))) ??
      false
    );
  }

  function changeSelectionActiveState(isActive: boolean) {
    onSelectionChange(configurationMapper(isActive, formData));
    onValidChange(formIsValid(formData));
  }

  function changeHandler(formValues: FormValues) {
    onSelectionChange(configurationMapper(dunningSelection.active, formValues));
    onValidChange(formIsValid(formValues));
  }

  if (!formData) return <></>;

  return (
    <div className="padding">
      <div className="card-description">
        <span>{i18n.t('dunning-selection.description')}</span>
      </div>
      <h3 className="card-title">{i18n.t('dunning-selection.active_title')}</h3>
      <Switch
        data-test-id={testAttribute('dssd', 'dunning-selection.switch-selection')}
        isChecked={dunningSelection.active}
        disabled={!userContext.rights.includes(RIGHTS.UPDATE_DUNNING_SELECTION)}
        onChange={e => changeSelectionActiveState(e)}
      />
      <DunningSelectionForm
        data-test-id={testAttribute('dssd', 'dunning-selection.form-select-dunning')}
        values={formData}
        groups={form}
        onChange={values => changeHandler(values)}
      />
    </div>
  );
};

export default DunningSelection;
