import { type FunctionComponent } from 'react';

import { Classes } from '@blueprintjs/core';

interface FormGroupingProps {
  title: string;
  className?: string;
}

const FormGrouping: FunctionComponent<FormGroupingProps> = props => {
  return (
    <div className={`form-grouping ${Classes.ELEVATION_0} ${props.className}`}>
      <b className="form-grouping__title">{props.title}</b>
      {props.children}
    </div>
  );
};

export default FormGrouping;
