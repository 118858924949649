import { type FunctionComponent } from 'react';

import { InputGroup } from '@blueprintjs/core';

interface TextFilterInputProps {
  value: string;
  onChange: (newValue: string) => void;
  label?: string;
}

export const TextFilterInput: FunctionComponent<TextFilterInputProps> = ({ value, onChange, label }) => (
  <>
    <span className="lib-table-filter__label">{label}</span>
    <InputGroup
      data-test-id={label}
      value={value ?? ''}
      type="text"
      fill
      onChange={(event: any) => onChange(event.target.value)}
    />
  </>
);
