import { type FunctionComponent, useContext, useEffect, useState } from 'react';

import { Card, Elevation, Spinner } from '@blueprintjs/core';

import DialogBox from 'components/Dialog/BasicDialog/DialogBox';
import { alertToast } from 'components/Toast/AlertToast';
import i18n from 'i18n/i18n';
import { PAYMENT_TYPE } from 'interfaces/enums';
import {
  type AbstractRuleSet,
  type DunningSelectionConfiguration,
  type DunningSelectionForecast,
  RIGHTS,
} from 'interfaces/Interfaces';
import { Button } from 'lib/Button';
import {
  downloadDunningForecastCSV,
  getBasicRuleSets,
  getDunningSelection,
  getDunningSelectionForecast,
  updateDunningSelection,
} from 'services/ApiService';
import { testAttribute } from 'util/Util';

import { DunningForecast } from './DunningForecast/DunningForecast';
import DunningSelection from './DunningSelection/DunningSelection';
import { Validators } from './DunningSelection/input/Validators';
import { UserContext } from '../../App';
import { useCallbackPrompt } from '../../hooks/UseCallbackPrompt';

import './DunningSelection.style.sass';

const defaultDunningSelection: DunningSelectionConfiguration = {
  active: true,
  contractStates: [],
  dunningProcedures: [],
  executionType: 'PERMANENT',
  minOpenAmount: 0,
  maxAnnouncements: -1,
  maxDisconnection: -1,
  maxNotifications: -1,
  maxTerminations: -1,
  maxThreats: -1,
  paymentType: PAYMENT_TYPE.ALL,
  planedExecutionDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString(),
  dueDateLimitation: new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString(),
  terminationProcedures: [],
  rules: [],
};

const DunningSelectionPage: FunctionComponent = () => {
  const [ruleSets, setRuleSets] = useState<AbstractRuleSet[]>([]);
  const [dunningSelection, setDunningSelection] = useState<DunningSelectionConfiguration>();
  const [dunningSelectionIsValid, setDunningSelectionIsValid] = useState(false);
  const [dunningForecast, setDunningForecast] = useState<DunningSelectionForecast>();
  const [dunningSelectionChanged, setDunningSelectionChanged] = useState<boolean>();
  const [forecastLoading, setForecastLoading] = useState<boolean>();
  const [downloadLoading, setDownloadLoading] = useState<boolean>();
  const [forecastRefreshingTime, setForecastRefreshingTime] = useState<string>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
  const userContext = useContext(UserContext);
  function loadDunningForecast(selection: DunningSelectionConfiguration) {
    if (selection.executionType == 'ONCE' && Validators.afterCurrentDate(new Date(selection.planedExecutionDate))) {
      return;
    }
    setForecastLoading(true);
    const forecastSelection = {
      ...defaultDunningSelection,
      ...selection,
    };
    getDunningSelectionForecast(forecastSelection)
      .then(fc => {
        setDunningForecast(fc);
        setDunningSelectionChanged(false);
      })
      .catch(e => {
        setDunningSelectionChanged(true);
        alertToast(e);
      })
      .finally(() => {
        setForecastLoading(false);
      });

    setForecastRefreshingTime(new Date(new Date().getTime()).toLocaleTimeString(i18n.language));
  }

  useEffect(() => {
    getBasicRuleSets()
      .then(rs => setRuleSets(rs))
      .catch(alertToast);
    getDunningSelection()
      .then(ds => {
        setDunningSelection(ds);
        loadDunningForecast(ds);
      })
      .catch(alertToast);
  }, []);

  function submitDunningSelection() {
    if (!dunningSelection) return;
    updateDunningSelection(dunningSelection)
      .then(() => {
        setDunningSelectionIsValid(false);
        setShowDialog(false);
      })
      .catch(e => alertToast(e))
      .finally(() => loadDunningForecast(dunningSelection));
  }

  const handleChange = (selection: DunningSelectionConfiguration) => {
    setShowDialog(true);
    setDunningSelection(selection);
    setDunningSelectionChanged(true);
  };

  function downloadForecastCsv(): void {
    if (!dunningSelection) return;
    const forecastSelection = Object.assign({}, defaultDunningSelection, dunningSelection, {
      planedExecutionDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    });
    setDownloadLoading(true);
    downloadDunningForecastCSV(forecastSelection)
      .catch(alertToast)
      .finally(() => {
        setDownloadLoading(false);
      });
  }

  if (!dunningSelection)
    return (
      <div className="dunning-selection">
        <Spinner />
      </div>
    );
  const hasUpdateDSRights = userContext.rights.includes(RIGHTS.UPDATE_DUNNING_SELECTION);
  return (
    <div className="dunning-selection">
      <DialogBox showDialog={showPrompt} confirmNavigation={confirmNavigation} cancelNavigation={cancelNavigation} />
      <div className="title">
        <div className="flex-between">
          {i18n.t('title_dunning_selection')}
          {hasUpdateDSRights && (
            <Button
              testId={testAttribute('4a4a', 'dunning-selection.save')}
              onClick={() => {
                submitDunningSelection();
              }}
              disabled={!dunningSelectionIsValid}>
              {i18n.t('dunning-selection.save')}
            </Button>
          )}
        </div>
      </div>
      <div className="flex-between">
        <Card className="left-card" interactive={false} elevation={Elevation.ONE}>
          <DunningSelection
            ruleSets={ruleSets}
            dunningSelection={dunningSelection}
            onSelectionChange={selection => {
              if (hasUpdateDSRights) {
                handleChange(selection);
              }
            }}
            onValidChange={isValid => {
              setDunningSelectionIsValid(isValid);
            }}
          />
        </Card>

        <Card className="right-card" interactive={false} elevation={Elevation.ONE}>
          <DunningForecast
            forecast={dunningForecast}
            refresh={() => loadDunningForecast(dunningSelection)}
            selectionHasChanged={dunningSelectionChanged && dunningSelectionIsValid}
            forecastLoading={forecastLoading}
            download={() => downloadForecastCsv()}
            downloadLoading={downloadLoading}
            refreshingTime={forecastRefreshingTime}
          />
        </Card>
      </div>
    </div>
  );
};

export { DunningSelectionPage };
