export const IconError = () => (
  <span className="pc-icon">
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9965 14.002L8 1.54419L1.00353 14.002H14.9965ZM8.87191 1.05452C8.48977 0.374081 7.51024 0.374081 7.1281 1.05452L0.131623 13.5123C-0.242746 14.1789 0.239002 15.002 1.00353 15.002H14.9965C15.761 15.002 16.2428 14.1789 15.8684 13.5123L8.87191 1.05452Z"
      />
      <path d="M9 12C9 12.5523 8.55229 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55229 11 9 11.4477 9 12Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.25 10L7.25 5.5H8.75L8.75 10H7.25Z" />
    </svg>
  </span>
);
