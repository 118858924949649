import { type FunctionComponent } from 'react';

import RadioButton from './RadioButton';

interface DunningRadioSelectProps {
  testId: string;
  options: Selectable[];
  checkedOptionKey?: string;
  disabled?: boolean;
  onSelect: (key: string) => void;
}

interface Selectable {
  key: string;
  label: string;
}

const RadioSelect: FunctionComponent<DunningRadioSelectProps> = (props: DunningRadioSelectProps) => {
  return (
    <div className="dunning-radio-container">
      {props.options.map(option => {
        return (
          <RadioButton
            testId={props.testId + option.key}
            key={option.key}
            isChecked={props.checkedOptionKey === option.key}
            label={option.label}
            disabled={props.disabled}
            onClick={() => props.onSelect(option.key)}
          />
        );
      })}
    </div>
  );
};

export { RadioSelect };
