import React, { type FunctionComponent } from 'react';

import i18n from 'i18n/i18n';

import CurrencyInput from '../../../../../../lib/CurrencyInput/CurrencyInput';
import { testAttribute } from '../../../../../../util/Util';
import {
  RuleDefinitionBaseProps,
  RuleDefinitionComputedValue,
  RuleDefinitionNumericValue,
  RuleDefinitionParamValue,
  useValidation,
} from './RuleDefinitionBaseProps';
import RuleInputVariableSelect from './RuleInputVariableSelect/RuleInputVariableSelect';

export interface RuleDefinitionIntegerProps extends RuleDefinitionBaseProps {
  removeParameterPanel?: boolean;
}

const RuleDefinitionCurrencyInput: FunctionComponent<RuleDefinitionIntegerProps> = ({
  value,
  onChange,
  label,
  isEditable,
  inputProps,
  validators,
  availableVariables,
  removeParameterPanel
}) => {
  const [validatedOnChange, error] = useValidation(onChange, value, validators);
  if (!value) return <></>;
  const isPlaceholder = value instanceof RuleDefinitionComputedValue || value instanceof RuleDefinitionParamValue;

  // if it is a predefined parameter, append its value on display
  const editedAvailableVariables = availableVariables?.map(k => {
    if (k.hasOwnProperty('value')) {
      const entries = Object.entries(k);
      let value;
      for (const entry of entries) {
        if (entry[0] === 'value') {
          value = entry[1];
        }
      }
      return {
        key: k.key,
        label: `${k.label} (€${value})`,
      };
    }
    return k;
  })

  return (
    <>
      <CurrencyInput
        testId={testAttribute('adi4', 'action-definition-float-input')}
        isEditable={isEditable}
        placeholder={i18n.t('dialog.rule.add-value')}
        value={value?.toValue()}
        showString={isPlaceholder}
        isValid={isPlaceholder}
        label={label}
        inputProps={inputProps}
        leftElement={ removeParameterPanel ? null :
          editedAvailableVariables && (
            <RuleInputVariableSelect
              selectables={editedAvailableVariables}
              isEditable={isEditable}
              onChange={v => validatedOnChange(v.key)}
            />
          )
        }
        onChange={value => validatedOnChange(new RuleDefinitionNumericValue(value))}
      />
    </>
  );
};

export default RuleDefinitionCurrencyInput;
