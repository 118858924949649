import { type FunctionComponent } from 'react';

import './Switch.style.sass';

/**
 * Figma design specification
 * https://www.figma.com/file/XKOJh2FJ678NacnAS2KqyA/UI%C2%A0components?node-id=2644%3A113974&t=KHyaMbJ8X3zQcwQF-0
 */

interface DunningSwitchProps {
  isChecked?: boolean;
  label?: string;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

const Switch: FunctionComponent<DunningSwitchProps> = props => {
  function onClick() {
    if (props.onChange) props.onChange(!props.isChecked);
  }

  return (
    <div className="dunning-switch-container" onClick={() => onClick()}>
      <div className={['switch', props.isChecked ? 'active' : null].join(' ')} />
      <span>{props.children}</span>
    </div>
  );
};

export { Switch };
