export const IconCaretDown = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99948 9.74094L10.3965 7L5.60246 7L7.99948 9.74094ZM7.6231 10.8296C7.82232 11.0574 8.17664 11.0574 8.37586 10.8296L11.8744 6.82915C12.1571 6.50583 11.9275 6 11.498 6H4.50097C4.07146 6 3.84184 6.50583 4.1246 6.82915L7.6231 10.8296Z"
      fill="#030307"
    />
  </svg>
);
