import React, { type FunctionComponent } from 'react';

import { Button, Intent, MenuItem, NonIdealState } from '@blueprintjs/core';
import { CaretDown } from '@blueprintjs/icons';
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';

import { RuleDefinitionBaseProps, RuleDefinitionStringValue, useValidation } from './RuleDefinitionBaseProps';
import i18n from '../../../../../../i18n/i18n';
import { testAttribute } from '../../../../../../util/Util';
import { Selectable } from '../../../../../DunningSelectionPage/DunningSelection/input/InputInterfaces';

interface RuleDefinitionSelectInputProps extends RuleDefinitionBaseProps {}

const RuleDefinitionSelectInput: FunctionComponent<RuleDefinitionSelectInputProps> = ({
  label,
  value,
  onChange,
  isEditable,
  inputProps,
  validators,
}) => {
  const [validatedOnChange, error] = useValidation(onChange, value, validators);

  if (!value || !(value instanceof RuleDefinitionStringValue)) return <></>;
  const selectables: Selectable[] = inputProps?.selectables ?? [];
  const selected: Selectable | undefined = selectables.find(s => s.key === value.toValue());
  if (!isEditable) {
    return (
      <>
        <span className="condition-text">
          {!!label && <>{label} : </>} {selected?.label}
        </span>
      </>
    );
  }

  const filterList: ItemPredicate<Selectable> = (query, selectable: Selectable, _index, exactMatch) => {
    const normalizedSelectable = selectable.label.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedSelectable === normalizedQuery;
    }
    return normalizedSelectable.includes(query.toLowerCase());
  };

  const renderList: ItemRenderer<Selectable> = (element, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        data-test-id={testAttribute('rlfn', 'select-general')}
        id={`select-general-${element.key}`}
        active={modifiers.active}
        key={element.key}
        onClick={handleClick}
        text={element.label}
      />
    );
  };

  return (
    <Select<Selectable>
      itemPredicate={filterList}
      items={selectables}
      itemRenderer={renderList}
      filterable={false}
      activeItem={value?.toValue() ?? ''}
      onItemSelect={res => validatedOnChange(new RuleDefinitionStringValue(res.key))}
      popoverProps={{ position: 'bottom' }}
      noResults={<NonIdealState description={<span>{i18n.t('action-page.no-data')}</span>} />}
      disabled={!isEditable}>
      <Button
        id="notification-action-day-type-button"
        className="rule-def-select-button"
        data-test-id={testAttribute('day1', 'dialog.rule.please-select')}
        minimal
        intent={error ? Intent.DANGER : Intent.NONE}
        outlined={isEditable}
        rightIcon={isEditable ? <CaretDown /> : undefined}>
        {selected?.label ?? i18n.t('dialog.rule.please-select')}
      </Button>
    </Select>
  );
};

export default RuleDefinitionSelectInput;
