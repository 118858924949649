/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContractStateOverview
 */
export interface ContractStateOverview {
    /**
     * The customer id associated with the contract
     * @type {number}
     * @memberof ContractStateOverview
     */
    customerId?: number;
    /**
     * The id of the contract
     * @type {number}
     * @memberof ContractStateOverview
     */
    contractId?: number;
    /**
     * The dunning level of the contract, which is the maximum of the levels of the receivables
     * @type {string}
     * @memberof ContractStateOverview
     */
    dunningLevel?: string;
    /**
     * True, if the contract finally invoiced
     * @type {boolean}
     * @memberof ContractStateOverview
     */
    isFinallyInvoiced?: boolean;
    /**
     * True, if the contract has customized ruleset assignment at the time of last execution
     * @type {boolean}
     * @memberof ContractStateOverview
     */
    isCustomized?: boolean;
    /**
     * The state of the last execution
     * @type {string}
     * @memberof ContractStateOverview
     */
    lastExecutionState?: string;
    /**
     * The date and time of the last letter sent
     * @type {Date}
     * @memberof ContractStateOverview
     */
    lastLetterSent?: Date;
    /**
     * The date and time of the last execution
     * @type {Date}
     * @memberof ContractStateOverview
     */
    lastExecutionTime?: Date;
    /**
     * The name of the active ruleset associated with the contract
     * @type {string}
     * @memberof ContractStateOverview
     */
    activeRuleSetName?: string;
    /**
     * If the execution is skipped for the contract.
     * @type {boolean}
     * @memberof ContractStateOverview
     */
    skipExecution?: boolean;
    /**
     * Deadline of the lock ( aka. skipping execution ) 
     * @type {Date}
     * @memberof ContractStateOverview
     */
    skipExecutionUntil?: Date;
}

/**
 * Check if a given object implements the ContractStateOverview interface.
 */
export function instanceOfContractStateOverview(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContractStateOverviewFromJSON(json: any): ContractStateOverview {
    return ContractStateOverviewFromJSONTyped(json, false);
}

export function ContractStateOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractStateOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'contractId': !exists(json, 'contractId') ? undefined : json['contractId'],
        'dunningLevel': !exists(json, 'dunningLevel') ? undefined : json['dunningLevel'],
        'isFinallyInvoiced': !exists(json, 'isFinallyInvoiced') ? undefined : json['isFinallyInvoiced'],
        'isCustomized': !exists(json, 'isCustomized') ? undefined : json['isCustomized'],
        'lastExecutionState': !exists(json, 'lastExecutionState') ? undefined : json['lastExecutionState'],
        'lastLetterSent': !exists(json, 'lastLetterSent') ? undefined : (new Date(json['lastLetterSent'])),
        'lastExecutionTime': !exists(json, 'lastExecutionTime') ? undefined : (new Date(json['lastExecutionTime'])),
        'activeRuleSetName': !exists(json, 'activeRuleSetName') ? undefined : json['activeRuleSetName'],
        'skipExecution': !exists(json, 'skipExecution') ? undefined : json['skipExecution'],
        'skipExecutionUntil': !exists(json, 'skipExecutionUntil') ? undefined : (new Date(json['skipExecutionUntil'])),
    };
}

export function ContractStateOverviewToJSON(value?: ContractStateOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'contractId': value.contractId,
        'dunningLevel': value.dunningLevel,
        'isFinallyInvoiced': value.isFinallyInvoiced,
        'isCustomized': value.isCustomized,
        'lastExecutionState': value.lastExecutionState,
        'lastLetterSent': value.lastLetterSent === undefined ? undefined : (value.lastLetterSent.toISOString()),
        'lastExecutionTime': value.lastExecutionTime === undefined ? undefined : (value.lastExecutionTime.toISOString()),
        'activeRuleSetName': value.activeRuleSetName,
        'skipExecution': value.skipExecution,
        'skipExecutionUntil': value.skipExecutionUntil === undefined ? undefined : (value.skipExecutionUntil.toISOString()),
    };
}

