export enum LICENSE_MODEL {
  LIGHT = 'DSL',
  UNLIMITED = 'DSU',
}

export enum NUMERIC_ARGUMENT_FUNCTION {
  DoubleMonthlyRate = 'DoubleMonthlyRate',
  DisconnectionRequirement = 'DisconnectionRequirement',
}

export class ErrorMessage {
  errorType: string;

  status: string;

  detail: string;

  title: string;

  traceId: string;

  additionalInformation: AdditionalInformation[];
}

export class AdditionalInformation {
  reason: string;
}

export class AssignedContracts {
  contractIds: number[];
}

export enum STATE {
  ACTIVE = 'ACTIVE',
  EDIT = 'EDIT',
  DELETED = 'DELETED',
}

export enum ALLOWED_TRANSITIONS {
  ADD_RULE = 'ADD_RULE',
  DELETE_RULE = 'DELETE_RULE',
  UPDATE_RULE = 'UPDATE_RULE',
  MAKE_ACTIVE = 'MAKE_ACTIVE',
  DELETE = 'DELETE',
  SET_TO_DEFAULT = 'SET_TO_DEFAULT',
  ARCHIVE = 'ARCHIVE',
  CHANGE_NAME = 'CHANGE_NAME',
  CHANGE_DESCRIPTION = 'CHANGE_DESCRIPTION',
}

export enum OPERATOR {
  EQ = 'EQ',
  NEQ = 'NEQ',
  GT = 'GT',
  GE = 'GE',
  LT = 'LT',
  LE = 'LE',
}

export enum OPERATOR_ICON {
  EQ = 'equals',
  NEQ = 'not-equal-to',
  GT = 'greater-than',
  GE = 'greater-than-or-equal-to',
  LT = 'less-than',
  LE = 'less-than-or-equal-to',
}

export enum ITEM_ATTRIBUTE {
  OPEN_AMOUNT = 'OPEN_AMOUNT',
  GROSS_AMOUNT = 'GROSS_AMOUNT',
  SETTLED_AMOUNT = 'SETTLED_AMOUNT',
  OVERDUE_WORKING_DAYS = 'OVERDUE_WORKING_DAYS',
  OVERDUE_CALENDAR_DAYS = 'OVERDUE_CALENDAR_DAYS',
  LEVEL = 'LEVEL',
}

export enum CASE_ATTRIBUTE {
  WORKING_DAYS_SINCE_LAST_NOTIFICATION = 'WORKING_DAYS_SINCE_LAST_NOTIFICATION',
  WORKING_DAYS_SINCE_LAST_EXECUTION = 'WORKING_DAYS_SINCE_LAST_EXECUTION',
  CALENDAR_DAYS_SINCE_LAST_NOTIFICATION = 'CALENDAR_DAYS_SINCE_LAST_NOTIFICATION',
  CALENDAR_DAYS_SINCE_LAST_EXECUTION = 'CALENDAR_DAYS_SINCE_LAST_EXECUTION',
  UNASSIGNED_PAYMENTS_AMOUNT = 'UNASSIGNED_PAYMENTS_AMOUNT',
  LEVEL = 'LEVEL',
  CALENDAR_DAYS_UNTIL_CONTRACT_ENDS = 'CALENDAR_DAYS_UNTIL_CONTRACT_ENDS',
  WORKING_DAYS_UNTIL_CONTRACT_ENDS = 'WORKING_DAYS_UNTIL_CONTRACT_ENDS',
  DISCONNECTION_STATUS = 'DISCONNECTION_STATUS',
  ADDITIONAL_VALUES = 'ADDITIONAL_VALUES',
  COMMUNICATION_PROFILE = 'COMMUNICATION_PROFILE',
  TERMINATION = 'TERMINATION',
  CONTRACT_TYPE = 'CONTRACT_TYPE',
  ENERGY = 'ENERGY',
  BALANCE_CONTRACT_AMOUNT = 'BALANCE_CONTRACT_AMOUNT',
  CREDIT_SCORE = 'CREDIT_SCORE',
  PRODUCT_CODE = 'PRODUCT_CODE',
  FORECAST_ANNUAL_CONSUMPTION = 'FORECAST_ANNUAL_CONSUMPTION',
}

export enum CUSTOMER_ATTRIBUTE {
  WORKING_DAYS_SINCE_LAST_NOTIFICATION = 'WORKING_DAYS_SINCE_LAST_NOTIFICATION',
  WORKING_DAYS_SINCE_LAST_EXECUTION = 'WORKING_DAYS_SINCE_LAST_EXECUTION',
  CALENDAR_DAYS_SINCE_LAST_NOTIFICATION = 'CALENDAR_DAYS_SINCE_LAST_NOTIFICATION',
  CALENDAR_DAYS_SINCE_LAST_EXECUTION = 'CALENDAR_DAYS_SINCE_LAST_EXECUTION',
  CUSTOMER_GROUP = 'CUSTOMER_GROUP',
  CUSTOMER_TYPE = 'CUSTOMER_TYPE',
}

export enum ERROR_CODE {
  RULE_NOT_VALID_DUNNING_LEVEL_NOT_IN_RULESET = 'JDS-RULE-0009',
  RULE_NOT_VALID_LEVEL_DESC_ORDER = 'JDS-RULE-0010',
  RULE_NOT_VALID_MISSING_CONTRACT_TERMINATION_CONDITION = 'JDS-RULE-0011',
  RULE_NOT_VALID_CONTRACT_ALREADY_TERMINATED = 'JDS-RULE-0012',
  RULE_NOT_VALID_NO_CHANGE_LEVEL_ACTION_RESTRICT = 'JDS-RULE-0013',
  RULE_NOT_VALID_NO_SETTLEMENT_DAY_TYPE_SELECTED = 'JDS-RULE-0014',
  RULE_NAME_IN_USE = 'JDS-00028',
  RULE_SET_IS_ASSOCIATED_WITH_CONTRACT = 'JDS-00023',
}

export enum ACTION_TYPE {
  FEE = 'Fee',
  CHANGE_OF_STATUS = 'ChangeOfStatus',
  NOTIFICATION = 'Notification',
  DISCONNECTION_THREAT = 'DisconnectionThreat',
  DISCONNECTION_ANNOUNCEMENT = 'DisconnectionAnnouncement',
  DISCONNECTION = 'Disconnection',
  CONTRACT_TERMINATION = 'ContractTermination',
  DEBT_COLLECTION = 'DebtCollection',
}

export enum AGGREG_FUNCTION {
  SUM = 'SUM',
}

export enum TABLE_TITLE {
  NAME = 'name',
  DESCRIPTION = 'description',
  TRIGGERED_ACTION = 'table.triggered-action',
  CREATED_ON = 'table.created-on',
  LAST_EDITOR = 'table.last-editor',
  STATUS = 'table.status',
  LAST_EDITED_ON = 'table.last-edited-on',
}

export enum DEFAULT_RULESET_TYPE {
  REGULAR = 'REGULAR',
  FINALLY_INVOICED = 'FINALLY_INVOICED',
  ALL = 'ALL',
}

export enum EXECUTION_STATUS {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESSFUL',
  AWAITING_SIBLINGS = 'AWAITING_SIBLINGS',
  RETRYING = 'RETRY',
  CANCELED = 'CANCELED',
}

export enum CONTRACT_STATE {
  OPEN = 'OPEN',
  BLOCKED = 'BLOCKED',
  ACTIVE = 'ACTIVE',
  WAITING = 'WAITING',
  CANCELLED = 'CANCELLED',
  CANCELLED_PREVIOUS = 'CANCELLED_PREVIOUS',
  DELIVERY_NET = 'DELIVERY_NET',
  TERMINATED = 'TERMINATED',
  EXPIRED = 'EXPIRED',
  DONE = 'DONE',
}

export enum CONTRACT_TYPE {
  WATER_SEWAGE = 'water_sewage',
  NON_COMMODITY = 'non_commodity',
  E_MOBILITY = 'emobility',
  ENERGY = 'energy',
  BUNDLE = 'bundle',
}

export enum ENERGY_TYPE {
  ELECTRICITY = 'electricity',
  GAS = 'gas',
}

export enum PAYMENT_TYPE {
  ALL = 'ALL',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  SELF_PAYER = 'SELF_PAYER',
}

export enum JWT_ROLES {
  SUPER_ADMIN = 'dunning_service_superadmin',
  ADMIN = 'dunning_service_admin',
  EDITOR = 'dunning_service_editor',
  CLERK = 'dunning_service_clerk',
}

export enum DAY_TYPE {
  WORKING_DAYS = 'WORKING_DAYS',
  CALENDAR_DAYS = 'CALENDAR_DAYS',
}

export enum CONTRACT_TARGET_TYPE {
  ALL = 'ALL',
  FINALLY_INVOICED = 'FINALLY_INVOICED',
  CUSTOMIZED = 'CUSTOMIZED',
  ONGOING = 'ONGOING',
}

export enum YES_NO_SELECTION {
  ALL = 'ALL',
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export const INITIAL_DUNNING_LEVEL_ID = '227e9bd4-198c-4129-b2db-0a353639b046';
