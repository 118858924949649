export const IconRefresh = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.50001 5.49998C9.50001 5.77612 9.72386 5.99998 10 5.99998L15.5 5.99999V0.5C15.5 0.223858 15.2761 0 15 0C14.7239 0 14.5 0.223858 14.5 0.5V4.25612C13.2044 2.01151 10.7792 0.500038 8 0.500038C4.03802 0.500038 0.79395 3.57184 0.518872 7.4641C0.499406 7.73956 0.706924 7.97864 0.98238 7.99811C1.25784 8.01757 1.49692 7.81005 1.51639 7.5346C1.75472 4.16223 4.56668 1.50004 8 1.50004C10.507 1.50004 12.6836 2.91956 13.7679 4.99999L10 4.99998C9.72387 4.99998 9.50001 5.22384 9.50001 5.49998Z"
      fill="#030307"
    />
    <path
      d="M6.49999 10.5001C6.49999 10.224 6.27613 10.0001 5.99999 10.0001L0.499989 10.0001V15.5001C0.499989 15.7762 0.723847 16.0001 0.999989 16.0001C1.27613 16.0001 1.49999 15.7762 1.49999 15.5001L1.49999 11.744C2.79558 13.9885 5.2208 15.5 8 15.5C11.9624 15.5 15.2067 12.4276 15.4812 8.5348C15.5006 8.25934 15.2931 8.02029 15.0176 8.00086C14.7422 7.98144 14.5031 8.189 14.4837 8.46446C14.2458 11.8373 11.4337 14.5 8 14.5C5.49298 14.5 3.31647 13.0806 2.2321 11.0001L5.99999 11.0001C6.27613 11.0001 6.49999 10.7762 6.49999 10.5001Z"
      fill="#030307"
    />
  </svg>
);
