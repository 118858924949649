import './RuleConditionGroup.style.sass';

interface RuleConditionGroupProps {
  title: string;
  children: string | JSX.Element | JSX.Element[];
}

function RuleConditionGroup(props: RuleConditionGroupProps) {
  return (
    <>
      <div className="rule-condition-group">
        <div className="rule-condition-title">{props.title}</div>
        {props.children}
      </div>
    </>
  );
}

export default RuleConditionGroup;
