import { type FunctionComponent } from 'react';

import i18n from 'i18n/i18n';

import FormDateInput from './input/FormDateInput';
import FormInfoText from './input/FormInfoText';
import FormMultiSelectInput from './input/FormMultiSelectInput';
import FormNumericInput from './input/FormNumericInput';
import FormRadioInput from './input/FormRadioInput';
import FormSelectInput from './input/FormSelectInput';
import { type Selectable } from './input/InputInterfaces';
import { type Validator } from './input/Validators';
import { testAttribute } from '../../../util/Util';
import FormGrouping from '../FormGrouping/FormGrouping';

type FormFieldTypes = 'numeric' | 'selection' | 'multi-select' | 'date-picker' | 'info-text' | 'radio-buttons';

export interface FormGroupingInterface {
  key: string;
  title: string;
  fields: FormFieldsDefinition[];
}

interface FormFieldsDefinition {
  type: FormFieldTypes;
  name: string;
  label: string;
  disabled?: boolean;
  validators?: Validator[];
  placeholder?: string;
  selectables?: Selectable[];
  additionalText?: string;
  additionalConfiguration?: Record<string, any>
}

type FormValues = Record<string, Record<string, { valid: boolean; value: any }>>;

interface DunningSelectionFormProps {
  values: FormValues;
  groups: FormGroupingInterface[];
  onChange?: (formValues: FormValues) => void;
}

const DunningSelectionForm: FunctionComponent<DunningSelectionFormProps> = ({ groups, values, onChange }) => {
  const formStateListener =
    (field: FormFieldsDefinition, groupKey: string) =>
    (value: any, valid = true) => {
      const newValues = {
        ...values,
        [groupKey]: {
          ...values[groupKey],
          [field.name]: {
            value,
            valid,
          },
        },
      };
      if (onChange) onChange(newValues);
    };

  const inputBuilders: {
    [key in FormFieldTypes]: (definition: FormFieldsDefinition, value: any, groupKey: string, idx: string) => any;
  } = {
    'date-picker': (field, value, groupKey, idx) => (
      <FormDateInput
        data-test-id={testAttribute('dssd', 'dunning-selection-form.date-input')}
        key={idx}
        value={value}
        {...field}
        onChange={formStateListener(field, groupKey)}
      />
    ),
    'info-text': (field, value, groupKey, idx) => <FormInfoText key={idx} text={field.additionalText ?? ''} />,
    'multi-select': (field, value, groupKey, idx) => (
      <FormMultiSelectInput
        data-test-id={testAttribute('dssd', 'dunning-selection-form.multi-select-input')}
        key={idx}
        value={value}
        {...field}
        selectables={field.selectables ?? []}
        onChange={formStateListener(field, groupKey)}
      />
    ),
    selection: (field, value, groupKey, idx) => (
      <FormSelectInput
        data-test-id={testAttribute('dssd', 'dunning-selection-form.select-input')}
        key={idx}
        value={value}
        {...field}
        selectables={field.selectables ?? []}
        onChange={formStateListener(field, groupKey)}
      />
    ),
    'radio-buttons': (field, value, groupKey, idx) => (
      <FormRadioInput
        data-test-id={testAttribute('dssd', 'dunning-selection-form.radio-button-input')}
        value={value}
        key={idx}
        {...field}
        selectables={field.selectables ?? []}
        onChange={formStateListener(field, groupKey)}
      />
    ),
    numeric: (field, value, groupKey, idx) => (
      <FormNumericInput
        data-test-id={testAttribute('dssd', 'dunning-selection-form.numeric-input')}
        key={idx}
        value={value}
        {...field}
        onChange={formStateListener(field, groupKey)}
      />
    ),
  };

  return (
    <form className="form dunning-selection-form">
      <h3 className="card-title">{i18n.t('dunning-selection.options_title')}</h3>
      {groups.map((group, groupIdx) => {
        const inputFields = group.fields.map((field, fieldIdx) => {
          return inputBuilders[field.type](
            field,
            values[group.key][field.name]?.value,
            group.key,
            `${groupIdx}-${fieldIdx}`,
          );
        });
        return (
          <FormGrouping title={group.title} key={group.key}>
            {inputFields}
          </FormGrouping>
        );
      })}
    </form>
  );
};

export default DunningSelectionForm;
