/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BasicRuleSetDto
 */
export interface BasicRuleSetDto {
    /**
     * The id of the ruleset
     * @type {string}
     * @memberof BasicRuleSetDto
     */
    rulesetId?: string;
    /**
     * The new name for the ruleset
     * @type {string}
     * @memberof BasicRuleSetDto
     */
    name: string;
    /**
     * The new description for the ruleset
     * @type {string}
     * @memberof BasicRuleSetDto
     */
    description?: string;
    /**
     * The date/time that the record was created
     * @type {Date}
     * @memberof BasicRuleSetDto
     */
    created?: Date;
    /**
     * The last time that the record was updated
     * @type {Date}
     * @memberof BasicRuleSetDto
     */
    updated?: Date;
    /**
     * Last editor of the ruleset
     * @type {string}
     * @memberof BasicRuleSetDto
     */
    lastEditor?: string;
    /**
     * The state of the ruleset
     * @type {string}
     * @memberof BasicRuleSetDto
     */
    state?: BasicRuleSetDtoStateEnum;
    /**
     * 
     * @type {string}
     * @memberof BasicRuleSetDto
     */
    triggeredAction?: string;
    /**
     * The events the ruleset will accept
     * @type {Set<string>}
     * @memberof BasicRuleSetDto
     */
    allowedTransitions?: Set<BasicRuleSetDtoAllowedTransitionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BasicRuleSetDto
     */
    subsequentId?: string;
}


/**
 * @export
 */
export const BasicRuleSetDtoStateEnum = {
    Active: 'ACTIVE',
    Edit: 'EDIT',
    Deleted: 'DELETED'
} as const;
export type BasicRuleSetDtoStateEnum = typeof BasicRuleSetDtoStateEnum[keyof typeof BasicRuleSetDtoStateEnum];

/**
 * @export
 */
export const BasicRuleSetDtoAllowedTransitionsEnum = {
    AddRule: 'ADD_RULE',
    DeleteRule: 'DELETE_RULE',
    UpdateRule: 'UPDATE_RULE',
    MakeActive: 'MAKE_ACTIVE',
    Delete: 'DELETE',
    SetToDefault: 'SET_TO_DEFAULT',
    Archive: 'ARCHIVE',
    ChangeName: 'CHANGE_NAME',
    ChangeDescription: 'CHANGE_DESCRIPTION',
    UpdateRuleSet: 'UPDATE_RULE_SET',
    ChangeRuleName: 'CHANGE_RULE_NAME',
    ChangeRuleDescription: 'CHANGE_RULE_DESCRIPTION'
} as const;
export type BasicRuleSetDtoAllowedTransitionsEnum = typeof BasicRuleSetDtoAllowedTransitionsEnum[keyof typeof BasicRuleSetDtoAllowedTransitionsEnum];


/**
 * Check if a given object implements the BasicRuleSetDto interface.
 */
export function instanceOfBasicRuleSetDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function BasicRuleSetDtoFromJSON(json: any): BasicRuleSetDto {
    return BasicRuleSetDtoFromJSONTyped(json, false);
}

export function BasicRuleSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasicRuleSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rulesetId': !exists(json, 'rulesetId') ? undefined : json['rulesetId'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'lastEditor': !exists(json, 'lastEditor') ? undefined : json['lastEditor'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'triggeredAction': !exists(json, 'triggeredAction') ? undefined : json['triggeredAction'],
        'allowedTransitions': !exists(json, 'allowedTransitions') ? undefined : json['allowedTransitions'],
        'subsequentId': !exists(json, 'subsequentId') ? undefined : json['subsequentId'],
    };
}

export function BasicRuleSetDtoToJSON(value?: BasicRuleSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rulesetId': value.rulesetId,
        'name': value.name,
        'description': value.description,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'lastEditor': value.lastEditor,
        'state': value.state,
        'triggeredAction': value.triggeredAction,
        'allowedTransitions': value.allowedTransitions === undefined ? undefined : Array.from(value.allowedTransitions as Set<any>),
        'subsequentId': value.subsequentId,
    };
}

