import { type FunctionComponent } from 'react';

import { Tooltip } from '@blueprintjs/core';

import i18n from '../../../i18n/i18n';
import { CancelledGray } from '../../../icons/CancelledGray';
import { CheckGreen } from '../../../icons/CheckGreen';
import { ClockBlue } from '../../../icons/ClockBlue';
import { RetryBlue } from '../../../icons/RetryBlue';
import { WarningRed } from '../../../icons/WarningRed';
import { EXECUTION_STATUS } from '../../../interfaces/enums';

interface ExecutionStateIndicatorProps {
  state: EXECUTION_STATUS | string | undefined;
}

const ExecutionStateIndicator: FunctionComponent<ExecutionStateIndicatorProps> = ({ state }) => {
  if (state === EXECUTION_STATUS.SUCCESS || EXECUTION_STATUS.SUCCESS.toString() === state) {
    return (
      <Tooltip content={i18n.t('dialog.execution.succeeded')}>
        <CheckGreen />
      </Tooltip>
    );
  }
  if (state === EXECUTION_STATUS.AWAITING_SIBLINGS || EXECUTION_STATUS.AWAITING_SIBLINGS.toString() === state) {
    return (
      <Tooltip content={i18n.t('dialog.execution.awaiting-siblings')}>
        <ClockBlue />
      </Tooltip>
    );
  }
  if (state === EXECUTION_STATUS.PENDING || EXECUTION_STATUS.PENDING.toString() === state) {
    return (
      <Tooltip content={i18n.t('dialog.execution.pending')}>
        <ClockBlue />
      </Tooltip>
    );
  }
  if (state === EXECUTION_STATUS.RETRYING || EXECUTION_STATUS.RETRYING.toString() === state) {
    return (
      <Tooltip content={i18n.t('dialog.execution.retry')}>
        <RetryBlue />
      </Tooltip>
    );
  }
  if (state === EXECUTION_STATUS.CANCELED || EXECUTION_STATUS.CANCELED.toString() === state) {
    return (
      <Tooltip content={i18n.t('dialog.execution.cancel')}>
        <CancelledGray />
      </Tooltip>
    );
  }
  return (
    <Tooltip content={i18n.t('dialog.execution.failed')}>
      <WarningRed />
    </Tooltip>
  );
};

export default ExecutionStateIndicator;
