import React, { FunctionComponent } from 'react';

import { IconName, MenuItem } from '@blueprintjs/core';
import { Equals, GreaterThan, GreaterThanOrEqualTo, LessThan, LessThanOrEqualTo, NotEqualTo } from '@blueprintjs/icons';
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';

import i18n from 'i18n/i18n';
import { OPERATOR } from 'interfaces/enums';
import { RelationalOperator } from 'interfaces/Interfaces';
import { Button } from 'lib/Button';
import { testAttribute } from 'util/Util';
import { RuleDefinitionBaseProps, RuleDefinitionOperatorValue, useValidation } from './RuleDefinitionBaseProps';

interface RuleDefinitionOperatorSelectProps extends RuleDefinitionBaseProps {}

const RuleDefinitionOperatorSelect: FunctionComponent<RuleDefinitionOperatorSelectProps> = (
  props: RuleDefinitionOperatorSelectProps,
) => {
  const [validatedOnChange, error] = useValidation(props.onChange, props.value, props.validators);
  const filterList: ItemPredicate<RelationalOperator> = (query, operator, _index, exactMatch) => {
    const normalizedTitle = operator.operator.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    }
    return (
      operator.operator.toLowerCase().includes(query.toLowerCase()) ||
      operator.text.toLowerCase().includes(query.toLowerCase())
    );
  };

  const renderList: ItemRenderer<RelationalOperator> = (element, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        id={`operator-select-${element.operator}`}
        data-test-id={testAttribute('osfn', 'operator-select', `${element.operator}`)}
        className="render"
        icon={element.icon}
        active={modifiers.active}
        key={element.operator}
        onClick={handleClick}
        text={element.text}
      />
    );
  };

  const availableOperators: RelationalOperator[] = [
    {
      text: i18n.t('dialog.rule.equals'),
      operator: OPERATOR.EQ,
      icon: <Equals />,
    },
    {
      text: i18n.t('dialog.rule.not-equal-to'),
      operator: OPERATOR.NEQ,
      icon: <NotEqualTo />,
    },
    {
      text: i18n.t('dialog.rule.greater-than'),
      operator: OPERATOR.GT,
      icon: <GreaterThan />,
    },
    {
      text: i18n.t('dialog.rule.greater-than-or-equal-to'),
      operator: OPERATOR.GE,
      icon: <GreaterThanOrEqualTo />,
    },
    {
      text: i18n.t('dialog.rule.less-than'),
      operator: OPERATOR.LT,
      icon: <LessThan />,
    },
    {
      text: i18n.t('dialog.rule.less-than-or-equal-to'),
      operator: OPERATOR.LE,
      icon: <LessThanOrEqualTo />,
    },
  ];

  const relationalOperators: RelationalOperator[] = availableOperators.filter(
    operator => props.inputProps?.availableOperators?.includes(operator.operator) ?? true,
  );

  function onOperatorSelect(operator: RelationalOperator) {
    validatedOnChange(new RuleDefinitionOperatorValue(operator.operator));
  }

  function getIcon(activeOperator: OPERATOR): IconName {
    const operator = relationalOperators.find(operator => operator.operator === activeOperator);

    return operator?.icon ?? <Equals />;
  }

  return (
    <Select<RelationalOperator>
      itemPredicate={filterList}
      inputProps={{ placeholder: i18n.t('dialog.rule.search-operator') }}
      items={relationalOperators}
      itemRenderer={renderList}
      onItemSelect={operator => onOperatorSelect(operator)}
      filterable
      popoverProps={{ position: 'bottom' }}
      disabled={!props.isEditable}>
      <Button
        type="tertiary"
        testId={testAttribute('glhf', 'operator-button', `${props.value?.toValue()}`)}
        icon={getIcon(props.value?.toValue())}
        onClick={() => undefined}
      />
    </Select>
  );
};

export default RuleDefinitionOperatorSelect;
