/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GroupSelectorDto
 */
export interface GroupSelectorDto {
    /**
     * 
     * @type {string}
     * @memberof GroupSelectorDto
     */
    attrib: GroupSelectorDtoAttribEnum;
    /**
     * 
     * @type {string}
     * @memberof GroupSelectorDto
     */
    cmp: GroupSelectorDtoCmpEnum;
    /**
     * 
     * @type {object}
     * @memberof GroupSelectorDto
     */
    argument: object;
}


/**
 * @export
 */
export const GroupSelectorDtoAttribEnum = {
    Level: 'LEVEL',
    OverdueWorkingDays: 'OVERDUE_WORKING_DAYS',
    OverdueCalendarDays: 'OVERDUE_CALENDAR_DAYS',
    OpenAmount: 'OPEN_AMOUNT',
    GrossAmount: 'GROSS_AMOUNT',
    SettledAmount: 'SETTLED_AMOUNT'
} as const;
export type GroupSelectorDtoAttribEnum = typeof GroupSelectorDtoAttribEnum[keyof typeof GroupSelectorDtoAttribEnum];

/**
 * @export
 */
export const GroupSelectorDtoCmpEnum = {
    Eq: 'EQ',
    Neq: 'NEQ',
    Gt: 'GT',
    Ge: 'GE',
    Lt: 'LT',
    Le: 'LE'
} as const;
export type GroupSelectorDtoCmpEnum = typeof GroupSelectorDtoCmpEnum[keyof typeof GroupSelectorDtoCmpEnum];


/**
 * Check if a given object implements the GroupSelectorDto interface.
 */
export function instanceOfGroupSelectorDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attrib" in value;
    isInstance = isInstance && "cmp" in value;
    isInstance = isInstance && "argument" in value;

    return isInstance;
}

export function GroupSelectorDtoFromJSON(json: any): GroupSelectorDto {
    return GroupSelectorDtoFromJSONTyped(json, false);
}

export function GroupSelectorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupSelectorDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attrib': json['attrib'],
        'cmp': json['cmp'],
        'argument': json['argument'],
    };
}

export function GroupSelectorDtoToJSON(value?: GroupSelectorDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attrib': value.attrib,
        'cmp': value.cmp,
        'argument': value.argument,
    };
}

