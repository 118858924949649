import { Tooltip } from '@blueprintjs/core';

import i18n from 'i18n/i18n';

import { IconFlag } from './svg/IconFlag';

import './Icon.style.sass';

interface DefaultCheckGreenProps {
  isRegular: boolean;
  isFinallyInvoiced: boolean;
}

export const DefaultCheckGreen = (props: DefaultCheckGreenProps) => (
  <Tooltip content={getContent(props.isRegular, props.isFinallyInvoiced)} placement="top">
    <div className="flag-line">
      <IconFlag />
    </div>
  </Tooltip>
);

function getContent(isRegular: boolean, isFinallyInvoiced: boolean): string {
  if (isRegular && isFinallyInvoiced) {
    return `${i18n.t('dialog.ruleset.default-procedure-both')}, ${i18n.t('dialog.ruleset.active')}`;
  }
  if (isRegular) {
    return `${i18n.t('dialog.ruleset.default-procedure')}, ${i18n.t('dialog.ruleset.active')}`;
  }
  return `${i18n.t('dialog.ruleset.finally-invoiced-procedure')}, ${i18n.t('dialog.ruleset.active')}`;
}
