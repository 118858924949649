import { type FunctionComponent } from 'react';

import { Card, Elevation, Tooltip } from '@blueprintjs/core';
import { IconInfo } from '../../icons/svg/IconInfo';
import { getCommunicationProfiles } from '../../services/ApiService';

interface GeneralInformationConfigProps {}

export const GeneralInformation: FunctionComponent<GeneralInformationConfigProps> = props => {
  const providedJwt: any = (window as any).JwtForDunning || 'undefined';

  return (
    <Card className="card" interactive={false} elevation={Elevation.TWO}>
      <h5 className="bp5-heading">Information </h5>
      <div className="row">
        <span>Provided JWT</span>
        <div>
          <Tooltip content="click to copy to clipboard">
            <div
              onClick={() => navigator.clipboard.writeText(providedJwt)}
              style={{
                fontSize: '8pt',
                cursor: 'pointer',
                overflow: 'auto',
                lineBreak: 'anywhere',
              }}>
              {providedJwt}
            </div>
          </Tooltip>
        </div>
        <span className="spacer" />
        <Tooltip
          content={`This is the JWT provided to the UI. If embedded in RCS, this token is provided by RCS,
           otherwise it takes a hardcoded to token for development purposes.`}>
          <IconInfo />
        </Tooltip>
      </div>
      <div className="row">
        <span style={{ alignSelf: 'self-start' }}>
          Provided communication Profiles: ({getCommunicationProfiles().length} profiles):
        </span>
        <div>
          {getCommunicationProfiles().map(p => {
            return (
              <div key={p.id}>
                {p.id}: {p.name}
              </div>
            );
          })}
        </div>
        <span className="spacer"></span>
        <Tooltip
          content={`This is the JWT provided to the UI. If embedded in RCS, this token is provided by RCS,
           otherwise it takes a hardcoded to token for development purposes.`}>
          <IconInfo />
        </Tooltip>
      </div>
    </Card>
  );
};

export default GeneralInformation;
