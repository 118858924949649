/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Comparison } from './Comparison';
import {
    ComparisonFromJSON,
    ComparisonFromJSONTyped,
    ComparisonToJSON,
} from './Comparison';

/**
 * List of features
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * Feature name
     * @type {string}
     * @memberof Feature
     */
    name?: string;
    /**
     * Feature is enabled or not, True to Enabled
     * @type {boolean}
     * @memberof Feature
     */
    enabled?: boolean;
    /**
     * Many features may have a comparison to some value
     * @type {Array<Comparison>}
     * @memberof Feature
     */
    comparisons?: Array<Comparison>;
}

/**
 * Check if a given object implements the Feature interface.
 */
export function instanceOfFeature(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FeatureFromJSON(json: any): Feature {
    return FeatureFromJSONTyped(json, false);
}

export function FeatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): Feature {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'comparisons': !exists(json, 'comparisons') ? undefined : ((json['comparisons'] as Array<any>).map(ComparisonFromJSON)),
    };
}

export function FeatureToJSON(value?: Feature | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'enabled': value.enabled,
        'comparisons': value.comparisons === undefined ? undefined : ((value.comparisons as Array<any>).map(ComparisonToJSON)),
    };
}

