import { type FunctionComponent } from 'react';

import i18n from 'i18n/i18n';
import { DEFAULT_RULESET_TYPE, TABLE_TITLE } from 'interfaces/enums';

import RuleSetRow from './RuleSetRow/RuleSetRow';
import {
  type AbstractRuleSet,
  type BasicRuleSet,
  type DefaultRuleSet,
  type RuleSetForReact,
} from '../../../interfaces/Interfaces';

import './RuleSetTable.style.sass';

// TODO(JDS-473): This should be inside DunningOverview. Also the name is missleading since there are multiple tables now. Maybe `RuleSetTable`?
// https://dev-docu.int.power.cloud/guidelines/languages-and-database/javascript/react-coding-guidelines#structure-and-naming

interface RuleSetTableProps {
  ruleSets: AbstractRuleSet[];
  tableHeaders: TABLE_TITLE[];
  defaultRuleSets: DefaultRuleSet[];
  highlightedRuleSetId: string;
  highlightedRuleId: string;
  expandedRuleSet: string;
  onUpdateRuleSet: (ruleset: RuleSetForReact, ruleId?: string) => void;
  onUpdateTable: (rulesetId?: string, ruleId?: string) => void;
  subsequentRuleSets: BasicRuleSet[];
  dunningSelectionConfigurationRuleSets: string[];
}

const RuleSetTable: FunctionComponent<RuleSetTableProps> = (props: RuleSetTableProps) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th colSpan={4}>{i18n.t(TABLE_TITLE.NAME)}</th>
          {props.tableHeaders.map(title => {
            return <th key={title}>{i18n.t(title)}</th>;
          })}

          <th />
        </tr>
      </thead>
      {props.ruleSets?.map((ruleSet: AbstractRuleSet) => {
        return (
          <RuleSetRow
            key={ruleSet.rulesetId}
            ruleSets={props.ruleSets}
            ruleSet={ruleSet}
            subsequentRuleSets={props.subsequentRuleSets}
            tableHeaders={props.tableHeaders}
            isDefaultRuleSetRegular={
              props.defaultRuleSets.find(
                rs => rs.contractTargetType === DEFAULT_RULESET_TYPE.REGULAR && rs.id === ruleSet.rulesetId,
              ) !== undefined
            }
            isDefaultRuleSetFinallyInvoiced={
              props.defaultRuleSets.find(
                rs => rs.contractTargetType === DEFAULT_RULESET_TYPE.FINALLY_INVOICED && rs.id === ruleSet.rulesetId,
              ) !== undefined
            }
            highlightedRuleSetId={props.highlightedRuleSetId}
            highlightedRuleId={props.highlightedRuleId}
            expandedRuleSet={props.expandedRuleSet}
            onUpdateRuleSet={(ruleSet, ruleId) => props.onUpdateRuleSet(ruleSet, ruleId)}
            onUpdateTable={(ruleSetId, ruleId) => props.onUpdateTable(ruleSetId, ruleId)}
            isUsedInDunningSelectionConfiguration={props.dunningSelectionConfigurationRuleSets.includes(
              ruleSet.rulesetId,
            )}

      />
        );
      })}
    </table>
  );
};

export default RuleSetTable;
