import { type FunctionComponent, useContext } from 'react';

import { LicenseContext } from 'App';

import { LICENSE_MODEL } from 'interfaces/enums';

interface IfLicensedProps {
  light?: boolean;
  unlimited?: boolean;
}

/**
 * If no license type is given, only shows if the license is the unlimited license
 */
const IfLicensed: FunctionComponent<IfLicensedProps> = ({ light, unlimited, children }) => {
  const license = useContext(LicenseContext);

  if (light && license.appLicense.name === LICENSE_MODEL.LIGHT) return <>{children}</>;
  if ((unlimited ?? light === undefined) && license.appLicense.name === LICENSE_MODEL.UNLIMITED) return <>{children}</>;
  return <></>;
};

export { IfLicensed };
