/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DefaultRuleSetDto
 */
export interface DefaultRuleSetDto {
    /**
     * ID of the rule set that will be assigned by default to the target contract type
     * @type {string}
     * @memberof DefaultRuleSetDto
     */
    id: string;
    /**
     * Target type of contracts for which a rules set is set as default: `REGULAR`, `FINALLY_INVOICED`, `ALL`
     * @type {string}
     * @memberof DefaultRuleSetDto
     */
    contractTargetType: DefaultRuleSetDtoContractTargetTypeEnum;
}


/**
 * @export
 */
export const DefaultRuleSetDtoContractTargetTypeEnum = {
    Regular: 'REGULAR',
    FinallyInvoiced: 'FINALLY_INVOICED',
    All: 'ALL'
} as const;
export type DefaultRuleSetDtoContractTargetTypeEnum = typeof DefaultRuleSetDtoContractTargetTypeEnum[keyof typeof DefaultRuleSetDtoContractTargetTypeEnum];


/**
 * Check if a given object implements the DefaultRuleSetDto interface.
 */
export function instanceOfDefaultRuleSetDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "contractTargetType" in value;

    return isInstance;
}

export function DefaultRuleSetDtoFromJSON(json: any): DefaultRuleSetDto {
    return DefaultRuleSetDtoFromJSONTyped(json, false);
}

export function DefaultRuleSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultRuleSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'contractTargetType': json['contractTargetType'],
    };
}

export function DefaultRuleSetDtoToJSON(value?: DefaultRuleSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'contractTargetType': value.contractTargetType,
    };
}

