import { type FunctionComponent } from 'react';

import { InputGroup } from '@blueprintjs/core';

import { handleNumberOnly } from '../../../../util/Util';

interface NumberFilterInputProps {
  value: string;
  onChange: (newValue: string) => void;
  label?: string;
}

export const NumberFilterInput: FunctionComponent<NumberFilterInputProps> = ({ value, onChange, label }) => (
  <>
    <span className="lib-table-filter__label">{label}</span>
    <InputGroup
      data-test-id="123"
      value={value ?? ''}
      type="number"
      fill
      onChange={(event: any) => onChange(event.target.value)}
      onKeyDown={e => handleNumberOnly(e)}
    />
  </>
);
