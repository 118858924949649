/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  SystemPropertyDto,
  SystemPropertyUpdateDto,
} from '../models/index';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SystemPropertyDtoFromJSON,
    SystemPropertyDtoToJSON,
    SystemPropertyUpdateDtoFromJSON,
    SystemPropertyUpdateDtoToJSON,
} from '../models/index';

export interface GetByPropertyByKeyRequest {
    property: string;
}

export interface UpdatePropertiesRequest {
    systemPropertyDto: Array<SystemPropertyDto>;
}

export interface UpdateSystemPropertyRequest {
    property: string;
    systemPropertyUpdateDto: SystemPropertyUpdateDto;
}

/**
 * 
 */
export class SystemPropertiesApi extends runtime.BaseAPI {

    /**
     * Get a particular system property
     * Get Property
     */
    async getByPropertyByKeyRaw(requestParameters: GetByPropertyByKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SystemPropertyDto>> {
        if (requestParameters.property === null || requestParameters.property === undefined) {
            throw new runtime.RequiredError('property','Required parameter requestParameters.property was null or undefined when calling getByPropertyByKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/properties/{property}`.replace(`{${"property"}}`, encodeURIComponent(String(requestParameters.property))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SystemPropertyDtoFromJSON(jsonValue));
    }

    /**
     * Get a particular system property
     * Get Property
     */
    async getByPropertyByKey(requestParameters: GetByPropertyByKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SystemPropertyDto> {
        const response = await this.getByPropertyByKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all registered properties
     * Get All
     */
    async getPropertiesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SystemPropertyDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/properties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SystemPropertyDtoFromJSON));
    }

    /**
     * List all registered properties
     * Get All
     */
    async getProperties(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SystemPropertyDto>> {
        const response = await this.getPropertiesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update a list of properties
     * Update multiple
     */
    async updatePropertiesRaw(requestParameters: UpdatePropertiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.systemPropertyDto === null || requestParameters.systemPropertyDto === undefined) {
            throw new runtime.RequiredError('systemPropertyDto','Required parameter requestParameters.systemPropertyDto was null or undefined when calling updateProperties.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/properties`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.systemPropertyDto.map(SystemPropertyDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a list of properties
     * Update multiple
     */
    async updateProperties(requestParameters: UpdatePropertiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePropertiesRaw(requestParameters, initOverrides);
    }

    /**
     * Update some property
     * Update
     */
    async updateSystemPropertyRaw(requestParameters: UpdateSystemPropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.property === null || requestParameters.property === undefined) {
            throw new runtime.RequiredError('property','Required parameter requestParameters.property was null or undefined when calling updateSystemProperty.');
        }

        if (requestParameters.systemPropertyUpdateDto === null || requestParameters.systemPropertyUpdateDto === undefined) {
            throw new runtime.RequiredError('systemPropertyUpdateDto','Required parameter requestParameters.systemPropertyUpdateDto was null or undefined when calling updateSystemProperty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/properties/{property}`.replace(`{${"property"}}`, encodeURIComponent(String(requestParameters.property))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SystemPropertyUpdateDtoToJSON(requestParameters.systemPropertyUpdateDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update some property
     * Update
     */
    async updateSystemProperty(requestParameters: UpdateSystemPropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSystemPropertyRaw(requestParameters, initOverrides);
    }

}
