import React, { type FunctionComponent } from 'react';
import { RuleDefinitionBaseProps, RuleDefinitionStringValue, useValidation } from './RuleDefinitionBaseProps';
import { ItemPredicate, ItemRenderer, MultiSelect } from '@blueprintjs/select';
import { MenuItem, NonIdealState } from '@blueprintjs/core';
import { testAttribute } from '../../../../../../util/Util';
import { Selectable } from '../../../../../DunningSelectionPage/DunningSelection/input/InputInterfaces';
import i18n from '../../../../../../i18n/i18n';
import { Tag } from '../../../../../../lib/Tag';

interface RuleDefinitionSelectInputProps extends RuleDefinitionBaseProps {}

const RuleDefinitionMultiSelectInput: FunctionComponent<RuleDefinitionSelectInputProps> = ({
  value,
  onChange,
  isEditable,
  inputProps,
  validators,
}) => {
  const [validatedOnChange, error] = useValidation(onChange, value, validators);

  if (!value || !(value instanceof RuleDefinitionStringValue)) return <></>;
  const selectables: Selectable[] = inputProps?.selectables ?? [];
  const currentOptions: Selectable[] = value
    .toValue()
    .split(',')
    .filter((s: string) => s.length)
    .map((key: string) => selectables.find(selectable => selectable.key === key));

  function onArgumentSelect(argument: Selectable) {
    if (currentOptions.map(k => k.key).includes(argument.key)) {
      validatedOnChange(
        new RuleDefinitionStringValue(
          currentOptions
            .filter(opt => opt.key !== argument.key)
            .map(opt => opt.key)
            .join(','),
        ),
      );
    } else {
      currentOptions.push(argument);
      validatedOnChange(new RuleDefinitionStringValue(currentOptions.map(opt => opt.key).join(',')));
    }
  }

  const filterList: ItemPredicate<Selectable> = (query, selectable: Selectable, _index, exactMatch) => {
    const normalizedSelectable = selectable.label.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedSelectable === normalizedQuery;
    }
    return normalizedSelectable.includes(query.toLowerCase());
  };

  const renderList: ItemRenderer<Selectable> = (element, { modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        data-test-id={testAttribute('rlfn', 'select-general', `${element}`)}
        id={`select-general-${element}`}
        active={modifiers.active}
        key={element.key}
        onClick={() => onArgumentSelect(element)}
        text={element.label}
      />
    );
  };

  if (!isEditable) {
    return (
      <span
        style={{
          display: 'flex',
          gap: '4px',
        }}>
        {currentOptions.map((item: Selectable) => (
          <Tag>{item.label}</Tag>
        ))}
      </span>
    );
  }

  return (
    <MultiSelect<Selectable>
      className={error ? "bp5-intent-danger" : ""}
      fill
      tagRenderer={item => item?.label}
      items={selectables}
      onRemove={onArgumentSelect}
      selectedItems={currentOptions}
      itemPredicate={filterList}
      itemRenderer={renderList}
      onItemSelect={operator => onArgumentSelect(operator)}
      popoverProps={{ position: 'bottom' }}
      noResults={<NonIdealState description={<span>{i18n.t('action-page.no-data')}</span>} />}
    />
  );
};

export default React.memo(RuleDefinitionMultiSelectInput);
