import React, {type FunctionComponent, ReactElement} from 'react';

import { NumericInput } from '@blueprintjs/core';

import i18n from 'i18n/i18n';

import {handleNumberOnly, isNumericValue, testAttribute} from '../../../../../../util/Util';
import {
  RuleDefinitionBaseProps,
  RuleDefinitionComputedValue,
  RuleDefinitionNumericValue,
  RuleDefinitionParamValue,
  useValidation,
} from './RuleDefinitionBaseProps';
import RuleInputVariableSelect from './RuleInputVariableSelect/RuleInputVariableSelect';

interface RuleDefinitionIntegerProps extends RuleDefinitionBaseProps {}

const RuleDefinitionIntegerInput: FunctionComponent<RuleDefinitionIntegerProps> = props => {
  let { value, onChange, label, isEditable, availableVariables, validators, inputProps } = props;
  const [validatedOnChange, error] = useValidation(onChange, value, validators);
  if (!value) {
    value = new RuleDefinitionNumericValue('');
  }
  // Append value of predefined variable on left element
  const editedAvailableVariables = availableVariables?.map(k => {
    if (k.hasOwnProperty('value')) {
      const entries = Object.entries(k);
      let value;
      for (const entry of entries) {
        if (entry[0] === 'value') {
          value = entry[1];
        }
      }
      return {
        key: k.key,
        label: `${k.label} (${value})`,
      };
    }
    return k;
  })

  // if the set value is a predefined parameter, append its value on display
  let formattedValue = value.toValue();
  if (!isNumericValue(formattedValue)) {
    availableVariables?.forEach(k => {
      if (k.hasOwnProperty('value') && k.label === formattedValue) {
        const entries = Object.entries(k);
        for (const entry of entries) {
          if (entry[0] === 'value') {
            formattedValue = `${k.label} (${entry[1]})`;
          }
        }
      }
    })
  }

  if (!isEditable) {
    return (
      <>
        <span>{label}</span>
        <span>{formattedValue}</span>
      </>
    );
  }
  const isPlaceholder = value instanceof RuleDefinitionComputedValue || value instanceof RuleDefinitionParamValue;
  let dynamicProps = {};
  if (!inputProps?.disableVariables) {
    dynamicProps = {
      leftElement: (
        <>
          {editedAvailableVariables && (
            <RuleInputVariableSelect
              selectables={editedAvailableVariables}
              isEditable={isEditable}
              onChange={v => validatedOnChange(v.key)}
            />
          )}
        </>
      ),
    };
  }

  return (
    <>
      {label}
      <NumericInput
        buttonPosition="none"
        minorStepSize={null}
        stepSize={1}
        type={isPlaceholder ? 'string' : 'number'}
        className="input-width-number"
        data-test-id={testAttribute('adi3', 'action-definition-integer-input')}
        placeholder={i18n.t('dialog.rule.add-value')}
        value={formattedValue}
        onKeyDown={e => {
          if ([',', '.'].includes(e.key)) e.preventDefault();
          handleNumberOnly(e);
        }}
        intent={error ? 'danger' : undefined}
        onChange={event => validatedOnChange(new RuleDefinitionNumericValue(event.target.value))}
        {...dynamicProps}
      />
    </>
  );
};

export default React.memo(RuleDefinitionIntegerInput);
