import { type FunctionComponent } from 'react';

import { type ColumnDefinition, ColumnModifier } from './types/ColumnDefinition';

interface TableCellProps<T> {
  column: ColumnDefinition<T>;
  rowItem: T;
}

export const TableCell: FunctionComponent<TableCellProps<any>> = ({ rowItem, column }) => {
  function createClasses() {
    const classList = ['lib-table__table-cell'];
    if (column.modifiers?.includes(ColumnModifier.FIT_CONTENT)) classList.push('lib-table__table-cell--fit-content');
    if (column.modifiers?.includes(ColumnModifier.FILL)) classList.push('lib-table__table-cell--fill');
    if (column.modifiers?.includes(ColumnModifier.SMALL)) classList.push('lib-table__table-cell--small');
    if (column.modifiers?.includes(ColumnModifier.CENTERED)) classList.push('lib-table__table-cell--centered');
    return classList.join(' ');
  }

  function getContent() {
    const value = column.accessor(rowItem);
    if (column.renderer) return column.renderer(value, rowItem);
    return value;
  }

  return <td className={createClasses()}>{getContent()}</td>;
};

export default TableCell;
