import { type FunctionComponent } from 'react';

import i18n from 'i18n/i18n';
import { type DunningSelectionForecast } from 'interfaces/Interfaces';

interface DunningForecastProps {
  forecast: DunningSelectionForecast;
}

const DunningForecastTable: FunctionComponent<DunningForecastProps> = ({ forecast }) => {
  return (
    <>
      <p className="card-subtitle">
        {i18n.t('dunning-selection.forecast.forecastedFor', {
          date: new Date(forecast.forecastedDate).toLocaleDateString(i18n.language),
        })}
      </p>
      <table>
        <thead>
          <tr>
            <th>{i18n.t('dunning-selection.forecast.actions')}</th>
            <th>{i18n.t('dunning-selection.forecast.total')}</th>
            <th>{i18n.t('dunning-selection.forecast.selected')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{i18n.t('dunning-selection.forecast.notifications')}</td>
            <td>{forecast.withoutDunningSelection.notifications}</td>
            <td>{forecast.withDunningSelection.notifications}</td>
          </tr>
          <tr>
            <td>{i18n.t('dunning-selection.forecast.disconnectionThreats')}</td>
            <td>{forecast.withoutDunningSelection.disconnectionsThreat}</td>
            <td>{forecast.withDunningSelection.disconnectionsThreat}</td>
          </tr>
          <tr>
            <td>{i18n.t('dunning-selection.forecast.disconnectionAnnouncements')}</td>
            <td>{forecast.withoutDunningSelection.disconnectionsAnnouncement}</td>
            <td>{forecast.withDunningSelection.disconnectionsAnnouncement}</td>
          </tr>
          <tr>
            <td>{i18n.t('dunning-selection.forecast.disconnections')}</td>
            <td>{forecast.withoutDunningSelection.disconnections}</td>
            <td>{forecast.withDunningSelection.disconnections}</td>
          </tr>
          <tr>
            <td>{i18n.t('dunning-selection.forecast.terminations')}</td>
            <td>{forecast.withoutDunningSelection.contractTermination}</td>
            <td>{forecast.withDunningSelection.contractTermination}</td>
          </tr>
          <tr>
            <td>{i18n.t('dunning-selection.forecast.totalActions')}</td>
            <td>{forecast.withoutDunningSelection.total}</td>
            <td>{forecast.withDunningSelection.total}</td>
          </tr>
          <tr>
            <td>{i18n.t('dunning-selection.forecast.totalOpenAmount')}</td>
            <td>{forecast.withoutDunningSelection.totalOpenAmount} €</td>
            <td>{forecast.withDunningSelection.totalOpenAmount} €</td>
          </tr>
        </tbody>
      </table>
      <p className="card-description">{i18n.t('dunning-selection.forecast.description')}</p>
    </>
  );
};

export { DunningForecastTable };
