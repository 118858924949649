/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuleSetDunningLevelDto
 */
export interface RuleSetDunningLevelDto {
    /**
     * The Level name
     * @type {string}
     * @memberof RuleSetDunningLevelDto
     */
    name: string;
    /**
     * The number of level
     * @type {number}
     * @memberof RuleSetDunningLevelDto
     */
    level: number;
    /**
     * The description of level
     * @type {string}
     * @memberof RuleSetDunningLevelDto
     */
    description?: string;
    /**
     * Id of dunning level
     * @type {string}
     * @memberof RuleSetDunningLevelDto
     */
    id: string;
}

/**
 * Check if a given object implements the RuleSetDunningLevelDto interface.
 */
export function instanceOfRuleSetDunningLevelDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "level" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function RuleSetDunningLevelDtoFromJSON(json: any): RuleSetDunningLevelDto {
    return RuleSetDunningLevelDtoFromJSONTyped(json, false);
}

export function RuleSetDunningLevelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetDunningLevelDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'level': json['level'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
    };
}

export function RuleSetDunningLevelDtoToJSON(value?: RuleSetDunningLevelDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'level': value.level,
        'description': value.description,
        'id': value.id,
    };
}

