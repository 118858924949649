import {
  RuleDefinitionComputedValue,
  RuleDefinitionParamValue,
  RuleDefinitionValue,
} from '../../RuleDefinition/RuleCondition/RuleInputs/RuleDefinitionBaseProps';

export const greaterOrEqualsZeroFactory = (message: string) => (rdf: RuleDefinitionValue) => {
  if (rdf instanceof RuleDefinitionComputedValue || rdf instanceof RuleDefinitionParamValue) return null;
  const value = rdf.toValue();
  if (!(value === undefined || Number.isNaN(value) || value >= 0)) {
    return {
      error: true,
      message,
    };
  }
  return null;
};
