import { type FunctionComponent, type ReactNode } from 'react';

import i18n from 'i18n/i18n';
import { type ExecutionFilter } from 'interfaces/Interfaces';
import { Tag } from 'lib/Tag';

interface ExecutionFilterTagsProps {
  filters: ExecutionFilter;
  onRemove?: (filterKey: string) => void;
}

const ExecutionFilterTags: FunctionComponent<ExecutionFilterTagsProps> = ({ filters, onRemove }) => {
  function createTag(filterKey: string, content: ReactNode) {
    if (onRemove) {
      return (
        <Tag onRemove={() => onRemove(filterKey)} key={filterKey}>
          {content}
        </Tag>
      );
    }
    return <Tag key={filterKey}>{content}</Tag>;
  }

  return (
    <div className="fill flex-row flex-wrap">
      {filters.contractId !== undefined &&
        createTag(
          'contractId',
          <>
            {i18n.t('action-page.filters.contractId')}: {filters.contractId}
          </>,
        )}
      {filters.customerId !== undefined &&
        createTag(
          'customerId',
          <>
            {i18n.t('action-page.filters.customerId')}: {filters.customerId}
          </>,
        )}
      {filters.executedAfter &&
        createTag(
          'executedAfter',
          <>
            {i18n.t('action-page.filters.executedAfter')}:{' '}
            {new Date(filters.executedAfter).toLocaleDateString(i18n.language)}
          </>,
        )}
      {filters.executedBefore &&
        createTag(
          'executedBefore',
          <>
            {i18n.t('action-page.filters.executedBefore')}:{' '}
            {new Date(filters.executedBefore).toLocaleDateString(i18n.language)}
          </>,
        )}
      {filters.actionTypes &&
        createTag(
          'actionTypes',
          <>
            {i18n.t('action-page.actionType')}:{' '}
            {filters.actionTypes?.map(action => i18n.t(`select.${action}`)).join(', ')}
          </>,
        )}
      {filters.dunningLevels &&
        createTag(
          'dunningLevels',
          <>
            {i18n.t('action-page.dunningLevel')}: {filters.dunningLevels?.map(level => level).join(', ')}
          </>,
        )}
      {filters.ruleSetNames &&
        createTag(
          'ruleSetNames',
          <>
            {i18n.t('action-page.Procedure')}: {filters.ruleSetNames?.map(ruleSet => ruleSet).join(', ')}
          </>,
        )}
    </div>
  );
};

export default ExecutionFilterTags;
