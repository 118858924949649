import { type FunctionComponent } from 'react';

import { Classes, Dialog } from '@blueprintjs/core';

import i18n from 'i18n/i18n';
import { type ArchivedRuleSet } from 'interfaces/Interfaces';
import { Button } from 'lib/Button';

import { testAttribute } from '../../../util/Util';

import './ArchiveRulesetDialog.style.sass';

interface ArchivedRuleSetDialogProps {
  open?: boolean;
  ruleset?: ArchivedRuleSet;
  onClose: () => void;
}

export const ArchivedRuleSetDialog: FunctionComponent<ArchivedRuleSetDialogProps> = ({
  open = false,
  ruleset,
  onClose,
}) => {
  return (
    <Dialog className="dialog archive-dialog" isOpen={open} canOutsideClickClose onClose={onClose}>
      <div className="dialog-title"> {ruleset?.name}</div>
      <span className={Classes.DIALOG_HEADER}>
        {ruleset?.archivedBy} - {ruleset?.archived && new Date(ruleset?.archived).toLocaleDateString(i18n.language)}
      </span>
      <pre className={Classes.DIALOG_BODY}>{ruleset?.representation}</pre>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button testId={testAttribute('bona', 'ok')} type="primary" onClick={onClose}>
            Ok
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ArchivedRuleSetDialog;
