import { type FunctionComponent } from 'react';

import './Radio.style.sass';

interface DunningRadioButtonProps {
  isChecked: boolean;
  label: string;
  testId: string;
  disabled?: boolean;
  onClick: () => void;
}

const RadioButton: FunctionComponent<DunningRadioButtonProps> = (props: DunningRadioButtonProps) => {
  function getButton(): string {
    if (props.disabled) {
      return props.isChecked
        ? 'dunning-radio-checked dunning-radio-disabled'
        : 'dunning-radio-unchecked dunning-radio-disabled';
    }
    return props.isChecked ? 'dunning-radio-checked' : 'dunning-radio-unchecked';
  }

  function getOutsideLayer(): string {
    if (props.disabled) {
      return props.isChecked
        ? 'radio-button-checked radio-button-disabled'
        : 'radio-button-unchecked radio-button-disabled';
    }
    return props.isChecked ? 'radio-button-checked' : 'radio-button-unchecked';
  }

  return (
    <div
      className="dunning-radio-container pointer padding-bottom padding-right"
      data-test-id={props.testId}
      onClick={() => !props.disabled && props.onClick()}>
      <div className={getOutsideLayer()}>
        <div className={getButton()} />
      </div>

      <div className={props.disabled ? 'dunning-radio-disabled' : 'dunning-radio-enabled'}>{props.label}</div>
    </div>
  );
};

export default RadioButton;
