import React, { FunctionComponent } from 'react';

import { testAttribute } from '../../util/Util';

interface LinkRendererProps {
  items: string | string[];
  baseUrl: (item: string) => string;
  separator?: string;
  testId?: string;
}

const LinkRenderer: FunctionComponent<LinkRendererProps> = ({ items, baseUrl, separator = ', ', testId = '123' }) => {
  let linkItems: string[];
  if (typeof items === 'string' && items.includes(',')) {
    linkItems = items.split(',').map(item => item.trim());
  } else if (Array.isArray(items)) {
    linkItems = items;
  } else {
    linkItems = [items];
  }

  return (
    <>
      {linkItems.map((item, index) => (
        <span key={item}>
          <a
            data-test-id={testAttribute('lklt', item, `${testId}`)}
            href={baseUrl(item)}
            target="_blank"
            rel="noreferrer">
            {item}
          </a>
          {linkItems.length > 1 && index < linkItems.length - 1 && separator}
        </span>
      ))}
    </>
  );
};

export { LinkRenderer };
