import React, { type FunctionComponent } from 'react';

import { Button, MenuItem } from '@blueprintjs/core';
import { Search, Variable } from '@blueprintjs/icons';
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';

import i18n from 'i18n/i18n';
import { testAttribute } from 'util/Util';
import { Selectable } from '../../../../../../DunningSelectionPage/DunningSelection/input/InputInterfaces';

interface RuleInputVariableSelectProps {
  isEditable?: boolean;
  selectables: { key: any; label: string }[];
  onChange: (value: { key: any; label: string }) => void;
}

const RuleInputVariableSelect: FunctionComponent<RuleInputVariableSelectProps> = (
  props: RuleInputVariableSelectProps,
) => {
  const renderList: ItemRenderer<Selectable> = (element, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        className="render"
        data-test-id={testAttribute('rlas', 'select-argument', `${element.key}`)}
        active={modifiers.active}
        key={JSON.stringify(element.key)}
        onClick={handleClick}
        text={element.label}
      />
    );
  };

  const filterList: ItemPredicate<Selectable> = (query, operator, _index, exactMatch) => {
    const normalizedTitle = operator.toString().toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    }
    return operator.toString().toLowerCase().includes(query.toLowerCase());
  };

  return (
    <Select<Selectable>
      itemPredicate={filterList}
      inputProps={{
        leftElement: <Search />,
        placeholder: i18n.t('dialog.rule.search-parameter'),
      }}
      items={props.selectables}
      itemRenderer={renderList}
      onItemSelect={props.onChange}
      filterable
      popoverProps={{ position: 'bottom' }}
      disabled={!props.isEditable}>
      <Button
        minimal
        data-test-id={testAttribute('xxa0', 'dialog.rule.search-parameter')}
        id="rule-argument-param-select"
        icon={props.isEditable ? <Variable /> : undefined}
      />
    </Select>
  );
};

export default RuleInputVariableSelect;
