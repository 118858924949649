import React, { type FunctionComponent, useEffect, useState } from 'react';

import { Card, Elevation, FormGroup, InputGroup } from '@blueprintjs/core';

import i18n from '../../i18n/i18n';
import { type SystemProperty, SystemPropertyType } from '../../interfaces/Interfaces';
import { Button } from '../../lib/Button';
import { Switch } from '../../lib/Switch';
import { getSystemProperties, updateSystemProperties } from '../../services/ApiService';
import { testAttribute } from '../../util/Util';
import { type Validator, Validators } from '../DunningSelectionPage/DunningSelection/input/Validators';
import { alertToast } from '../Toast/AlertToast';

import './SettingsPage.style.sass';
import DialogBox from "../Dialog/BasicDialog/DialogBox";
import {useCallbackPrompt} from "../../hooks/UseCallbackPrompt";

function sortSystemProperties(sp: SystemProperty[]) {
  return sp.sort((a, b) => a.property.toLowerCase().localeCompare(b.property.toLowerCase()));
}

const SettingsPage: FunctionComponent = () => {
  const [inputFields, setInputFields] = useState<SystemProperty[]>([]);
  const [errorIndex, setErrorIndex] = useState<Record<number, boolean>>({});
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  useEffect(() => {
    getSystemProperties()
      .then(sp => {
        setInputFields(sortSystemProperties(sp));
        setErrorIndex(new Array(sp.length).fill(0));
        setErrorMessage(new Array(sp.length).fill(null));
      })
      .catch(alertToast);
  }, []);

  function onSubmitProperties() {
    updateSystemProperties(inputFields)
      .then(() => {
        setSaveButtonDisabled(true);
        setShowDialog(false);
      })
      .catch(e => alertToast(e.error));
  }

  async function validateInput(input: string, validators: Validator[], index: number) {
    if (validators != null) {
      const validationResult = await Promise.all(validators.map(validator => validator(input)));
      const error = validationResult.find(result => result?.error);
      if (error) {
        errorIndex[index] = true;
        setErrorIndex({ ...errorIndex });
        errorMessage[index] = error.message ?? '';
        setErrorMessage([...errorMessage]);
      } else {
        errorIndex[index] = false;
        setErrorIndex({ ...errorIndex });
        errorMessage[index] = '';
        setErrorMessage([...errorMessage]);
      }
    }
  }

  function propertiesAreInvalid() {
    return Object.entries(errorIndex).some(entry => entry[1]);
  }

  async function onChangeHandler(value: string, index: number, validators: Validator[]) {
    setShowDialog(true);
    await validateInput(value, validators, index);
    inputFields[index].value = value;
    setInputFields([...inputFields]);
    setSaveButtonDisabled(false);
  }

  function isRequiredField(sp: SystemProperty) {
    return sp.requiredField;
  }

  return (
    <div className="settings">
      <DialogBox showDialog={showPrompt} confirmNavigation={confirmNavigation} cancelNavigation={cancelNavigation} />
      <div className="title">
        <div className="flex-between">
          {i18n.t('title_settings')}
          <Button
            testId={testAttribute('4b4c', 'settings.save')}
            onClick={() => onSubmitProperties()}
            disabled={saveButtonDisabled || propertiesAreInvalid()}>
            {i18n.t('dunning-selection.save')}
          </Button>
        </div>
      </div>
      <Card className="settings-card" interactive={false} elevation={Elevation.ONE}>
        <form className="form pc-properties">
          {inputFields.map((sp, index) => (
            <div key={`pc-container-${sp.property}`} className="property-container" id={`pc-container-${index}`}>
              {sp.type === SystemPropertyType.BOOLEAN && (
                <FormGroup
                  className={`pc-form-group-${index}`}
                  label={<p>{i18n.t(sp.property)}</p>}
                  labelFor={sp.property}>
                  <Switch
                    data-test-id={testAttribute('stti', `settings-switch-${index}`)}
                    isChecked={sp.value === 'true'}
                    onChange={e => onChangeHandler(String(e), index, isRequiredField(sp) ? [Validators.required] : [])}
                  />
                </FormGroup>
              )}
              {sp.type === SystemPropertyType.INTEGER && (
                <FormGroup
                  className={`pc-form-group-${index}`}
                  label={<p>{i18n.t(sp.property)}</p>}
                  labelFor={sp.property}
                  helperText={
                    errorMessage[index] !== null && errorMessage[index] !== '' && errorIndex[index] ? (
                      <span className="red-text">{i18n.t(errorMessage[index])}</span>
                    ) : undefined
                  }>
                  <InputGroup
                    data-test-id={testAttribute('stti', `settings-property-${index}`)}
                    defaultValue={sp.value === '-1' ? '' : sp.value}
                    name={sp.property}
                    type="number"
                    fill
                    onChange={event =>
                      onChangeHandler(
                        isRequiredField(sp) || event.target.value !== '' ? event.target.value : '-1',
                        index,
                        isRequiredField(sp) ? [Validators.required, Validators.gtInteger] : [Validators.gtInteger],
                      )
                    }
                    intent={errorIndex[index] ? 'danger' : undefined}
                  />
                </FormGroup>
              )}
              {sp.type === SystemPropertyType.STRING && (
                <FormGroup
                  className={`pc-form-group-${index}`}
                  label={<p>{i18n.t(sp.property)}</p>}
                  labelFor={sp.property}
                  helperText={
                    errorMessage[index] !== null && errorMessage[index] !== '' ? (
                      <span className="red-text">{i18n.t(errorMessage[index])}</span>
                    ) : undefined
                  }>
                  <InputGroup
                    data-test-id={testAttribute('stti', `settings-property-${index}`)}
                    defaultValue={sp.value}
                    name={sp.property}
                    type="text"
                    fill
                    onChange={event =>
                      onChangeHandler(
                        isRequiredField(sp) || event.target.value !== null ? event.target.value : '',
                        index,
                        isRequiredField(sp) ? [Validators.required] : [],
                      )
                    }
                    intent={errorIndex[index] ? 'danger' : undefined}
                  />
                </FormGroup>
              )}

              {sp.type === SystemPropertyType.DECIMAL && (
                <FormGroup
                  className={`pc-form-group-${index}`}
                  label={<p>{i18n.t(sp.property)}</p>}
                  labelFor={sp.property}
                  helperText={
                    errorMessage[index] !== null && errorMessage[index] !== '' && errorIndex[index] ? (
                      <span className="red-text">{i18n.t(errorMessage[index])}</span>
                    ) : undefined
                  }>
                  <InputGroup
                    data-test-id={testAttribute('stti', `settings-property-${index}`)}
                    defaultValue={sp.value === '-1' ? '' : sp.value}
                    name={sp.property}
                    type="number"
                    fill
                    onChange={event =>
                      onChangeHandler(
                        isRequiredField(sp) || event.target.value !== '' ? event.target.value : '-1',
                        index,
                        isRequiredField(sp) ? [Validators.required] : [],
                      )
                    }
                    intent={errorIndex[index] ? 'danger' : undefined}
                  />
                </FormGroup>
              )}
            </div>
          ))}
        </form>
      </Card>
    </div>
  );
};
export default SettingsPage;
