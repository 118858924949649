import { type FunctionComponent, useState } from 'react';

import { Icon } from '@blueprintjs/core';
import { ChevronDown, ChevronRight } from '@blueprintjs/icons';

import { type ColumnDefinition, ColumnModifier } from './types/ColumnDefinition';
import { type TableCellRenderer, type TableRowExpansionRenderer } from './types/TableRenderer';
import TableCell from './TableCell';

interface TableRowProps<T> {
  columns: Array<ColumnDefinition<T>>;
  rowItem: T;
  expansionRenderer?: TableRowExpansionRenderer<T>;
  cellRenderer?: TableCellRenderer<T>;
}

export const TableRow: FunctionComponent<TableRowProps<any>> = ({
  rowItem,
  columns,
  expansionRenderer,
  cellRenderer,
  ...passthroughs
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <tr {...passthroughs} onClick={() => setExpanded(!expanded)} className="lib-table__table-row">
        {expansionRenderer && (
          <TableCell
            rowItem={rowItem}
            column={{
              headerName: '',
              modifiers: [ColumnModifier.FIT_CONTENT, ColumnModifier.SMALL, ColumnModifier.CENTERED],
              accessor: () => expanded,
              renderer: () => <Icon icon={expanded ? <ChevronDown /> : <ChevronRight />} />,
            }}
          />
        )}
        {columns.map((col, idx) =>
          cellRenderer ? cellRenderer(rowItem, col) : <TableCell column={col} rowItem={rowItem} key={idx} />,
        )}
      </tr>
      {expanded && expansionRenderer && (
        <tr>
          <td colSpan={columns.length + 1}>{expansionRenderer(rowItem)}</td>
        </tr>
      )}
    </>
  );
};

export default TableRow;
