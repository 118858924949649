import { type ReactNode } from 'react';

export enum FilterType {
  FIXED,
  DYNAMIC,
}

export type FilterRenderer<T> = (opts: { value: T; onChange: (newValue: T) => void; label?: string }) => ReactNode;

export interface Filter<T = any> {
  key: string;
  name: string;
  render: FilterRenderer<T>;
  valueRenderer?: (value: T) => string;
  type: FilterType;
}
