import React from 'react';

import { Plus, Trash } from '@blueprintjs/icons';

import { Param } from 'interfaces/Interfaces';
import { Button } from 'lib/Button';
import { testAttribute } from 'util/Util';

import i18n from '../../../../../../i18n/i18n';
import { ValidationResult } from '../../../../../DunningSelectionPage/DunningSelection/input/Validators';
import { RuleConditionInputs } from '../RuleConditionInputs/RuleConditionInputs';
import { RuleConditionDefinition } from '../RuleConditionTypes';
import RuleConditionTypeSelect from '../RuleConditionTypeSelect/RuleConditionTypeSelect';

import './RuleCondtions.style.sass';

interface RuleConditionGroupProps<O extends { reactId: any }, T> {
  conditionTypes: Array<{ type: T; name: string }>;
  conditions: O[];
  availableParameters: Param[];
  isEditable: boolean;
  onChange: (value: O[], validation: Record<string, Record<string, ValidationResult[] | undefined>>) => void;
  definitions: Record<any, RuleConditionDefinition>;
  newConditionFactory: () => O;
  typeSetter: (object: O, type: T) => void;
  typeGetter: (object: O) => T | '';
  addTranslationKey?: string;
  section: string;
}

function RuleConditions<O extends { reactId: any }, T>({
  conditions,
  onChange,
  isEditable,
  conditionTypes,
  newConditionFactory,
  definitions,
  availableParameters,
  typeSetter,
  typeGetter,
  addTranslationKey = 'dialog.rule.add-condition',
  section,
}: RuleConditionGroupProps<O, T>) {
  const conditionInputManipulationHandler = (
    condition: O,
    validationResults: Record<any, ValidationResult[] | undefined>,
  ) => {
    onChange(conditions, { [condition.reactId ?? '']: validationResults });
  };

  const addCondition = () => {
    const newCondition = newConditionFactory();
    onChange([...conditions, newCondition], {
      [newCondition.reactId]: {
        type: [
          {
            error: true,
            message: 'error.rule-editor.aggThis',
          },
        ],
      },
    });
  };

  const deleteCondition = (id?: string) => {
    onChange(
      conditions.filter(c => c.reactId !== id),
      { [id ?? '']: {} },
    );
  };

  const updateConditionTypeHandlerFactory = (condition: O) => (newValue: T) => {
    const newCond: O = newConditionFactory();
    typeSetter(newCond, newValue);
    onChange(
      conditions.map(cond => (cond.reactId == condition.reactId ? newCond : cond)),
      { [newCond.reactId ?? '']: {} },
    );
  };

  return (
    <>
      {conditions?.map((condition: O, index) => {
        return (
          <div
            id={`condition-${condition.reactId}`}
            className="rule-condition-row"
            data-test-id={testAttribute('rlsf', section, `${index}`)}
            key={condition.reactId}>
            <RuleConditionTypeSelect
              conditionType={typeGetter(condition)}
              conditionTypes={conditionTypes}
              isEditable={isEditable}
              onConditionTypeChange={updateConditionTypeHandlerFactory(condition)}
            />
            <div className="rule-condition-inputs">
              <RuleConditionInputs<O>
                availableParameters={availableParameters}
                definition={definitions[typeGetter(condition)]}
                condition={condition}
                onChange={conditionInputManipulationHandler}
                isEditable={isEditable}
              />
            </div>
            {isEditable && (
              <Button
                type="tertiary"
                id="trash-button"
                disabled={!isEditable}
                testId={testAttribute('lafi', 'trash', `${condition.reactId}`)}
                icon={<Trash />}
                onClick={() => deleteCondition(condition.reactId)}
              />
            )}
          </div>
        );
      })}
      <div className="right-text" hidden={!isEditable}>
        <Button
          type="tertiary"
          id="add-icon-case"
          icon={<Plus />}
          testId={testAttribute('lafi', 'plus', section)}
          onClick={addCondition}>
          {i18n.t(addTranslationKey)}
        </Button>
      </div>
    </>
  );
}

export default RuleConditions;
