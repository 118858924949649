import React, { type FunctionComponent } from 'react';

import { MenuItem } from '@blueprintjs/core';
import { Plus } from '@blueprintjs/icons';
import { type ItemPredicate, type ItemRenderer, Select } from '@blueprintjs/select';
import { nanoid } from 'nanoid';

import i18n from '../../../../i18n/i18n';
import { DunningLevel, DunningLevelForReact } from '../../../../interfaces/Interfaces';
import { Button } from '../../../../lib/Button';
import { generateClassNames, generateUUID, testAttribute } from '../../../../util/Util';

interface DunningLevelSelectInputProps {
  selectedLevel: DunningLevelForReact;
  availableLevels: DunningLevelForReact[];
  onChange: (value: DunningLevelForReact) => void;
  disabled: boolean;
  isInitialDunningLevel?: boolean;
}

const DunningLevelSelectInput: FunctionComponent<DunningLevelSelectInputProps> = props => {
  async function onChangeDunningLevel(val: DunningLevelForReact) {
    const dunningLevel = {
      ...val,
      level: props.selectedLevel.level,
    };
    dunningLevel.reactId = nanoid();
    if (props.onChange) props.onChange(dunningLevel);
  }

  const itemRendered: ItemRenderer<DunningLevelForReact> = (item: DunningLevelForReact, { modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        className="render"
        data-test-id={testAttribute('ahln', 'dialog.rule.dunning-level', `${item.id}`)}
        key={item.id}
        text={item.name}
        onClick={() => {
          onChangeDunningLevel(item);
        }}
      />
    );
  };
  function getClassName() {
    const selectDnLevelBtnClass = props.disabled ? 'bp-select-dn-level-disabled' : 'bp-select-dn-level';
    return generateClassNames(
      props.selectedLevel.name && selectDnLevelBtnClass,
      !props.selectedLevel.name && 'bp-select-dn-level-placeholder',
      !props.selectedLevel.isValid && 'danger',
    );
  }

  const buttonPlaceholder = props.isInitialDunningLevel ? '' : i18n.t('dialog.enter-name');
  const itemPredicate: ItemPredicate<DunningLevelForReact> = (query, dunningLevel) => {
    return dunningLevel.name.toString().toLowerCase().includes(query.toLowerCase());
  };

  function createDunningLevel(title: string): DunningLevelForReact {
    const dunningLevel = new DunningLevel();
    dunningLevel.id = generateUUID();
    dunningLevel.name = title;
    dunningLevel.level = props.selectedLevel.level;
    return new DunningLevelForReact(dunningLevel);
  }

  function renderCreateDunningLevel(query: string, active: boolean, handleClick: React.MouseEventHandler<HTMLElement>) {
    return (
      <MenuItem
        icon={<Plus />}
        text={i18n.t('dialog.ruleset.create-dn-level', { name: query })}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={false}
      />
    );
  }

  return (
    <div>
      <Select<DunningLevelForReact>
        inputProps={{
          placeholder: i18n.t('dialog.ruleset.search-dn-level'),
        }}
        className={props.disabled ? 'full-width-disabled' : 'full-width'}
        createNewItemFromQuery={createDunningLevel}
        createNewItemRenderer={renderCreateDunningLevel}
        items={props.availableLevels}
        itemRenderer={itemRendered}
        itemPredicate={itemPredicate}
        disabled={props.disabled}
        activeItem={props.selectedLevel}
        onItemSelect={res => onChangeDunningLevel(res)}
        filterable
        popoverProps={{ position: 'bottom' }}
        data-test-id={testAttribute('dnlv', 'dialog.enter-name', `${props.selectedLevel.level}`)}>
        <Button
          testId={testAttribute('dnlv', 'bp-select-dn-level-button', `${props.selectedLevel.level}`)}
          className={getClassName()}
          disabled={props.disabled}
          id="customer-case-attrib-button"
          type="default"
          fill>
          {props.selectedLevel.name ? props.selectedLevel.name : buttonPlaceholder}
        </Button>
      </Select>
    </div>
  );
};

export { DunningLevelSelectInput };
