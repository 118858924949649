/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DunningSelectionConfigurationDto
 */
export interface DunningSelectionConfigurationDto {
    /**
     * Dunning selection is active
     * @type {boolean}
     * @memberof DunningSelectionConfigurationDto
     */
    active?: boolean;
    /**
     * Execution type: ONCE, PERMANENT, WORKING_DAYS
     * @type {string}
     * @memberof DunningSelectionConfigurationDto
     */
    executionType: DunningSelectionConfigurationDtoExecutionTypeEnum;
    /**
     * The date this execution should be valid
     * @type {Date}
     * @memberof DunningSelectionConfigurationDto
     */
    planedExecutionDate?: Date;
    /**
     * Payment type: all, direct debit, self payer
     * @type {string}
     * @memberof DunningSelectionConfigurationDto
     */
    paymentType?: DunningSelectionConfigurationDtoPaymentTypeEnum;
    /**
     * Contract states: done,blocked,delivery_net,canceled,waiting,active,cancellation_previous,terminated,expired,open
     * @type {Set<string>}
     * @memberof DunningSelectionConfigurationDto
     */
    contractStates?: Set<DunningSelectionConfigurationDtoContractStatesEnum>;
    /**
     * The execution will be saved only if the procedure id assign to the contract is present in this dunningProcedures or dunningProcedures is empty.
     * @type {Set<string>}
     * @memberof DunningSelectionConfigurationDto
     */
    dunningProcedures?: Set<string>;
    /**
     * Max number of notification
     * @type {number}
     * @memberof DunningSelectionConfigurationDto
     */
    maxNotifications?: number;
    /**
     * Max number of contract terminations
     * @type {number}
     * @memberof DunningSelectionConfigurationDto
     */
    maxTerminations?: number;
    /**
     * Max number of threads
     * @type {number}
     * @memberof DunningSelectionConfigurationDto
     */
    maxThreats?: number;
    /**
     * Max number of contract announcements
     * @type {number}
     * @memberof DunningSelectionConfigurationDto
     */
    maxAnnouncements?: number;
    /**
     * Max number of contract disconnections
     * @type {number}
     * @memberof DunningSelectionConfigurationDto
     */
    maxDisconnection?: number;
    /**
     * If contract termination is triggered for an execution, the execution will be saved only if the procedure id is found in the terminationProcedures or terminationProcedures is empty
     * @type {Set<string>}
     * @memberof DunningSelectionConfigurationDto
     */
    terminationProcedures?: Set<string>;
    /**
     * 
     * @type {number}
     * @memberof DunningSelectionConfigurationDto
     */
    minOpenAmount?: number;
    /**
     * Rules that should be taken into account during execution. If empty, all rules will be applied
     * @type {Set<string>}
     * @memberof DunningSelectionConfigurationDto
     */
    rules?: Set<string>;
    /**
     * Exclude overdue receivables based on the due date limitation
     * @type {Date}
     * @memberof DunningSelectionConfigurationDto
     */
    dueDateLimitation?: Date;
}


/**
 * @export
 */
export const DunningSelectionConfigurationDtoExecutionTypeEnum = {
    Once: 'ONCE',
    Permanent: 'PERMANENT',
    WorkingDays: 'WORKING_DAYS'
} as const;
export type DunningSelectionConfigurationDtoExecutionTypeEnum = typeof DunningSelectionConfigurationDtoExecutionTypeEnum[keyof typeof DunningSelectionConfigurationDtoExecutionTypeEnum];

/**
 * @export
 */
export const DunningSelectionConfigurationDtoPaymentTypeEnum = {
    All: 'ALL',
    DirectDebit: 'DIRECT_DEBIT',
    SelfPayer: 'SELF_PAYER'
} as const;
export type DunningSelectionConfigurationDtoPaymentTypeEnum = typeof DunningSelectionConfigurationDtoPaymentTypeEnum[keyof typeof DunningSelectionConfigurationDtoPaymentTypeEnum];

/**
 * @export
 */
export const DunningSelectionConfigurationDtoContractStatesEnum = {
    Open: 'OPEN',
    Active: 'ACTIVE',
    Blocked: 'BLOCKED',
    Waiting: 'WAITING',
    Canceled: 'CANCELED',
    CancellationPrevious: 'CANCELLATION_PREVIOUS',
    DeliveryNet: 'DELIVERY_NET',
    Terminated: 'TERMINATED',
    Expired: 'EXPIRED',
    Done: 'DONE'
} as const;
export type DunningSelectionConfigurationDtoContractStatesEnum = typeof DunningSelectionConfigurationDtoContractStatesEnum[keyof typeof DunningSelectionConfigurationDtoContractStatesEnum];


/**
 * Check if a given object implements the DunningSelectionConfigurationDto interface.
 */
export function instanceOfDunningSelectionConfigurationDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "executionType" in value;

    return isInstance;
}

export function DunningSelectionConfigurationDtoFromJSON(json: any): DunningSelectionConfigurationDto {
    return DunningSelectionConfigurationDtoFromJSONTyped(json, false);
}

export function DunningSelectionConfigurationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DunningSelectionConfigurationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'executionType': json['executionType'],
        'planedExecutionDate': !exists(json, 'planedExecutionDate') ? undefined : (new Date(json['planedExecutionDate'])),
        'paymentType': !exists(json, 'paymentType') ? undefined : json['paymentType'],
        'contractStates': !exists(json, 'contractStates') ? undefined : json['contractStates'],
        'dunningProcedures': !exists(json, 'dunningProcedures') ? undefined : json['dunningProcedures'],
        'maxNotifications': !exists(json, 'maxNotifications') ? undefined : json['maxNotifications'],
        'maxTerminations': !exists(json, 'maxTerminations') ? undefined : json['maxTerminations'],
        'maxThreats': !exists(json, 'maxThreats') ? undefined : json['maxThreats'],
        'maxAnnouncements': !exists(json, 'maxAnnouncements') ? undefined : json['maxAnnouncements'],
        'maxDisconnection': !exists(json, 'maxDisconnection') ? undefined : json['maxDisconnection'],
        'terminationProcedures': !exists(json, 'terminationProcedures') ? undefined : json['terminationProcedures'],
        'minOpenAmount': !exists(json, 'minOpenAmount') ? undefined : json['minOpenAmount'],
        'rules': !exists(json, 'rules') ? undefined : json['rules'],
        'dueDateLimitation': !exists(json, 'dueDateLimitation') ? undefined : (new Date(json['dueDateLimitation'])),
    };
}

export function DunningSelectionConfigurationDtoToJSON(value?: DunningSelectionConfigurationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'executionType': value.executionType,
        'planedExecutionDate': value.planedExecutionDate === undefined ? undefined : (value.planedExecutionDate.toISOString().substring(0,10)),
        'paymentType': value.paymentType,
        'contractStates': value.contractStates === undefined ? undefined : Array.from(value.contractStates as Set<any>),
        'dunningProcedures': value.dunningProcedures === undefined ? undefined : Array.from(value.dunningProcedures as Set<any>),
        'maxNotifications': value.maxNotifications,
        'maxTerminations': value.maxTerminations,
        'maxThreats': value.maxThreats,
        'maxAnnouncements': value.maxAnnouncements,
        'maxDisconnection': value.maxDisconnection,
        'terminationProcedures': value.terminationProcedures === undefined ? undefined : Array.from(value.terminationProcedures as Set<any>),
        'minOpenAmount': value.minOpenAmount,
        'rules': value.rules === undefined ? undefined : Array.from(value.rules as Set<any>),
        'dueDateLimitation': value.dueDateLimitation === undefined ? undefined : (value.dueDateLimitation.toISOString().substring(0,10)),
    };
}

