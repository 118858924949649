import { type FunctionComponent, useState } from 'react';

import BasicDialog from 'components/Dialog/BasicDialog/BasicDialog';
import i18n from 'i18n/i18n';
import { IconRedo } from 'icons/svg/IconRedo';
import { type ExecutionFilter } from 'interfaces/Interfaces';
import { Button } from 'lib/Button';
import { getBulkRetryStatus } from 'services/ApiService';
import { removeUndefined, testAttribute } from 'util/Util';

import ExecutionFilterTags from '../ExecutionFilterTags/ExecutionFilterTags';

interface BulkRetryButtonProps {
  filters: ExecutionFilter;
  onConfirmedClick: () => void;
}

const BulkRetryButton: FunctionComponent<BulkRetryButtonProps> = props => {
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isRetryRunningDialogOpen, setRetryRunningDialogOpen] = useState(false);

  async function openConfirmDialog() {
    const status = await getBulkRetryStatus();
    if (status.isRunning) {
      setRetryRunningDialogOpen(true);
    } else {
      setConfirmDialogOpen(true);
    }
  }

  async function confirmBulkRetry() {
    props.onConfirmedClick();
    setConfirmDialogOpen(false);
  }

  function cancelBulkRetry() {
    setConfirmDialogOpen(false);
  }

  function isSelectionFiltered() {
    const filtersWithoutState = { ...props.filters };
    filtersWithoutState.states = undefined;
    return Object.keys(removeUndefined(filtersWithoutState)).length >= 1;
  }

  return (
    <>
      {isRetryRunningDialogOpen && (
        <BasicDialog
          title={i18n.t('action-page.bulk-retry-running-dialog.title')}
          cancelButtonText={i18n.t('ok')}
          disableConfirmButton
          onConfirm={() => setRetryRunningDialogOpen(false)}
          onCloseDialog={() => setRetryRunningDialogOpen(false)}>
          {i18n.t('action-page.bulk-retry-running-dialog.content')}
        </BasicDialog>
      )}
      {isConfirmDialogOpen && (
        <BasicDialog
          title={i18n.t('action-page.bulk-retry-dialog.title')}
          confirmButtonText={i18n.t('ok')}
          disableConfirmButton={false}
          onConfirm={() => confirmBulkRetry()}
          onCloseDialog={() => cancelBulkRetry()}>
          <div className="dunning-action-page">
            {isSelectionFiltered() ? (
              <>
                <p>{i18n.t('action-page.bulk-retry-dialog.content')}</p>
                <span className="flex">
                  <ExecutionFilterTags filters={props.filters} />
                </span>
              </>
            ) : (
              <p>{i18n.t('action-page.bulk-retry-dialog.content-no-condition')}</p>
            )}
          </div>
        </BasicDialog>
      )}
      <Button
        testId={testAttribute('Kd6F', 'action-page.bulk-retry')}
        tooltip={i18n.t('action-page.bulk-retry')}
        type="tertiary"
        icon={<IconRedo />}
        onClick={() => openConfirmDialog()}
      />
    </>
  );
};

export default BulkRetryButton;
