import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const translationsEn = require('./translations/en.json');
const translationsDe = require('./translations/de.json');

const translations = {
  ...translationsEn,
  ...translationsDe,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: translations,
    lng: getLanguageFromSettings(),
    fallbackLng: 'en',
  });

function getLanguageFromSettings() {
  const crmiLocale = (window as any).CrmiLocale || 'en_GB';
  const localeRegexp = /^([a-z]{2,})[_-][a-z]+/i;
  const findings = crmiLocale.match(localeRegexp);
  if (Array.isArray(findings) && findings.length >= 2) {
    return findings[1];
  }
  console.warn(
    "CRMI language settings '%s' do not match en_GB or de_DE format. Maybe something has changed.",
    crmiLocale,
  );

  return 'en';
}

export function getLanguageWithRegionTag() {
  return i18n.language === null || i18n.language === 'de' ? 'de-DE' : 'en-GB';
}

/**
 * Gets the fractional separator based on the current language set in the internationalization (i18n) library.
 *
 * @returns {string} The fractional separator character (`,` for German language or `.` for other languages).
 */
export function getLanguageFractionalSeparator() {
  return i18n.language === null || i18n.language === 'de' ? ',' : '.';
}

/**
 * Gets the thousands separator based on the current language set in the internationalization (i18n) library.
 *
 * @returns {string} The thousands separator character (`.` for German language or `,` for other languages).
 */
export function getLanguageThousandsSeparator() {
  return i18n.language === null || i18n.language === 'de' ? '.' : ',';
}

export default i18n;
