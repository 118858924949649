import React, { FunctionComponent } from 'react';

import { Icon, Popover, Tooltip } from '@blueprintjs/core';
import { Cog, DragHandleHorizontal, InfoSign } from '@blueprintjs/icons';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';

import i18n from 'i18n/i18n';
import { TABLE_TITLE } from 'interfaces/enums';
import { Button } from 'lib/Button';
import { testAttribute } from 'util/Util';

import '../OverviewHeader.style.sass';

interface MenuPopoverProps {
  activeHeaders: TABLE_TITLE[];
  inactiveHeaders: TABLE_TITLE[];
  onUpdateHeader: (active: TABLE_TITLE[], inactive: TABLE_TITLE[]) => void;
}

const MenuPopover: FunctionComponent<MenuPopoverProps> = (props: MenuPopoverProps) => {
  function onDragEnd(result: DropResult) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newActive = Array.from(props.activeHeaders);
    const newInactive = Array.from(props.inactiveHeaders);

    if (result.source.droppableId === 'droppable1') {
      const [removed] = newActive.splice(result.source.index, 1);
      result.destination.droppableId === 'droppable1'
        ? newActive.splice(result.destination.index, 0, removed)
        : newInactive.splice(result.destination.index, 0, removed);
    } else {
      const [removed] = newInactive.splice(result.source.index, 1);
      result.destination.droppableId === 'droppable1'
        ? newActive.splice(result.destination.index, 0, removed)
        : newInactive.splice(result.destination.index, 0, removed);
    }

    localStorage.setItem('activeTitle', JSON.stringify(newActive));
    props.onUpdateHeader(newActive, newInactive);
  }

  return (
    <Popover
      placement="bottom-end"
      content={
        <DragDropContext onDragEnd={e => onDragEnd(e)}>
          <div className="header-menu-grid">
            <div>
              <div className="dunning-bold-title">{i18n.t('dialog.active-columns')}</div>

              <div className="inner-grid">
                {i18n.t(TABLE_TITLE.NAME)}
                <Tooltip content={i18n.t('dialog.no-edit-columns')} position="bottom">
                  <Icon className="grey" icon={<InfoSign />} />
                </Tooltip>
              </div>
              <Droppable droppableId="droppable1">
                {(provided: DroppableProvided) => (
                  <div className="drop" ref={provided.innerRef} {...provided.droppableProps}>
                    {props.activeHeaders.map((title: string, index: number) => (
                      <div key={title}>
                        <Draggable draggableId={title} index={index}>
                          {(provided: DraggableProvided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <div className="inner-grid">
                                {i18n.t(title)}
                                <Icon icon={<DragHandleHorizontal />} />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      </div>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>

            <div>
              <div className="dunning-bold-title">{i18n.t('dialog.inactive-columns')}</div>
              <Droppable droppableId="droppable2">
                {(provided: DroppableProvided) => (
                  <div className="drop" ref={provided.innerRef} {...provided.droppableProps}>
                    {props.inactiveHeaders.map((title: string, index: number) => (
                      <div key={title}>
                        <Draggable draggableId={title} index={index}>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <div className="inner-grid">
                                {i18n.t(title)}
                                <Icon className="padding-left" icon={<DragHandleHorizontal />} />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      </div>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </DragDropContext>
      }>
      <Button testId={testAttribute('a6v6', 'cog')} type="tertiary" icon={<Cog />} onClick={() => undefined} />
    </Popover>
  );
};

export default MenuPopover;
