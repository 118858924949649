import { greaterOrEqualsZeroFactory } from './Validators/GreaterOrEqualsZeroValidator';
import { isSanitizedProvider } from './Validators/IsSanitizedValidator';
import { requiredValidatorProvider } from './Validators/RequiredInputValidator';
import { currencyValidatorProvider } from './Validators/CurrencyValidator';

export const RuleValidators = {
  required: requiredValidatorProvider('error.required_field'),
  validCurrency: currencyValidatorProvider('error.rule-editor.currency'),
  customer: {
    required: requiredValidatorProvider('error.rule-editor.customer-case'),
    sanitized: isSanitizedProvider('error.rule-editor.customer-case'),
    nonNegativeNumber: greaterOrEqualsZeroFactory('dunning-selection.error.negative_number'),
  },
  group: {
    required: requiredValidatorProvider('error.rule-editor.group'),
    sanitized: isSanitizedProvider('error.rule-editor.group'),
    nonNegativeNumber: greaterOrEqualsZeroFactory('dunning-selection.error.negative_number'),
  },
  case: {
    required: requiredValidatorProvider('error.rule-editor.case'),
    sanitized: isSanitizedProvider('error.rule-editor.case'),
    nonNegativeNumber: greaterOrEqualsZeroFactory('dunning-selection.error.negative_number'),
  },
  aggregatedThis: {
    required: requiredValidatorProvider('error.rule-editor.aggThis'),
    sanitized: isSanitizedProvider('error.rule-editor.aggThis'),
    nonNegativeNumber: greaterOrEqualsZeroFactory('dunning-selection.error.negative_number'),
  },
  aggregatedAll: {
    required: requiredValidatorProvider('error.rule-editor.aggAll'),
    sanitized: isSanitizedProvider('error.rule-editor.aggAll'),
  },
  action: {
    greaterOrEqualsZero: greaterOrEqualsZeroFactory('error.rule-editor.settlement_days'),
    required: requiredValidatorProvider('error.rule-editor.action'),
  },
};
