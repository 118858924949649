import { getRuleSet } from './ApiService';
import { type RuleSetForReact } from '../interfaces/Interfaces';

const ruleSetWithRulesCacheMap = new Map<string, Promise<RuleSetForReact>>();

export const getRuleSetWithRule = (ruleSetId: string): Promise<RuleSetForReact> | undefined => {
  if (!ruleSetWithRulesCacheMap.has(ruleSetId)) {
    ruleSetWithRulesCacheMap.set(ruleSetId, getRuleSet(ruleSetId));
  }
  return ruleSetWithRulesCacheMap.get(ruleSetId);
};

export function clearRuleSetCacheById(ruleSetId: string) {
  ruleSetWithRulesCacheMap.delete(ruleSetId);
}

export function clearRuleSetCache() {
  ruleSetWithRulesCacheMap.clear();
}
