export const IconBan = () => (
  <span className="pc-icon">
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.0347 13.7209C10.8944 14.5266 9.50246 15 8 15C4.13401 15 1 11.866 1 8C1 5.99606 1.84207 4.1888 3.19166 2.91275L12.0347 13.7209ZM12.8083 13.0873L3.96526 2.27908C5.10562 1.47336 6.49753 1 8 1C11.866 1 15 4.13401 15 8C15 10.0039 14.1579 11.8112 12.8083 13.0873Z"
      />
    </svg>
  </span>
);
